import React, { Component } from 'react';
import qs from 'qs';
import { toast } from 'react-toastify';

import firebase, { functions } from '../../firebase';

const db = firebase.firestore();
const usersRef = db.collection('users');
const invitationsRef = db.collection('invitations');
const confirmInvitation = functions.httpsCallable('confirmInvitation');

export default class Confirmation extends Component {
  componentDidMount() {
    this.confirm();
  }
  async confirm() {
    const { user, history } = this.props;
    const { token } = qs.parse(window.location.search.slice(1), { arrayLimit: Infinity });
    const ref = invitationsRef.doc(token);
    const invitationSnap = await ref.get();
    if (!invitationSnap.exists) return this.redirectToRoot('Invalid URL or expired');

    const { companyId, } = invitationSnap.data();
    if (user.companies && user.companies[companyId]) return this.redirectToRoot('すでに参加済みです');

    await confirmInvitation({ token });
    toast.success('参加しました');
    history.push(`/companies/${companyId}`);
  }
  redirectToRoot(errorMessage) {
    const { history } = this.props;
    toast.error(errorMessage);
    history.push('/');
  }
  render() {
    return <div className="confirmation" />;
  }
}
