import React, { Fragment, Component, useState, forwardRef, } from 'react';
import qs from 'qs';
import { Button, FormGroup, Label, Input, } from 'reactstrap';
import { groupBy, keyBy, last, isEqual, sortBy, omitBy, isUndefined, chunk, pick, mapValues, omit } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import Select from 'react-select';
import { TextEncoder, TextDecoder } from 'text-encoding';
import { unparse as unparseCsv, parse as parseCsv } from 'papaparse';
import fileDownload from 'js-file-download';
import { useToggle } from 'react-use';
import { Container, Draggable } from 'react-smooth-dnd';
import arrayMove from 'array-move';

import firebase from '../../firebase';
import { accountItemCategories, } from '../../shared/category';
import { serial, } from '../../shared/util';
import { accountCategories, journalTags, accountItemSettingCsvUrls, } from '../../shared/config';
import { mapKeysToJa, mapKeysFromJa, } from '../../shared/texts';
import { usedSegments } from '../../shared/models/company';
import { fields, checkTargets, } from '../../shared/models/accountItemSetting';
import { batch, getCollectionData, } from '../../shared/firebase';
import HelpLink from '../HelpLink';
import CompanyPage from '../hocs/CompanyPage';
import { canReadAccountItemAuditorSetting } from '../../abilities';
import { periodOfFiscalYear, readFile } from '../../utils';
import useQueryParams from '../hooks/useQueryParams';
import useScrollFocusByQuery from '../hooks/useScrollFocusByQuery';
import NumberInput from '../NumberInput';
import QuerySelector from '../QuerySelector';
import ModelFormModal from '../modals/ModelFormModal';
import EditButton from '../EditButton';
import ProgressButton from '../ProgressButton';
import ImportButton from '../ImportButton';
import ToTop from '../ToTop';

const db = firebase.firestore();
const companiesRef = db.collection('companies');
const { keys, entries } = Object;
const { isNaN } = Number;

function CompanyAccountItemSettings (props) {
  const { company, taxes, user, period, match: { params: { companyId } }, location, accountItemsGroupedByCategory, sortedAccountItems, accountItemSettingsById = {}, accountItemAuditorSettingsById = {}, accountItemSettings, accountItemAuditorSettings, } = props;
  const accountItemsByName = keyBy(sortedAccountItems, 'name');
  const queryParams = useQueryParams();
  const [targetPeriod, setTargetPeriod] = useState();
  const [isImporting, toggleImporting] = useToggle(false);
  const displayJournalTags = omit(journalTags, [...(!usedSegments(company).includes(1) ? ['segment1s'] : []), ...(!usedSegments(company).includes(2) ? ['segment2s', 'segment3s'] : [])]);
  const availableTaxes = sortBy(taxes.filter(_ => _.available), 'name');
  const { accountItemNames, } = queryParams;
  const rowGroups = accountItemCategories.map((category) => {
    const accountItems = accountItemsGroupedByCategory[category.name] || [];
    const rows = accountItems
      .filter(_ => _.available)
      .map(({ id, name: accountName }) => {
        const accountItemSetting = accountItemSettingsById[`${accountName.replace(/\//g, '_')}`];
        const accountItemAuditorSetting = accountItemAuditorSettingsById[`${accountName.replace(/\//g, '_')}__${period}`];
        return {
          accountName,
          ...(canReadAccountItemAuditorSetting(user) && { threshold: accountItemAuditorSetting?.threshold }),
          // NOTE: noteがまだない場合があるので、`pick(accountItemSetting, ['note', ...])`のようにできない。エクスポートの考慮。
          ...['journalsCheckDimensions', ...keys(displayJournalTags).map(_ => `${_}JournalCheckTargets`), 'defaultDebitTaxes', 'defaultCreditTaxes', 'note'].reduce((x, y) => ({ ...x, [y]: accountItemSetting?.[y] }), {}),
          accountItemSetting,
          accountItemAuditorSetting,
        };
      });
    let filteredRows = rows;
    if(queryParams.accountItemNames) {
      filteredRows = filteredRows.filter(_ => queryParams.accountItemNames.includes(_.accountName));
    }
    return {
      category,
      rows,
      filteredRows,
    };
  });
  const onClickExport = async () => {
    const encoder = new TextEncoder('Shift_JIS', { NONSTANDARD_allowLegacyEncoding: true });
    const fileContent = encoder.encode(unparseCsv(rowGroups.flatMap(_ => _.filteredRows).map(row => mapKeysToJa(({
      ...omit(row, ['accountItemSetting', 'accountItemAuditorSetting']),
      journalsCheckDimensions: keys(displayJournalTags).filter(_ => row.journalsCheckDimensions ? row.journalsCheckDimensions.includes(_) : true),
      ...keys(displayJournalTags).reduce((x, k) => {
        return {
          ...x,
          [`${k}JournalCheckTargets`]: row[`${k}JournalCheckTargets`] ? keys(checkTargets).filter(_ => row[`${k}JournalCheckTargets`].includes(_)) : keys(checkTargets),
        };
      }, {}),
      defaultDebitTaxes: (row.defaultDebitTaxes ? availableTaxes.map(_ => _.name_ja).filter(_ => row.defaultDebitTaxes.includes(_)) : availableTaxes.map(_ => _.name_ja)),
      defaultCreditTaxes: (row.defaultCreditTaxes ? availableTaxes.map(_ => _.name_ja).filter(_ => row.defaultCreditTaxes.includes(_)) : availableTaxes.map(_ => _.name_ja)),
    })))));
    fileDownload(fileContent, `科目設定_${period}.csv`);
  }
  const onSelectFiles = async ({ target, target: { files: [file] } }) => {
    if(!file) return;

    toggleImporting(true);
    await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          const decoder = new TextDecoder('Shift_JIS');
          const fileContent = decoder.decode(await readFile(file, 'readAsArrayBuffer'));
          const { data } = parseCsv(fileContent, { header: true });
          const rows = data.map(_ => mapKeysFromJa(_, { メモ: 'note' }));
          if(canReadAccountItemAuditorSetting(user)) {
            await chunk(rows.filter(_ => _.threshold >= 0), 500).reduce(async (x, rows) => {
              await x;
              const batch = db.batch();
              rows.forEach((row) => {
                const { accountName, threshold } = row;
                batch.set(companiesRef.doc(companyId).collection('accountItemAuditorSettings').doc(`${accountName.replace(/\//g, '_')}__${period}`), { accountName, threshold: parseInt(threshold, 10), period });
              });
              await batch.commit();
            }, Promise.resolve());
          }
          await chunk(rows, 500).reduce(async (x, rows) => {
            await x;
            const batch = db.batch();
            rows.filter(_ => _.accountName).forEach((row) => {
              const { accountName, note, journalsCheckDimensions, defaultDebitTaxes, defaultCreditTaxes, } = row;
              batch.set(companiesRef.doc(companyId).collection('accountItemSettings').doc(`${accountName.replace(/\//g, '_')}`), omitBy({
                accountName,
                period: null,
                note,
                journalsCheckDimensions: journalsCheckDimensions.split(',').filter(_ => _).filter(_ => keys(displayJournalTags).includes(_)),
                ...keys(journalTags).reduce((x, k) => {
                  return {
                    ...x,
                    [`${k}JournalCheckTargets`]: row[`${k}JournalCheckTargets`]?.split(',').filter(_ => _).filter(_ => keys(checkTargets).includes(_)),
                  };
                }, {}),
                defaultDebitTaxes: defaultDebitTaxes.split(',').filter(_ => _).filter(_ => availableTaxes.map(_ => _.name_ja).includes(_)),
                defaultCreditTaxes: defaultCreditTaxes.split(',').filter(_ => _).filter(_ => availableTaxes.map(_ => _.name_ja).includes(_)),
              }, isUndefined));
            });
            await batch.commit();
          }, Promise.resolve());
          toast.success('インポートしました');
        } catch(e) {
          console.error(e);
          toast.error('インポート失敗しました');
        }
        toggleImporting(false);
        target.value = '';
        resolve();
      }, 300);
    });
  }
  const taxesByCode = keyBy(taxes, 'code');
  const accountItemNameOptions = sortedAccountItems.map(_ => ({ label: _.name, value: _.name, }));
  const periodOptions = ((company || {}).fiscalYears || []).map(_ => ({ label: `${_.start_date.replace(/-/g, '/').slice(0, 7)} - ${_.end_date.replace(/-/g, '/').slice(0, 7)}`, value: periodOfFiscalYear(_), }));
  const onClickCopyFromDefault = async () => {
    if(!window.confirm('kansapoデフォルトに従った設定にします。よろしいですか？')) return;

    const [accountItemDataByName, categoryDataByName] = await Promise.all(entries(accountItemSettingCsvUrls).map(async ([type, url]) => {
      const csv = await fetch(url).then(_ => _.text());
      const { data: rows } = parseCsv(csv, { header: true });
      return keyBy(rows, ({ accountItem: '科目', category: 'カテゴリ' })[type]);
    }));
    const newData = sortedAccountItems
      .map(_ => ({ ..._, accountItemSetting: accountItemSettingsById[`${_.name.replace(/\//g, '_')}`] }))
      .filter(_ => _.accountItemSetting == null)
      .map((accountItem) => {
        const { name, } = accountItem;
        const accountCategoryName = accountItem.account_category || last(accountItem.categories);
        const accountItemData = accountItemDataByName[name];
        const categoryData = categoryDataByName[accountCategoryName];
        return {
          ref: company.ref.collection('accountItemSettings').doc(`${name.replace(/\//g, '_')}`),
          ...mapValues(pick(mapKeysFromJa(accountItemData ?? categoryData), ['journalsCheckDimensions', ...keys(journalTags).map(_ => `${_}JournalCheckTargets`), 'defaultDebitTaxes', 'defaultCreditTaxes']), (v, k) => v.split(',').filter(_ => _).map(_ => _ === 'default_tax_code' ? taxesByCode[accountItem.default_tax_code]?.name_ja : _)),
          accountName: name,
          period: null,
        };
      });
    await batch(db, newData, (batch, _) => batch.set(_.ref, omit(_, 'ref')));
    toast.success('kansapoデフォルトに従った設定にしました。');
  };
  const onClickClear = async () => {
    if(!window.confirm('本当に設定をクリアしますか？')) return;

    await batch(db, [...accountItemSettings], (batch, _) => batch.delete(_.ref));
    toast.success('設定をクリアしました');
  };
  const processOrderSettingImport = async (data) => {
    const rows = data.map(_ => mapKeysFromJa(_, { メモ: 'note' }));
    const rowsGroupedByCategory = groupBy(rows, _ => accountItemsByName[_.accountName]?.account_category);
    await batch(db, entries(rowsGroupedByCategory), (b, [category, rows]) => b.set(company.ref.collection('settings').doc('accountItemsOrder'), {
      [category]: rows.map(_ => _.accountName),
    }, { merge: true }));
  };

  return (
    <div className="company">
      <div className="container-fluid py-5">
        <div className="d-flex flex-column justify-content-center align-items-stretch">
          <div className="d-flex justify-content-end mb-3">
            <HelpLink text="科目設定を行う" />
          </div>
          <h3 className="text-center mb-4">科目設定</h3>
          <div className="d-flex justify-content-between flex-wrap mb-3 align-items-end">
            <div>
              <QuerySelector paramName="accountItemNames" width={300} isMulti options={accountItemNameOptions} label="勘定科目" />
            </div>
            <div className="d-flex flex-column gap-1">
              <div className="d-flex align-items-end justify-content-end gap-1">
                <ProgressButton color="primary" process={onClickCopyFromDefault}>
                  kansapoデフォルトに従う
                </ProgressButton>
                <ProgressButton color="danger" process={onClickClear}>
                  <span className="fas fa-eraser mr-1" />
                  設定をクリア
                </ProgressButton>
              </div>
              <div className="d-flex align-items-end justify-content-end">
                <ProgressButton icon={<span className="fas fa-download" />} color="secondary" process={onClickExport} className="ml-1">
                  エクスポート
                </ProgressButton>
                <Button color="secondary" className="ml-1" disabled={isImporting}> 
                  <label className="m-0 cursor-pointer">
                    <span className={classnames('fas mr-1', { 'fa-upload': !isImporting, 'fa-spin fa-spinner': isImporting})} />
                    インポート
                    <Input type="file" className="d-none" onChange={onSelectFiles} accept="text/*" />
                  </label>
                </Button>
                <ImportButton color="secondary" className="ml-1" label="並び替えインポート" onComplete={processOrderSettingImport} /> 
              </div>
            </div>
          </div>
          <div className="vertical-sticky-table" style={{ zIndex: 0 }}>
            {
              availableTaxes.length > 0 ? (
                <table className="table table-hover table-bordered">
                  <thead className="thead-light text-center">
                    <tr>
                      <th></th>
                      <th>勘定科目名</th>
                      {
                        canReadAccountItemAuditorSetting(user) && (
                          <th>基準値</th>
                        )
                      }
                      <th>チェックタグ</th>
                      <th>借方デフォルト税区分</th>
                      <th>貸方デフォルト税区分</th>
                      <th>メモ</th>
                      <th></th>
                    </tr>
                  </thead>
                  {
                    rowGroups.map((rowGroup) => {
                      const { category, filteredRows } = rowGroup;
                      return (
                        <RowGroup key={category.name} {...{ company, user, period, displayJournalTags, availableTaxes, category, rows: filteredRows, }} />
                      );
                    })
                  }
                </table>
              ) : (
                <div className="text-center">
                  データはまだありません。
                </div>
              )
            }
          </div>
        </div>
      </div>
      <ToTop />
    </div>
  );
};

function RowGroup (props) {
  const { company, user, period, displayJournalTags, availableTaxes, category, rows, } = props;
  const [isDragging, toggleDragging] = useToggle(false);
  const onDrop = async ({ addedIndex, removedIndex }) => {
    const newAccountNames = arrayMove(rows, removedIndex, addedIndex).map(_ => _.accountName);
    await company.ref.collection('settings').doc('accountItemsOrder').set({
      [category.name]: newAccountNames,
    }, { merge: true });
  };
                          {
                          }

  return (
    <Container
      dragHandleSelector=".drag-handle"
      onDrop={onDrop}
      onDragStart={_ => toggleDragging(true)}
      onDragEnd={_ => toggleDragging(false)}
      dropPlaceholder={{ style: { background: 'eee', } }}
      render={(ref) => (
        <tbody ref={ref} className="thead-light border-3">
          {
            rows.map((row) => {
              const { accountName } = row;
              return (
                <Draggable
                  key={accountName}
                  render={() => (
                    <tr style={{ display: !isDragging && 'table-row', }}>
                      <Row {...{ row, company, period, displayJournalTags, availableTaxes, user, }} />
                    </tr>
                  )}
                />
              );
            })
          }
        </tbody>
      )}
    />
  );
};

const Row = React.memo(function (props) {
  const { row, period, company, displayJournalTags, availableTaxes, user, } = props;
  const { accountName, accountItemSetting, accountItemAuditorSetting,  } = row;
  const [state, setState] = useState({});
  const { threshold: _threshold, } = state;
  const threshold = (_threshold != null ? _threshold : row.threshold) || '';
  const queryParams = useQueryParams();
  const { elm, focusColor, } = useScrollFocusByQuery(_ => _.queryParams.accountName === accountName);
  const onChange = async (isForAuditor = false, type, accountName, field, _value) => {
    const value = parseFloat(_value, 10);
    setState({ [field]: value });
    if(isNaN(value)) return;
    await company.ref
      .collection('accountItemAuditorSettings')
      .doc(`${accountName.replace(/\//g, '_')}__${period}`)
      .set({ ...omit(accountItemAuditorSetting, 'id'), accountName, [field]: value, period });
  }
  const accountItemSettingRef = company.ref.collection('accountItemSettings').doc(`${accountName.replace(/\//g, '_')}`);
  const journalsCheckDimensions = keys(displayJournalTags).filter(_ => row.journalsCheckDimensions ? row.journalsCheckDimensions.includes(_) : true);
  const defaultDebitTaxes = (row.defaultDebitTaxes ? availableTaxes.map(_ => _.name_ja).filter(_ => row.defaultDebitTaxes.includes(_)) : availableTaxes.map(_ => _.name_ja));
  const defaultCreditTaxes = (row.defaultCreditTaxes ? availableTaxes.map(_ => _.name_ja).filter(_ => row.defaultCreditTaxes.includes(_)) : availableTaxes.map(_ => _.name_ja));

  return (
    <Fragment>
      <td ref={elm} style={{ width: 50, }}>
        <div className="drag-handle text-muted cursor-pointer px-2 py-1">
          <span className="fas fa-grip-vertical" />
        </div>
      </td>
      <td style={{ minWidth: 300 }} id={`row-${accountName}`} style={{ backgroundColor: focusColor, }}>
        {accountName}
      </td>
      {
        canReadAccountItemAuditorSetting(user) && (
          <td>
            <NumberInput value={threshold} onChange={onChange.bind(null, true, 'number', accountName, 'threshold')} />
          </td>
        )
      }
      <td>
        {
          journalsCheckDimensions.map((dimension) => {
            const checkTargetKeys = row[`${dimension}JournalCheckTargets`] ? keys(checkTargets).filter(_ => row[`${dimension}JournalCheckTargets`].includes(_)) : keys(checkTargets);
            return (
              <div key={dimension}>
                {journalTags[dimension]}
                <span className="ml-1 small text-muted">
                  &lt;{checkTargetKeys.map(_ => checkTargets[_]?.label).join(', ')}&gt;
                </span>
              </div>
            );
          })
        }
      </td>
      <td>
        {
          defaultDebitTaxes.map((tax) => {
            return (
              <div key={tax}>
                {tax}
              </div>
            );
          })
        }
      </td>
      <td>
        {
          defaultCreditTaxes.map((tax) => {
            return (
              <div key={tax}>
                {tax}
              </div>
            );
          })
        }
      </td>
      <td style={{ whiteSpace: 'pre-line' }}>
        {row.note}
      </td>
      <td>
        <EditButton itemRef={accountItemSettingRef} FormModal={ModelFormModal} processValues={_ => ({ ..._, accountName, period: null, })} formProps={{ title: '科目設定 編集', values: { ...accountItemSetting, }, fields: fields({ displayJournalTags, availableTaxes, }), }} usesSet />
      </td>
    </Fragment>
  );
}, isEqual);

CompanyAccountItemSettings.MonthSelectDisabled = true;

export default CompanyPage(CompanyAccountItemSettings);
