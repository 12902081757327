import React, { Component, Fragment, useState, useEffect, useMemo, } from 'react';
import { Table, Button, } from 'reactstrap';
import numeral from 'numeral';
import { chunk, uniqBy, orderBy, uniq, isEqual, get, sumBy, isEmpty, isNumber as _isNumber, groupBy, sortBy, keyBy, range, debounce, pick, } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { format as formatDate, startOfMonth, endOfMonth, addYears, addMonths, } from 'date-fns';
import { Link } from 'react-router-dom';
import fileDownload from 'js-file-download';
import { unparse as unparseCsv, } from 'papaparse';
import { TextEncoder, } from 'text-encoding';
import qs from 'qs';
import Select from 'react-select';
import { useHistory, useLocation } from 'react-router';

import firebase from '../../firebase';
import { canWriteNote } from '../../abilities';
import { dimensions, breakdownItemsLimitUnit, } from '../../shared/config';
import { getCollectionData, } from '../../shared/firebase';
import HelpLink from '../HelpLink';
import useQueryParams from '../hooks/useQueryParams';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionsFetch from '../hooks/useCollectionsFetch';
import CompanyPage from '../hocs/CompanyPage';
import AccountItemDisplay from '../AccountItemDisplay';
import OverlayLoading from '../OverlayLoading';
import CommentsDropdown from '../CommentsDropdown';
import HoveredCommenter from '../HoveredCommenter';
import QuerySelector from '../QuerySelector';
import HoveredNoter from '../HoveredNoter';
import { log, startOfMonthByFiscalYears, existsInFiscalYears, fiscalYearOfPeriod, fullPathWithParams, pickSearch, trialItemsToRowItems, computeSectionTrialAmount, computeSectionBudget, } from '../../utils';
import env from '../../env';
import './CompanyPeriodComparison.scss';

const db = firebase.firestore();
const auth = firebase.auth();
const companiesRef = db.collection('companies');
const { keys, entries, } = Object;
const { abs } = Math;
const isFiniteNumber = _ => _isNumber(_) && isFinite(_);
const COLUMN_WIDTH = 150;
const AMOUNT_HEIGHT = '24px';
const metrics = {
  budgetAmount: { label: '予算' },
  amount: { label: '当期実績' },
  budgetDiff: { label: '差額' },
  achievementRate: { label: '達成率' },
  estimatedAmount: { label: '着地見込' },
  prevYearAmount: { label: '前期実績' },
  prevYearDiff: { label: '増減額' },
  prevYearDiffRate: { label: '増減率' },
};
const metricOptions = entries(metrics).map(([k, v]) => ({ label: v.label, value: k }));
const dimensionOptions = entries(dimensions).filter(_ => _[0] !== 'sections').map(([k, v]) => ({ label: v, value: k }));
const aggregationTypes = {
  cumulative: { label: '累計', },
  single: { label: '単月', },
};
const aggregationTypeOptions = entries(aggregationTypes).map(([k, v]) => ({ label: v.label, value: k }));

export class CompanySectionsBudgets extends Component {
  constructor() {
    super();
    this.state = {
      isReady: false,
    };
  }
  async componentDidMount() {
    await new Promise(_ => setTimeout(_, 100));
    this.listenComments();
    this.listenNotes();
    this.listenSectionTrials();
  }
  componentWillUnmount() {
    this.unlistenSectionTrials && this.unlistenSectionTrials();
    this.unlistenComments && this.unlistenComments();
    this.unlistenNotes && this.unlistenNotes();
  }
  componentDidUpdate(prevProps, prevState) {
    if(
      ['period', 'targetMonth'].some(_ => prevProps[_] !== this.props[_])
      || ['dimension'].some(_ => this.queryParams(prevProps)[_] !== this.queryParams()[_])
    ) {
      this.unlistenSectionTrials && this.unlistenSectionTrials();
      this.listenSectionTrials();
      this.setState({ isReady: false });
    }
    if(
      ['sectionBudgets'].some(_ => prevProps[_] !== this.props[_])
      || ['comments', 'sectionTrials', 'currentYearTrial', 'prevYearTrial'].some(_ => prevState[_] !== this.state[_])
      || ['aggregationType', 'customSectionIds'].some(_ => !isEqual(this.queryParams(prevProps)[_], this.queryParams()[_]))
    ) {
      this.setState({ isReady: false });
      this.setRowsDebounced();
    }
  }
  queryParams(props) {
    const { location: { search } } = props || this.props;
    return qs.parse(decodeURI(search.slice(1)), { arrayLimit: Infinity });
  }
  filteredRows = (rows) => {
    let filteredRows = rows || this.state.rows || [];
    return entries(groupBy(filteredRows, 'itemKey'))
      .reduce((x, [itemKey, [mainRow, ...subRows]]) => {
        const showsSubRow = this.state[`shouldShowSubRows__${itemKey}`];
        return [
          ...x,
          mainRow,
          ...subRows.map((row, i) => {
            return {
              ...row,
              breakDownItemIndex: i,
              subItemsCount: subRows.length,
              shows: showsSubRow,
            };
          })
        ];
      }, []);
  }
  months() {
    const { period, company: { fiscalYears } } = this.props;
    const { start_date: startDate, } = fiscalYearOfPeriod(period, fiscalYears);
    return [
      range(-12, 0).map(_ => endOfMonth(addMonths(startDate, _))),
      range(0, 12).map(_ => endOfMonth(addMonths(startDate, _))),
    ];
  }
  isWithinTargetMonth = (date) => {
    const { targetMonth } = this.props;
    const { aggregationType = 'cumulative', } = this.queryParams();
    return ({
      cumulative: date <= endOfMonth(targetMonth.toString().replace(/\d{2}$/, _ => '-' + _)),
      single: startOfMonth(targetMonth.toString().replace(/\d{2}$/, _ => '-' + _)) <= date && date <= endOfMonth(targetMonth.toString().replace(/\d{2}$/, _ => '-' + _)),
    })[aggregationType];
  }
  listenComments = debounce(() => {
    const { match: { params: { companyId, } } } = this.props;
    this.unlistenComments = companiesRef
      .doc(companyId)
      .collection('comments')
      .where('queryKey', '==', 'sectionBudgets')
      .orderBy('createdAt')
      .onSnapshot(({ docs }) => {
        const comments = docs.map(_ => ({ id: _.id, ..._.data() }));
        this.setState({ comments, commentsGroupedByCommenteeKey: groupBy(comments, 'commenteeKey') });
      });
  }, 300)
  listenNotes() {
    const { match: { params: { companyId, } } } = this.props;
    this.unlistenNotes = companiesRef
      .doc(companyId)
      .collection('notes')
      .where('queryKey', '==', 'sectionBudgets')
      .onSnapshot(({ docs }) => {
        const notes = docs.map(_ => ({ id: _.id, ..._.data() }));
        this.setState({ notes, notesByKey: keyBy(notes, 'noteKey') });
      });
  }
  listenSectionTrials = debounce(() => {
    const { match: { params: { companyId } } } = this.props;
    const { dimension, } = this.queryParams();
    if(dimension == null) return;

    const months = this.months();
    const [prevYearFirstEndDate] = months[0];
    const [lastEndDate] = months[1].slice(-1);
    this.unlistenSectionTrials = companiesRef
      .doc(companyId)
      .collection('sectionTrials')
      .where('dimension', '==', dimension)
      .where('closingDate', '>=', formatDate(prevYearFirstEndDate, 'YYYY-MM-DD'))
      .where('closingDate', '<=', formatDate(lastEndDate, 'YYYY-MM-DD'))
      .onSnapshot(debounce(({ docs }) => {
        const sectionTrials = docs.map(_ => _.data());
        const sectionTrialsGroupedByClosingDate = groupBy(sectionTrials, _ => formatDate(_.closingDate, 'YYYYMM'));
        const sectionTrialsGroupedByItemKey = groupBy(sectionTrials, 'itemKey');
        this.setState({ sectionTrials, sectionTrialsGroupedByClosingDate, sectionTrialsGroupedByItemKey, });
      }, 300));
  }, 300)
  setRowsDebounced = debounce(() => {
    const rows = this.generateRows();
    this.setState({ rows, isReady: true });
  }, 500)
  monthItems(options = {}) {
    const { sectionTrialsGroupedByClosingDate = {}, } = this.state;
    const sectionBudgetsGroupedByClosingDate = options.sectionBudgetsGroupedByClosingDate || this.props.sectionBudgetsGroupedByClosingDate || {};
    const { dimension, } = this.queryParams();
    return this.months().map((months) => {
      return months.map((closingDate) => {
        const balances = sectionTrialsGroupedByClosingDate[formatDate(closingDate, 'YYYYMM')] || [];
        const budgets = sectionBudgetsGroupedByClosingDate[formatDate(closingDate, 'YYYYMM')] || [];
        const exists = !isEmpty(balances);
        return {
          closingDate,
          exists,
          balances,
          balancesByItemKey: keyBy(balances, 'itemKey'),
          budgets,
          budgetsGroupedByItemKey: groupBy(budgets, 'itemKey'),
          dimension,
        };
      })
    });
  }
  generateRows = ({ isAll = false, sectionBudgetsGroupedItemKey, sectionBudgetsGroupedByClosingDate, } = {}) => {
    const { targetMonth, company, accountItems = [], sections = [], } = this.props;
    const customAccountItems = this.relatedCustomAccountItems();
    const { notesByKey, } = this.state;
    const { dimension, } = this.queryParams();
    const [prevYearMonths, currentYearMonths] = this.months();
    const sectionsById = keyBy(sections, 'id');
    const [prevYearMonthItems, currentYearMonthItems] = this.monthItems({ sectionBudgetsGroupedByClosingDate });
    const prevYearMonthItemsByMonth = keyBy(prevYearMonthItems, _ => formatDate(_.closingDate, 'MM'));
    const allItems = [...currentYearMonthItems, ...prevYearMonthItems];
    const items = trialItemsToRowItems(allItems, { targetMonth, accountItems, customAccountItems, screenType: 'sectionsBudgets', documentType: 'pl', itemType: 'pl', dimension, sectionTrials: true, })
    const rows = items
      .map(this.itemToRow.bind(this, { isAll, sectionBudgetsGroupedItemKey, company, customAccountItems, notesByKey, sectionsById, }))
      .filter(_ => _.customSectionColumns.some(_ => abs(_.amount) > 0 || abs(_.budgetAmount) > 0 || abs(_.prevYearAmount) > 0));
    return Object.values(groupBy(rows, 'mainRowItemKey')).map(([mainItem, ...subItems]) => {
      return [mainItem, ...orderBy(subItems, _ => sumBy(_.customSectionColumns, 'amount'), 'desc')];
    }).flat();
  }
  sortedCustomSections = () => {
    const { writableCustomSections = [], } = this.props;
    return sortBy(writableCustomSections, _ => _.index != null ? _.index : writableCustomSections.indexOf(_));
  }
  filteredCustomSections = () => {
    const { writableCustomSections = [], } = this.props;
    const customSectionsById = keyBy(writableCustomSections, 'id');
    const { customSectionIds = [], } = this.queryParams();
    return customSectionIds.map(_ => customSectionsById[_]).filter(_ => _);
  }
  relatedCustomAccountItems = () => {
    const { customAccountItems } = this.props;
    const { dimension, } = this.queryParams();
    return customAccountItems.filter(_ => (_.dimension || 'none') === 'none' || _.dimension === dimension);
  }
  itemToRow = (options, item) => {
    const { isAll, company, notesByKey = {}, sectionsById, } = options;
    const { writableCustomSections, targetMonth, sections, } = this.props;
    const { sectionTrialsGroupedByItemKey = {}, } = this.state;
    const { isCustom = false, isManual = false, itemName, itemKey, isSubRow, subItemName, hierarchyLevel, accountItemName, displayExpression = '0,0', } = item;
    const customAccountItems = this.relatedCustomAccountItems();
    const sectionBudgetsGroupedItemKey = options.sectionBudgetsGroupedItemKey || this.props.sectionBudgetsGroupedItemKey || {};
    const { currentYearTrial, prevYearTrial } = this.state;
    const { dimension = 'none', aggregationType, } = this.queryParams();
    const [prevYearMonths, currentYearMonths] = this.months();
    const targetRangeMonths = currentYearMonths.filter(_ => this.isWithinTargetMonth(_));
    const prevYearTargetRangeMonths = targetRangeMonths.map(_ => addMonths(_, -12));
    const outRangeMonths = currentYearMonths.filter(_ => !this.isWithinTargetMonth(_));
    const isCategory = isEmpty(accountItemName);
    const rowKey = (isCustom ? 'cus__' : '') + (isCategory ? 'cat__' : '') + ((dimension !== 'none' && isSubRow) ? `${itemName}__${dimension}__${subItemName}` : itemName);
    const mainRowItemKey = isCustom ? itemKey : item.mainRowItemKey;
    const rowName = subItemName || itemName;
    const targetCustomSections = isAll ? writableCustomSections : this.filteredCustomSections();
    const usedSectionIds = writableCustomSections.map(_ => _.sectionIds).flat();

    const customSectionColumns = targetCustomSections.map((customSection, i) => {
      const sections = customSection.sectionIds.map(_ => sectionsById[_]).filter(_ => _);
      const key = `${rowKey}_${customSection.id}`;
      const commenteeKey = `${key}_${targetMonth}`;
      const noteKey = `${key}_${targetMonth}`;
      const amount = computeSectionTrialAmount(item, sectionTrialsGroupedByItemKey, dimension, targetRangeMonths, customAccountItems, customSection.sectionIds, [customSection.id]);
      const budgetAmount = computeSectionBudget(item, sectionBudgetsGroupedItemKey, dimension, targetRangeMonths, customAccountItems, [customSection.id]);
      const outRangeBudgetAmount = computeSectionBudget(item, sectionBudgetsGroupedItemKey, dimension, outRangeMonths, customAccountItems, [customSection.id]);
      const budgetDiff = amount - budgetAmount;
      const achievementRate = amount / budgetAmount;
      const estimatedAmount = ({
        cumulative: _ => amount + outRangeBudgetAmount,
        single: _ => amount,
      })[aggregationType]();
      const prevYearAmount = computeSectionTrialAmount(item, sectionTrialsGroupedByItemKey, dimension, prevYearTargetRangeMonths, customAccountItems, customSection.sectionIds, [customSection.id]);
      const prevYearDiff = amount - prevYearAmount;
      const prevYearDiffRate = prevYearDiff / prevYearAmount;
      const note = (notesByKey[noteKey] || {}).value;
      return { key, commenteeKey, noteKey, customSection, amount, budgetAmount, budgetDiff, achievementRate, estimatedAmount, prevYearAmount, prevYearDiff, prevYearDiffRate, note, sections, };
    });

    const totalAmount = sumBy(customSectionColumns, 'amount');
    const totalUsedSectionsAmount = computeSectionTrialAmount(item, sectionTrialsGroupedByItemKey, dimension, targetRangeMonths, customAccountItems, usedSectionIds, false);
    const totalUsedSectionsPrevYearAmount = computeSectionTrialAmount(item, sectionTrialsGroupedByItemKey, dimension, prevYearTargetRangeMonths, customAccountItems, usedSectionIds, false);
    const totalBudgetAmount = sumBy(customSectionColumns, 'budgetAmount');
    const totalPrevYearAmount = sumBy(customSectionColumns, 'prevYearAmount');
    const trialAmount = get(currentYearTrial, ['balancesByItemKey', itemKey, 'closing_balance']);
    const prevYearTrialAmount = get(prevYearTrial, ['balancesByItemKey', itemKey, 'closing_balance']);
    const diff = totalUsedSectionsAmount - trialAmount;
    const prevYearDiff = totalUsedSectionsPrevYearAmount - prevYearTrialAmount;
    const summaryColumns = [
      {
        isSummaryColumn: true,
        name: 'sum',
        key: `${rowKey}_sum`,
        amount: totalAmount,
        budgetAmount: totalBudgetAmount,
        budgetDiff: totalAmount - totalBudgetAmount,
        achievementRate: totalAmount / totalBudgetAmount,
        estimatedAmount: sumBy(customSectionColumns, 'estimatedAmount'),
        prevYearAmount: totalPrevYearAmount,
        prevYearDiff: totalAmount - totalPrevYearAmount,
        prevYearDiffRate: (totalAmount - totalPrevYearAmount) / totalPrevYearAmount,
      },
      {
        isSummaryColumn: true,
        name: 'uniqSum',
        key: `${rowKey}_uniqSum`,
        amount: totalUsedSectionsAmount,
        budgetAmount: NaN,
        budgetDiff: NaN,
        achievementRate: NaN,
        estimatedAmount: NaN,
        prevYearAmount: totalUsedSectionsPrevYearAmount,
        prevYearDiff: totalUsedSectionsAmount - totalUsedSectionsPrevYearAmount,
        prevYearDiffRate: (totalUsedSectionsAmount - totalUsedSectionsPrevYearAmount) / totalUsedSectionsPrevYearAmount,
      },
      {
        isSummaryColumn: true,
        name: 'trial',
        key: `${rowKey}_trial`,
        amount: trialAmount,
        budgetAmount: NaN,
        budgetDiff: NaN,
        achievementRate: NaN,
        estimatedAmount: NaN,
        prevYearAmount: prevYearTrialAmount,
        prevYearDiff: trialAmount - prevYearTrialAmount,
        prevYearDiffRate: (trialAmount - prevYearTrialAmount) / prevYearTrialAmount,
      },
      {
        isSummaryColumn: true,
        name: 'diff',
        key: `${rowKey}_diff`,
        amount: diff,
        budgetAmount: NaN,
        budgetDiff: NaN,
        achievementRate: NaN,
        estimatedAmount: NaN,
        prevYearAmount: prevYearDiff,
        prevYearDiff: NaN,
        prevYearDiffRate: NaN,
      },
    ];
    return { hierarchyLevel, isCategory, isCustom, itemName, itemKey, mainRowItemKey, key: rowKey, subItemName, isSubRow, rowName, customSectionColumns, summaryColumns, displayExpression, diff, };
  }
  onSelect = (name, { value }) => {
    const { history, location } = this.props;
    const path = fullPathWithParams({ [name]: value }, location);
    history.replace(encodeURI(path));
  }
  onClickComment = (commenteeKey) => {
    const { [`hovered_commenter__${commenteeKey}`]: commenter } = this;
    if(commenter) {
      commenter.open();
      commenter.focus();
    }
  }
  toggleSubRows(itemKey) {
    this.setState({ [`shouldShowSubRows__${itemKey}`]: !this.state[`shouldShowSubRows__${itemKey}`] });
    this.setRowsDebounced();
  }
  showMoreSubRows(itemKey) {
    this.setState({ [`breakdownItemsLimitRate_${itemKey}`]: (this.state[`breakdownItemsLimitRate_${itemKey}`] || 1) + 1 });
    this.setRowsDebounced();
  }
  renderRow({ hierarchyLevel = 1, key, isCategory, isCustom, itemName, itemKey, subItemName, isSubRow, rowName, customSectionColumns, summaryColumns, diff, displayExpression, breakDownItemIndex, subItemsCount, }) {
    const { role, targetMonth, accountItemSettingsById, user, members, period, match: { params: { companyId } } } = this.props;
    const { commentsGroupedByCommenteeKey = {}, notesByKey = {} } = this.state;
    const { dimension, metrics: metricsForFilter = keys(metrics), } = this.queryParams();
    const allColumns = [...customSectionColumns, ...summaryColumns];
    const shouldShowSubRows = this.state[`shouldShowSubRows__${itemKey}`];
    const accountItemSetting = accountItemSettingsById[`${rowName.replace(/\//g, '_')}`];
    const breakdownItemsLimitRate = this.state[`breakdownItemsLimitRate_${itemKey}`] || 1;
    const showingCount = breakdownItemsLimitUnit * breakdownItemsLimitRate;
    const shouldHide = isSubRow && (breakDownItemIndex + 1) > showingCount;
    if(shouldHide) return null;
    const isLastSubRow = (breakDownItemIndex + 1) === showingCount;
    const leftSubItemsCount = subItemsCount - showingCount;
    return (
      <tr key={itemKey + key} className={classnames({ 'sub-row': isSubRow })}>
        <th className="border-right" style={{ width: COLUMN_WIDTH * 2.5, textIndent: `${hierarchyLevel - 1}rem`, fontWeight: isCategory ? 700 : 400 }} className={classnames({ 'table-danger': !isSubRow && abs(diff) > 0 })} colSpan={5 - hierarchyLevel}>
          <div className="d-flex justify-content-between align-items-start">
            <div>
              {
                dimension !== 'none' && !isSubRow && !isCategory && !isCustom && (
                  <Button size="sm" className="small px-0 mr-1" color="link" onClick={this.toggleSubRows.bind(this, itemKey)}>
                    <span className={classnames('fas cursor-pointer', { 'fa-plus': !shouldShowSubRows, 'fa-minus': shouldShowSubRows })} />
                  </Button>
                )
              }
              <AccountItemDisplay accountItemName={rowName} accountItemSetting={accountItemSetting} iconStyles={{ textIndent: 0 }} isCustom={isCustom} />
              {
                isLastSubRow && leftSubItemsCount > 0 && (
                  <div className="mt-2">
                    <Button size="sm" onClick={this.showMoreSubRows.bind(this, itemKey)}>
                      もっと見る (残り{leftSubItemsCount}件)
                    </Button>
                  </div>
                )
              }
            </div>
            <div className="text-nowrap">
              {
                metricsForFilter.map((metric) => {
                  const { label } = metrics[metric];
                  return (
                    <div key={metric} style={{ lineHeight: AMOUNT_HEIGHT }}>
                      {label}
                    </div>
                  );
                })
              }
            </div>
          </div>
        </th>
        {
          allColumns.map((column, index) => {
            const { key, } = column;
            return (
              <Column
                key={key}
                _key={key}
                commenterRef={(key, el) => this[`hovered_commenter__${key}`] = el}
                {...{ ...column, role, metricsForFilter, index, isCategory, isCustom, user, members, isSubRow, itemName, dimension, subItemName, period, companyId, commentsGroupedByCommenteeKey, notesByKey, displayExpression, yearMonth: targetMonth, }}
              />
            );
          })
        }
      </tr>
    );
  }
  onClickExport = async () => {
    const { user, company, period, targetMonth, uniqSectionBudgetContainers, } = this.props;
    this.setState({ isExporting: true });
    const encoder = new TextEncoder('Shift_JIS', { NONSTANDARD_allowLegacyEncoding: true });
    const sectionBudgets = (await Promise.all(chunk(uniqSectionBudgetContainers.map(_ => _.id), 10).map(_ => getCollectionData(company.ref.collection('sectionBudgets').where('sectionBudgetContainerId', 'in', _))))).flat();
    const sectionBudgetsGroupedByClosingDate = groupBy(sectionBudgets, _ => formatDate(_.closingDate, 'YYYYMM'));
    const sectionBudgetsGroupedItemKey = groupBy(sectionBudgets, 'itemKey');
    const rows = this.generateRows({ isAll: true, sectionBudgetsGroupedByClosingDate, sectionBudgetsGroupedItemKey, });
    const filteredRows = this.filteredRows(rows);
    const data = filteredRows.map((row) => {
      return ['amount', 'budgetAmount', 'budgetDiff', 'achievementRate', 'estimatedAmount', 'prevYearAmount', 'prevYearDiff', 'prevYearDiffRate', 'note'].map((rowType) => {
        return {
          ...pick(row, ['itemName', 'subItemName']),
          rowType,
          ...(
            row.customSectionColumns.reduce((x, y) => {
              const { customSection } = y;
              return {
                ...x,
                [customSection.name]: y[rowType],
              };
            }, {})
          ),
          ...(
            row.summaryColumns.reduce((x, y) => {
              const { name } = y;
              return {
                ...x,
                [name]: y[rowType],
              };
            }, {})
          ),
        };
      });
    }).reduce((x, y) => [...x, ...y], []);
    const fileContent = encoder.encode(unparseCsv(data));
    fileDownload(fileContent, `部門別予実_${targetMonth}.csv`);
    await log(company, 'sectionsBudgets', 'exportCsv', user, { period, targetMonth, });
    this.setState({ isExporting: false });
  }
  fetchLastTrials = debounce(async () => {
    const { targetMonth } = this.queryParams();
    this.setState({ isFetchingLastTrials: true });
    const [currentYearTrial, prevYearTrial] = await this.fetch(this.months()[1].filter(_ => !this.isOverMonth(_)).slice(-1)[0]);
    if(this.queryParams().targetMonth !== targetMonth) return;
    this.setState({ currentYearTrial, prevYearTrial, isFetchingLastTrials: false });
  }, 100)
  isOverMonth = (date) => {
    const { targetMonth } = this.props;
    return date > endOfMonth(targetMonth.toString().replace(/\d{2}$/, _ => '-' + _));
  }
  async fetch(closingDate) {
    const { company: { sourceId: companySourceId, }, company, match: { params: { companyId } } } = this.props;
    const token = await auth.currentUser.getIdToken(true);
    const prevYearClosingDate = endOfMonth(addYears(closingDate, -1));
    try {
      return await Promise.all([closingDate, prevYearClosingDate].filter(_ => existsInFiscalYears(_, company)).map(async (closingDate) => {
        const url = `${env('CLOUD_FUNCTIONS_ENDPOINT')}/fetchFreeeTrial?${qs.stringify({ type: 'pl', companyId, companySourceId, startDate: formatDate(startOfMonthByFiscalYears(closingDate, company), 'YYYY-MM-DD'), endDate: formatDate(closingDate, 'YYYY-MM-DD'), dimension: 'none', })}`;
        const headers = { 'Firebase-User-Token': token };
        const res = await fetch(url, { headers });
        if(res.status !== 200) {
          const { errors } = await res.json();
          throw new Error(errors.join('\n'));
        }
        const balances = await res.json() || [];
        return { closingDate, balances, balancesByItemKey: keyBy(balances, 'itemKey') };
      }));
    } catch(e) {
      toast.error(e.message);
      console.error(e);
    }
  }
  render() {
    const { user, company, targetMonth, members = [], history, location, match: { params: { companyId } }, location: { search } } = this.props;
    const { isFetchingLastTrials = false, isSyncing = false, comments = [], isReady = false, isExporting = false, } = this.state;
    const { dimension, customSectionIds, } = this.queryParams();
    const pickedSearch = pickSearch(search, ['period', 'targetMonth']);
    const filteredRows = this.filteredRows();
    const customSectionOptions = this.sortedCustomSections().map(_ => ({ label: _.name, value: _.id }));

    return (
      <div className="company-period-comparison">
        <div className="px-3 py-3">
          <div className="d-flex justify-content-end mb-2">
            <HelpLink text="部門別予実管理を行う" />
          </div>
          <div className="mb-2">
            <h3 className="m-0 text-center">部門別予実</h3>
          </div>
          <div className="py-2">
            <div className="mb-3 d-flex justify-content-between align-items-end">
              <div>
                <div className="d-flex align-items-end flex-wrap">
                  <QuerySelector paramName="customSectionIds" width={500} isMulti options={customSectionOptions} label="カスタム部門" {...{ history, location }} />
                  {
                    !isEmpty(customSectionIds) && (
                      <PrevSelectedCustomSectionsButton className="ml-2" user={user} company={company} customSections={this.sortedCustomSections()} />
                    )
                  }
                </div>
                <div className="mt-2 d-flex align-items-center flex-wrap">
                  <QuerySelector paramName="metrics" width={300} isMulti options={metricOptions} label="数値項目" {...{ history, location }} />
                  <QuerySelector className="ml-2" paramName="dimension" width={150} options={dimensionOptions} label="明細" {...{ history, location }} selectorProps={{ isClearable: false }} />
                  <QuerySelector className="ml-2" paramName="aggregationType" width={150} options={aggregationTypeOptions} label="集計種別" {...{ history, location }} selectorProps={{ isClearable: false }} />
                </div>
              </div>
              <div className="d-flex align-items-center">
                <Button color="secondary" size="sm" onClick={this.fetchLastTrials} disabled={isFetchingLastTrials} className="ml-2">
                  網羅性チェック
                </Button>
                <Button color="secondary" onClick={this.onClickExport} disabled={isExporting} className="ml-2">
                  <span className={classnames('fas', { 'fa-spin fa-spinner': isExporting, 'fa-download': !isExporting })} />
                </Button>
                <CommentsDropdown className="ml-2" companyId={companyId} currentUser={user} comments={comments.filter(_ => _.yearMonth === targetMonth)} usersById={keyBy(members, 'id')} onClickComment={this.onClickComment} />
              </div>
            </div>
            <div className="d-flex justify-content-center position-relative" style={{ zIndex: 0 }}>
              <Table className="sticky-table table-hover" style={{ width: 'auto' }}>
                <thead className="text-center" style={{ lineHeight: '20px' }}>
                  <tr>
                    <th className="border-right" style={{ width: COLUMN_WIDTH * 2.5, height: 70, fontSize: '80%', }}>&nbsp;</th>
                    {
                      this.filteredCustomSections().map(({ id, name, }) => {
                        return (
                          <th key={id} style={{ width: COLUMN_WIDTH, height: 70, }}>
                            <Link to={`/companies/${companyId}/sectionBudgets${pickedSearch}&customSectionId=${id}`} style={{ height: '100%', overflow: 'hidden', display: 'block', }}>
                              {name}
                            </Link>
                          </th>
                        );
                      })
                    }
                    <th style={{ width: COLUMN_WIDTH, height: 70 }}>合計</th>
                    <th style={{ width: COLUMN_WIDTH, height: 70, fontSize: '11px' }}>カスタム部門に使用しているfreee部門の合計</th>
                    <th style={{ width: COLUMN_WIDTH, height: 70 }}>試算表残高</th>
                    <th style={{ width: COLUMN_WIDTH, height: 70 }}>差額</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredRows.filter(_ => !_.isSubRow || _.shows).map((row) => {
                      return this.renderRow(row);
                    })
                  }
                </tbody>
              </Table>
              <OverlayLoading isOpen={!isReady} />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

class Column extends Component {
  shouldComponentUpdate(nextProps) {
    const watchedPropNames = [
      '_key',
      'commenteeKey',
      'metricsForFilter',
      'amount',
      'budgetAmount',
      'budgetDiff',
      'achievementRate',
      'estimatedAmount',
      'prevYearAmount',
      'prevYearDiff',
      'prevYearDiffRate',
      'commentsGroupedByCommenteeKey',
      'notesByKey',
    ]
    if(!isEqual(pick(this.props, watchedPropNames), pick(nextProps, watchedPropNames))) {
      return true;
    }
    return false;
  }
  render() {
    const { role, metricsForFilter, isCategory, isCustom, _key: key, commenteeKey, noteKey, sections = [], members, user, isSubRow, yearMonth, amount, budgetAmount, budgetDiff, achievementRate, estimatedAmount, prevYearAmount, prevYearDiff, prevYearDiffRate, isSummaryColumn, itemName, subItemName, period, companyId, commentsGroupedByCommenteeKey, notesByKey, commenterRef, dimension, displayExpression } = this.props;
    const pathParams = {
      period,
      targetMonth: yearMonth,
      subItems: [
        { dimension: 'sections', itemNames: sections.map(_ => _.name), },
        ...(
          isSubRow ? [{
            dimension,
            itemNames: [subItemName],
          }] : []
        ),
      ],
    };
    return (
      <td className={classnames('text-right has-hovered-contents')} style={{ width: COLUMN_WIDTH }}>
        {
          metricsForFilter.includes('budgetAmount') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              <span>{isFiniteNumber(budgetAmount) ? numeral(budgetAmount).format(displayExpression) : '-'}</span>
            </div>
          )
        }
        {
          metricsForFilter.includes('amount') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              {
                (isCategory || isCustom || isSummaryColumn) ? (
                  <span>{isFiniteNumber(amount) ? numeral(amount).format(displayExpression) : '-'}</span>
                ) : (
                  <Link to={encodeURI(`/companies/${companyId}/accountItems/${itemName}?${qs.stringify(pathParams)}`)}>
                    {isFiniteNumber(amount) ? numeral(amount).format(displayExpression) : '-'}
                  </Link>
                )
              }
            </div>
          )
        }
        {
          metricsForFilter.includes('budgetDiff') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              {isFiniteNumber(budgetDiff) ? numeral(budgetDiff).format('+' + displayExpression) : '-'}
            </div>
          )
        }
        {
          metricsForFilter.includes('achievementRate') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              {isFiniteNumber(achievementRate) ? numeral(achievementRate).format('0,0.0%') : '-'}
            </div>
          )
        }
        {
          metricsForFilter.includes('estimatedAmount') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              <span>{isFiniteNumber(estimatedAmount) ? numeral(estimatedAmount).format(displayExpression) : '-'}</span>
            </div>
          )
        }
        {
          metricsForFilter.includes('prevYearAmount') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              <span>{isFiniteNumber(prevYearAmount) ? numeral(prevYearAmount).format(displayExpression) : '-'}</span>
            </div>
          )
        }
        {
          metricsForFilter.includes('prevYearDiff') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              {isFiniteNumber(prevYearDiff) ? numeral(prevYearDiff).format('+' + displayExpression) : '-'}
            </div>
          )
        }
        {
          metricsForFilter.includes('prevYearDiffRate') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              {isFiniteNumber(prevYearDiffRate) ? numeral(prevYearDiffRate).format('+0,0.0%') : '-'}
            </div>
          )
        }
        {
          !isSummaryColumn && (
            <Fragment>
              <HoveredCommenter
                ref={commenterRef.bind(null, key)}
                companyId={companyId}
                currentUser={user}
                commenteeKey={commenteeKey}
                queryKey="sectionBudgets"
                values={{ yearMonth: yearMonth.toString() }}
                comments={commentsGroupedByCommenteeKey[commenteeKey]}
                users={members}
                about={['部門別予実', yearMonth.toString(), itemName, subItemName].filter(_ => _).join(' ')}
              />
              <HoveredNoter companyId={companyId} noteKey={noteKey} pageType="CompanySectionsBudgets" queryKey="sectionBudgets" values={{ yearMonth: yearMonth.toString() }} note={notesByKey[noteKey]} writable={canWriteNote(user, role)} />
            </Fragment>
          )
        }
      </td>
    );
  }
}

function PrevSelectedCustomSectionsButton (props) {
  const { user, company, customSections, ...extraProps } = props;
  const history = useHistory();
  const location = useLocation();
  const customSectionsById = keyBy(customSections, 'id');
  const { customSectionIds = [], } = useQueryParams();
  const settingsPath = ['settings', 'companies', company.id, 'viewSettings', 'sectionsBudgets', 'customSectionIds'];
  const settings = get(user, settingsPath);
  const [prevAccessSettings, setPrevAccessSettings] = useState([]);
  const [isPrevSelectable, setIsPrevSelectable] = useState(false);
  const onClick = () => {
    const path = fullPathWithParams({ customSectionIds: prevAccessSettings }, location);
    history.replace(encodeURI(path));
    setIsPrevSelectable(false);
  }
  useEffect(() => {
    setPrevAccessSettings(settings);
    const isPrevSelectable = !isEqual(settings, customSectionIds);
    setIsPrevSelectable(isPrevSelectable);
  }, []);
  useEffect(() => {
    user.ref.update({ [settingsPath.join('.')]: customSectionIds });
  }, [customSectionIds.join(',')]);
  
  return isPrevSelectable && (
    <Button onClick={onClick} {...extraProps}>
      前回のカスタム部門を選択
    </Button>
  );
}

export default CompanyPage(function CompanySectionsBudgetsContainer(props) {
  const { customSections, writableCustomSections, location, period, history, match: { params: { companyId } } } = props;
  const sortedCustomSections = sortBy(writableCustomSections || [], _ => _.index != null ? _.index : (writableCustomSections || []).indexOf(_));
  const { dimension, aggregationType, customSectionIds, } = useQueryParams();
  const sectionBudgetContainers = useCollectionSubscription(dimension && companiesRef.doc(companyId).collection('sectionBudgetContainers').where('dimension', '==', dimension).where('period', '==', period), [dimension, period,]);
  const uniqSectionBudgetContainers = uniqBy(orderBy(sectionBudgetContainers, _ => _.createdAt.toDate(), 'desc'), 'customSectionId');
  const uniqRelatedSectionBudgetContainerIds = uniqSectionBudgetContainers.filter(_ => (customSectionIds || []).includes(_.customSectionId)).map(_ => _.id);
  const sectionBudgets = useCollectionsFetch(dimension && chunk(uniqRelatedSectionBudgetContainerIds, 10).map(_ => companiesRef.doc(companyId).collection('sectionBudgets').where('dimension', '==', dimension).where('period', '==', period).where('sectionBudgetContainerId', 'in', _)), [dimension, period, uniqRelatedSectionBudgetContainerIds.join('-')]);
  const sectionBudgetsGroupedByClosingDate = groupBy(sectionBudgets, _ => formatDate(_.closingDate, 'YYYYMM'));
  const sectionBudgetsGroupedItemKey = groupBy(sectionBudgets, 'itemKey');
  useEffect(() => {
    const path = fullPathWithParams({ dimension: dimension || 'none', aggregationType: aggregationType || 'cumulative', customSectionIds: customSectionIds || sortedCustomSections.slice(0, 5).map(_ => _.id) }, location);
    history.replace(encodeURI(path));
  }, [customSections]);

  return (
    <CompanySectionsBudgets
      {...{
        ...props,
        sectionBudgets,
        sectionBudgetsGroupedByClosingDate,
        sectionBudgetsGroupedItemKey,
        uniqSectionBudgetContainers,
      }}
    />
  );
});
