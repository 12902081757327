import React from 'react';
import { Button } from 'reactstrap';
import { pick, } from 'lodash';

import firebase, { functions } from '../../firebase';
import { fieldDisplayValue } from '../../shared/util';
import { fields } from '../../shared/models/user';
import HeaderNav from '../HeaderNav';
import EditButton from '../EditButton';
import ModelFormModal from '../modals/ModelFormModal';

const db = firebase.firestore();
const { entries } = Object;

export default function Profile (props) {
  const { user } = props;

  return (
    <div className="profile">
      <HeaderNav {...props} />
      <div className="container py-5">
        <div className="d-flex flex-column justify-content-center align-items-stretch">
          <div className="d-flex justify-content-center">
            <h3>プロフィール</h3>
          </div>
          <div className="row mt-4">
            <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
              <div className="card p-3">
                <div>
                  {
                    user && (
                      <div>
                        {
                          entries(pick(fields, ['email', 'displayName'])).map(([fieldName, { label, type }]) => {
                            const value = fieldDisplayValue(user[fieldName], fields[fieldName]);
                            return (
                              <div className="mb-3" key={fieldName}>
                                <div className="text-muted small">{label}</div>
                                <div>{value || '(未登録)'}</div>
                              </div>
                            );
                          })
                        }
                      </div>
                    )
                  }
                </div>
                <EditButton itemRef={user.ref} FormModal={ModelFormModal} formProps={{ title: 'プロフィール編集', fields: pick(fields, ['displayName']), }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
