import React, { Component, Fragment } from 'react';
import { Table, Button, Nav, NavItem, NavLink } from 'reactstrap';
import numeral from 'numeral';
import { mergeWith, chunk, isError, pickBy, mapKeys, orderBy, isEmpty, isNumber as _isNumber, groupBy, last, keyBy, range, uniq, debounce, sumBy, pick, isEqual, } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { format as formatDate, endOfMonth, addMonths, addYears, startOfMonth, } from 'date-fns';
import { Link } from 'react-router-dom';
import fileDownload from 'js-file-download';
import { unparse as unparseCsv, } from 'papaparse';
import { TextEncoder, } from 'text-encoding';
import qs from 'qs';
import Select from 'react-select';
import { LineChart, Line } from 'recharts';
import { ResizableBox } from 'react-resizable';

import firebase from '../../firebase';
import { getAllCollectionDataByChunk, } from '../../shared/firebase';
import { canWriteTrial, canWriteNote } from '../../abilities';
import { mapKeysToJa, jas, } from '../../shared/texts';
import { dimensionsOfCompany, } from '../../shared/models/company';
import { computeStatus, } from '../../shared/models/journalsAndTrialsFetchJob';
import { monthlyTrendsComparisons, breakdownItemsLimitUnit, colors, documentTypes, allDimensions, } from '../../shared/config';
import HelpLink from '../HelpLink';
import AccountItemDisplay from '../AccountItemDisplay';
import TrialQueriesSection from '../TrialQueriesSection';
import CompanyPage from '../hocs/CompanyPage';
import OverlayLoading from '../OverlayLoading';
import CommentsDropdown from '../CommentsDropdown';
import TrialImportButton from '../TrialImportButton';
import TrialScrapingButton from '../TrialScrapingButton';
import HoveredCommenter from '../HoveredCommenter';
import HoveredNoter from '../HoveredNoter';
import QuerySelector from '../QuerySelector';
import SyncCustomAccountItemManualValuesButton from '../SyncCustomAccountItemManualValuesButton';
import ItemNameFilterSelector from '../ItemNameFilterSelector';
import ModalButton from '../ModalButton';
import JournalsAndTrialsFetchJobErrorDetail from '../JournalsAndTrialsFetchJobErrorDetail';
import AllCollapseButton from '../AllCollapseButton';
import JournalsSyncButton from '../JournalsSyncButton';
import { log, startOfMonthByFiscalYears, existsInFiscalYears, fiscalYearOfPeriod, formatTrialCommentAbout, fullPathWithParams, computeTrialAmount, accountItemOccurrenceOfTrials, trialItemsToRowItems, periodOfFiscalYear, filterRowsByAmount, groupTrials, groupTrialSubItems, } from '../../utils';
import env from '../../env';
import './CompanyMonthlyTrends.css';

const db = firebase.firestore();
const auth = firebase.auth();
const companiesRef = db.collection('companies');
const { entries, keys, } = Object;
const { abs } = Math;
const COLUMN_WIDTH = 160;
const AMOUNT_HEIGHT = 24;
const AMOUNT_HEIGHT_PX = AMOUNT_HEIGHT + 'px';
const isFiniteNumber = _ => _isNumber(_) && isFinite(_);
const metrics = {
  amount: { label: _ => '当期実績' },
  comparisonAmount: { label: _ => ({ prevYearSameMonth: '前年同期', prevMonth: '前月' })[_.comparison], },
  diff: { label: _ => ({ prevYearSameMonth: '対前年同期', prevMonth: '対前月' })[_.comparison], },
  diffRate: { label: _ => '増減率', isRate: true, },
};
const freeeTrialReportUrls = {
  bs: `https://secure.freee.co.jp/reports/trial_balance_sheet`,
  pl: `https://secure.freee.co.jp/reports/trial_profit_and_loss`,
  cr: `https://secure.freee.co.jp/reports/trial_profit_and_loss`,
};

export default CompanyPage(class CompanyMonthlyTrends extends Component {
  constructor() {
    super();
    this.state = {
      leftColumnWidth: COLUMN_WIDTH * 2.5,
    };
  }
  async componentDidMount() {
    this.setInitialQueryParams();
    await new Promise(_ => setTimeout(_, 100));
    this.listenTrials();
    this.fetchTrialSubItems();
    this.listenComments();
    this.listenNotes();
    this.listenJournalsAndTrialsFetchJob();
  }
  componentWillUnmount() {
    this.unlistenTrials && this.unlistenTrials();
    this.unlistenComments && this.unlistenComments();
    this.unlistenNotes && this.unlistenNotes();
    this.unlistenJournalsAndTrialsFetchJob && this.unlistenJournalsAndTrialsFetchJob();
  }
  componentDidUpdate(prevProps, prevState) {
    if(
      ['trials', 'trialSubItemsGroupedByClosingDate', 'comments', 'notes', 'currentYearTrial', 'prevYearTrial'].some(_ => prevState[_] !== this.state[_])
      || ['comparison'].some(_ => this.queryParams(prevProps)[_] !== this.queryParams()[_])
      || this.isSubFiltered() && keys(this.state || {}).filter(_ => _.startsWith('shouldShowSubRows__')).some(_ => prevState[_] !== this.state[_])
    ) {
      this.setState({ isSettingRows: true });
      this.setRowsDebounced();
    }
    if(
      ['period', 'targetMonth'].some(_ => prevProps[_] !== this.props[_])
    ) {
      this.unlistenTrials && this.unlistenTrials();
      this.listenTrials();
    }
    if(
      ['targetMonth'].some(_ => prevProps[_] !== this.props[_])
      || ['dimension', 'isSubFilter'].some(_ => this.queryParams(prevProps)[_] !== this.queryParams()[_])
      || !isEqual(this.state.openItemKeys, prevState.openItemKeys)
    ) {
      this.fetchTrialSubItems();
    }
    if(
      keys(this.state || {}).filter(_ => _.startsWith('shouldShowSubRows__')).some(_ => prevState[_] !== this.state[_])
      || ['isSubFilter'].some(_ => this.queryParams(prevProps)[_] !== this.queryParams()[_])
    ) {
      this.setState({
        openItemKeys: this.isSubFiltered() ? [] : keys(this.state || {}).filter(_ => _.startsWith('shouldShowSubRows__')).filter(_ => this.state[_]).map(_ => _.replace('shouldShowSubRows__', '')),
      });
    }
    if(
      ['documentType'].some(_ => this.queryParams(prevProps)[_] !== this.queryParams()[_])
      || ['allComments'].some(_ => prevState[_] !== this.state[_])
    ) {
      this.setComments();
    }
    if(
      ['rows'].some(_ => prevState[_] !== this.state[_])
    ) {
      this.toggleCommentRow();
      this.onRowsChanged();
    }
  }
  toggleCommentRow() {
    const { rows, commentRowToggled = false, } = this.state;
    const { commenteeKey, } = this.queryParams();
    if(commentRowToggled || !commenteeKey) return;

    this.onClickComment(commenteeKey);
    this.setState({ commentRowToggled: true });
  }
  async onRowsChanged() {
    const { rowsChangeListeners = [] } = this.state;
    if(isEmpty(rowsChangeListeners)) return;

    const fails = (await Promise.all(rowsChangeListeners.map(async _ => !(await _()) && _))).filter(_ => _);
    this.setState({ rowsChangeListeners: fails });
  }
  months() {
    const { period, targetMonth, company: { fiscalYears } } = this.props;
    const { start_date: startDate, } = fiscalYearOfPeriod(period, fiscalYears);
    return [
      range(-12, 0).map(_ => endOfMonth(addMonths(startDate, _))),
      range(0, 12).map(_ => endOfMonth(addMonths(startDate, _))),
    ];
  }
  fetchLastTrials = debounce(async () => {
    const { documentType, targetMonth } = this.queryParams();
    this.setState({ isFetchingLastTrials: true });
    const [currentYearTrial, prevYearTrial] = await this.fetch(this.months()[1].filter(_ => !this.isOverMonth(_)).slice(-1)[0], { dimensions: ['none'] }) || [];
    if(this.queryParams().documentType !== documentType || this.queryParams().targetMonth !== targetMonth) return;
    this.setState({ currentYearTrial, prevYearTrial, isFetchingLastTrials: false });
  }, 100)
  fetchWalletables = async () => {
    const { company: { sourceId: companySourceId, }, match: { params: { companyId } } } = this.props;
    const token = await auth.currentUser.getIdToken(true);
    const url = `${env('CLOUD_FUNCTIONS_ENDPOINT')}/fetchFreeeWalletables?${qs.stringify({ companyId, companySourceId })}`;
    const headers = { 'Firebase-User-Token': token };
    try {
      const res = await fetch(url, { headers });
      if(res.status !== 200) {
        const { errors } = await res.json();
        throw new Error(errors.join('\n'));
      }
      const walletables = await res.json();
      return walletables;
    } catch(e) {
      toast.error(e.message);
      console.error(e);
    }
  }
  async fetch(closingDate, { dimensions: _dimensions } = {}) {
    const { company: { sourceId: companySourceId, }, company, match: { params: { companyId } } } = this.props;
    const { documentType } = this.queryParams();
    const token = await auth.currentUser.getIdToken(true);
    const prevYearClosingDate = endOfMonth(addYears(closingDate, -1));
    const res = await (_dimensions || keys(dimensionsOfCompany(company))).reduce(async (x, dimension) => {
      const prevs = await x;

      try {
        const url = `${env('CLOUD_FUNCTIONS_ENDPOINT')}/fetchFreeeTrial?${qs.stringify({ type: documentType, companyId, companySourceId, startDate: formatDate(startOfMonthByFiscalYears(closingDate, company), 'YYYY-MM-DD'), endDate: formatDate(closingDate, 'YYYY-MM-DD'), dimension })}`;
        const headers = { 'Firebase-User-Token': token };
        const res = await fetch(url, { headers });
        if(res.status !== 200) {
          const { errors } = await res.json();
          throw new Error(errors.join('\n'));
        }
        const balances = await res.json() || [];
        return [...prevs, { closingDate, balances, balancesByItemKey: keyBy(balances, 'itemKey') }];
      } catch (e) {
        toast.error(`[${allDimensions[dimension]}] 失敗しました。 ${e.message}`);
        console.error(e);
        return [...prevs, e];
      }
    }, Promise.resolve([]));
    if(res.some(isError)) return;

    return res;
  }
  monthItems(options) {
    const { trialsGroupedByClosingDate = {}, } = this.state;
    const trialSubItemsGroupedByClosingDate = options?.trialSubItemsGroupedByClosingDate || this.state.trialSubItemsGroupedByClosingDate || {};
    const { dimension, documentType, } = this.queryParams();
    return this.months().map((months) => {
      return months.map((closingDate) => {
        const balances = trialsGroupedByClosingDate[formatDate(closingDate, 'YYYYMM')] || [];
        const trialSubItems = trialSubItemsGroupedByClosingDate[formatDate(closingDate, 'YYYYMM')] || [];
        const exists = !isEmpty(balances);
        return {
          closingDate,
          exists,
          balances,
          // NOTE: カスタム科目の計算に使われない、かつ、「当期純損益金額」がPLにもBSにも存在し衝突するため、documentTypeで絞る
          balancesByItemKey: keyBy(balances.filter(_ => _.type === documentType), 'itemKey'),
          trialSubItemsGroupedByItemKey: groupBy(trialSubItems, 'itemKey'),
          dimension,
        };
      });
    });
  }
  listenTrials = debounce(() => {
    const { match: { params: { companyId } } } = this.props;
    const { dimension, } = this.queryParams();
    const months = this.months();
    const [prevYearFirstEndDate] = months[0];
    const [lastEndDate] = months[1].slice(-1);
    this.unlistenTrials = companiesRef
      .doc(companyId)
      .collection('trials')
      .where('dimension', '==', 'none')
      .where('closingDate', '>=', formatDate(prevYearFirstEndDate, 'YYYY-MM-DD'))
      .where('closingDate', '<=', formatDate(lastEndDate, 'YYYY-MM-DD'))
      .onSnapshot(({ docs }) => {
        const trials = docs.map(_ => _.data());
        const trialsGroupedByClosingDate = groupBy(trials, _ => formatDate(_.closingDate, 'YYYYMM'));
        const trialsGroupedByItemKey = groupTrials(trials);
        this.setState({ trials, trialsGroupedByClosingDate, trialsGroupedByItemKey, });
      });
  }, 1000)
  fetchTrialSubItems = debounce(() => {
    const { company, match: { params: { companyId } } } = this.props;
    const { dimension, } = this.queryParams();
    const months = this.months();
    const [prevYearFirstEndDate] = months[0];
    const [lastEndDate] = months[1].slice(-1);
    this.setState({ trialSubItemsGroupedByClosingDate: {}, trialSubItemsGroupedByItemKey: {}, });
    const refs = this.isSubFiltered() ? [company.ref.collection(`trialSubItemChunks__${dimension}`)] : chunk(this.state.openItemKeys, 10).map(_ => company.ref.collection(`trialSubItemChunks__${dimension}`).where('itemKey', 'in', _));
    refs.map(async (ref) => {
      const trialSubItems = (await getAllCollectionDataByChunk(ref
        .where('closingDate', '>=', formatDate(prevYearFirstEndDate, 'YYYY-MM-DD'))
        .where('closingDate', '<=', formatDate(lastEndDate, 'YYYY-MM-DD'))
      )).flatMap(_ => _.subItems.map(__ => ({ ..._, ...__ })));
      const trialSubItemsGroupedByClosingDate = groupBy(trialSubItems, _ => formatDate(_.closingDate, 'YYYYMM'));
      const trialSubItemsGroupedByItemKey = groupTrialSubItems(trialSubItems);
      this.setState(state => {
        return {
          trialSubItemsGroupedByItemKey: { ...state.trialSubItemsGroupedByItemKey, ...trialSubItemsGroupedByItemKey, },
          trialSubItemsGroupedByClosingDate: mergeWith({ ...state.trialSubItemsGroupedByClosingDate }, trialSubItemsGroupedByClosingDate, (a, b) => [...(a || []), ...(b || [])]),
        };
      });
    });
  }, 1000)
  listenComments() {
    const { match: { params: { companyId, } } } = this.props;
    this.unlistenComments = companiesRef
      .doc(companyId)
      .collection('comments')
      .where('queryKey', '==', 'trial')
      .orderBy('createdAt')
      .onSnapshot(({ docs }) => {
        this.setState({ allComments: docs.map(_ => ({ id: _.id, ..._.data() })) });
      });
  }
  listenNotes() {
    const { match: { params: { companyId, } } } = this.props;
    this.unlistenNotes = companiesRef
      .doc(companyId)
      .collection('notes')
      .where('pageType', '==', 'CompanyMonthlyTrends')
      .where('queryKey', '==', 'trial')
      .onSnapshot(({ docs }) => {
        const notes = docs.map(_ => ({ id: _.id, ..._.data() }));
        this.setState({ notes, notesByKey: keyBy(notes, 'noteKey') });
      });
  }
  listenJournalsAndTrialsFetchJob() {
    const { match: { params: { companyId, } } } = this.props;
    this.unlistenJournalsAndTrialsFetchJob = companiesRef
      .doc(companyId)
      .collection('journalsAndTrialsFetchJobs')
      .doc('0')
      .onSnapshot((doc) => {
        this.setState({ journalsAndTrialsFetchJob: { id: doc.id, ref: doc.ref, exists: doc.exists, ...doc.data() } });
      });
  }
  setComments() {
    const { period, company: { fiscalYears = [] } } = this.props;
    const { allComments = [] } = this.state;
    const { documentType } = this.queryParams();
    const { start_date: startDate, end_date: endDate } = fiscalYearOfPeriod(period, fiscalYears);
    const comments = allComments.filter(_ => formatDate(startDate, 'YYYYMM') <= _.yearMonth && _.yearMonth <= formatDate(endDate, 'YYYYMM') && _.documentType === documentType);
    this.setState({ comments, commentsGroupedByCommenteeKey: groupBy(comments, 'commenteeKey') });
  }
  setRowsDebounced = debounce(() => {
    if(this.state.trials == null) return;

    const rows = this.generateRows();
    this.setState({ rows, isSettingRows: false });
  }, 1000)
  generateRows = (options) => {
    const { targetMonth, company, customAccountItems: _customAccountItems = [], accountItems = [], accountItemsOrderSetting, } = this.props;
    const { comparison, dimension, documentType } = this.queryParams();
    const { notesByKey, currentYearTrial = {}, prevYearTrial = {} } = this.state;
    const trialSubItemsGroupedByClosingDate = options?.trialSubItems && groupBy(options.trialSubItems, _ => formatDate(_.closingDate, 'YYYYMM'));
    const trialSubItemsGroupedByItemKey = options?.trialSubItems && groupTrialSubItems(options.trialSubItems);
    const [prevYearMonthItems, currentYearMonthItems] = this.monthItems({ trialSubItemsGroupedByClosingDate });
    const prevYearMonthItemsByMonth = keyBy(prevYearMonthItems, _ => formatDate(_.closingDate, 'MM'));
    const customAccountItems = _customAccountItems.filter(_ => (_.dimension || 'none') === 'none' || _.dimension === dimension);
    const allItems = [...currentYearMonthItems, ...prevYearMonthItems];
    const items = trialItemsToRowItems(allItems, { targetMonth, accountItems, customAccountItems, documentType, dimension, screenType: 'monthlyTrends', accountItemsOrderSetting, })
    const rows = items
      .map(this.itemToRow.bind(this, { trialSubItemsGroupedByItemKey, company, dimension, comparison, documentType, customAccountItems, currentYearMonthItems, prevYearMonthItems, prevYearMonthItemsByMonth, notesByKey, currentYearTrial, prevYearTrial, }))
      .filter(_ => _.monthColumns.some(_ => abs(_.amount) > 0 || abs(_.comparisonAmount) > 0));
    return Object.values(groupBy(rows, 'mainRowItemKey')).map(([mainItem, ...subItems]) => {
      const sortedSubItems = orderBy(subItems, _ => Math.abs(_.monthColumnsByYearMonth[targetMonth].amount), 'desc').map((subRow, i) => {
        const showsSubRow = this.state[`shouldShowSubRows__${subRow.itemKey}`];
        return {
          ...subRow,
          breakDownItemIndex: i,
          subItemsCount: subItems.length,
          shows: showsSubRow,
        };
      });
      return [{ ...mainItem, subItems: sortedSubItems }, ...sortedSubItems];
    }).flat();
  }
  isOverMonth = (date) => {
    const { targetMonth } = this.props;
    return date > endOfMonth(targetMonth.toString().replace(/\d{2}$/, _ => '-' + _));
  }
  itemToRow(option, item) {
    const { dimension, comparison, documentType, customAccountItems, currentYearMonthItems, prevYearMonthItems, prevYearMonthItemsByMonth, notesByKey, currentYearTrial, prevYearTrial, } = option;
    const { trialsGroupedByItemKey = {}, commentsGroupedByCommenteeKey = {} } = this.state;
    const trialSubItemsGroupedByItemKey = option.trialSubItemsGroupedByItemKey || this.state.trialSubItemsGroupedByItemKey || {};
    const { isCustom = false, itemName, itemKey, isSubRow, subItemName, hierarchy_level: hierarchyLevel, account_item_name: accountItemName, displayExpression = '0,0', } = item;
    const isCategory = isEmpty(accountItemName);
    const rowKey = (isCustom ? 'cus__' : '') + (isCategory ? 'cat__' : '') + ((dimension !== 'none' && isSubRow) ? `${itemName}__${dimension}__${subItemName}` : itemName);
    const mainRowItemKey = isCustom ? itemKey : item.mainRowItemKey;
    const rowName = subItemName || itemName;
    const monthColumns = currentYearMonthItems.map((monthItem, i) => {
      const { closingDate } = monthItem;
      const yearMonth = formatDate(closingDate, 'YYYYMM');
      const isOverMonth = this.isOverMonth(closingDate);
      const comparisonMonthItem = ({
        prevYearSameMonth: prevYearMonthItemsByMonth[formatDate(closingDate, 'MM')],
        prevMonth: currentYearMonthItems[i - 1] || last(prevYearMonthItems),
      })[comparison] || {};
      const key = `${rowKey}_${yearMonth}`;
      const closingBalance = isOverMonth ? 0 : computeTrialAmount('closing', item, trialsGroupedByItemKey, trialSubItemsGroupedByItemKey, dimension, [closingDate], customAccountItems, documentType);
      const openingBalance = isOverMonth ? 0 : computeTrialAmount('opening', item, trialsGroupedByItemKey, trialSubItemsGroupedByItemKey, dimension, [closingDate], customAccountItems, documentType);
      const comparisonClosingBalance = isOverMonth ? 0 : computeTrialAmount('closing', item, trialsGroupedByItemKey, trialSubItemsGroupedByItemKey, dimension, [comparisonMonthItem.closingDate], customAccountItems, documentType);
      const comparisonOpeningBalance = isOverMonth ? 0 : computeTrialAmount('opening', item, trialsGroupedByItemKey, trialSubItemsGroupedByItemKey, dimension, [comparisonMonthItem.closingDate], customAccountItems, documentType);
      const occurrence = isOverMonth ? 0 : computeTrialAmount('occurrence', item, trialsGroupedByItemKey, trialSubItemsGroupedByItemKey, dimension, [closingDate], customAccountItems, documentType);
      const comparisonOccurrence = isOverMonth ? 0 : computeTrialAmount('occurrence', item, trialsGroupedByItemKey, trialSubItemsGroupedByItemKey, dimension, [comparisonMonthItem.closingDate], customAccountItems, documentType);
      const amount = ({ bs: closingBalance, pl: occurrence, cr: occurrence })[documentType];
      const comparisonAmount = ({ bs: comparisonClosingBalance, pl: comparisonOccurrence, cr: comparisonOccurrence })[documentType];
      const diff = amount - comparisonAmount;
      const diffRate = diff / comparisonAmount;
      const note = (notesByKey[key] || {}).value;
      return { key, closingDate, yearMonth, amount, comparisonAmount, closingBalance, comparisonClosingBalance, openingBalance, comparisonOpeningBalance, occurrence, comparisonOccurrence, diff, diffRate, note };
    });
    const monthColumnsByYearMonth = keyBy(monthColumns, 'yearMonth');
    const [firstMonthColumn] = monthColumns.slice(0, 1);
    const totalOccurrence = sumBy(monthColumns, 'occurrence');
    const comparisonTotalOccurrence = sumBy(monthColumns, 'comparisonOccurrence');
    const totalAmount = (firstMonthColumn.openingBalance || 0) + totalOccurrence;
    const comparisonTotalAmount = (firstMonthColumn.comparisonOpeningBalance || 0) + comparisonTotalOccurrence;
    const totalDiff = totalAmount - comparisonTotalAmount;
    const trialAmount = ((currentYearTrial.balancesByItemKey || {})[itemKey] || {}).closing_balance;
    const comparisonTrialAmount = ((prevYearTrial.balancesByItemKey || {})[itemKey] || {}).closing_balance;
    const diff = totalAmount - trialAmount;
    const summaryColumns = [
      { isSummaryColumn: true, name: 'opening', key: `${rowKey}_opening`, amount: firstMonthColumn.openingBalance, comparisonAmount: comparison === 'prevYearSameMonth' && firstMonthColumn.comparisonOpeningBalance, diff: comparison === 'prevYearSameMonth' && firstMonthColumn.openingBalance - firstMonthColumn.comparisonOpeningBalance, diffRate: comparison === 'prevYearSameMonth' && (firstMonthColumn.openingBalance - firstMonthColumn.comparisonOpeningBalance) / firstMonthColumn.comparisonOpeningBalance },
      { isSummaryColumn: true, name: 'sum', key: `${rowKey}_sum`, amount: totalOccurrence, comparisonAmount: comparison === 'prevYearSameMonth' && comparisonTotalOccurrence, diff: comparison === 'prevYearSameMonth' && totalOccurrence - comparisonTotalOccurrence, diffRate: comparison === 'prevYearSameMonth' && (totalOccurrence - comparisonTotalOccurrence) / comparisonTotalOccurrence },
      { isSummaryColumn: true, name: 'total', key: `${rowKey}_total`, amount: totalAmount, comparisonAmount: comparison === 'prevYearSameMonth' && comparisonTotalAmount, diff: comparison === 'prevYearSameMonth' && totalDiff, diffRate: comparison === 'prevYearSameMonth' && totalDiff / comparisonTotalOccurrence },
      { isSummaryColumn: true, name: 'trial', key: `${rowKey}_trial`, amount: trialAmount, comparisonAmount: comparison === 'prevYearSameMonth' && comparisonTrialAmount },
      { isSummaryColumn: true, name: 'diff', key: `${rowKey}_diff`, amount: diff, },
    ];
    const comments = [...monthColumns, ...summaryColumns].map(_ => commentsGroupedByCommenteeKey[_.key] || []).flat();
    return { ...item, hierarchyLevel, isCategory, isCustom, itemName, itemKey, mainRowItemKey, key: rowKey, subItemName, isSubRow, rowName, monthColumns, monthColumnsByYearMonth, summaryColumns, diff, displayExpression, comments, };
  }
  toggleSubRows(itemKey, shows) {
    this.setState({ isSettingRows: shows, [`shouldShowSubRows__${itemKey}`]: (shows != null ? shows : !this.state[`shouldShowSubRows__${itemKey}`]) });
  }
  showMoreSubRows(itemKey) {
    this.setState({ [`breakdownItemsLimitRate_${itemKey}`]: (this.state[`breakdownItemsLimitRate_${itemKey}`] || 1) + 1 });
  }
  renderRow({ hierarchyLevel = 1, key, isCategory, isCustom, isManual, itemName, itemKey, subItemName, isSubRow, rowName, monthColumns, summaryColumns, diff, displayExpression, breakDownItemIndex, subItemsCount, }) {
    const { role, company, accountItemSettingsById, user, members, period, match: { params: { companyId } } } = this.props;
    const { commentsGroupedByCommenteeKey = {}, notesByKey = {}, showsChart = true, leftColumnWidth, } = this.state;
    const { dimension, metrics: metricsForFilter = keys(metrics), comparison, documentType, subItemNamesForFilter } = this.queryParams();
    const allColumns = [...monthColumns, ...summaryColumns];
    const shouldShowSubRows = this.state[`shouldShowSubRows__${itemKey}`];
    const accountItemSetting = accountItemSettingsById[`${rowName.replace(/\//g, '_')}`];
    const breakdownItemsLimitRate = this.state[`breakdownItemsLimitRate_${itemKey}`] || 1;
    const showingCount = breakdownItemsLimitUnit * breakdownItemsLimitRate;
    const shouldHide = isSubRow && (breakDownItemIndex + 1) > showingCount;
    if(shouldHide) return null;
    const isLastSubRow = (breakDownItemIndex + 1) === showingCount;
    const leftSubItemsCount = subItemsCount - showingCount;
    return (
      <tr key={itemKey + key} className={classnames('item-row', { 'sub-row': isSubRow })}>
        <th style={{ width: leftColumnWidth, textIndent: `${hierarchyLevel - 1}rem`, fontWeight: isCategory ? 700 : 400 }} className={classnames({ 'table-danger': !isSubRow && abs(diff) > 0 })} colSpan={5 - hierarchyLevel}>
          <div className="d-flex justify-content-between align-items-start">
            <div>
              {
                dimension !== 'none' && !isSubRow && !isCategory && !isCustom && (
                  <Button size="sm" className="small px-0 mr-1" color="link" onClick={_ => this.toggleSubRows(itemKey)}>
                    <span className={classnames('fas cursor-pointer', { 'fa-plus': !shouldShowSubRows, 'fa-minus': shouldShowSubRows })} />
                  </Button>
                )
              }
              <AccountItemDisplay accountItemName={rowName} accountItemSetting={accountItemSetting} iconStyles={{ textIndent: 0 }} isCustom={isCustom} />
              {isCustom && isManual && <SyncCustomAccountItemManualValuesButton color="link" size="sm" company={company} />}
              {
                isLastSubRow && leftSubItemsCount > 0 && (
                  <div className="mt-2">
                    <Button size="sm" onClick={this.showMoreSubRows.bind(this, itemKey)}>
                      もっと見る (残り{leftSubItemsCount}件)
                    </Button>
                  </div>
                )
              }
            </div>
            <div className="text-nowrap">
              {
                metricsForFilter.map((metric) => {
                  const { label } = metrics[metric];
                  return (
                    <div key={metric} style={{ lineHeight: AMOUNT_HEIGHT_PX }}>
                      {label({ comparison })}
                    </div>
                  );
                })
              }
            </div>
          </div>
        </th>
        <td style={{ width: 0, padding: 0, position: 'relative', }}>
          {showsChart && <RowChart data={monthColumns.map(_ => pick(_, ['amount', 'comparisonAmount']))} height={AMOUNT_HEIGHT * metricsForFilter.length + 25} />}
        </td>
        {
          allColumns.map((column, index) => {
            const { key, name, } = column;
            return (
              <Column
                key={key}
                _key={key}
                name={name}
                commenterRef={(key, el) => this[`hovered_commenter__${key}`] = el}
                {...{ ...column, company, role, metricsForFilter, company, index, isCategory, isCustom, documentType, user, members, isSubRow, itemName, subItemName, period, companyId, dimension, comparison, comments: commentsGroupedByCommenteeKey[key], notesByKey, displayExpression, shouldCheck: isEmpty(subItemNamesForFilter) } }
              />
            );
          })
        }
      </tr>
    );
  }
  onSelect = (name, { value }) => {
    const { history, location } = this.props;
    const path = fullPathWithParams({ [name]: value }, location);
    history.replace(encodeURI(path));
  }
  onClickComment = (commenteeKey) => {
    const { rows, } = this.state;
    const [itemName] = commenteeKey.split('_');
    const targetRow = rows?.find(_ => _.key === itemName);
    if(targetRow == null) return;

    const shows = this.state[`shouldShowSubRows__${targetRow.itemKey}`];
    if(shows) {
      this.toggleCommenter(commenteeKey);
    } else {
      this.toggleSubRows(targetRow.itemKey, true);
      this.setState({ rowsChangeListeners: [...(this.state.rowsChangeListeners || []), () => this.toggleCommenter(commenteeKey)] });
    }
  }
  toggleCommenter = (commenteeKey) => {
    const { [`hovered_commenter__${commenteeKey}`]: commenter } = this;
    if(commenter) {
      commenter.open();
      commenter.focus();
      return true;
    }
  }
  setInitialQueryParams() {
    const { history, location } = this.props;
    const { comparison, dimension, documentType } = this.queryParams();
    const path = fullPathWithParams({ comparison: comparison || 'prevYearSameMonth', dimension: dimension || 'none', documentType: documentType || 'bs' }, location);
    history.replace(encodeURI(path));
  }
  toggleTab (documentType) {
    const { history, location } = this.props;
    const path = fullPathWithParams({ documentType }, location);
    history.replace(encodeURI(path));
  }
  queryParams(props) {
    const { location: { search } } = props || this.props;
    return pickBy(qs.parse(decodeURI(search.slice(1)), { arrayLimit: Infinity }), _ => _ !== '');
  }
  isSubFiltered = () => (this.queryParams().dimension || 'none') !== 'none' && this.queryParams().isSubFilter === '1';
  filteredRows = (_rows) => {
    const { targetMonth, } = this.props;
    const rows = _rows || this.state.rows || [];
    const queryParams = this.queryParams();
    const { dimension, itemNamesForFilter, subItemNamesForFilter, } = queryParams;
    const isSubFiltered = this.isSubFiltered();
    let filteredRows = rows;
    if(!isEmpty(itemNamesForFilter)) {
      filteredRows = filteredRows.filter(_ => itemNamesForFilter.includes(_.itemName));
    }
    if(!isSubFiltered) {
      entries(metrics).map(([metric, { isRate }]) => {
        filteredRows = filterRowsByAmount(filteredRows, queryParams, metric, _ => _.monthColumns.find(_ => _.yearMonth === targetMonth.toString())?.[metric] * (isRate ? 100 : 1));
      });
    } else {
      filteredRows = entries(groupBy(filteredRows, 'itemKey'))
        .map(([itemKey, [mainRow, ...subRows]]) => {
          let filteredSubRows = subRows;
          if(!isEmpty(subItemNamesForFilter)) {
            filteredSubRows = filteredSubRows.filter(row => subItemNamesForFilter.some(_ => row.subItemName === _));
          }
          entries(metrics).map(([metric, { isRate }]) => {
            filteredSubRows = filterRowsByAmount(filteredSubRows, queryParams, metric, _ => _.monthColumns.find(_ => _.yearMonth === targetMonth.toString())?.[metric] * (isRate ? 100 : 1));
          });
          return {
            mainRow: (
              subRows.length === 0 ? mainRow : {
                ...mainRow,
                monthColumns: mainRow.monthColumns.map((column, i) => {
                  const amount = !isSubFiltered ? column.amount : sumBy(filteredSubRows, _ => _.monthColumns[i].amount);
                  const comparisonAmount = !isSubFiltered ? column.comparisonAmount : sumBy(filteredSubRows, _ => _.monthColumns[i].comparisonAmount);
                  const diff = amount - comparisonAmount;
                  const diffRate = diff / comparisonAmount;
                  return {
                    ...column,
                    amount,
                    comparisonAmount,
                    diff,
                    diffRate,
                  };
                }),
                summaryColumns: mainRow.summaryColumns.map((column, i) => {
                  const { name } = column;
                  const amount = (['trial', 'diff'].includes(name) || !isSubFiltered) ? column.amount : sumBy(filteredSubRows, _ => _.summaryColumns[i].amount);
                  const comparisonAmount = (['trial', 'diff'].includes(name) || !isSubFiltered) ? column.comparisonAmount : sumBy(filteredSubRows, _ => _.summaryColumns[i].comparisonAmount);
                  const diff = amount - comparisonAmount;
                  const diffRate = diff / comparisonAmount;
                  return {
                    ...column,
                    amount,
                    comparisonAmount,
                    diff,
                    diffRate,
                  };
                }),
              }
            ),
            subRows: filteredSubRows.map((row, i) => {
              return {
                ...row,
                breakDownItemIndex: i,
                subItemsCount: filteredSubRows.length,
              };
            }),
          };
        })
        .filter(_ => !isSubFiltered || _.subRows.length > 0)
        .reduce((x, y) => [...x, y.mainRow, ...y.subRows], []);
    }
    return filteredRows;
  }
  onClickExport = async () => {
    const { company, user, period, targetMonth } = this.props;
    const { documentType, dimension = 'none', metrics: metricsForFilter = keys(metrics), } = this.queryParams();
    this.setState({ isExporting: true });
    const months = this.months();
    const [prevYearFirstEndDate] = months[0];
    const [lastEndDate] = months[1].slice(-1);
    const encoder = new TextEncoder('Shift_JIS', { NONSTANDARD_allowLegacyEncoding: true });
    const trialSubItems = dimension !== 'none' && (await getAllCollectionDataByChunk(company.ref
      .collection(`trialSubItemChunks__${dimension}`)
      .where('closingDate', '>=', formatDate(prevYearFirstEndDate, 'YYYY-MM-DD'))
      .where('closingDate', '<=', formatDate(lastEndDate, 'YYYY-MM-DD')),
    )).flatMap(_ => _.subItems.map(__ => ({ ..._, ...__ })));
    const rows = this.generateRows({ trialSubItems });
    const filteredRows = this.filteredRows(rows);
    const data = filteredRows.map((row) => {
      return [...metricsForFilter, ...(row.monthColumns.every(_ => isEmpty(_.note)) ? [] : ['note'])].map((rowType) => {
        return {
          ...pick(row, ['itemName', 'subItemName']),
          rowType: jas[rowType],
          ...(
            row.monthColumns.reduce((x, y) => {
              const { closingDate } = y;
              return {
                ...x,
                [formatDate(closingDate, '_YYYYMM')]: y[rowType],
              };
            }, {})
          ),
          ...(
            row.summaryColumns.reduce((x, y) => {
              const { name } = y;
              return {
                ...x,
                [name]: y[rowType],
              };
            }, {})
          ),
        };
      });
    }).reduce((x, y) => [...x, ...y], []);
    const fileContent = encoder.encode(unparseCsv(data.map(_ => mapKeysToJa(_, { diff: '差額', }))));
    fileDownload(fileContent, `月次推移_${targetMonth}.csv`);
    await log(company, 'monthlyTrends', 'exportCsv', user, { period, targetMonth, documentType, });
    this.setState({ isExporting: false });
  }
  render() {
    const { period, company, role, location, history, user, members = [], match: { params: { companyId } } } = this.props;
    const { trials, rows = [], comments = [], isSettingRows = false, isFetchingLastTrials = false, isExporting = false, showsChart = true, journalsAndTrialsFetchJob, } = this.state;
    const queryParams = this.queryParams();
    const { comparison, dimension, documentType, } = queryParams;
    const [, currentYearMonthItems] = this.monthItems();
    const { status: statusOfJob, } = journalsAndTrialsFetchJob?.exists && computeStatus(journalsAndTrialsFetchJob) || {};
    const jobProcessingDate = statusOfJob === 'processing' ? journalsAndTrialsFetchJob.endDate : null;
    const comparisonOptions = entries(monthlyTrendsComparisons).map(([k, v]) => ({ label: v, value: k }));
    const dimensionOptions = entries(dimensionsOfCompany(company)).map(([k, v]) => ({ label: v, value: k }));
    const filteredRows = this.filteredRows();
    const metricOptions = entries(metrics).map(([k, v]) => ({ label: v.label({ comparison }), value: k }));
    const itemNameOptions = uniq(rows.map(_ => _.itemName)).map(_ => ({ label: _, value: _ }));
    const subItemNameOptions = uniq(rows.map(_ => _.subItemName).filter(_ => _)).map(_ => ({ label: _, value: _ }));

    return (
      <div className="company-monthly-trends">
        <div className="p-5">
          <div className="d-flex justify-content-end mb-3">
            <HelpLink text="月次推移を活用する" />
          </div>
          <div className="mb-3">
            <h3 className="text-center">月次推移</h3>
          </div>
          <Nav tabs>
            {
              entries(documentTypes).map(([k, { name }]) => {
                return (
                  <NavItem key={k}>
                    <NavLink className={classnames('cursor-pointer', { active: documentType === k })} onClick={this.toggleTab.bind(this, k)}>
                      {name}
                    </NavLink>
                  </NavItem>
                )
              })
            }
          </Nav>
          <div className="py-3">
            <div className="mb-3 d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center flex-wrap gap-1">
                <TrialQueriesSection 
                  company={company}
                  screenType="monthlyTrends"
                  items={[
                    [
                      {
                        name: 'metrics',
                        type: 'multiSelect',
                        options: metricOptions,
                        label: '数値項目',
                        props: { width: 200, }
                      },
                      {
                        name: 'itemNamesForFilter',
                        type: 'multiSelect',
                        options: itemNameOptions,
                        label: '科目名',
                        isFront: true,
                        props: { width: 300, }
                      },
                      {
                        name: 'dimension',
                        type: 'select',
                        options: dimensionOptions,
                        label: '明細種別',
                        isFront: true,
                        props: { width: 150, selectorProps: { isClearable: false } },
                      },
                      {
                        name: 'comparison',
                        type: 'select',
                        options: comparisonOptions,
                        label: '比較対象',
                        isFront: true,
                        props: { width: 250, selectorProps: { isClearable: false } },
                      },
                    ],
                    [
                      ...(
                        dimension !== 'none' ? [
                          {
                            name: 'isSubFilter',
                            type: 'boolean',
                            label: '明細単位で検索する',
                          },
                        ] : []
                      ),
                      ...(
                        dimension !== 'none' && queryParams.isSubFilter === '1' ? [
                          {
                            name: 'subItemNamesForFilter',
                            type: 'multiSelect',
                            options: subItemNameOptions,
                            label: '明細名',
                            props: { width: 300, }
                          }
                        ] : []
                      ),
                    ],
                    [
                      {
                        name: 'amountMin',
                        type: 'numeric',
                        label: '当期実績下限',
                        props: { width: 200, }
                      },
                      {
                        name: 'amountMax',
                        type: 'numeric',
                        label: '当期実績上限',
                        props: { width: 200, }
                      },
                    ],
                    [
                      {
                        name: 'comparisonAmountMin',
                        type: 'numeric',
                        label: metrics.comparisonAmount.label({ comparison }) + '下限',
                        props: { width: 200, }
                      },
                      {
                        name: 'comparisonAmountMax',
                        type: 'numeric',
                        label: metrics.comparisonAmount.label({ comparison }) + '上限',
                        props: { width: 200, }
                      },
                    ],
                    [
                      {
                        name: 'diffMin',
                        type: 'numeric',
                        label: metrics.diff.label({ comparison }) + '下限',
                        props: { width: 200, }
                      },
                      {
                        name: 'diffMax',
                        type: 'numeric',
                        label: metrics.diff.label({ comparison }) + '上限',
                        props: { width: 200, }
                      },
                    ],
                    [
                      {
                        name: 'diffRateMin',
                        type: 'numeric',
                        label: '増減率下限(%)',
                        props: { width: 200, }
                      },
                      {
                        name: 'diffRateMax',
                        type: 'numeric',
                        label: '増減率上限(%)',
                        props: { width: 200, }
                      },
                    ],
                  ]}
                />
              </div>
              <div className="d-flex align-items-center gap-1">
                <Button color="secondary" size="sm" onClick={this.fetchLastTrials} disabled={isFetchingLastTrials}>
                  網羅性チェック
                </Button>
                <Button color="secondary" onClick={this.onClickExport} disabled={isExporting}>
                  <span className={classnames('fas', { 'fa-spin fa-spinner': isExporting, 'fa-download': !isExporting })} />
                </Button>
                <CommentsDropdown company={company} companyId={companyId} currentUser={user} comments={comments} usersById={keyBy(members, 'id')} onClickComment={this.onClickComment} />
              </div>
            </div>
            {rows.filter(_ => !_.isSubRow).some(_ => _.summaryColumns.find(_ => _.name === 'diff' && abs(_.amount) > 0)) && <div className="alert alert-danger">差額があります</div>}
            <div className="d-flex justify-content-center position-relative" style={{ zIndex: 0 }}>
              <Table className="sticky-table table-hover" style={{ width: 'auto', wordBreak: 'break-all' }}>
                <thead className="text-center" style={{ lineHeight: '20px' }}>
                  <tr>
                    <th className="text-left p-0">
                      <ResizableBox width={COLUMN_WIDTH * 2.5} className="p-2" height={44} axis="x" resizeHandles={['e']} onResize={(e, _) => this.setState({ leftColumnWidth: _.size.width })}>
                        <div className="d-flex align-items-center gap-2 h-100">
                          {
                            dimension !== 'none' && (
                              <AllCollapseButton size="sm" className="small px-0" color="link" rows={filteredRows} visibilities={mapKeys(pickBy(this.state, (v, k) => k.startsWith('shouldShowSubRows__')), (v, k) => k.replace('shouldShowSubRows__', ''))} setVisibilities={_ => { this.setState(mapKeys(_, (v, k) => 'shouldShowSubRows__' + k)); }} />
                            )
                          }
                          <span className={classnames('fas fa-chart-line cursor-pointer', { 'text-success': showsChart, 'text-muted': !showsChart, })} onClick={_ => this.setState({ showsChart: !showsChart, })} />
                          &nbsp;
                        </div>
                      </ResizableBox>
                    </th>
                    {
                      currentYearMonthItems.map(({ closingDate, exists }, i) => {
                        const isProcessingMonth = formatDate(closingDate, 'YYYY-MM-DD') === jobProcessingDate;
                        return (
                          <th key={i} className={classnames('text-nowrap', { 'table-success': exists, 'table-danger': trials && !exists })} style={{ width: COLUMN_WIDTH }}>
                            {formatDate(closingDate, 'YYYY/MM')}
                            {
                              canWriteTrial(user, role) && (
                                <Fragment>
                                  <TrialImportButton companyId={company.id} closingDate={closingDate} dimension={dimension} documentType={documentType} fetchWalletables={this.fetchWalletables.bind(this)} />
                                  <JournalsSyncButton size="sm" className="small ml-1 px-0" color="link" company={company} period={period} targetMonth={formatDate(closingDate, 'YYYYMM')} />
                                </Fragment>
                              )
                            }
                          </th>
                        );
                      })
                    }
                    <th style={{ width: COLUMN_WIDTH }}>期首残高</th>
                    <th style={{ width: COLUMN_WIDTH }}>発生額合計</th>
                    <th style={{ width: COLUMN_WIDTH }}>合計</th>
                    <th style={{ width: COLUMN_WIDTH }}>試算表残高</th>
                    <th style={{ width: COLUMN_WIDTH }}>差額</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredRows.filter(_ => !_.isSubRow || _.shows).map((row) => {
                      return this.renderRow(row);
                    })
                  }
                </tbody>
              </Table>
              <OverlayLoading isOpen={isSettingRows || isFetchingLastTrials} />
            </div>
          </div>
        </div>
      </div>
    );
  }
});

class Column extends Component {
  shouldComponentUpdate(nextProps) {
    const watchedPropNames = [
      '_key',
      'metricsForFilter',
      'amount',
      'comparisonAmount',
      'diff',
      'diffRate',
      'comments',
      'notesByKey',
      'shouldCheck',
    ]
    if(!isEqual(pick(this.props, watchedPropNames), pick(nextProps, watchedPropNames))) {
      return true;
    }
    return false;
  }
  render() {
    const { company, role, metricsForFilter, company: { fiscalYears }, index, isCategory, isCustom, _key: key, members, user, name, isSubRow, closingDate, amount, comparisonAmount, diff, diffRate, isSummaryColumn, itemName, subItemName, period, companyId, dimension, comparison, comments, notesByKey, commenterRef, documentType, displayExpression, style, shouldCheck, } = this.props;
    const comparisonMonth = ({ prevYearSameMonth: addYears(closingDate, -1), prevMonth: addMonths(closingDate, -1) })[comparison];
    const shouldRender = !isSubRow ? (
      !isSummaryColumn ? true : (
        shouldCheck ? true : (
          ['opening', 'sum', 'total'].includes(name)
        )
      )
    ) : (!isSummaryColumn || ['opening', 'sum', 'total'].includes(name));
    const fiscalYear = fiscalYearOfPeriod(period, fiscalYears);
    const prevPeriod = periodOfFiscalYear(fiscalYears[fiscalYears.indexOf(fiscalYear) - 1]);
    return (
      <td className={classnames('text-right has-hovered-contents', { 'table-danger': shouldCheck && !isSubRow && name === 'diff' && abs(amount) > 0 })} style={{ width: COLUMN_WIDTH, ...style }}>
        {
          shouldRender && (
            <Fragment>
              {
                metricsForFilter.includes('amount') && (
                  <div style={{ lineHeight: AMOUNT_HEIGHT_PX }}>
                    {
                      (isCategory || isCustom || isSummaryColumn) ? (
                        <span>{isFiniteNumber(amount) ? numeral(amount).format(displayExpression) : '-'}</span>
                      ) : (
                        <Link to={encodeURI(`/companies/${companyId}/accountItems/${itemName}?${qs.stringify({ period, targetMonth: formatDate(closingDate, 'YYYYMM'), ...(isSubRow && { subItems: [{ dimension, itemNames: [subItemName] }] }), singleMonth: '1' })}`)}>
                          {isFiniteNumber(amount) ? numeral(amount).format(displayExpression) : '-'}
                        </Link>
                      )
                    }
                  </div>
                )
              }
              {
                metricsForFilter.includes('comparisonAmount') && (
                  <div className="text-muted" style={{ lineHeight: AMOUNT_HEIGHT_PX }}>
                    {
                      (isCategory || isCustom || isSummaryColumn) ? (
                        <span>{isFiniteNumber(comparisonAmount) ? numeral(comparisonAmount).format(displayExpression) : '-'}</span>
                      ) : (
                        <Link to={encodeURI(`/companies/${companyId}/accountItems/${itemName}?${qs.stringify({ period: ({ prevYearSameMonth: prevPeriod, prevMonth: index === 0 ? prevPeriod : period })[comparison], targetMonth: formatDate(comparisonMonth, 'YYYYMM'), subItems: [{ dimension, itemNames: [subItemName] }], singleMonth: '1' })}`)}>
                          {isFiniteNumber(comparisonAmount) ? numeral(comparisonAmount).format(displayExpression) : '-'}
                        </Link>
                      )
                    }
                  </div>
                )
              }
              {
                metricsForFilter.includes('diff') && (
                  <div className={classnames('small', { 'text-dark': diff > 0, 'text-danger': diff < 0 })} style={{ lineHeight: AMOUNT_HEIGHT_PX }}>
                    {isFiniteNumber(diff) ? numeral(diff).format('+' + displayExpression) : '-'}
                  </div>
                )
              }
              {
                metricsForFilter.includes('diffRate') && (
                  <div className={classnames('small', { 'text-dark': diff > 0, 'text-danger': diff < 0 })} style={{ lineHeight: AMOUNT_HEIGHT_PX }}>
                    ({isFiniteNumber(diffRate) ? numeral(diffRate).format('+0,0.0%') : '-'})
                  </div>
                )
              }
            </Fragment>
          )
        }
        {
          !isSummaryColumn && (
            <Fragment>
              <HoveredCommenter
                ref={commenterRef.bind(null, key)}
                company={company}
                companyId={companyId}
                currentUser={user}
                commenteeKey={key}
                queryKey="trial"
                values={{ documentType, yearMonth: formatDate(closingDate, 'YYYYMM') }}
                comments={comments}
                users={members}
                about={formatTrialCommentAbout({ documentType, date: closingDate, itemName, subItemName })}
              />
              <HoveredNoter companyId={companyId} noteKey={key} pageType="CompanyMonthlyTrends" queryKey="trial" values={{ documentType, yearMonth: formatDate(closingDate, 'YYYYMM') }} note={notesByKey[key]} writable={canWriteNote(user, role)} />
            </Fragment>
          )
        }
      </td>
    );
  }
}

class RowChart extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if(['data', 'height'].some(_ => !isEqual(this.props[_], nextProps[_]))) {
      return true;
    }
    return false;
  }
  render() {
    const { data, height } = this.props;
    return (
      <div className="item-row-chart-wrapper" style={{ position: 'absolute', top: 0, left: COLUMN_WIDTH / 2, pointerEvents: 'none', zIndex: 2 }}>
        <LineChart width={COLUMN_WIDTH * (data.length - 1)} height={height} data={data}>
          {
            ['amount', 'comparisonAmount'].map((key, i) => {
              return (
                <Line key={key} type="monotone" dataKey={key} stroke={colors[i]} strokeWidth={2} />
              );
            })
          }
        </LineChart>
      </div>
    );
  }
}
