const { isEmpty } = require('lodash');

const fields = ({ freeeSections, customSections, companyMembers, } ) => {
  return {
    name: {
      type: 'string',
      label: '部門名',
      validations: {
        required: v => !isEmpty(v),
        unique: (v, s) => !s.conflicted && !customSections.map(_ => _.name).includes(v),
      },
    },
    sectionIds: {
      type: 'multiSelect',
      label: 'freee部門',
      options: freeeSections.map(_ => ({ label: [...(_.parents.map(_ => _.name)), _.name].join(' - '), value: _.id })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
  };
};

module.exports = {
  parents: function parents(section, allSections) {
    if(section.parent_id == null) {
      return [];
    } else {
      const parent = allSections.find(_ => _.id === section.parent_id);
      return [parent, ...parents(parent, allSections)];
    };
  },
  fields,
};
