import React from 'react';
import { useWindowScroll } from 'react-use';
import { Button, } from 'reactstrap';

export default function ToTop (props) {
  const { threshold = 100 } = props;
  const { x, y } = useWindowScroll();

  return y >= threshold && (
    <div className="position-fixed d-flex flex-column justify-content-center" style={{ right: 5, height: '100%', top: 0, }}>
      <Button color="link" className="shadow-sm bg-white rounded-pill" onClick={_ => window.scrollTo(x, 0)}>
        <span className="fas fa-circle-up" />
      </Button>
    </div>
  );
};
