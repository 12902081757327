import React from 'react';
import { Button, } from 'reactstrap';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';

import firebase from '../firebase';
const auth = firebase.auth();

export default function PleaseConfirmEmail (props) {
  const { firebaseUser } = props;
  const [isSending, toggleSending] = useToggle();
  const onClickSend = async () => {
    toggleSending();
    try {
      await firebaseUser.sendEmailVerification({ url: window.location.href, });
      toast.success('メールアドレス確認メールを送信しました');
    } catch(e) {
      toast.error('送信失敗しました');
      console.error(e);
    }
    toggleSending();
  };

  return (
    <div className="container p-5">
      <p>
        ご登録いただいたメールアドレスにメールをお送りしました。
        <br />
        そちらのメール本文内のリンクをクリックして、メールアドレス確認を完了してください。
        <br />
        完了後、この画面を
        <span onClick={_ => window.location.reload()} className="text-primary cursor-pointer">再読み込み</span>
        してください。
      </p>
      <p>
        <Button color="primary" onClick={onClickSend} disabled={isSending}>
          確認メールを再送信する
        </Button>
      </p>
      <p className="text-muted small">
        ※ メールが届かない場合や、最初からやり直す場合は、一度
        <span onClick={_ => auth.signOut()} className="text-primary cursor-pointer">ログアウト</span>
        してください。
      </p>
    </div>
  );
};
