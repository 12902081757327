import React from 'react';
import classnames from 'classnames';

export default function UserDisplay (props) {
  const { user: { email, displayName, type = 'auditee' } = {} } = props;
  return (
    <>
      <span className={classnames('fas mr-1', ({ auditee: 'text-secondary fa-user', auditor: 'text-info fa-user-tie' })[type])} />
      {displayName}
      <span className="ml-1 small text-muted">
        {`<${email}>`}
      </span>
    </>
  );
};
