import React, { Component } from 'react';
import { Input } from 'reactstrap';
import numeral from 'numeral';
import classnames from 'classnames';

const isNumeric = value => !Number.isNaN(parseFloat(value, 10));

export default class NumberInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: isNumeric(props.value) ? numeral(props.value).format('0,0.[00000]') : props.value,
      prevPropValue: props.value,
    };
  }

  static getDerivedStateFromProps({ value }, { prevPropValue }) {
    const newState = {
      value: isNumeric(value) ? numeral(value).format('0,0.[00000]') : value,
      prevPropValue: value,
    };
    return value !== prevPropValue ? newState : null;
  }

  withinRange = (value) => {
    const { max, min } = this.props;
    if (isNumeric(max) && max < value) return max;
    if (isNumeric(min) && min > value) return min;
    return Number(value);
  }

  onChange = ({ target: { value } }) => {
    const { onChange, } = this.props;
    const numericValue = numeral(value).value()
    if (value === '') {
      onChange(null);
    } else if (isNumeric(numericValue)) {
      onChange(this.withinRange(numericValue));
    }
    this.setState({ value });
  }

  onFocus = ({ target: { value } }) => {
    const removedComma = numeral(value).value();
    if (isNumeric(removedComma)) {
      this.setState({ value: removedComma });
    }
  };

  onBlur = (event) => {
    const { target: { value } } = event;
    const { onChange, onBlur } = this.props;
    const removedComma = numeral(value).value()
    if (isNumeric(removedComma)) {
      onChange(this.withinRange(removedComma));
      this.setState({ value: numeral(this.withinRange(removedComma)).format('0,0.[00000]') });
    }
    setTimeout(() => {
      onBlur && onBlur(event);
    });
  };

  render() {
    const { className = 'text-right', ...extraProps } = this.props;
    const { value } = this.state;
    return (
      <Input
        className={className}
        {...extraProps}
        type="text"
        value={value}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
      />
    );
  }
}
