const { isEmpty } = require('lodash');

const routes = [
  '自動で経理',
  '取引（収入・支出）',
  '口座振替',
  '請求書',
  '振替伝票',
  '手動',
  '振伝取引',
  '減価償却',
  '除却',
  '売却',
  '在庫棚卸',
  '給与計算',
  '開始残高',
  '経費申請',
  '法人税申告',
  'freee受発注',
  '発注書',
  '支払依頼',
  '減損',
  'Square',
  '配賦',
  'freeeカードで経理',
];

module.exports = {
  routes,
};
