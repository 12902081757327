import React, { Component } from 'react';
import { MentionsInput, Mention } from 'react-mentions';

export default class CommentInput extends Component {
  render() {
    const { value, onChange, mentionOptions } = this.props;
    return (
      <MentionsInput
        value={value || ''}
        onChange={onChange}
        allowSpaceInQuery
        className="mentions"
        placeholder="@でメンション"
      >
        <Mention
          trigger="@"
          data={mentionOptions}
          className="mentions__mention"
        />
      </MentionsInput>
    );
  }
};
