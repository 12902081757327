import React from 'react';
import { omit, } from 'lodash';
import { toast } from 'react-toastify';
import { Button, } from 'reactstrap';
import { useToggle, } from 'react-use';
import classnames from 'classnames';

export default function AllCollapseButton (props) {
  const { rows, visibilities, setVisibilities, ...extraProps } = props;
  const collpasibleRows = rows.filter(_ => !_.isSubRow && !_.isCategory && !_.isCustom);
  const onClick = () => {
    setVisibilities(collpasibleRows.reduce((x, y) => ({ ...x, [y.itemKey]: !opensAll }), {}));
  };
  const opensAll = collpasibleRows.every(_ => visibilities[_.itemKey]);

  return (
    <Button onClick={onClick} {...extraProps}>
      <span className={classnames('fas cursor-pointer', { 'fa-plus': !opensAll, 'fa-minus': opensAll })} />
    </Button>
  );
};
