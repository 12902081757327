import React from 'react';
import { toast } from 'react-toastify';
import { Button, } from 'reactstrap';
import { useToggle } from 'react-use';

export default function ProgressButton ({ label = '', icon = null, children, process = _ => _, disabled, ...extraProps }) {
  const [isProcessing, toggleProcessing] = useToggle(false);
  const onClick = async () => {
    toggleProcessing(true);
    await process();
    toggleProcessing(false);
  };

  return (
    <Button onClick={onClick} disabled={isProcessing || disabled} {...extraProps}>
      <span className="d-flex align-items-center gap-1">
        {isProcessing ? <span className="fas fa-spin fa-spinner" /> : icon}
        {children || label}
      </span>
    </Button>
  );
};
