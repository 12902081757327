import React, { Component, Fragment, useEffect, useState, useMemo, } from 'react';
import { Button, Input, Nav, NavItem, NavLink, } from 'reactstrap';
import { isEqual, uniq, sumBy, isEmpty, zip, groupBy, range, keyBy } from 'lodash';
import classnames from 'classnames';
import { format as formatDate, startOfMonth, endOfMonth, addMonths, addMinutes } from 'date-fns';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { useList, } from 'react-use';
import { ResizableBox } from 'react-resizable';

import OverlayLoading from '../OverlayLoading';
import HelpLink from '../HelpLink';
import { log, fiscalYearOfPeriod, fullPathWithParams, } from '../../utils';
import { routes, } from '../../shared/models/journal';
import { usedSegments, dimensionsOfCompany, } from '../../shared/models/company';
import { directions, dimensionValues, } from '../../shared/config';
import { mapKeysToJa, } from '../../shared/texts';
import firebase from '../../firebase';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import usePagination from '../hooks/usePagination';
import CompanyPage from '../hocs/CompanyPage';
import Pagination from '../Pagination';
import ProgressButton from '../ProgressButton';
import QuerySelector from '../QuerySelector';
import ExportButton from '../ExportButton';

const { entries } = Object;
const journalsAnalysisTypes = {
  tax: {
    label: '税区分分析',
    computeAnalysisFactors: _ => _.availableTaxes.map(_ => ({ id: _.id, name: _.name_ja })),
    analysisDimensionOfJournal: _ => _.source[`${directions[_.direction]}税区分`],
    journalsScreenFilterName: 'taxId',
  },
  route: {
    label: '仕訳種別分析',
    computeAnalysisFactors: _ => _.routes.map(_ => ({ id: _, name: _ })),
    analysisDimensionOfJournal: _ => _.source['登録した方法'],
    journalsScreenFilterName: 'route',
  },
};
const db = firebase.firestore();
const companiesRef = db.collection('companies');
const COLUMN_WIDTH = 160;
const countPerPage = 50;

export default CompanyPage(function CompanyJournalsByTax (props) {
  const { company, user, period, targetMonth, location, history, taxes, sortedAccountItems, } = props;
  const availableTaxes = useMemo(_ => taxes.filter(_ => _.available), [taxes]);
  const queryParams = useQueryParams();
  const {
    journalsAnalysisType = 'tax',
    accountItemNames,
    dimension = 'none',
  } = queryParams;
  const { label: journalsAnalysisTypeLabel, computeAnalysisFactors, analysisDimensionOfJournal, } = journalsAnalysisTypes[journalsAnalysisType];
  const analysisFactors = useMemo(_ => computeAnalysisFactors({ availableTaxes, routes, }), [journalsAnalysisType, availableTaxes, routes]);
  const columnWidth = Math.max(COLUMN_WIDTH, 1400 / (analysisFactors.length + 2 + (dimension === 'none' ? 0 : 1)));
  const [columnWidths, { set: setColumnWidths, updateAt: updateColumnWidthAt, }] = useList([columnWidth * 1.5, columnWidth]);
  const [opens, setOpens] = useState({});
  const journalMetas = useCollectionSubscription(company.ref.collection('journalMetas').where('period', '==', period), [company, period]);
  const freeeSections = useCollectionSubscription(company.ref.collection('sections'), [company]);
  const allFreeeSections = [{ id: 0, name: '未選択', }, ...freeeSections]
  const freeeSectionsById = keyBy(allFreeeSections, 'id');
  const journals = useCollectionSubscription(company.ref.collection('journals').where('date', '>=', formatDate(startOfMonth(targetMonth + '01'), 'YYYY/MM/DD')).where('date', '<=', formatDate(endOfMonth(targetMonth + '01'), 'YYYY/MM/DD')), [company, targetMonth]);
  const journalsGroupedByAccountName = groupBy(journals, 'accountName');
  const dimensionOptions = entries(dimensionsOfCompany(company)).map(([k, v]) => ({ label: v, value: k }));
  const subItems = useCollectionSubscription(dimension !== 'none' && company.ref.collection(dimensionValues[dimension].collectionName), [company, dimension]);
  const subItemsByName = keyBy([{ id: 0, name: '未選択', }, ...subItems], 'name');
  const accountItemNameOptions = sortedAccountItems.map(_ => ({ label: _.name, value: _.name, }));
  const mainRowGroups = useMemo(() => {
    return sortedAccountItems.map((accountItem) => {
      const journals = journalsGroupedByAccountName[accountItem.name];
      const journalsGroupedByAnalysisFactors = groupBy(journals, _ => analysisDimensionOfJournal(_));
      const subItemNames = dimension === 'none' ? [] : ['未選択', ...uniq(journals?.map(_ => _[dimension]).filter(_ => _))];
      const [mainRow, ...subRows] = [null, ...subItemNames].map((subItemName) => {
        const columns = analysisFactors.map((analysisFactor) => {
          const journals = journalsGroupedByAnalysisFactors[analysisFactor.name] || [];
          const filteredJournals = subItemName == null ? journals : journals.filter(_ => _[dimension] === (subItemName == '未選択' ? '' : subItemName));
          const amount = sumBy(filteredJournals, _ => _.amount * ({ debit: -1, credit: 1 })[_.direction]);
          return {
            analysisFactor,
            amount,
            journals,
          };
        });
        const totalAmount = sumBy(columns, 'amount');
        return {
          accountItem,
          subItemName,
          key: [accountItem.id, subItemName].join('__'),
          subject: accountItem.name,
          columns,
          totalAmount,
          isSubRow: subItemName != null,
        };
      });
      return {
        key: accountItem.id,
        accountItem,
        rows: [mainRow, ...subRows],
        journals,
        subItemNames,
      };
    }).filter(_ => _.journals?.length > 0);
  }, [sortedAccountItems, journalsAnalysisType, dimension, journals, analysisFactors]);

  let filteredMainRowGroups = mainRowGroups;
  if(!isEmpty(accountItemNames)) {
    filteredMainRowGroups = filteredMainRowGroups.filter(_ => accountItemNames.includes(_.accountItem.name));
  }

  const pagination = usePagination({ countPerPage, totalCount: filteredMainRowGroups.length, });
  const { pageNumber, offset, first, } = pagination;
  const displayMainRowGroups = filteredMainRowGroups.slice(offset, offset + countPerPage);
  const summaryRowGroups = [{
    key: 'total',
    rows: [
      (() => {
        const columns = analysisFactors.map((analysisFactor, i) => {
          const amount = sumBy(mainRowGroups, _ => sumBy(_.rows, _ => _.columns[i].amount));
          return {
            analysisFactor,
            amount,
            journals: [],
          };
        });
        const totalAmount = sumBy(columns, 'amount');
        return {
          isTotal: true,
          key: 'total',
          accountItem: null,
          subject: '合計',
          columns,
          totalAmount,
          journals: [],
        };
      })(),
    ],
  }];
  const displayRowGroups = [...displayMainRowGroups, ...summaryRowGroups];
  const isOpenAll = mainRowGroups.every(_ => opens[_.key]);
  const onClickToggleAllSubRows = () => {
    setOpens(mainRowGroups.reduce((x, y) => ({ ...x, [y.key]: !isOpenAll, }), {}));
  };
  const rowsForExport = [...filteredMainRowGroups, ...summaryRowGroups].flatMap(_ => _.rows).map((row) => {
    const { subject, subItemName, columns, totalAmount, } = row;
    return mapKeysToJa({
      subject,
      ...(
        dimension !== 'none' && {
          subItemName,
        }
      ),
      ...columns.reduce((x, column) => {
        const { amount, analysisFactor } = column;
        return {
          ...x,
          [analysisFactor.name]: amount,
        };
      }, {}),
      totalAmount,
    }, { subject: '科目' });
  });
  const logExport = async () => {
    await log(company, 'journalsByTax', 'exportCsv', user, { period, targetMonth, });
  };
  const toggleTab = (journalsAnalysisType) => {
    history.replace(location.pathname + '?' + qs.stringify({ ...queryParams, journalsAnalysisType, }));
  };
  useEffect(() => {
    const path = fullPathWithParams({
      dimension: dimension || 'none',
    }, location);
    history.replace(encodeURI(path));
  }, []);
  useEffect(() => {
    setColumnWidths([columnWidth * 1.5, columnWidth]);
  }, [columnWidth]);

  return (
    <div className="company-journals-by-tax">
      <div className="py-5 container-fluid">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="仕訳分析を行う" />
        </div>
        <div className="mb-3 d-flex flex-column justify-content-center align-items-stretch">
          <h3 className="text-center">仕訳分析</h3>
        </div>
        <Nav tabs className="mb-2">
          {
            entries(journalsAnalysisTypes).map(([k, { label }]) => {
              return (
                <NavItem key={k}>
                  <NavLink className={classnames('cursor-pointer', { active: journalsAnalysisType === k })} onClick={toggleTab.bind(null, k)}>
                    {label}
                  </NavLink>
                </NavItem>
              )
            })
          }
        </Nav>
        <div className="d-flex align-items-end justify-content-start gap-2">
          <div className="d-flex align-items-end justify-content-start gap-1">
            <QuerySelector paramName="accountItemNames" width={180} isMulti options={accountItemNameOptions} label="科目で絞り込み" />
            <QuerySelector paramName="dimension" width={180} options={dimensionOptions} label="明細" />
          </div>
          <ExportButton outline fileName={`${journalsAnalysisTypeLabel}.csv`} rows={rowsForExport} onFinish={logExport} />
        </div>
        <div className="mt-2 d-flex align-items-end gap-1">
          <Pagination {...pagination} />
        </div>
        <div className="mt-2 d-flex justify-content-start position-relative" style={{ zIndex: 0 }}>
          <table className="table table-hover sticky-table">
            <thead className="thead-light text-center">
              <tr>
                {
                  dimension !== 'none' && (
                    <th style={{ borderRight: 'none', width: 50, height: 75, }}>
                      <Button size="sm" className="small px-0" color="link" onClick={onClickToggleAllSubRows}>
                        <span className={classnames('fas cursor-pointer', { 'fa-plus': !isOpenAll, 'fa-minus': isOpenAll })} />
                      </Button>
                      &nbsp;
                    </th>
                  )
                }
                <th style={{ borderLeft: 'none', height: 75,}}>
                  <ResizableBox width={columnWidths[0]} height={24} axis="x" resizeHandles={['e']} onResize={(e, _) => updateColumnWidthAt(0, _.size.width,)}>&nbsp;</ResizableBox>
                </th>
                {
                  dimension !== 'none' && (
                    <th style={{ height: 75, }}>
                      <ResizableBox width={columnWidths[1]} height={24} axis="x" resizeHandles={['e']} onResize={(e, _) => updateColumnWidthAt(1, _.size.width,)}>&nbsp;</ResizableBox>
                    </th>
                  )
                }
                {
                  analysisFactors.map((analysisFactor) => {
                    return (
                      <th key={analysisFactor.id} style={{ width: columnWidth, height: 75, verticalAlign: 'middle', }}>
                        {analysisFactor.name}
                      </th>
                    );
                  })
                }
                <th style={{ width: columnWidth, height: 75, }}>合計</th>
              </tr>
            </thead>
            <tbody>
              {
                displayRowGroups.map((rowGroup) => {
                  const { key, } = rowGroup;
                  return (
                    <RowGroup key={key} {...{ columnWidth, opens, dimension, company, period, targetMonth, subItemsByName, rowGroupKey: key, setOpens, ...rowGroup, columnWidths, } } />
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});

const RowGroup = React.memo(function (props) {
  const { columnWidth, opens, dimension, company, period, targetMonth, subItemsByName, rowGroupKey, setOpens, rows, columnWidths, } = props;
  const isOpen = !!opens[rowGroupKey];
  const onClickToggleSubRows = () => {
    setOpens({ ...opens, [rowGroupKey]: !isOpen });
  };

  return (
    <Fragment>
      {
        (isOpen ? rows : rows.slice(0, 1)).map((row) => {
          const { key } = row;
          return (
            <Row key={key} {...{ columnWidth, dimension, company, period, targetMonth, subItemsByName, isOpen, rowGroupKey, onClickToggleSubRows, ...row, columnWidths, } } />
          );
        })
      }
    </Fragment>
  );
}, isEqual);

const Row  = React.memo(function (props) {
  const { columnWidth, dimension, subItemsByName, isTotal, onClickToggleSubRows, isOpen, isSubRow, subject, subItemName, accountItem, columns, totalAmount, company, period, targetMonth, columnWidths, } = props;
  const { journalsAnalysisType = 'tax' } = useQueryParams();
  const { journalsScreenFilterName, } = journalsAnalysisTypes[journalsAnalysisType];
  const subItem = subItemsByName[subItemName];
  const SubItemComponent = isSubRow ? 'th' : 'td';

  return (
    <tr className={classnames({ 'font-weight-bold': isTotal })}>
      {
        dimension !== 'none' && (
          <th style={{ borderTop: isSubRow && 'none', borderRight: 'none', borderBottom: 'none', borderLeft: 'none', width: 50, }}>
            {
              !isSubRow && !isTotal && (
                <Button size="sm" className="small px-0 mr-1" color="link" onClick={onClickToggleSubRows}>
                  <span className={classnames('fas cursor-pointer', { 'fa-plus': !isOpen, 'fa-minus': isOpen })} />
                </Button>
              )
            }
          </th>
        )
      }
      <th style={{ width: columnWidths[0], borderLeft: 'none', borderBottom: 'none', borderTop: isSubRow && 'none', borderRight: 'none', }}>
        {!isSubRow && subject}
      </th>
      {
        dimension !== 'none' && (
          <SubItemComponent className="font-weight-normal" style={{ width: columnWidths[1], borderLeft: 'none' }}>
            {subItemName}
          </SubItemComponent>
        )
      }
      {
        columns.map((column) => {
          const { amount, analysisFactor } = column;
          return (
            <td key={analysisFactor.id} className="text-right" style={{ width: columnWidth }}>
              {
                isTotal ? (
                  numeral(amount).format()
                ) : (
                  <Link to={encodeURI(`/companies/${company.id}/journals?${qs.stringify({ period, targetMonth, [journalsScreenFilterName]: analysisFactor.id, accountItemName: accountItem.name, ...(dimension !== 'none' && { [`${dimension.replace(/s$/, '')}Id`]: subItem?.id, }), })}`)}>
                    {numeral(amount).format()}
                  </Link>
                )
              }
            </td>
          );
        })
      }
      <td className="text-right" style={{ width: columnWidth }}>
        {numeral(totalAmount).format()}
      </td>
    </tr>
  );
}, isEqual);
