import React, { useState, useEffect } from 'react';
import { Button, FormGroup, Input, Label, } from 'reactstrap';
import { get, keyBy, difference, last, sortBy, } from 'lodash';
import { toast } from 'react-toastify';
import { format as formatDate, endOfMonth, } from 'date-fns';
import { useToggle, useMap, useList, } from 'react-use';
import { Link } from 'react-router-dom';
import qs from 'qs';
import classnames from 'classnames';
import numeral from 'numeral';
import { TextEncoder, } from 'text-encoding';
import { unparse as unparseCsv, } from 'papaparse';
import fileDownload from 'js-file-download';

import firebase from '../../firebase';
import { startOfMonthByFiscalYears, } from '../../utils';
import { mapKeysToJa, } from '../../shared/texts';
import { journalTags, detectionStatuses, dimensions, } from '../../shared/config';
import env from '../../env';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import HelpLink from '../HelpLink';
import CheckButton from '../Detection/CheckButton';
import IgnoreButton from '../Detection/IgnoreButton';
import ResetIgnoredButton from '../Detection/ResetIgnoredButton';
import CompanyPage from '../hocs/CompanyPage';

const db = firebase.firestore();
const auth = firebase.auth();
const companiesRef = db.collection('companies');
const { keys } = Object;
const COUNT_PER_PAGE = 100;

export default CompanyPage(CompanyTrialsCheck);

function CompanyTrialsCheck(props) {
  const { period, targetMonth, company, company: { sourceId: companySourceId, }, match: { params: { companyId } } } = props;
  const [selectedDetections, { set: setSelectedDetections, push: selectDetection, removeAt: removeSelectedDetectionAt }] = useList([]);
  const unselectDetection = _ => removeSelectedDetectionAt(selectedDetections.indexOf(_));
  const [showsAll, toggleShowsAll] = useToggle();
  const [isFetching, toggleFetching] = useToggle();
  const [isExporting, toggleExporting] = useToggle();
  const [syncingDates, { set: setSyncingDate, remove: removeSyncingDate }] = useMap();
  const [limit, setLimit] = useState(COUNT_PER_PAGE);
  const isSelecting = selectedDetections.length > 0;
  const trialsCheckJobs = useCollectionSubscription(companiesRef.doc(companyId).collection('trialsCheckJobs').where('period', '==', period), [companyId, period]);
  const sortedTrialsCheckJobs = sortBy(trialsCheckJobs, _ => _.createdAt.toDate());
  let detectionsRef = companiesRef
    .doc(companyId)
    .collection('trialsCheckDetections')
    .where('targetMonth', '==', targetMonth)
    .limit(limit);
  if(!showsAll) detectionsRef = detectionsRef.where('status', '==', 'initial');
  const detections = useCollectionSubscription(detectionsRef, [companyId, period, targetMonth, showsAll, limit], { initialItems: null });
  const sortedDetections = sortBy(detections || [], 'closingDate');
  const customJournalsCheckRules = useCollectionSubscription(companiesRef.doc(companyId).collection('customJournalsCheckRules'), [companyId]);
  const customJournalsCheckRulesById = keyBy(customJournalsCheckRules, 'id');
  const isChecking = sortedTrialsCheckJobs.filter(_ => _.status === 'initial').length > 0;
  const isFailed = (last(sortedTrialsCheckJobs) || {}).status === 'failed';
  const hasLoadedAll = detections.length < limit;
  const loadMore = async () => {
    toggleFetching(true);
    setLimit(limit + COUNT_PER_PAGE);
  };
  const onClickExport = async () => {
    toggleExporting();
    const { docs: detectionDocs } = await companiesRef
      .doc(companyId)
      .collection('trialsCheckDetections')
      .where('period', '==', period)
      .where('targetMonth', '==', targetMonth)
      .get();
    const items = detectionDocs.map((detectionDoc) => {
      const { targetMonth, accountName, subjectName, invalidDimensions = [], type, status, ignoringNote, customJournalsCheckRuleId, } = detectionDoc.data();
      const description = (({
        dimensionBalances: () => {
          return invalidDimensions.map(({ dimension, invalidRows }) => {
            return invalidRows.map((invalidRow) => (
              `${journalTags[dimension]}「${invalidRow.name}」の残高が${numeral(invalidRow.closing_balance).format('0,0')}です`
            ))
          }).flat().join('\n');
        },
        custom: () => get(customJournalsCheckRulesById, `${customJournalsCheckRuleId}.message`),
      }) || (() => ''))[type]();
      return mapKeysToJa({
        targetMonth,
        subjectName: subjectName || accountName,
        description,
        status: detectionStatuses[status],
        ignoringNote,
      });
    });
    const encoder = new TextEncoder('Shift_JIS', { NONSTANDARD_allowLegacyEncoding: true });
    const fileContent = encoder.encode(unparseCsv(sortBy(items, 'date')));
    fileDownload(fileContent, `残高チェック検出事項_${period}.csv`);
    toggleExporting();
  };
  useEffect(() => {
    if(detections && detections.length > 0) {
      toggleFetching(false);
    }
  }, [detections]);
  useEffect(() => {
    setLimit(COUNT_PER_PAGE);
  }, [period, targetMonth, showsAll]);

  return (
    <div className="company-custom-account-items">
      <div className="container py-5">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="残高チェックを行う" />
        </div>
        <div className="d-flex justify-content-center mb-2">
          <h3>残高チェック</h3>
        </div>
        {
          isFailed && (
            <div className="alert alert-danger">
              {formatDate(last(sortedTrialsCheckJobs).finishedAt.toDate(), 'YYYY/MM/DD HH:mm:ss')}に失敗しました。
              <br />
              "{last(sortedTrialsCheckJobs).errorMessage}"
            </div>
          )
        }
        <div className="mb-3 d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center flex-wrap">
            <FormGroup check>
              <Label check>
                <Input type="checkbox" checked={showsAll} onChange={toggleShowsAll} className="mr-1" />
                無視済みのものも表示する
              </Label>
            </FormGroup>
          </div>
          <div className="d-flex align-items-center">
            <Button color="secondary" onClick={onClickExport} disabled={isExporting || isSelecting} className="ml-2">
              <span className={classnames('fas mr-1', { 'fa-spin fa-spinner': isExporting, 'fa-download': !isExporting })} />
              エクスポート
            </Button>
            <CheckButton className="ml-2" collectionRef={companiesRef.doc(companyId).collection('trialsCheckJobs')} period={period} targetMonth={targetMonth} isChecking={isChecking} disabled={isSelecting} />
          </div>
        </div>
        {
          isSelecting && (
            <div className="w-100 position-fixed" style={{ bottom: 0, left: 0, zIndex: 3, }}>
              <div className="container">
                <div className="card p-2" style={{ backgroundColor: 'rgba(255, 255, 255, 0.85' }}>
                  <div className="p-2 d-flex justify-content-between align-items-center">
                    <div>
                      {selectedDetections.length} 件を選択中
                    </div>
                    <div className="d-flex">
                      <IgnoreButton className="ml-2" detections={selectedDetections} onFinished={_ => setSelectedDetections([])} />
                      <ResetIgnoredButton className="ml-2" detections={selectedDetections} onFinished={_ => setSelectedDetections([])} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        {
          sortedDetections.length > 0 ? (
            <div>
              <table className="table table-hover">
                <thead className="text-center thead-light" style={{ lineHeight: '20px' }}>
                  <tr>
                    <th>
                      <Input type="checkbox" className="position-relative m-0" checked={difference(sortedDetections, selectedDetections).length === 0} onChange={_ => _.target.checked ? setSelectedDetections(sortedDetections) : setSelectedDetections([])} />
                    </th>
                    <th>年月</th>
                    <th>主体</th>
                    <th>検出事項</th>
                    <th>状態</th>
                    {showsAll && <th>無視メモ</th>}
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    sortedDetections.map((detection, i) => {
                      const { id, targetMonth, accountName, subjectName, type, status, ignoringNote, } = detection;
                      const statusText = detectionStatuses[status];
                      const DetectionText = ({
                        dimensionBalances: DimensionBalancesDetectionText,
                        custom: CustomRuleDetectionText,
                      })[type];
                      const closingDate = endOfMonth(targetMonth + '01');

                      return (
                        <tr key={id} className={classnames({ 'table-secondary': status === 'ignored', })} data-id={id}>
                          <td>
                            <Input type="checkbox" className="position-relative m-0" checked={selectedDetections.includes(detection)} onClick={_ => selectedDetections.includes(detection) ? unselectDetection(detection) : selectDetection(detection)} />
                          </td>
                          <td>
                            {targetMonth}
                          </td>
                          <td>
                            {subjectName || accountName}
                          </td>
                          <td>
                            <DetectionText {...{ accountName, detection, companyId, period, targetMonth, customJournalsCheckRulesById, }} />
                          </td>
                          <td className={classnames({ 'text-muted': status === 'initial' })}>
                            {statusText}
                          </td>
                          {
                            showsAll && (
                              <td>
                                <div style={{ whiteSpace: 'pre-line' }}>
                                  {ignoringNote}
                                </div>
                              </td>
                            )
                          }
                          <td className="text-nowrap text-right">
                            {
                              (({
                                initial: () => <IgnoreButton detections={[detection]} disabled={isSelecting} />,
                                ignored: () => <ResetIgnoredButton detections={[detection]} disabled={isSelecting} />,
                              })[status] || (_ => null))()
                            }
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
              {
                !hasLoadedAll && (
                  <div className="d-flex justify-content-center">
                    <Button color="primary" onClick={loadMore} disabled={isFetching}>
                      {isFetching && <span className="fas fa-spinner fa-spin mr-1" />}
                      もっと読み込む
                    </Button>
                  </div>
                )
              }
            </div>
          ) : (
            <span>検出事項はありません</span>
          )
        }
      </div>
    </div>
  );
}

function DimensionBalancesDetectionText ({ accountName, detection, companyId, period, targetMonth, }) {
  const { invalidDimensions = [],} = detection;
  return (
    <div>
      <ul>
        {
          invalidDimensions.map(({ dimension, invalidRows }) => {
            return invalidRows.map((invalidRow) => (
              <li key={dimension}>
                {journalTags[dimension]}「{invalidRow.name}」の残高が
                <Link to={encodeURI(`/companies/${companyId}/accountItems/${accountName}?${qs.stringify({ period, targetMonth, subItems: [{ dimension, itemNames: [invalidRow.name], }] })}`)}>
                  {numeral(invalidRow.closing_balance).format('0,0')}
                </Link>
                です
              </li>
            ))
          }).flat()
        }
      </ul>
      <Link className="small ml-2" to={`/companies/${companyId}/accountItemSettings?${qs.stringify({ period, targetMonth, accountName })}`} target="_blank">
        [設定]
      </Link>
    </div>
  );
}

function CustomRuleDetectionText ({ detection, companyId, customJournalsCheckRulesById, period, targetMonth, }) {
  const queryParams = useQueryParams();
  const { customJournalsCheckRuleId, customPartnerId, subjectName, accountItemName, dimension = 'none', subName, } = detection;
  const customJournalsCheckRule = customJournalsCheckRulesById[customJournalsCheckRuleId];
  if(customJournalsCheckRule == null) return null;

  const { message } = customJournalsCheckRule;

  // TODO: 汎用的にする必要がある
  return ({
    balance: _ => (
      <div>
        {message}
        <Link className="small ml-2" to={`/companies/${companyId}/partnerBalances?${qs.stringify({ ...queryParams, customPartnerType: 'customer', customPartnerIds: [customPartnerId], })}`} target="_blank">
          [詳細]
        </Link>
      </div>
    ),
    noneDimensionBalance: _ => (
      <div>
        {message}
        <Link className="small ml-2" to={`/companies/${companyId}/accountItems/${accountItemName || subjectName}?${qs.stringify({ period, targetMonth, ...(dimension !== 'none' && { subItems: [{ dimension, itemNames: [subName], }], }), })}`} target="_blank">
          [詳細]
        </Link>
      </div>
    ),
  })[customJournalsCheckRule.ruleType]();
};
