import React, { useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, } from 'reactstrap';
import { omit, get, mapValues } from 'lodash';
import { useList, } from 'react-use';

import { fields, metricFields, } from '../../shared/models/customChart';
import { fieldDisplayValue } from '../../shared/util';
import { colors } from '../../shared/config';
import useFormState from '../hooks/useFormState';
import Field from '../Field';
import ListForm from '../ListForm';

const { entries } = Object;

function MetricForm (props) {
  const { scopeType, chartType, customSections, accountItems, customAccountItems, values, onChange, index, partners, freeeItems, sections, } = props;
  const _metricFields = metricFields({ scopeType, chartType, customSections, accountItems, customAccountItems, index, partners, freeeItems, sections, });
  const _metricFieldsWithOptions = {
    ..._metricFields,
    color: {
      ..._metricFields.color,
      options: colors.map((_, i) => ({ label: <div style={{ background: _, width: 30, height: 30, }}></div>, value: i })),
    },
  };
  const statedFields = useFormState(values, _metricFieldsWithOptions, values);
  useEffect(() => {
    onChange({
      ...mapValues(statedFields, 'value'),
      isValid: Object.values(statedFields).every(_ => _.isValid),
    });
  }, [...Object.values(statedFields).map(_ => _.value)]);

  return (
    <div className="d-flex flex-wrap">
      {
        entries(statedFields).map(([fieldName, fieldSetting]) => {
          const values = mapValues(statedFields, 'value');
          const width = ({
            metricId: 200,
            color: 80,
          })[fieldName] || 150;
          return !fieldSetting.shouldHide && (
            <div className="mr-2" style={{ width, }} title={fieldDisplayValue(fieldSetting.value, { ...fieldSetting, values })}>
              <Field name={fieldName} {...fieldSetting} values={values} inputProps={{ components: { IndicatorsContainer: _ => null } }}/>
            </div>
          );
        })
      }
    </div>
  );
}

export default function CustomChartFormModal(props) {
  const { customSections, accountItems, customAccountItems, isOpen, values, onClickClose, partners, freeeItems, sections, } = props;
  const isNew = !values;
  const statedFields = useFormState(values, fields(), isOpen);
  const [metrics, { set: setMetrics, updateAt: updateMetricAt }] = useList(get(values, 'metrics') || []);
  const isUnsubmittable =
    Object.values(statedFields).some(_ => !_.isValid) ||
    metrics.some(_ => !_.isValid);
  const onSubmit = (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;
    props.onSubmit({ ...mapValues(statedFields, 'value'), metrics: metrics.map(_ => omit(_, 'isValid')), });
  };
  const onChangeMetrics = (items, prevItems) => {
    setMetrics(items);
  };

  return (
    <Modal isOpen={isOpen} style={{ minWidth: 800 }}>
      <ModalHeader>
        チャート {isNew ? '追加' : '編集'}
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          {
            entries(statedFields).map(([fieldName, fieldSetting]) => (
              <Field
                key={fieldName}
                name={fieldName}
                {...fieldSetting}
              />
            ))
          }
          <div>
            <label>データ</label>
            <ListForm
              items={metrics}
              renderItem={(item, itemIndex) => {
                return (
                  <div className="card p-3">
                    <MetricForm
                      scopeType={statedFields.scopeType.value}
                      chartType={statedFields.chartType.value}
                      metrics={metrics}
                      index={itemIndex}
                      values={item}
                      onChange={_ => updateMetricAt(itemIndex, { ...item, ..._, })}
                      {...{ customSections, accountItems, customAccountItems, partners, freeeItems, sections, }}
                    />
                  </div>
                );
              }}
              onChange={onChangeMetrics}
              minItems={1}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>保存</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
