import React, { Fragment, useState } from 'react';
import { Button, Form, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import { isEmail } from 'validator';
import copy from 'copy-to-clipboard';
import { isEqual, isEmpty, mapValues, pick, omit, get, } from 'lodash';
import Select from 'react-select';

import firebase, { functions } from '../../firebase';
import { fields, addingFields, } from '../../shared/models/member';
import UserDisplay from '../UserDisplay';
import HelpLink from '../HelpLink';
import CompanyPage from '../hocs/CompanyPage';
import { log } from '../../utils';
import { roles } from '../../shared/config';
import { canWriteCompanyMember } from '../../abilities';
import useFormState from '../hooks/useFormState';
import Field from '../Field';
import ModelFormModal from '../modals/ModelFormModal';
import ModalButton from '../ModalButton';

const db = firebase.firestore();
const usersRef = db.collection('users');
const { entries } = Object;
const roleOptions = entries(roles).map(([k, v]) => ({ label: v, value: k, }));
const addCompanyMember = functions.httpsCallable('addCompanyMember');
const updateCompanyMember = functions.httpsCallable('updateCompanyMember');
const removeCompanyMember = functions.httpsCallable('removeCompanyMember');

function CompanyMembers (props) {
  const { user, company, members = [], match: { params: { companyId } } } = props;
  const creationUrl = `${window.location.origin}/?signUp=1`;
  const onClickRemove = async (member) => {
    if(!window.confirm('本当に除外しますか？')) return;

    await removeCompanyMember({ companyId, memberId: member.id, });
    await company.ref.update({ updatedAt: new Date() });
    toast.success('除外しました');
    await log(company, 'member', 'remove', user, { data: { id: member.uid, ...pick(member, ['displayName', 'email', 'type', 'role']) } });
  };
  const onClickCopy = () => {
    copy(creationUrl);
    toast.success('クリップボードにコピーしました');
  }

  return (
    <div className="company-members">
      <div className="container py-5">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="メンバー・権限管理" />
        </div>
        <div className="d-flex justify-content-center mb-2">
          <h3>ユーザー設定</h3>
        </div>
        <div className="d-flex justify-content-end">
          {
            canWriteCompanyMember(user, companyId) && (
              members.length >= (company.licenseCount || 10) ? (
                <div className="flex-fill alert alert-warning">
                  メンバー数の上限に達しています。
                </div>
              ) : (
                <ModalButton title="追加する" content={_ => <AddingFormModalContent {..._} company={company} user={user} />}>
                  <span className="fas fa-plus mr-1" />
                  追加する
                </ModalButton>
              )
            )
          }
        </div>
        <table className="table table-hover mt-5">
          <tbody>
            {
              (members || []).map((member) => {
                const { uid, companies, } = member;
                const role = get(companies, `${companyId}.role`);
                const enabledScreens = get(companies, `${companyId}.enabledScreens`);
                const slackUserId = get(companies, `${companyId}.slackUserId`);
                const onSubmitEdit = async (toggleModal, values) => {
                  if(role !== 'owner' && values.role === 'owner' && !window.confirm('オーナーを変更しますか？（現在のオーナーは管理者になります）')) return;

                  await updateCompanyMember({ companyId, memberId: uid, ...values, ...(role !== 'owner' && { currentOwnerId: members.find(_ => _.companies[companyId].role === 'owner')?.id, }), });
                  await company.ref.update({ updatedAt: new Date() });
                  if(!isEqual([role, enabledScreens], [values.role, values.enabledScreens])) {
                    await log(company, 'member', 'update', user, {
                      data: { id: uid, ...pick(member, ['displayName', 'email', 'type']), ...values },
                      prevData: { id: uid, ...pick(member, ['displayName', 'email', 'type']), ...{ role, enabledScreens: enabledScreens || [], }, },
                    });
                  }
                  toast.success('更新しました');
                  toggleModal(false);
                };

                return (
                  <tr key={uid}>
                    <td>
                      <UserDisplay user={member} />
                      {!isEmpty(slackUserId) && <div className="text-muted small">({slackUserId})</div>}
                    </td>
                    <td>
                      <span>{roles[role]}</span>
                    </td>
                    <td className="text-right text-nowrap">
                      {
                        canWriteCompanyMember(user, companyId) && (
                          <Fragment>
                            <ModalButton Modal={ModelFormModal} modalProps={({ toggleModal }) => ({ title: '編集', fields: fields({ company, currentRole: role, }), values: { role, enabledScreens, slackUserId, }, onSubmit: onSubmitEdit.bind(null, toggleModal), })}>
                              <span className="fas fa-edit mr-1" />
                              編集
                            </ModalButton>
                            <Button className="ml-1 bg-danger text-light" onClick={onClickRemove.bind(null, { ...member, role })} disabled={role === 'owner' || user.uid === uid}>
                              <span className="fas fa-trash mr-1" />
                              除外
                            </Button>
                          </Fragment>
                        )
                      }
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

function AddingFormModalContent (props) {
  const { company, user, toggleModal, } = props;
  const statedFields = useFormState(null, addingFields(), false);
  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;

    toggleSubmitting(true);
    const values = mapValues(statedFields, 'value');
    const { data: { type, user: addedUser, } } = await addCompanyMember({ companyId: company.id, ...values, });
    const message = ({
      exists: 'すでにメンバーです',
      add: '追加しました',
      invite: '招待しました',
    })[type];
    toast[type === 'exists' ? 'error' : 'success'](message);
    const logData = ({
      add: { ...addedUser, ...pick(values, ['role', 'enabledScreens']), },
      invite: { ...pick(values, 'email'), },
    })[type];
    logData && await log(company, 'member', type, user, { data: logData, });
    toggleSubmitting(false);
    toggleModal(false);
  };

  return (
    <Form onSubmit={onSubmit}>
      {
        entries(statedFields).map(([fieldName, fieldSetting]) => (
          <Field
            key={fieldName}
            name={fieldName}
            values={mapValues(statedFields, 'value')}
            {...fieldSetting}
          />
        ))
      }
      <Button block className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable || isSubmitting}>追加する</Button>
    </Form>
  );
}

function AddingForm (props) {
}

CompanyMembers.PeriodSelectDisabled = true;
CompanyMembers.MonthSelectDisabled = true;

export default CompanyPage(CompanyMembers);
