const { groupBy, invoke, sum, sumBy, uniq, keyBy, } = require('lodash');

const accountItemCategories = [
  ...[
    ...[
      { name: '現金・預金', parent: '流動資産', hidden: true, },
      { name: '売上債権', parent: '流動資産', hidden: true, },
      { name: '棚卸資産', parent: '流動資産', hidden: true, },
      { name: '他流動資産', parent: '流動資産', hidden: true, },
      { name: '流動資産', parent: '資産', },
      { name: '有価証券', parent: '資産', },
      { name: '有形固定資産', parent: '固定資産', },
      { name: '無形固定資産', parent: '固定資産', },
      { name: '投資その他の資産', parent: '固定資産', },
      { name: '固定資産', parent: '資産', },
      { name: '繰延資産', parent: '資産', },
      { name: '諸口', parent: '資産', },
      { name: '資産', },
    ].map(_ => ({ ..._, direction: 'debit', hierarchyLevelOffset: 1, })),
    ...[
      { name: '仕入債務', parent: '流動負債', hidden: true, },
      { name: '他流動負債', parent: '流動負債', hidden: true, },
      { name: '流動負債', parent: '負債', },
      { name: '固定負債', parent: '負債', },
      { name: '負債', parent: '負債及び純資産', },
      { name: '資本金', roma: 'shihon', parent: '株主資本', hidden: true, closesItems: true, },
      { name: '新株式申込証拠金', roma: 'shinkabushiki', parent: '株主資本', closesItems: true, },
      { name: '資本準備金', roma: 'shihonjunbi', parent: '資本剰余金', closesItems: true, },
      { name: 'その他資本剰余金', roma: 'sonotashihonjouyo', parent: '資本剰余金', closesItems: true, },
      { name: '資本剰余金', roma: 'shihonjouyo', parent: '株主資本', closesItems: true, },
      { name: '利益準備金', roma: 'riekijunbi', parent: '利益剰余金', closesItems: true, },
      { name: '任意積立金', roma: 'ninitsumitate', parent: 'その他利益剰余金', closesItems: true, },
      { name: '繰越利益剰余金', roma: 'kurikoshijouyo', parent: 'その他利益剰余金', hidden: true, closesItems: true, },
      { name: '当期純損益金額', roma: 'toukijunsoneki', parent: 'その他利益剰余金', closesItems: true, compute: (f, xs, closes = false) => closes ? 0 : computeCategoryAmount('親会社株主に帰属する当期純利益', f, xs), },
      { name: 'その他利益剰余金', roma: 'sonotariekijouyo', parent: '利益剰余金', closesItems: true, },
      { name: '利益剰余金', roma: 'riekijouyo', parent: '株主資本', closesItems: true, },
      { name: '自己株式', roma: 'jikokabushiki', parent: '株主資本', closesItems: true, },
      { name: '自己株式申込証拠金', roma: 'jikokabushikishouko', parent: '株主資本', closesItems: true, },
      { name: '株主資本', roma: 'kabunushishihon', parent: '純資産', },
      { name: '他有価証券評価差額金', roma: 'yuukashoukensagaku', parent: '評価・換算差額等', closesItems: true, },
      { name: '繰延ヘッジ損益', roma: 'kurinobehejji', parent: '評価・換算差額等', closesItems: true, },
      { name: '土地再評価差額金', roma: 'tochisaihyoukasagaku', parent: '評価・換算差額等', closesItems: true, },
      { name: '為替換算調整勘定', roma: 'kawasekansan', parent: '評価・換算差額等', closesItems: true, },
      { name: '退職給付に係る調整累計額', ciName: '退職給付に係る調整額', roma: 'taishokuchousei', closesItems: true, parent: '評価・換算差額等', },
      { name: '評価・換算差額等', roma: 'hyoukakansan', parent: '純資産', },
      { name: '新株予約権', roma: 'shinkabuyoyaku', parent: '純資産', closesItems: true, },
      { name: '非支配株主持分', roma: 'hishihaimochibun', parent: '純資産', closesItems: true, },
      { name: '純資産', roma: 'junshisan', parent: '負債及び純資産', },
      { name: '負債及び純資産', },
    ].map(_ => ({ ..._, direction: 'credit', })),
  ].map(_ => ({ ..._, type: 'bs', denominatorCategoryName: '資産', })),
  ...[
    { name: '売上高', direction: 'credit', closesItems: true,  denominatorCategoryName: '売上高', },

    // NOTE: 以下、売上原価が親だが、一旦parentをなくす。売上原価は試算表の単独の行を使う。
    { name: '期首商品棚卸', direction: 'debit', denominatorCategoryName: '売上原価', },
    { name: '当期商品仕入', direction: 'debit',  denominatorCategoryName: '売上原価', },
    { name: '他勘定振替高(商)', direction: 'debit', denominatorCategoryName: '売上原価', },
    { name: '期末商品棚卸', direction: 'credit', denominatorCategoryName: '売上原価', },
    { name: '商品売上原価', direction: 'debit', denominatorCategoryName: '売上原価', computable: true, compute: (f, xs) => computeCategoryAmount('期首商品棚卸', f, xs) + computeCategoryAmount('当期商品仕入', f, xs) + computeCategoryAmount('他勘定振替高(商)', f, xs) - computeCategoryAmount('期末商品棚卸', f, xs), sum: ['期首商品棚卸', '当期商品仕入', '他勘定振替高(商)', '期末商品棚卸'], },
    { name: '期首製品棚卸', direction: 'debit', denominatorCategoryName: '売上原価', },
    { name: '当期製品製造原価', direction: 'debit', denominatorCategoryName: '売上原価', computable: true, compute: (f, xs) => computeCategoryAmount('製造原価', f, xs), },
    { name: '他勘定振替高(製)', direction: 'debit', denominatorCategoryName: '売上原価', },
    { name: '期末製品棚卸', direction: 'credit', denominatorCategoryName: '売上原価', },
    { name: '製品売上原価', direction: 'debit', denominatorCategoryName: '売上原価', computable: true, compute: (f, xs) => computeCategoryAmount('期首製品棚卸', f, xs) + computeCategoryAmount('当期製品製造原価', f, xs) + computeCategoryAmount('他勘定振替高(製)', f, xs) - computeCategoryAmount('期末製品棚卸', f, xs), sum: ['期首製品棚卸', '当期製品製造原価', '他勘定振替高(製)', '期末製品棚卸'], },
    { name: '売上原価', direction: 'debit', closesItems: true, denominatorCategoryName: '売上原価', computable: true, compute: (f, xs) => computeCategoryAmount('商品売上原価', f, xs) + computeCategoryAmount('製品売上原価', f, xs), },
    { name: '振替', direction: 'debit', denominatorCategoryName: '売上原価', },
    { name: '売上総利益', direction: 'credit', computable: true, compute: (f, xs) => computeCategoryAmount('売上高', f, xs) - computeCategoryAmount('売上原価', f, xs), sum: ['売上高', '売上原価'], },
    { name: '販売管理費', direction: 'debit', denominatorCategoryName: '販売管理費', },
    { name: '営業利益', direction: 'credit', computable: true, compute: (f, xs) => computeCategoryAmount('売上総利益', f, xs) - computeCategoryAmount('販売管理費', f, xs), sum: ['売上総利益', '販売管理費'], },
    { name: '営業外収益', direction: 'credit', denominatorCategoryName: '営業外収益', },
    { name: '営業外費用', direction: 'debit', denominatorCategoryName: '営業外費用', },
    { name: '経常利益', direction: 'credit', computable: true, compute: (f, xs) => computeCategoryAmount('営業利益', f, xs) + computeCategoryAmount('営業外収益', f, xs) - computeCategoryAmount('営業外費用', f, xs), sum: ['営業利益', '営業外収益', '営業外費用'], },
    { name: '特別利益', direction: 'credit', denominatorCategoryName: '特別利益', },
    { name: '特別損失', direction: 'debit', denominatorCategoryName: '特別損失', },
    { name: '税引前当期純利益', direction: 'credit', computable: true, compute: (f, xs) => computeCategoryAmount('経常利益', f, xs) + computeCategoryAmount('特別利益', f, xs) - computeCategoryAmount('特別損失', f, xs), sum: ['経常利益', '特別利益', '特別損失'], },
    { name: '法人税等', direction: 'debit', },
    { name: '法人税等調整額', direction: 'debit', },
    { name: '当期純利益', direction: 'credit', computable: true, compute: (f, xs) => computeCategoryAmount('税引前当期純利益', f, xs) - computeCategoryAmount('法人税等', f, xs) - computeCategoryAmount('法人税等調整額', f, xs), sum: ['税引前当期純利益', '法人税等', '法人税等調整額'], },
    { name: '非支配株主持分損益', direction: 'debit', },
    { name: '親会社株主に帰属する当期純利益', direction: 'credit', computable: true, hidden: true, compute: (f, xs) => computeCategoryAmount('当期純利益', f, xs) - computeCategoryAmount('非支配株主持分損益', f, xs), sum: ['当期純利益', '非支配株主持分損益'], },
  ].map(_ => ({ ..._, type: 'pl', })),
  ...[
    { name: '期首原材料棚卸', direction: 'debit', },
    { name: '当期原材料仕入高', direction: 'debit', },
    { name: '期末原材料棚卸', direction: 'credit', },
    { name: '材料費', direction: 'debit', computable: true, compute: (f, xs) => computeCategoryAmount('期首原材料棚卸', f, xs) + computeCategoryAmount('当期原材料仕入高', f, xs) - computeCategoryAmount('期末原材料棚卸', f, xs), sum: ['期首原材料棚卸', '当期原材料仕入高', '期末原材料棚卸'], },
    { name: '労務費', direction: 'debit', },
    { name: '製造経費', direction: 'debit', },
    { name: '期首仕掛品棚卸', direction: 'debit', },
    { name: '仕掛品', direction: 'debit', },
    { name: '総製造費用', direction: 'debit', computable: true, compute: (f, xs) => computeCategoryAmount('材料費', f, xs) + computeCategoryAmount('労務費', f, xs) + computeCategoryAmount('製造経費', f, xs), sum: ['材料費', '労務費', '製造経費'], },
    { name: '期末仕掛品棚卸', direction: 'credit', },
    { name: '製造原価', direction: 'debit', computable: true, compute: (f, xs) => computeCategoryAmount('期首仕掛品棚卸', f, xs) + computeCategoryAmount('総製造費用', f, xs) - computeCategoryAmount('期末仕掛品棚卸', f, xs), sum: ['期首仕掛品棚卸', '総製造費用', '期末仕掛品棚卸'], },
  ].map(_ => ({ ..._, type: 'cr', })),
].map((_category) => {
  const category = {
    ..._category,
    id: _category.name,
    key: _ => [_category.type, _category.name].join('--'),
    directionValue: ({ debit: 1, credit: -1 })[_category.direction],
    parents: _ => categoryParents(category),
    indent: _ => category.parents().length,
    hierarchyLevel: _ => (category.selfAndParents().filter(_ => !_.hidden).length - 1) + (category.hierarchyLevelOffset || 0) + 1,
    descendants: _ => accountItemCategories.filter(_ => _.parents().map(_ => _.name).includes(category.name)),
    selfAndDescendants: _ => [category, ...category.descendants()],
    descendantsAndSelf: _ => [...category.descendants(), category],
    selfAndParents: _ => [category, ...category.parents()],
    children: _ => accountItemCategories.filter(_ => _.parent === category.name),
    compute: (f, xs, closes) => {
      return _category.compute ? (
        _category.compute(f, xs, closes)
      ) : category.children().length > 0 ? (
        sumBy(category.children().map(_ => _.name), _ => computeCategoryAmount(_, f, xs, closes))
      ) : sumBy(xs.filter(_ => _.category === _category.name), _ => f(_, xs));
    },
    sum: _category.sum ? ((amountsByCategory, isSum) => sumBy(_category.sum, _ => accountItemCategoriesByName[_].compute(amountsByCategory, true))) : _category.compute || (amountsByCategory => amountsByCategory[category.name]),
    ratio: (item, categoryItemsByCategory) => item.conclusiveAmount / category.denominator(item, categoryItemsByCategory),
    denominator: (item, categoryItemsByCategory) => categoryItemsByCategory[category.denominatorCategoryName]?.conclusiveAmount,
  };
  return category;
});
const accountItemCategoryNames = accountItemCategories.map(_ => _.name);
const accountItemCategoriesByName = keyBy(accountItemCategories, 'name');
const accountItemCategoriesGroupedByType = groupBy(accountItemCategories, 'type');
const accountItemCategoryParentNames = uniq(accountItemCategories.map(_ => _.parent));
const categoryParents = (category) => {
  return category.parent ?
    [accountItemCategoriesByName[category.parent], ...categoryParents(accountItemCategoriesByName[category.parent])]
  : [];
};
const accountItemCategoryChildren = accountItemCategories.filter(_ => !accountItemCategoryParentNames.includes(_.name))
const computeCategoryAmount = (categoryName, f, xs, closes) => {
  return accountItemCategoriesByName[categoryName].compute(f, xs, closes);
};

module.exports = {
  accountItemCategories,
  accountItemCategoryNames,
  accountItemCategoriesByName,
  accountItemCategoriesGroupedByType,
  accountItemCategoryChildren,
};
