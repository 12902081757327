import React, { Component } from 'react';
import { toast } from 'react-toastify';

import HeaderNav from '../HeaderNav';

export default function AdminPageHOC(WrappedComponent) {
  return class AdminPage extends Component {
    constructor() {
      super();
      this.state = {};
    }
    componentWillMount() {
      this.checkAdmin();
    }
    checkAdmin() {
      const { user, history } = this.props;
      if(!user.admin) {
        history.push('/');
        toast.error('Not authorized');
      }
    }
    componentDidMount() {
    }
    componentWillUnmount() {
    }
    componentDidUpdate(prevProps, prevState) {
    }
    render() {
      const { user } = this.props;
      return !!user.admin && (
        <div>
          <HeaderNav {...{ ...this.props }} admin />
          <WrappedComponent {...{
            ...this.props,
          }}
          />
        </div>
      );
    }
  };
}
