import React, { useState, } from 'react';
import fileDownload from 'js-file-download';
import { TextEncoder, } from 'text-encoding';
import { unparse as unparseCsv, } from 'papaparse';
import { isFunction } from 'lodash';
import classnames from 'classnames';
import { Button, } from 'reactstrap';

export default function ExportButton ({ fileName, rows, label = 'エクスポート', onFinish = _ => _, ...extraProps }) {
  const [isExporting, setIsExporting] = useState(false);

  const onClickExport = async () => {
    setIsExporting(true);
    const encoder = new TextEncoder('Shift_JIS', { NONSTANDARD_allowLegacyEncoding: true });
    const fileContent = encoder.encode(unparseCsv(isFunction(rows) ? (await rows()) : rows));
    fileDownload(fileContent, fileName);
    setIsExporting(false);
    await onFinish();
  };

  return (
    <Button color="secondary" onClick={onClickExport} disabled={isExporting} {...extraProps}>
      <span className={classnames('fas mr-1', { 'fa-spin fa-spinner': isExporting, 'fa-download': !isExporting })} />
      {label}
    </Button>
  );
};
