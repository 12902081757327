const { get } = require('lodash');

const isActive = (user = {}) => user.status !== 'inactive';
const isAdmin = (user = {}) => user.admin;
const isCompanyAdminAtLeast = (user, companyId) => isActive(user) && ['owner', 'admin'].includes(get(user, `companies.${companyId}.role`));
const isAdminOrCompanyAdminAtLeast = (user, companyId) => isAdmin(user) || isCompanyAdminAtLeast(user, companyId);
const isAuditor = (user) => isActive(user) && user.type === 'auditor';
const isAtLeastMember = (role) => ['owner', 'admin', 'member'].includes(role);

export const canReadAccountItemAuditorSetting = isAuditor;

export const canUpdateJournalModifyingStatus = isAuditor;

export const canImportJournalModifyings = isAuditor;

export const canCreateComment = _ => true;

export const canDeleteComment = (user, createdBy) => {
  return createdBy.uid === user.uid;
};

export const canDeleteCommentReply = (user, createdBy) => {
  return createdBy.uid === user.uid;
};

export const canUpdateCommentReply = (user, createdBy) => {
  return createdBy.uid === user.uid;
};

export const canUpdateCommentDone = (user, createdBy) => {
  return createdBy.uid === user.uid;
};

export const canUpdateCommentBody = (user, createdBy) => {
  return createdBy.uid === user.uid;
};

export const canWriteCompanyMember = isAdminOrCompanyAdminAtLeast;

export const canWriteBudgetSubject = (user, companyId) => isAdminOrCompanyAdminAtLeast(user, companyId);
export const canWriteCustomSection = canWriteBudgetSubject;
export const canWriteCustomSections = isAdminOrCompanyAdminAtLeast;
export const canWriteCustomSegments = isAdminOrCompanyAdminAtLeast;
export const canWriteCustomPartners = isAdminOrCompanyAdminAtLeast;

export const canSyncCompany = (user, role) => isAdmin(user) || isAtLeastMember(role);
export const canWriteCustomChart = (user, role) => isAdmin(user) || isAtLeastMember(role);
export const canWriteTrial = (user, role) => isAdmin(user) || isAtLeastMember(role);
export const canWriteNote = (user, role) => isAdmin(user) || isAtLeastMember(role);
export const canWriteSectionTrial = (user, role) => isAdmin(user) || isAtLeastMember(role);
export const canWriteSectionBudget = (user, role) => isAdmin(user) || isAtLeastMember(role);
