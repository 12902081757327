import React, { useState, useEffect } from 'react';
import { Button, FormGroup, Input, Label, } from 'reactstrap';
import { pickBy, isEmpty, get, difference, keyBy, last, sortBy, } from 'lodash';
import { format as formatDate, endOfMonth, startOfMonth, addMinutes, } from 'date-fns';
import { useToggle, useList, } from 'react-use';
import { Link } from 'react-router-dom';
import qs from 'qs';
import classnames from 'classnames';
import { TextEncoder, } from 'text-encoding';
import { unparse as unparseCsv, } from 'papaparse';
import fileDownload from 'js-file-download';

import firebase from '../../firebase';
import { fiscalYearOfPeriod, } from '../../utils';
import { mapKeysToJa, } from '../../shared/texts';
import { journalTags, detectionStatuses, combinationItems, } from '../../shared/config';
import { types, } from '../../shared/models/detection';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import CompanyJournalsCheckTabs from '../CompanyJournalsCheckTabs';
import HelpLink from '../HelpLink';
import CheckButton from '../Detection/CheckButton';
import IgnoreButton from '../Detection/IgnoreButton';
import ResetIgnoredButton from '../Detection/ResetIgnoredButton';
import CompanyPage from '../hocs/CompanyPage';
import QuerySelector from '../QuerySelector';

const { keys, entries } = Object;
const db = firebase.firestore();
const companiesRef = db.collection('companies');
const COUNT_PER_PAGE = 100;

export default CompanyPage(CompanyJournalsCheck);

function CompanyJournalsCheck(props) {
  const { user, period, journalsFetchJobs = [], targetMonth, company: { fiscalYears, sourceId: companySourceId }, match: { params: { companyId } }, location } = props;
  const journalsFetchJobsByEndDate = keyBy(journalsFetchJobs, 'endDate');
  const { start_date: startDate, end_date: endDate } = fiscalYearOfPeriod(period, fiscalYears);
  const [selectedDetections, { set: setSelectedDetections, push: selectDetection, removeAt: removeSelectedDetectionAt }] = useList([]);
  const unselectDetection = _ => removeSelectedDetectionAt(selectedDetections.indexOf(_));
  const { type: typeForFilter, } = useQueryParams();
  const [showsAll, toggleShowsAll] = useToggle();
  const [isFetching, toggleFetching] = useToggle();
  const [isExporting, toggleExporting] = useToggle();
  const [limit, setLimit] = useState(COUNT_PER_PAGE);
  const journalsCheckJobs = useCollectionSubscription(companiesRef.doc(companyId).collection('journalsCheckJobs').where('period', '==', period), [companyId, period]);
  const sortedJournalsCheckJobs = sortBy(journalsCheckJobs, _ => _.createdAt.toDate());
  const isSelecting = selectedDetections.length > 0;
  let detectionsRef = companiesRef
    .doc(companyId)
    .collection('journalsCheckDetections')
    .where('journal.date', '>=', formatDate(startOfMonth(targetMonth + '01'), 'YYYY/MM/DD'))
    .where('journal.date', '<=', formatDate(endOfMonth(targetMonth + '01'), 'YYYY/MM/DD'))
    .limit(limit);
  if(!showsAll) detectionsRef = detectionsRef.where('status', '==', 'initial');
  if(typeForFilter) detectionsRef = keys(types).includes(typeForFilter) ? detectionsRef.where('type', '==', typeForFilter) : detectionsRef.where('type', '==', 'custom').where('customJournalsCheckRuleId', '==', typeForFilter);

  const detections = useCollectionSubscription(detectionsRef, [companyId, period, targetMonth, showsAll, typeForFilter, limit], { initialItems: null });
  const sortedDetections = sortBy(detections || [], 'journal.date');
  const customJournalsCheckRules = useCollectionSubscription(companiesRef.doc(companyId).collection('customJournalsCheckRules'), [companyId]);
  const customJournalsCheckRulesById = keyBy(customJournalsCheckRules, 'id');
  const typeOptions = [...entries(pickBy(types, _ => _.targetType === 'journal')).map(([k, v]) => ({ value: k, label: v.label, })), ...customJournalsCheckRules.filter(_ => _.ruleType === 'journal').map(_ => ({ value: _.id, label: _.name, }))];
  const isChecking = sortedJournalsCheckJobs.filter(_ => _.status === 'initial').length > 0;
  const isFailed = (last(sortedJournalsCheckJobs) || {}).status === 'failed';
  const hasLoadedAll = sortedDetections.length < limit;
  const loadMore = async () => {
    toggleFetching(true);
    setLimit(limit + COUNT_PER_PAGE);
  };
  const onClickExport = async () => {
    toggleExporting();
    const { docs: detectionDocs } = await companiesRef
      .doc(companyId)
      .collection('journalsCheckDetections')
      .where('journal.date', '>=', formatDate(startOfMonth(targetMonth + '01'), 'YYYY/MM/DD'))
      .where('journal.date', '<=', formatDate(endOfMonth(targetMonth + '01'), 'YYYY/MM/DD'))
      .get();
    const items = detectionDocs.map((detectionDoc) => {
      const { journal, type, emptyTagNames, combination, status, ignoringNote, differentTaxName, customJournalsCheckRuleId, } = detectionDoc.data();
      const { date, accountName, journalNumber, } = journal;
      const description = (({
        emptyTags: () => `${accountName}の${emptyTagNames.map(_ => journalTags[_]).join(', ')}が未選択`,
        approvement: () => '作成者と承認者が同じです',
        combination: () => `「借方: ${combinationItems[combination.debit]} 貸方: ${combinationItems[combination.credit]}」の組み合わせがあります。`,
        differentTax: () => `${accountName}の税区分が"${differentTaxName}"`,
        custom: () => get(customJournalsCheckRulesById, `${customJournalsCheckRuleId}.message`),
      })[type] || (() => ''))();
      const freeeUrl = `https://secure.freee.co.jp/reports/journals?end_date=${formatDate(endDate, 'YYYY-MM-DD')}&start_date=${formatDate(startDate, 'YYYY-MM-DD')}&txn_number=${journalNumber}`;
      return mapKeysToJa({
        journalNumber,
        date,
        description,
        status: detectionStatuses[status],
        ignoringNote,
        freeeUrl,
      });
    });
    const encoder = new TextEncoder('Shift_JIS', { NONSTANDARD_allowLegacyEncoding: true });
    const fileContent = encoder.encode(unparseCsv(sortBy(items, 'date')));
    fileDownload(fileContent, `仕訳チェック検出事項_${period}.csv`);
    toggleExporting();
  };
  useEffect(() => {
    if(detections && detections.length > 0) {
      toggleFetching(false);
    }
  }, [detections]);
  useEffect(() => {
    setLimit(COUNT_PER_PAGE);
  }, [period, targetMonth, showsAll]);

  return (
    <div className="company-journals-check">
      <div className="container py-5">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="仕訳チェックを行う" />
        </div>
        <div className="d-flex justify-content-center mb-2">
          <h3>仕訳チェック</h3>
        </div>
        <div className="mb-3">
          <CompanyJournalsCheckTabs activeTab="detections" companyId={companyId} search={location.search} />
        </div>
        {
          isFailed && (
            <div className="alert alert-danger">
              {formatDate(last(sortedJournalsCheckJobs).finishedAt.toDate(), 'YYYY/MM/DD HH:mm:ss')}に失敗しました。
              <br />
              "{last(sortedJournalsCheckJobs).errorMessage}"
            </div>
          )
        }
        <div className="mb-3 d-flex justify-content-between align-items-end">
          <div className="d-flex align-items-end flex-wrap gap-2">
            <div>
              <QuerySelector paramName="type" width={300} options={typeOptions} label="種別" disabled={isSelecting} />
            </div>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" checked={showsAll} onChange={toggleShowsAll} className="mr-1" disabled={isSelecting} />
                無視済みのものも表示する
              </Label>
            </FormGroup>
          </div>
          <div className="d-flex align-items-center">
            <Button color="secondary" onClick={onClickExport} disabled={isExporting || isSelecting} className="ml-2">
              <span className={classnames('fas mr-1', { 'fa-spin fa-spinner': isExporting, 'fa-download': !isExporting })} />
              エクスポート
            </Button>
            <CheckButton className="ml-2" collectionRef={companiesRef.doc(companyId).collection('journalsCheckJobs')} period={period} targetMonth={targetMonth} isChecking={isChecking} user={user} disabled={isSelecting} />
          </div>
        </div>
        {
          isSelecting && (
            <div className="w-100 position-fixed" style={{ bottom: 0, left: 0, zIndex: 3, }}>
              <div className="container">
                <div className="card p-2" style={{ backgroundColor: 'rgba(255, 255, 255, 0.85' }}>
                  <div className="p-2 d-flex justify-content-between align-items-center">
                    <div>
                      {selectedDetections.length} 件を選択中
                    </div>
                    <div className="d-flex">
                      <IgnoreButton className="ml-2" detections={selectedDetections} onFinished={_ => setSelectedDetections([])} />
                      <ResetIgnoredButton className="ml-2" detections={selectedDetections} onFinished={_ => setSelectedDetections([])} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        {
          sortedDetections.length > 0 ? (
            <div>
              <table className="table table-hover">
                <thead className="text-center thead-light" style={{ lineHeight: '20px' }}>
                  <tr>
                    <th>
                      <Input type="checkbox" className="position-relative m-0" checked={difference(sortedDetections, selectedDetections).length === 0} onChange={_ => _.target.checked ? setSelectedDetections(sortedDetections) : setSelectedDetections([])} />
                    </th>
                    <th>仕訳番号</th>
                    <th>取引日</th>
                    <th>検出事項</th>
                    <th>状態</th>
                    {showsAll && <th>無視メモ</th>}
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    sortedDetections.map((detection, i) => {
                      const { id, journal, type, status, ignoringNote, } = detection;
                      const { date, journalNumber, } = journal;
                      const url = `https://secure.freee.co.jp/reports/journals?end_date=${formatDate(endDate, 'YYYY-MM-DD')}&start_date=${formatDate(startDate, 'YYYY-MM-DD')}&txn_number=${journalNumber}`;
                      const statusText = detectionStatuses[status];
                      const DetectionText = ({
                        emptyTags: EmptyTagDetectionText,
                        differentTax: DifferentTaxDetectionText,
                        approvement: ApprovementDetectionText,
                        combination: CombinationDetectionText,
                        custom: CustomRuleDetectionText,
                      })[type];
                      const closingDate = endOfMonth(date);
                      const journalsFetchJob = journalsFetchJobsByEndDate[formatDate(closingDate, 'YYYY-MM-DD')];

                      return (
                        <tr key={id} className={classnames({ 'table-secondary': status === 'ignored', })}>
                          <td>
                            <Input type="checkbox" className="position-relative m-0" checked={selectedDetections.includes(detection)} onChange={_ => selectedDetections.includes(detection) ? unselectDetection(detection) : selectDetection(detection)} />
                          </td>
                          <td>
                            <a href={url} target="_blank" rel="noopener">
                              {journalNumber}
                            </a>
                          </td>
                          <td>
                            {date}
                          </td>
                          <td>
                            <DetectionText {...{ detection, companyId, period, targetMonth, customJournalsCheckRulesById }} />
                          </td>
                          <td className={classnames({ 'text-muted': status === 'initial' })}>
                            {statusText}
                          </td>
                          {
                            showsAll && (
                              <td>
                                <div style={{ whiteSpace: 'pre-line' }}>
                                  {ignoringNote}
                                </div>
                              </td>
                            )
                          }
                          <td className="text-nowrap text-right">
                            <a className="btn btn-secondary mr-2" href={url} target="_blank">
                              <span className="fas fa-external-link-alt mr-1" />
                              freeeへ
                            </a>
                            {
                              (({
                                initial: () => <IgnoreButton detections={[detection]} disabled={isSelecting} />,
                                ignored: () => <ResetIgnoredButton detections={[detection]} disabled={isSelecting} />,
                              })[status] || (_ => null))()
                            }
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
              {
                !hasLoadedAll && (
                  <div className="d-flex justify-content-center">
                    <Button color="primary" onClick={loadMore} disabled={isFetching}>
                      {isFetching && <span className="fas fa-spinner fa-spin mr-1" />}
                      もっと読み込む
                    </Button>
                  </div>
                )
              }
            </div>
          ) : (
            <span>検出事項はありません</span>
          )
        }
      </div>
    </div>
  );
}

function EmptyTagDetectionText ({ detection, companyId, period, targetMonth, }) {
  const { journal, emptyTagNames = [],} = detection;
  const { accountName } = journal;
  return (
    <div>
      {accountName}の
      {emptyTagNames.map(_ => journalTags[_]).join(', ')}が未選択
      <Link className="small ml-2" to={`/companies/${companyId}/accountItemSettings?${qs.stringify({ period, targetMonth, accountName, })}`} target="_blank">
        [設定]
      </Link>
    </div>
  );
}

function DifferentTaxDetectionText ({ detection, companyId, period, targetMonth, }) {
  const { journal, differentTaxName, } = detection;
  const { accountName } = journal;
  return (
    <div>
      {accountName}の税区分が"{differentTaxName}"
      <Link className="small ml-2" to={`/companies/${companyId}/accountItemSettings?${qs.stringify({ period, targetMonth, accountName, })}`} target="_blank">
        [設定]
      </Link>
    </div>
  );
}

function ApprovementDetectionText ({ detection, }) {
  return (
    <div>
      作成者と承認者が同じです
    </div>
  );
};

function CombinationDetectionText ({ detection, }) {
  const { combination, } = detection;
  return (
    <div>
      <div>
        「借方: {combinationItems[combination.debit]} 貸方: {combinationItems[combination.credit]}」の組み合わせがあります。
      </div>
    </div>
  );
};

function CustomRuleDetectionText ({ detection, companyId, customJournalsCheckRulesById, }) {
  const { customJournalsCheckRuleId, } = detection;
  const customJournalsCheckRule = customJournalsCheckRulesById[customJournalsCheckRuleId];
  if(customJournalsCheckRule == null) return null;

  const { message } = customJournalsCheckRule;

  return (
    <div>
      {message}
      <Link className="small ml-2" to={`/companies/${companyId}/customJournalsCheckRules?${qs.stringify({ customJournalsCheckRuleId, })}`} target="_blank">
        [設定]
      </Link>
    </div>
  );
};
