import React, { Component } from 'react';
import { Label, Form, FormGroup, Button, Input, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import qs from 'qs';

import firebase from '../../firebase';
import { userTypes } from '../../shared/config';
import { canCreateComment } from '../../abilities';
import Comment from '../Comment';
import CommentInput from '../CommentInput';
import './index.css';

const db = firebase.firestore();
const companiesRef = db.collection('companies');
const { entries, } = Object;

export default class HoveredCommenter extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    return null;
    const { commenteeKey } = qs.parse(decodeURI(window.location.search.slice(1)), { arrayLimit: Infinity });
    if(commenteeKey === this.props.commenteeKey) {
      this.open();
      this.focus();
    }
  }
  toggleDropdown = (key) => {
    if(this.props.unopenable) return;

    this.setState({ shouldShowDropdown: !this.state.shouldShowDropdown });
  }
  onChangeText = (name, { target: { value } }) => {
    this.setState({ [name]: value });
  }
  onClickNew = _ => this.setState({ shouldShowNew: true })
  onSubmit = async (event) => {
    event.preventDefault();
    const { companyId, currentUser, commenteeKey, queryKey, values, about } = this.props;
    const { body } = this.state;
    try {
      await companiesRef.doc(companyId).collection('comments').add({ ...values, location: window.location.href, about, commenteeKey, queryKey, body, createdAt: new Date(), createdBy: { uid: currentUser.uid, type: currentUser.type }, done: false });
      toast.success('コメントしました');
      this.setState({ body: '', shouldShowNew: false });
    } catch(e) {
      console.error(e);
      toast.error('コメント失敗しました');
    }
  }
  open = () => this.setState({ shouldShowDropdown: true })
  focus = async () => {
    this.setState({ isFlashingInput: true });
    await new Promise(_ => setTimeout(_, 0));
    this.flashInput.focus();
    this.setState({ isFlashingInput: false });
  }
  onCheck = (name, { target: { checked: value } }) => this.setState({ [name]: value })
  render() {
    const { company, companyId, currentUser, comments = [], users = [], unopenable = false, onClick = _ => _, contentHeight, } = this.props;
    const { isFlashingInput = false, shouldShowDropdown = false, body, shouldShowNew = false, showsAll = false } = this.state;
    const filteredComments = showsAll ? comments : comments.filter(_ => !_.done);
    const mentionOptions = [...entries(userTypes).map(([k, v]) => ({ id: k, display: v })), ...users.map(_ => ({ id: _.id, display: _.displayName }))];
    return (
      <div className={classnames('hovered-commenter', { 'always-visible': shouldShowDropdown || filteredComments.length > 0 })} style={{ zIndex: shouldShowDropdown && 100 }}>
        <Dropdown direction="right" isOpen={shouldShowDropdown && !unopenable} toggle={this.toggleDropdown} onClick={onClick}>
          <DropdownToggle
            tag="span"
            style={{ fontSize: 22 }}
          >
            <span className={classnames('fa-comment cursor-pointer', { 'text-info fas': filteredComments.length > 0, 'text-muted far': filteredComments.length === 0 })} />
          </DropdownToggle>
          <DropdownMenu tabIndex="">
            <div className="p-2 overflow-auto" style={{ minWidth: 450, maxHeight: contentHeight, }}>
              <div className="mb-1">
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" onChange={this.onCheck.bind(this, 'showsAll')} checked={showsAll} />
                    完了済みも表示する
                  </Label>
                </FormGroup>
              </div>
              <div style={{ ...(contentHeight == null && { maxHeight: 500, overflow: 'scroll' }) }}>
                {
                  filteredComments.map((comment) => {
                    const { id } = comment;
                    return (
                      <Comment key={id} {...{ ...comment, company, companyId, commentId: id, currentUser, users }} />
                    );
                  })
                }
              </div>
              <div>
                {
                  canCreateComment(currentUser) && (
                    shouldShowNew ? (
                      <div>
                        <Form onSubmit={this.onSubmit}>
                          <div className="mb-2">
                            <CommentInput value={body} onChange={this.onChangeText.bind(this, 'body')} mentionOptions={mentionOptions} />
                          </div>
                          <Button>
                            保存
                          </Button>
                        </Form>
                      </div>
                    ) : (
                      <div>
                        {
                          filteredComments.length > 0 ? (
                            <Button size="sm" className="p-0" color="link" onClick={this.onClickNew}>
                              <span className="fas fa-plus mr-1" />
                              新たにコメントする
                            </Button>
                          ) : (
                            <Button onClick={this.onClickNew}>
                              コメントする
                            </Button>
                          )
                        }
                      </div>
                    )
                  )
                }
              </div>
            </div>
          </DropdownMenu>
        </Dropdown>
        {
          isFlashingInput && (<input ref={_ => this.flashInput = _} />)
        }
      </div>
    );
  }
};
