import React from 'react';
import { range, } from 'lodash';
import { toast } from 'react-toastify';
import { parse as parseCsv, unparse as unparseCsv, } from 'papaparse';
import { format as formatDate, endOfMonth, addMonths, } from 'date-fns';

import { getCollectionData, } from '../shared/firebase';
import { fiscalYearOfPeriod, } from '../utils';
import useQueryParams from './hooks/useQueryParams';
import { enabledBudgetSubjectTypes, } from '../shared/models/company';
import { processManualCsvRows, } from '../shared/models/customAccountItem';
import ProgressButton from './ProgressButton';

const { keys, entries } = Object;

export default function SyncCustomAccountItemManualValuesButton (props) {
  const { company, children, ...extraProps } = props;
  const queryParams = useQueryParams();
  const { start_date: startDate, end_date: endDate } = fiscalYearOfPeriod(parseInt(queryParams.period, 10), company.fiscalYears);
  const months = range(0, 12).map(_ => endOfMonth(addMonths(startDate, _))).filter(_ => _ <= endOfMonth(endDate));
  const onClickFetch = async () => {
    const customAccountItems = await getCollectionData(company.ref.collection('customAccountItems').where('isManual', '==', true));
    const customSections = await getCollectionData(company.ref.collection('customSections').orderBy('createdAt'));
    const customSegment1s = await getCollectionData(company.ref.collection('customSegment1s').orderBy('createdAt'));
    const customSegment2s = await getCollectionData(company.ref.collection('customSegment2s').orderBy('createdAt'));
    const customSegment3s = await getCollectionData(company.ref.collection('customSegment3s').orderBy('createdAt'));
    await customAccountItems.filter(_ => _.isManual).reduce(async (x, customAccountItem) => {
      await x;

      await Promise.all(keys(enabledBudgetSubjectTypes(company)).map(async (budgetSubjectType) => {
        const budgetSubjects = ({
          section: customSections,
          segment1: customSegment1s,
          segment2: customSegment2s,
          segment3: customSegment3s,
        })[budgetSubjectType] || [];
        return Promise.all(['budget', 'performance'].map(async (budgetPerformanceType) => {
          const url = customAccountItem[`${budgetSubjectType}__${budgetPerformanceType}__url`];
          if(url == null) return;

          try {
            const { data: rows } = parseCsv(await fetch(url).then(_ => _.text()), { header: true });
            await processManualCsvRows(customAccountItem, budgetSubjects, months, budgetPerformanceType, rows);
          } catch(e) {
            console.error(e);
            toast.error(`失敗しました。(${customAccountItem.name})`);
          }
        }));
      }));
    }, Promise.resolve());
    toast.success('スプレッドシートから同期しました');
  };

  return (
    <ProgressButton process={onClickFetch} icon={<span className="fas fa-sync" />} {...extraProps}>
      {children}
    </ProgressButton>
  );
}
