import React, { Component } from 'react';
import { Label, Form, FormGroup, Button, Input, } from 'reactstrap';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { format as formatDate } from 'date-fns';
import { sortBy, isEmpty, pick, omit, keyBy } from 'lodash';
import bytes from 'bytes';
import { Link } from 'react-router-dom';
import qs from 'qs';

import firebase from '../firebase';
import { formatMentions } from '../shared/comment';
import { userTypes } from '../shared/config';
import { canDeleteComment, canUpdateCommentReply, canDeleteCommentReply, canUpdateCommentDone, canUpdateCommentBody } from '../abilities';
import { autoLink, nl2br } from '../utils';
import CommentInput from './CommentInput';

const db = firebase.firestore();
const companiesRef = db.collection('companies');
const storageRef = firebase.storage().ref();
const { entries, } = Object;

export default class Comment extends Component {
  constructor() {
    super();
    this.state = {};
  }
  onClickReply = () => {
    const { done } = this.props;
    if(done) return;
    this.open('ReplyForm');
  }
  toggleDone = async (currentDone) => {
    const { companyId, commentId } = this.props;
    await companiesRef.doc(companyId).collection('comments').doc(commentId).update({ done: !currentDone, updatedAt: new Date() });
    toast.success(`${currentDone ? '未完了' : '完了済み'}にしました`);
  }
  open = (name) => this.setState({ [`shouldShow${name}`]: true })
  close = (name) => this.setState({ [`shouldShow${name}`]: false })
  onClickDelete = async () => {
    const { done } = this.props;
    if(done) return;
    if(!window.confirm('本当に削除しますか？')) return;
    const { companyId, commentId } = this.props;
    await companiesRef.doc(companyId).collection('comments').doc(commentId).delete();
    toast.success('削除しました');
  }
  onClickDeleteReply = async (id) => {
    const { done } = this.props;
    if(done) return;
    if(!window.confirm('本当に削除しますか？')) return;
    const { companyId, commentId, replies } = this.props;
    await companiesRef.doc(companyId).collection('comments').doc(commentId).update({ replies: omit(replies, id) });
    toast.success('削除しました');
  }
  onChangeText = (name, { target: { value } }) => {
    this.setState({ [name]: value });
  }
  onSubmitMainEdit = async (event) => {
    event.preventDefault();
    const { companyId, commentId, } = this.props;
    const { formBody } = this.state;
    try {
      await companiesRef.doc(companyId).collection('comments').doc(commentId).update({ body: formBody });
      toast.success('更新しました');
      this.setState({ shouldShowMainEditForm: false });
    } catch(e) {
      console.error(e);
      toast.error('更新失敗しました');
    }
  }
  onSubmitReply = async (event) => {
    event.preventDefault();
    const { companyId, currentUser, commentId, replies = {}, } = this.props;
    const { formReplyBody } = this.state;
    const id = formatDate(new Date(), 'YYMMDDHHmmss') + Math.random().toString(36).slice(-3);
    try {
      await companiesRef.doc(companyId).collection('comments').doc(commentId).update({ replies: { ...replies, [id]: { body: formReplyBody, createdAt: new Date(), createdBy: { uid: currentUser.uid, type: currentUser.type || 'auditee' } } } });
      toast.success('返信しました');
      this.close('ReplyForm');
    } catch(e) {
      console.error(e);
      toast.error('返信失敗しました');
    }
  }
  onSubmitReplyEdit = async (id, event) => {
    event.preventDefault();
    const { companyId, commentId, replies = {}, } = this.props;
    const formBody = this.state[`replyFormBody__${id}`];
    try {
      await companiesRef.doc(companyId).collection('comments').doc(commentId).update({ replies: { ...replies, [id]: { ...replies[id], body: formBody } } });
      toast.success('更新しました');
      this.setState({ [`shouldShowReplyEditForm__${id}`]: false });
    } catch(e) {
      console.error(e);
      toast.error('更新失敗しました');
    }
  }
  stopPropagation = e => e.stopPropagation()
  onSelectFiles = async ({ target: { files: newFiles } }) => {
    this.setState({ isProcessingFiles: true });
    const { companyId, commentId, } = this.props;
    await Array.from(newFiles).reduce(async (x, newFile) => {
      await x;
      const id = formatDate(new Date(), 'YYMMDDHHmmss') + Math.random().toString(36).slice(-3);
      const ref = storageRef.child(`companies/${companyId}/comments/${commentId}/files/${id}/${newFile.name}`);
      try {
        await ref.put(newFile, { contentType: newFile.type});
        const url = await ref.getDownloadURL();
        const commentRef = await companiesRef.doc(companyId).collection('comments').doc(commentId);
        const { files = {} } = (await commentRef.get()).data();
        await companiesRef.doc(companyId).collection('comments').doc(commentId).update({ files: { ...files, [id]: { url, ...pick(newFile, ['name', 'size', 'type']), createdAt: new Date() } } });
        toast.success('ファイル添付しました');
      } catch(e) {
        console.error(e);
        toast.success('ファイル添付に失敗しました');
      }
    }, Promise.resolve());
    this.setState({ isProcessingFiles: false });
  }
  onClickDeleteFile = async (fileId) => {
    const { done } = this.props;
    if(done) return;
    if(!window.confirm('本当に削除しますか？')) return;
    const { companyId, commentId, files = {} } = this.props;
    await companiesRef.doc(companyId).collection('comments').doc(commentId).update({ files: omit(files, fileId) });
    toast.success('ファイル削除しました');
  }
  onClickEditMain = () => {
    const { done } = this.props;
    if(done) return;
    this.setState({ shouldShowMainEditForm: true, formBody: this.props.body });
  }
  onClickReplyEdit = (id, body) => {
    const { done } = this.props;
    if(done) return;
    this.setState({ [`shouldShowReplyEditForm__${id}`]: true, [`replyFormBody__${id}`]: body });
  }
  render() {
    const { company, done = false, currentUser, users = [], usersById: _usersById = {}, createdBy, createdAt, about = '', commenteeKey, replies = {}, files = {}, body, link = false, location, } = this.props;
    const usersById = !isEmpty(_usersById) ? _usersById : keyBy(users, 'id');
    const { shouldShowReplyForm = false, formReplyBody = '', isProcessingFiles = false, shouldShowMainEditForm = false, formBody = '' } = this.state;
    const opacityStyle = { opacity: done && 0.4 };
    const mentionOptions = [...entries(userTypes).map(([k, v]) => ({ id: k, display: v })), ...users.map(_ => ({ id: _.id, display: _.displayName }))];
    const url = link && location && new URL(location);

    return (
      <div className="comment mb-4 pb-4 p-2 border-bottom">
        <div className="mb-1 text-muted small">
          <div style={opacityStyle}>
            {
              link && location ? (
                <Link to={url.pathname + '?' + qs.stringify({ ...qs.parse(url.search.slice(1)), commenteeKey })}>
                  [{about}]
                </Link>
              ) : (
                <span>
                  [{about}]
                </span>
              )
            }
          </div>
          <div style={opacityStyle}>
            <span>
              {(usersById[createdBy.uid] || {}).displayName}
            </span>
            <span className="ml-2">
              {formatDate(createdAt.toDate(), 'YYYY/MM/DD HH:mm')}
            </span>
          </div>
          <div onClick={this.stopPropagation} className="d-flex gap-1">
            {
              !company.disablesCommentFiles && (
                <span className="cursor-pointer text-muted" style={opacityStyle}>
                  <Label className="m-0 cursor-pointer">
                    {
                      isProcessingFiles ? (
                        <span className="fas fa-spin fa-spinner" />
                      ) : (
                        <span>
                          <span className="fas fa-paperclip" />
                          {
                            !done && (
                              <Input type="file" className="d-none" onChange={this.onSelectFiles} multiple />
                            )
                          }
                        </span>
                      )
                    }
                  </Label>
                </span>
              )
            }
            {
              canUpdateCommentDone(currentUser, createdBy) && (
                <span className="cursor-pointer text-success" onClick={this.toggleDone.bind(this, done)}>
                  {
                    done ? (
                      <span>
                        <span className="fas fa-undo mr-1" />
                        戻す
                      </span>
                    ) : (
                      <span>
                        <span className="fas fa-check mr-1" />
                        完了
                      </span>
                    )
                  }
                </span>
              )
            }
            {
              canUpdateCommentBody(currentUser, createdBy) && (
                <span className="cursor-pointer text-muted" onClick={this.onClickEditMain} style={opacityStyle}>
                  <span className="fas fa-pencil-alt mr-1" />
                  編集
                </span>
              )
            }
            {
              canDeleteComment(currentUser, createdBy) && (
                <span className="cursor-pointer text-danger" onClick={this.onClickDelete} style={opacityStyle}>
                  <span className="fas fa-trash mr-1" />
                  削除
                </span>
              )
            }
          </div>
        </div>
        <div style={opacityStyle}>
          {
            !isEmpty(files) && (
              <div className="small py-1">
                {
                  entries(files).map(([fileId, { name, size, url }]) => {
                    return (
                      <div key={fileId}>
                        <a href={url} download={name} target="_blank">{name} ({bytes(size, { decimalPlaces: 0 })})</a>
                        <span className="text-danger cursor-pointer ml-2" onClick={this.onClickDeleteFile.bind(this, fileId)}>
                          <span className="fas fa-trash" />
                        </span>
                      </div>
                    );
                  })
                }
              </div>
            )
          }
          {
            shouldShowMainEditForm ? (
              <Form className="mt-2" onSubmit={this.onSubmitMainEdit} onClick={this.stopPropagation}>
                <FormGroup>
                  <div className="mb-2">
                    <CommentInput value={formBody} onChange={this.onChangeText.bind(this, 'formBody')} mentionOptions={mentionOptions} />
                  </div>
                </FormGroup>
                <div className="d-flex justify-content-between">
                  <Button className="flex-grow-1" tag="span" onClick={this.close.bind(this, 'MainEditForm')}>キャンセル</Button>
                  <Button className="ml-2 flex-grow-1" color="primary" onClick={this.onSubmitMainEdit} disabled={!formBody}>保存</Button>
                </div>
              </Form>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: nl2br(autoLink(formatMentions(body), { linkAttr: { target: '_blank' } })) }} />
            )
          }
          <div className="border-left pl-2 ml-2 mt-2">
            {
              sortBy(entries(replies), _ => _[1].createdAt.toDate()).map(([id, { body, createdAt, createdBy, }], i) => {
                const shouldShowForm = this.state[`shouldShowReplyEditForm__${id}`];
                const formBody = this.state[`replyFormBody__${id}`];
                return (
                  <div key={id} className={classnames({ 'mt-1': i > 0 })}>
                    <div className="text-muted d-flex small align-items-center">
                      <span>
                        {(usersById[createdBy.uid] || {}).displayName}
                      </span>
                      <span className="ml-2">
                        {formatDate(createdAt.toDate(), 'YYYY/MM/DD HH:mm')}
                      </span>
                      <span onClick={this.stopPropagation}>
                        {
                          canUpdateCommentReply(currentUser, createdBy) && (
                            <span className="cursor-pointer text-muted ml-2" onClick={this.onClickReplyEdit.bind(this, id, body)} style={opacityStyle}>
                              <span className="fas fa-pencil-alt mr-1" />
                              編集
                            </span>
                          )
                        }
                        {
                          canDeleteCommentReply(currentUser, createdBy) && (
                            <span className="cursor-pointer text-danger ml-2" onClick={this.onClickDeleteReply.bind(this, id)}>
                              <span className="fas fa-trash mr-1" />
                              削除
                            </span>
                          )
                        }
                      </span>
                    </div>
                    {
                      shouldShowForm ? (
                        <Form className="mt-2" onSubmit={this.onSubmitReplyEdit.bind(this, id)} onClick={this.stopPropagation}>
                          <FormGroup>
                            <div className="mb-2">
                              <CommentInput value={formBody} onChange={this.onChangeText.bind(this, `replyFormBody__${id}`)} mentionOptions={mentionOptions} />
                            </div>
                          </FormGroup>
                          <div className="d-flex justify-content-between">
                            <Button className="flex-grow-1" tag="span" onClick={this.close.bind(this, `ReplyEditForm__${id}`)}>キャンセル</Button>
                            <Button className="ml-2 flex-grow-1" color="primary" onClick={this.onSubmitReplyEdit.bind(this, id)} disabled={!formBody}>保存</Button>
                          </div>
                        </Form>
                      ) : (
                        <div dangerouslySetInnerHTML={{ __html: nl2br(autoLink(formatMentions(body), { linkAttr: { target: '_blank' } })) }} />
                      )
                    }
                  </div>
                );
              })
            }
            {
              shouldShowReplyForm && (
                <Form className="mt-2" onSubmit={this.onSubmitReply} onClick={this.stopPropagation}>
                  <FormGroup>
                    <div className="mb-2">
                      <CommentInput value={formReplyBody} onChange={this.onChangeText.bind(this, 'formReplyBody')} mentionOptions={mentionOptions} />
                    </div>
                  </FormGroup>
                  <div className="d-flex justify-content-between">
                    <Button className="flex-grow-1" tag="span" onClick={this.close.bind(this, 'ReplyForm')}>キャンセル</Button>
                    <Button className="ml-2 flex-grow-1" color="primary" onClick={this.onSubmitReply} disabled={!formReplyBody}>保存</Button>
                  </div>
                </Form>
              )
            }
          </div>
          <div className="mt-2">
            <Button onClick={this.onClickReply} style={opacityStyle}>
              返信する
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
