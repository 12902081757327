import React, { useEffect } from 'react';
import { Button, } from 'reactstrap';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { format as formatDate, addMonths, startOfMonth, endOfMonth, } from 'date-fns';
import ReactTooltip from 'react-tooltip';

import useCollectionFetch from './hooks/useCollectionFetch';
import useQueryParams from './hooks/useQueryParams';
import useDocumentSubscription from './hooks/useDocumentSubscription';

export default function JournalsSyncButton (props) {
  const { company, label, period, targetMonth, serial = false, ...extraProps } = props;
  const targetDateString = formatDate(endOfMonth(targetMonth + '01'), 'YYYY-MM-DD');
  const targetMonthDate = new Date(targetDateString);
  const journalsFetchJob = useDocumentSubscription(company.ref.collection('journalsFetchJobs').doc('0'), [company]);
  const jobProcessingDate = ['initial', 'requested', 'saved'].includes(journalsFetchJob?.status) ? journalsFetchJob.endDate : null;
  const jobCompletedAt = (_ => _ && formatDate(_.toDate(), 'YYYY/MM/DD HH:mm:ss'))(journalsFetchJob?.months?.[targetMonth]?.completedAt);
  const sync = async () => {
    const startDate = formatDate(startOfMonth(targetMonth + '01'), 'YYYY-MM-DD');
    const endDate = formatDate(endOfMonth(targetMonth + '01'), 'YYYY-MM-DD');
    await company.ref.collection('journalsFetchJobs').doc('0').set({
      period,
      startDate,
      endDate,
      freeeCompanyId: company.sourceId,
      status: 'initial',
      createdAt: new Date(),
      computationDate: null,
      serial,
    }, { merge: true });
  }
  useEffect(() => {
    ReactTooltip.rebuild()
  }, [journalsFetchJob]);

  return (
    <Button data-tip={jobCompletedAt} disabled={['initial', 'requested', 'processing', 'saved'].includes(journalsFetchJob?.status)} onClick={sync} {...extraProps}>
      <span className={classnames('fas fa-sync cursor-pointer', { 'fa-spin': jobProcessingDate === targetDateString, 'mr-1': !isEmpty(label), })} />
      {label}
    </Button>
  )
};
