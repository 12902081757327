import React, { Component, Fragment, } from 'react';
import { Table, Button, Nav, NavItem, NavLink } from 'reactstrap';
import numeral from 'numeral';
import { mergeWith, chunk, isError, pickBy, mapKeys, orderBy, isEmpty, isNumber as _isNumber, groupBy, last, keyBy, range, uniq, debounce, sumBy, pick, isEqual, } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { format as formatDate, endOfMonth, addMonths, isEqual as isEqualDate, differenceInCalendarMonths, differenceInMonths, } from 'date-fns';
import { Link } from 'react-router-dom';
import fileDownload from 'js-file-download';
import { unparse as unparseCsv, } from 'papaparse';
import { TextEncoder, } from 'text-encoding';
import qs from 'qs';
import Select from 'react-select';
import { ResizableBox } from 'react-resizable';

import firebase from '../../firebase';
import { getAllCollectionDataByChunk, } from '../../shared/firebase';
import { canWriteTrial, canWriteNote } from '../../abilities';
import { dimensionsOfCompany, } from '../../shared/models/company';
import { mapKeysToJa, } from '../../shared/texts';
import { breakdownItemsLimitUnit, documentTypes, } from '../../shared/config';
import { computeStatus, } from '../../shared/models/journalsAndTrialsFetchJob';
import HelpLink from '../HelpLink';
import CompanyPage from '../hocs/CompanyPage';
import AccountItemDisplay from '../AccountItemDisplay';
import TrialQueriesSection from '../TrialQueriesSection';
import OverlayLoading from '../OverlayLoading';
import HoveredCommenter from '../HoveredCommenter';
import HoveredNoter from '../HoveredNoter';
import CommentsDropdown from '../CommentsDropdown';
import TrialImportButton from '../TrialImportButton';
import TrialScrapingButton from '../TrialScrapingButton';
import ItemNameFilterSelector from '../ItemNameFilterSelector';
import SyncCustomAccountItemManualValuesButton from '../SyncCustomAccountItemManualValuesButton';
import AllCollapseButton from '../AllCollapseButton';
import ModalButton from '../ModalButton';
import JournalsAndTrialsFetchJobErrorDetail from '../JournalsAndTrialsFetchJobErrorDetail';
import JournalsSyncButton from '../JournalsSyncButton';
import { log, startOfMonthByFiscalYears, existsInFiscalYears, closingDate, periodByDate, formatTrialCommentAbout, fullPathWithParams, computeTrialAmount, trialItemsToRowItems, fiscalYearOfPeriod, periodOfFiscalYear, filterRowsByAmount, groupTrials, groupTrialSubItems, } from '../../utils';
import env from '../../env';
import './CompanyPeriodComparison.scss';

const db = firebase.firestore();
const auth = firebase.auth();
const companiesRef = db.collection('companies');
const { entries, keys } = Object;
const { abs } = Math;
const isFiniteNumber = _ => _isNumber(_) && isFinite(_);
const COLUMN_WIDTH = 160;

export default CompanyPage(class CompanyPeriodComparison extends Component {
  constructor() {
    super();
    this.state = {
      isSettingRows: false,
      leftColumnWidth: COLUMN_WIDTH * 2,
    };
  }
  async componentDidMount() {
    this.setInitialQueryParams();
    await new Promise(_ => setTimeout(_, 100));
    this.listenTrials();
    this.fetchTrialSubItems();
    this.listenComments();
    this.listenNotes();
    this.listenJournalsAndTrialsFetchJob();
  }
  componentWillUnmount() {
    this.unlistenTrials && this.unlistenTrials();
    this.unlistenComments && this.unlistenComments();
    this.unlistenNotes && this.unlistenNotes();
    this.unlistenJournalsAndTrialsFetchJob && this.unlistenJournalsAndTrialsFetchJob();
  }
  componentDidUpdate(prevProps, prevState) {
    if(
      ['trials', 'trialSubItemsGroupedByClosingDate'].some(_ => prevState[_] !== this.state[_])
      || ['documentType'].some(_ => this.queryParams(prevProps)[_] !== this.queryParams()[_])
      || this.isSubFiltered() && keys(this.state || {}).filter(_ => _.startsWith('shouldShowSubRows__')).some(_ => prevState[_] !== this.state[_])
    ) {
      this.setRowsDebounced();
    }
    if(
      ['period', 'targetMonth'].some(_ => prevProps[_] !== this.props[_])
    ) {
      this.unlistenTrials && this.unlistenTrials();
      this.setState({ isSettingRows: false });
      this.listenTrials();
    }
    if(
      ['targetMonth'].some(_ => prevProps[_] !== this.props[_])
      || ['dimension', 'isSubFilter'].some(_ => this.queryParams(prevProps)[_] !== this.queryParams()[_])
      || !isEqual(this.state.openItemKeys, prevState.openItemKeys)
    ) {
      this.fetchTrialSubItems();
    }
    if(
      keys(this.state || {}).filter(_ => _.startsWith('shouldShowSubRows__')).some(_ => prevState[_] !== this.state[_])
      || ['isSubFilter'].some(_ => this.queryParams(prevProps)[_] !== this.queryParams()[_])
    ) {
      this.setState({
        openItemKeys: this.isSubFiltered() ? [] : keys(this.state || {}).filter(_ => _.startsWith('shouldShowSubRows__')).filter(_ => this.state[_]).map(_ => _.replace('shouldShowSubRows__', '')),
      });
    }
    if(
      ['allComments'].some(_ => prevState[_] !== this.state[_])
      || ['period', 'location'].some(_ => prevProps[_] !== this.props[_])
    ) {
      this.setComments();
    }
    if(
      ['rows'].some(_ => prevState[_] !== this.state[_])
    ) {
      this.toggleCommentRow();
      this.onRowsChanged();
    }
  }
  toggleCommentRow() {
    const { rows, commentRowToggled = false, } = this.state;
    const { commenteeKey, } = this.queryParams();
    if(commentRowToggled || !commenteeKey) return;

    this.onClickComment(commenteeKey);
    this.setState({ commentRowToggled: true });
  }
  async onRowsChanged() {
    const { rowsChangeListeners = [] } = this.state;
    if(isEmpty(rowsChangeListeners)) return;

    const fails = (await Promise.all(rowsChangeListeners.map(async _ => !(await _()) && _))).filter(_ => _);
    this.setState({ rowsChangeListeners: fails });
  }
  setInitialQueryParams() {
    const { history, location } = this.props;
    const { dimension, documentType } = this.queryParams();
    const path = fullPathWithParams({ dimension: dimension || 'none', documentType: documentType || 'bs' }, location);
    history.replace(encodeURI(path));
  }
  toggleTab (documentType) {
    const { history, location } = this.props;
    const path = fullPathWithParams({ documentType }, location);
    history.replace(encodeURI(path));
  }
  queryParams(props) {
    const { location: { search } } = props || this.props;
    return pickBy(qs.parse(decodeURI(search.slice(1)), { arrayLimit: Infinity }), _ => _ !== '');
  }
  terms() {
    const { period, targetMonth, company } = this.props;
    const targetClosingDate = endOfMonth(targetMonth + '01');
    return range(-12, 0.1)
      .map(_ => endOfMonth(addMonths(targetClosingDate, _)))
      .filter(_ => existsInFiscalYears(_, company))
      .filter((_, i) => i === 0 || i === 12 || (differenceInCalendarMonths(closingDate(company, period), _) % 3 === 0))
      .map((closingDate) => {
        return [startOfMonthByFiscalYears(closingDate, company), closingDate];
      });
  }
  setComments() {
    const { allComments = [], } = this.state;
    const { documentType } = this.queryParams();
    const yearMonths = this.terms().map(_ => formatDate(_[1], 'YYYYMM'));
    const comments = allComments.filter(_ => yearMonths.includes(_.yearMonth) && _.documentType === documentType);
    this.setState({ comments, commentsGroupedByCommenteeKey: groupBy(comments, 'commenteeKey') });
  }
  listenTrials = debounce(() => {
    const { match: { params: { companyId } } } = this.props;
    const { dimension, } = this.queryParams();
    const terms = this.terms();
    const [[, firstTermEndDate]] = terms;
    const [[, lastTermEndDate]] = terms.slice(-1);
    this.unlistenTrials = companiesRef
      .doc(companyId)
      .collection('trials')
      .where('dimension', '==', 'none')
      .where('closingDate', '>=', formatDate(firstTermEndDate, 'YYYY-MM-DD'))
      .where('closingDate', '<=', formatDate(lastTermEndDate, 'YYYY-MM-DD'))
      .onSnapshot(debounce(({ docs }) => {
        const trials = docs.map(_ => _.data());
        const trialsGroupedByClosingDate = groupBy(trials, _ => formatDate(_.closingDate, 'YYYYMM'));
        const trialsGroupedByItemKey = groupTrials(trials);
        this.setState({ trials, trialsGroupedByClosingDate, trialsGroupedByItemKey, });
      }, 300));
  }, 300)
  fetchTrialSubItems = debounce(() => {
    const { company, match: { params: { companyId } } } = this.props;
    const { dimension, } = this.queryParams();
    const terms = this.terms();
    const [[, firstTermEndDate]] = terms;
    const [[, lastTermEndDate]] = terms.slice(-1);
    this.setState({ trialSubItemsGroupedByClosingDate: {}, trialSubItemsGroupedByItemKey: {}, });
    const refs = this.isSubFiltered() ? [company.ref.collection(`trialSubItemChunks__${dimension}`)] : chunk(this.state.openItemKeys, 10).map(_ => company.ref.collection(`trialSubItemChunks__${dimension}`).where('itemKey', 'in', _));
    refs.map(async (ref) => {
      const trialSubItems = (await getAllCollectionDataByChunk(ref
        .where('closingDate', '>=', formatDate(firstTermEndDate, 'YYYY-MM-DD'))
        .where('closingDate', '<=', formatDate(lastTermEndDate, 'YYYY-MM-DD'))
      )).flatMap(_ => _.subItems.map(__ => ({ ..._, ...__ })));
      const trialSubItemsGroupedByClosingDate = groupBy(trialSubItems, _ => formatDate(_.closingDate, 'YYYYMM'));
      const trialSubItemsGroupedByItemKey = groupTrialSubItems(trialSubItems);
      this.setState(state => {
        return {
          trialSubItemsGroupedByItemKey: { ...state.trialSubItemsGroupedByItemKey, ...trialSubItemsGroupedByItemKey, },
          trialSubItemsGroupedByClosingDate: mergeWith({ ...state.trialSubItemsGroupedByClosingDate }, trialSubItemsGroupedByClosingDate, (a, b) => [...(a || []), ...(b || [])]),
        };
      });
    });
  }, 1000)
  listenComments() {
    const { match: { params: { companyId, } } } = this.props;
    this.unlistenComments = companiesRef
      .doc(companyId)
      .collection('comments')
      .where('queryKey', '==', 'trial')
      .orderBy('createdAt')
      .onSnapshot(({ docs }) => {
        this.setState({ allComments: docs.map(_ => ({ id: _.id, ..._.data() })) });
      });
  }
  listenNotes() {
    const { match: { params: { companyId, } } } = this.props;
    this.unlistenNotes = companiesRef
      .doc(companyId)
      .collection('notes')
      .where('pageType', '==', 'CompanyPeriodComparison')
      .where('queryKey', '==', 'trial')
      .onSnapshot(({ docs }) => {
        this.setState({ notes: docs.map(_ => ({ id: _.id, ..._.data() })) });
      });
  }
  listenJournalsAndTrialsFetchJob() {
    const { match: { params: { companyId, } } } = this.props;
    this.unlistenJournalsAndTrialsFetchJob = companiesRef
      .doc(companyId)
      .collection('journalsAndTrialsFetchJobs')
      .doc('0')
      .onSnapshot((doc) => {
        this.setState({ journalsAndTrialsFetchJob: { id: doc.id, ref: doc.ref, exists: doc.exists, ...doc.data() } });
      });
  }
  termItems(options) {
    const { period, targetMonth, company } = this.props;
    const { trialsGroupedByClosingDate = {} } = this.state;
    const trialSubItemsGroupedByClosingDate = options?.trialSubItemsGroupedByClosingDate || this.state.trialSubItemsGroupedByClosingDate || {};
    const { dimension, documentType, } = this.queryParams();
    return this.terms().map(([startDate, endDate]) => {
      const balances = trialsGroupedByClosingDate[formatDate(endDate, 'YYYYMM')] || [];
      const trialSubItems = trialSubItemsGroupedByClosingDate[formatDate(endDate, 'YYYYMM')] || [];
      const exists = !isEmpty(balances);
      return {
        startDate,
        endDate,
        closingDate: endDate,
        exists,
        balances,
        isInPeriod: differenceInMonths(closingDate(company, period), endDate) < 12,
        // NOTE: カスタム科目の計算に使われない、かつ、「当期純損益金額」がPLにもBSにも存在し衝突するため、documentTypeで絞る
        balancesByItemKey: keyBy(balances.filter(_ => _.type === documentType), 'itemKey'),
        trialSubItemsGroupedByItemKey: groupBy(trialSubItems, 'itemKey'),
        dimension,
      };
    });
  }
  setRowsDebounced = debounce(() => {
    const rows = this.generateRows();
    this.setState({ rows, isSettingRows: true });
  }, 500)
  generateRows = (options) => {
    const { company, targetMonth, company: { fiscalYears }, period, customAccountItems: _customAccountItems = [], accountItems = [], accountItemsOrderSetting, } = this.props;
    const { dimension, documentType } = this.queryParams();
    const trialSubItemsGroupedByClosingDate = options?.trialSubItems && groupBy(options.trialSubItems, _ => formatDate(_.closingDate, 'YYYYMM'));
    const trialSubItemsGroupedByItemKey = options?.trialSubItems && groupTrialSubItems(options.trialSubItems);
    const customAccountItems = _customAccountItems.filter(_ => (_.dimension || 'none') === 'none' || _.dimension === dimension);
    const termItems = this.termItems({ trialSubItemsGroupedByClosingDate, });
    const fiscalYear = fiscalYearOfPeriod(period, fiscalYears);
    const prevPeriod = periodOfFiscalYear(fiscalYears[fiscalYears.indexOf(fiscalYear) - 1]);
    const prevYearClosingDate = closingDate(company, prevPeriod);
    const prevYearClosingDateTermItem = termItems.find(_ => isEqualDate(_.endDate, prevYearClosingDate));
    const [prevYearSameTermItem] = termItems;
    const [lastTermItem] = termItems.slice(-1);
    const baseTermItem = ({ bs: prevYearClosingDateTermItem, pl: prevYearSameTermItem, cr: prevYearSameTermItem })[documentType] || {};
    const items = trialItemsToRowItems(termItems, { targetMonth, accountItems, customAccountItems, documentType, dimension, screenType: 'periodComparison', accountItemsOrderSetting, })
    const rows = items
      .map(this.itemToRow.bind(this, { trialSubItemsGroupedByItemKey, company, termItems, dimension, lastTermItem, baseTermItem, customAccountItems, }))
      .filter(_ => _.columns.some(_ => abs(_.amount) > 0));
    return Object.values(groupBy(rows, 'mainRowItemKey')).map(([mainItem, ...subItems]) => {
      const sortedSubItems = orderBy(subItems, _ => Math.abs(_.columnsByYearMonth[targetMonth].amount), 'desc').map((subRow, i) => {
        const showsSubRow = this.state[`shouldShowSubRows__${subRow.itemKey}`];
        return {
          ...subRow,
          breakDownItemIndex: i,
          subItemsCount: subItems.length,
          shows: showsSubRow,
        };
      });
      return [{ ...mainItem, subItems: sortedSubItems }, ...sortedSubItems];
    }).flat();
  }
  itemToRow(option, item) {
    const { company, termItems, dimension, lastTermItem, baseTermItem, customAccountItems } = option;
    const { trialsGroupedByItemKey = {}, commentsGroupedByCommenteeKey = {} } = this.state;
    const trialSubItemsGroupedByItemKey = option.trialSubItemsGroupedByItemKey || this.state.trialSubItemsGroupedByItemKey || {};
    const { documentType } = this.queryParams();
    const { isCustom = false, itemName, itemKey, isSubRow, subItemName, hierarchy_level: hierarchyLevel, account_item_name: accountItemName, displayExpression = '0,0', } = item;
    const isCategory = isEmpty(accountItemName);
    const rowKey = (isCustom ? 'cus__' : '') + (isCategory ? 'cat__' : '') + ((dimension !== 'none' && isSubRow) ? `${itemName}__${dimension}__${subItemName}` : itemName);
    const mainRowItemKey = isCustom ? itemKey : item.mainRowItemKey;
    const rowName = subItemName || itemName;
    const lastTermClosingBalance = computeTrialAmount('closing', item, trialsGroupedByItemKey, trialSubItemsGroupedByItemKey, dimension, [lastTermItem.closingDate], customAccountItems, documentType);
    const baseTermClosingBalance = computeTrialAmount('closing', item, trialsGroupedByItemKey, trialSubItemsGroupedByItemKey, dimension, [baseTermItem.closingDate], customAccountItems, documentType);
    const diff = lastTermClosingBalance - baseTermClosingBalance;
    const diffRate = diff / baseTermClosingBalance;
    const columns = termItems.map((termItem) => {
      const { startDate, endDate } = termItem;
      const yearMonth = formatDate(endDate, 'YYYYMM');
      const key = `${rowKey}_${formatDate(endDate, 'YYYYMM')}`;
      const amount = computeTrialAmount('closing', item, trialsGroupedByItemKey, trialSubItemsGroupedByItemKey, dimension, [termItem.closingDate], customAccountItems, documentType);
      const value = isFiniteNumber(amount) ? numeral(amount).format(displayExpression) : '-';
      const period = periodByDate(endDate, company);
      return { key, yearMonth, startDate, endDate, amount, value, period };
    });
    const columnsByYearMonth = keyBy(columns, 'yearMonth');
    const comments = columns.map(_ => commentsGroupedByCommenteeKey[_.key] || []).flat();
    return { ...item, hierarchyLevel, isCategory, isCustom, key: rowKey, itemName, itemKey, mainRowItemKey, subItemName, isSubRow, rowName, columns, columnsByYearMonth, diff, diffRate, displayExpression, baseTermClosingBalance, comments, };
  }
  toggleSubRows(itemKey, shows) {
    this.setState({ isSettingRows: shows, [`shouldShowSubRows__${itemKey}`]: (shows != null ? shows : !this.state[`shouldShowSubRows__${itemKey}`]) });
    this.setRowsDebounced();
  }
  showMoreSubRows(itemKey) {
    this.setState({ [`breakdownItemsLimitRate_${itemKey}`]: (this.state[`breakdownItemsLimitRate_${itemKey}`] || 1) + 1 });
  }
  renderRow({ hierarchyLevel, isCategory, isCustom, isManual, key, itemName, itemKey, subItemName, isSubRow, rowName, columns, diff, diffRate, displayExpression, breakDownItemIndex, subItemsCount, }) {
    const { company, role, accountItemSettingsById, members, user, period, match: { params: { companyId } } } = this.props;
    const { comments = [], notes = [], leftColumnWidth, } = this.state;
    const { dimension, documentType } = this.queryParams();
    const shouldShowSubRows = this.state[`shouldShowSubRows__${itemKey}`];
    const commentsGroupedByCommenteeKey = groupBy(comments, 'commenteeKey');
    const notesByKey = keyBy(notes, 'noteKey');
    const accountItemSetting = accountItemSettingsById[`${rowName.replace(/\//g, '_')}`];
    const breakdownItemsLimitRate = this.state[`breakdownItemsLimitRate_${itemKey}`] || 1;
    const showingCount = breakdownItemsLimitUnit * breakdownItemsLimitRate;
    const shouldHide = isSubRow && (breakDownItemIndex + 1) > showingCount;
    if(shouldHide) return null;
    const isLastSubRow = (breakDownItemIndex + 1) === showingCount;
    const leftSubItemsCount = subItemsCount - showingCount;
    return (
      <tr key={itemKey + key} className={classnames('item-row', { 'sub-row': isSubRow })}>
        <th style={{ width: leftColumnWidth, textIndent: `${hierarchyLevel - 1}rem`, fontWeight: isCategory ? 700 : 400 }} colSpan={5 - hierarchyLevel}>
          {
            dimension !== 'none' && !isSubRow && !isCategory && !isCustom && (
              <Button size="sm" className="small px-0 mr-1" color="link" onClick={_ => this.toggleSubRows(itemKey)}>
                <span className={classnames('fas cursor-pointer', { 'fa-plus': !shouldShowSubRows, 'fa-minus': shouldShowSubRows })} />
              </Button>
            )
          }
          <AccountItemDisplay accountItemName={rowName} accountItemSetting={accountItemSetting} iconStyles={{ textIndent: 0 }} isCustom={isCustom} />
          {isCustom && isManual && <SyncCustomAccountItemManualValuesButton color="link" size="sm" company={company} />}
          {
            isLastSubRow && leftSubItemsCount > 0 && (
              <div className="mt-2">
                <Button onClick={this.showMoreSubRows.bind(this, itemKey)}>
                  もっと見る (残り{leftSubItemsCount}件)
                </Button>
              </div>
            )
          }
        </th>
        {
          columns.map(({ value, key, endDate, period }, i) => {
            return (
              <td key={i} className="text-right has-hovered-contents" style={{ width: COLUMN_WIDTH }}>
                {
                  (isCategory || isCustom) ? (
                    <span>{value}</span>
                  ) : (
                    <Link to={encodeURI(`/companies/${companyId}/accountItems/${itemName}?${qs.stringify({ period, targetMonth: formatDate(endDate, 'YYYYMM'), ...(isSubRow && { subItems: [{ dimension, itemNames: [subItemName] }] }), })}`)}>
                      {value}
                    </Link>
                  )
                }
                <HoveredCommenter
                  ref={_ => this[`hovered_commenter__${key}`] = _}
                  company={company}
                  companyId={companyId}
                  currentUser={user}
                  commenteeKey={key}
                  queryKey="trial"
                  values={{ documentType, yearMonth: formatDate(endDate, 'YYYYMM') }}
                  comments={commentsGroupedByCommenteeKey[key]}
                  users={members}
                  about={formatTrialCommentAbout({ documentType, date: endDate, itemName, subItemName })}
                />
                <HoveredNoter companyId={companyId} noteKey={key} pageType="CompanyPeriodComparison" queryKey="trial" values={{ documentType, yearMonth: formatDate(endDate, 'YYYYMM') }} note={notesByKey[key]} writable={canWriteNote(user, role)} />
              </td>
            );
          })
        }
        <td className={classnames('text-right', { 'text-danger': diff < 0 })} style={{ width: COLUMN_WIDTH }}>
          {isFiniteNumber(diff) ? numeral(diff).format(displayExpression) : '-'}
        </td>
        <td className={classnames('text-right', { 'text-danger': diff < 0 })} style={{ width: COLUMN_WIDTH }}>
          {isFiniteNumber(diffRate) ? numeral(diffRate).format('0,0.00%') : '-'}
        </td>
      </tr>
    );
  }
  onSelect = (name, { value }) => {
    const { history, location } = this.props;
    const path = fullPathWithParams({ [name]: value }, location);
    history.replace(encodeURI(path));
  }
  fetchWalletables = async () => {
    const { company: { sourceId: companySourceId, }, match: { params: { companyId } } } = this.props;
    const token = await auth.currentUser.getIdToken(true);
    const url = `${env('CLOUD_FUNCTIONS_ENDPOINT')}/fetchFreeeWalletables?${qs.stringify({ companyId, companySourceId })}`;
    const headers = { 'Firebase-User-Token': token };
    try {
      const res = await fetch(url, { headers });
      if(res.status !== 200) {
        const { errors } = await res.json();
        throw new Error(errors.join('\n'));
      }
      const walletables = await res.json();
      return walletables;
    } catch(e) {
      toast.error(e.message);
      console.error(e);
    }
  }
  onClickComment = (commenteeKey) => {
    const { rows, } = this.state;
    const [itemName] = commenteeKey.split('_');
    const targetRow = rows?.find(_ => _.key === itemName);
    if(targetRow == null) return;

    const shows = this.state[`shouldShowSubRows__${targetRow?.itemKey}`];
    if(shows) {
      this.toggleCommenter(commenteeKey);
    } else {
      this.toggleSubRows(targetRow.itemKey, true);
      this.setState({ rowsChangeListeners: [...(this.state.rowsChangeListeners || []), () => this.toggleCommenter(commenteeKey)] });
    }
  }
  toggleCommenter = (commenteeKey) => {
    const { [`hovered_commenter__${commenteeKey}`]: commenter } = this;
    if(commenter) {
      commenter.open();
      commenter.focus();
      return true;
    }
  }
  isSubFiltered = () => (this.queryParams().dimension || 'none') !== 'none' && this.queryParams().isSubFilter === '1';
  filteredRows = (_rows) => {
    const { targetMonth, } = this.props;
    const rows = _rows || this.state.rows || [];
    const queryParams = this.queryParams();
    const { dimension, itemNamesForFilter, subItemNamesForFilter, } = queryParams;
    const isSubFiltered = this.isSubFiltered();
    let filteredRows = rows;
    if(!isEmpty(itemNamesForFilter)) {
      filteredRows = filteredRows.filter(_ => itemNamesForFilter.includes(_.itemName));
    }
    if(!isSubFiltered) {
      filteredRows = filterRowsByAmount(filteredRows, queryParams, 'amount', _ => _.columns.find(_ => _.yearMonth === targetMonth.toString())?.amount);
      filteredRows = filterRowsByAmount(filteredRows, queryParams, 'diff', _ => _.diff);
      filteredRows = filterRowsByAmount(filteredRows, queryParams, 'diffRate', _ => _.diffRate * 100);
    } else {
      filteredRows = entries(groupBy(filteredRows, 'itemKey'))
        .map(([itemKey, [mainRow, ...subRows]]) => {
          let filteredSubRows = subRows;
          if(!isEmpty(subItemNamesForFilter)) {
            filteredSubRows = filteredSubRows.filter(row => subItemNamesForFilter.some(_ => row.subItemName === _));
          }
          filteredSubRows = filterRowsByAmount(filteredSubRows, queryParams, 'amount', _ => _.columns.find(_ => _.yearMonth === targetMonth.toString())?.amount);
          filteredSubRows = filterRowsByAmount(filteredSubRows, queryParams, 'diff', _ => _.diff);
          filteredSubRows = filterRowsByAmount(filteredSubRows, queryParams, 'diffRate', _ => _.diffRate * 100);
          const mainRowDiff = !isSubFiltered ? mainRow.diff : sumBy(filteredSubRows, _ => _.diff);
          const mainRowBaseTermClosingBalance = !isSubFiltered ? mainRow.baseTermClosingBalance : sumBy(filteredSubRows, _ => _.baseTermClosingBalance);
          return {
            mainRow: (
              subRows.length === 0 ? mainRow : {
                ...mainRow,
                columns: mainRow.columns.map((column, i) => {
                  const amount = !isSubFiltered ? column.amount : sumBy(filteredSubRows, _ => _.columns[i].amount);
                  return {
                    ...column,
                    amount,
                    value: isFiniteNumber(amount) ? numeral(amount).format(mainRow.displayExpression) : '-',
                  };
                }),
                diff: mainRowDiff,
                diffRate: mainRowDiff / mainRowBaseTermClosingBalance,
              }
            ),
            subRows: filteredSubRows.map((row, i) => {
              return {
                ...row,
                breakDownItemIndex: i,
                subItemsCount: filteredSubRows.length,
              };
            }),
          };
        })
        .filter(_ => !isSubFiltered || _.subRows.length > 0)
        .reduce((x, y) => [...x, y.mainRow, ...y.subRows], []);
    }
    return filteredRows;
  }
  onClickExport = async () => {
    const { user, company, period, targetMonth } = this.props;
    const { documentType, dimension = 'none', } = this.queryParams();
    this.setState({ isExporting: true });
    const terms = this.terms();
    const [[, firstTermEndDate]] = terms;
    const [[, lastTermEndDate]] = terms.slice(-1);
    const trialSubItems = dimension !== 'none' && (await getAllCollectionDataByChunk(company.ref
      .collection(`trialSubItemChunks__${dimension}`)
      .where('closingDate', '>=', formatDate(firstTermEndDate, 'YYYY-MM-DD'))
      .where('closingDate', '<=', formatDate(lastTermEndDate, 'YYYY-MM-DD')),
    )).flatMap(_ => _.subItems.map(__ => ({ ..._, ...__ })));
    const { notes = [], } = this.state;
    const notesByKey = keyBy(notes, 'noteKey');
    const encoder = new TextEncoder('Shift_JIS', { NONSTANDARD_allowLegacyEncoding: true });
    const rows = this.generateRows({ trialSubItems });
    const filteredRows = this.filteredRows(rows);
    const data = filteredRows.map((row) => {
      return {
        ...pick(row, ['itemName', 'subItemName']),
        ...(
          row.columns.reduce((x, { endDate, amount, key }) => {
            return {
              ...x,
              [`amount_${formatDate(endDate, 'YYYYMM')}`]: amount,
              [`note_${formatDate(endDate, 'YYYYMM')}`]: (notesByKey[key] || {}).value,
            };
          }, {})
        ),
        ...pick(row, ['diff', 'diffRate']),
      };
    })
    const fileContent = encoder.encode(unparseCsv(data.map(mapKeysToJa)));
    fileDownload(fileContent, `期間比較_${targetMonth}.csv`);
    await log(company, 'periodComparizon', 'exportCsv', user, { period, targetMonth, documentType, });
    this.setState({ isExporting: false });
  }
  render() {
    const { period, company, role, location, history, user, members = [], match: { params: { companyId } } } = this.props;
    const { comments = [], trials, rows = [], isSettingRows = false, isExporting = false, leftColumnWidth, journalsAndTrialsFetchJob, } = this.state;
    const queryParams = this.queryParams();
    const { dimension, documentType, } = queryParams;
    const { status: statusOfJob, } = journalsAndTrialsFetchJob?.exists && computeStatus(journalsAndTrialsFetchJob) || {};
    const jobProcessingDate = statusOfJob === 'processing' ? journalsAndTrialsFetchJob.endDate : null;
    const dimensionOptions = entries(dimensionsOfCompany(company)).map(([k, v]) => ({ label: v, value: k }));
    const itemNameOptions = uniq(rows.map(_ => _.itemName)).map(_ => ({ label: _, value: _ }));
    const subItemNameOptions = uniq(rows.map(_ => _.subItemName).filter(_ => _)).map(_ => ({ label: _, value: _ }));
    const filteredRows = this.filteredRows();

    return (
      <div className="company-period-comparison">
        <div className="p-5">
          <div className="d-flex justify-content-end mb-3">
            <HelpLink text="期間比較を活用する" />
          </div>
          <div className="mb-3">
            <h3 className="text-center">期間比較</h3>
          </div>
          <Nav tabs>
            {
              entries(documentTypes).map(([k, { name }]) => {
                return (
                  <NavItem key={k}>
                    <NavLink className={classnames('cursor-pointer', { active: documentType === k })} onClick={this.toggleTab.bind(this, k)}>
                      {name}
                    </NavLink>
                  </NavItem>
                )
              })
            }
          </Nav>
          <div className="py-3">
            <div className="mb-3 d-flex justify-content-between align-items-center">
              <TrialQueriesSection 
                company={company}
                screenType="periodComparison"
                items={[
                  [
                    {
                      name: 'itemNamesForFilter',
                      type: 'multiSelect',
                      options: itemNameOptions,
                      label: '科目名',
                      isFront: true,
                      props: { width: 300, }
                    },
                    {
                      name: 'dimension',
                      type: 'select',
                      options: dimensionOptions,
                      label: '明細種別',
                      isFront: true,
                      props: { width: 150, selectorProps: { isClearable: false } },
                    },
                  ],
                  [
                    ...(
                      dimension !== 'none' ? [
                        {
                          name: 'isSubFilter',
                          type: 'boolean',
                          label: '明細単位で検索する',
                        },
                      ] : []
                    ),
                    ...(
                      dimension !== 'none' && queryParams.isSubFilter === '1' ? [
                        {
                          name: 'subItemNamesForFilter',
                          type: 'multiSelect',
                          options: subItemNameOptions,
                          label: '明細名',
                          props: { width: 300, }
                        }
                      ] : []
                    ),
                  ],
                  [
                    {
                      name: 'amountMin',
                      type: 'numeric',
                      label: '金額下限',
                      props: { width: 200, }
                    },
                    {
                      name: 'amountMax',
                      type: 'numeric',
                      label: '金額上限',
                      props: { width: 200, }
                    },
                  ],
                  [
                    {
                      name: 'diffMin',
                      type: 'numeric',
                      label: '増減額下限',
                      props: { width: 200, }
                    },
                    {
                      name: 'diffMax',
                      type: 'numeric',
                      label: '増減額上限',
                      props: { width: 200, }
                    },
                  ],
                  [
                    {
                      name: 'diffRateMin',
                      type: 'numeric',
                      label: '増減率下限(%)',
                      props: { width: 200, }
                    },
                    {
                      name: 'diffRateMax',
                      type: 'numeric',
                      label: '増減率上限(%)',
                      props: { width: 200, }
                    },
                  ],
                ]}
              />
              <div className="d-flex align-items-center">
                <Button color="secondary" onClick={this.onClickExport} disabled={isExporting} className="mr-2">
                  <span className={classnames('fas', { 'fa-spin fa-spinner': isExporting, 'fa-download': !isExporting })} />
                </Button>
                <CommentsDropdown company={company} companyId={companyId} currentUser={user} comments={comments} usersById={keyBy(members, 'id')} users={members} onClickComment={this.onClickComment} />
              </div>
            </div>
            <div className="d-flex justify-content-center position-relative" style={{ zIndex: 0 }}>
              <Table className="sticky-table table-hover" style={{ width: 'auto', wordBreak: 'break-all' }}>
                <thead className="text-center align-middle" style={{ lineHeight: '20px' }}>
                  <tr>
                    <th className="text-left p-0">
                      <ResizableBox width={COLUMN_WIDTH * 2} height={67} axis="x" resizeHandles={['e']} onResize={(e, _) => this.setState({ leftColumnWidth: _.size.width })}>
                        {
                          dimension !== 'none' && (
                            <AllCollapseButton size="sm" className="small px-0" color="link" rows={filteredRows} visibilities={mapKeys(pickBy(this.state, (v, k) => k.startsWith('shouldShowSubRows__')), (v, k) => k.replace('shouldShowSubRows__', ''))} setVisibilities={_ => { this.setState(mapKeys(_, (v, k) => 'shouldShowSubRows__' + k)); }} />
                          )
                        }
                        &nbsp;
                      </ResizableBox>
                    </th>
                    {
                      this.termItems().map(({ startDate, endDate, isInPeriod, exists }, i) => {
                        const isProcessingMonth = formatDate(endDate, 'YYYY-MM-DD') === jobProcessingDate;
                        return (
                          <th key={i} className={classnames('text-nowrap', { 'table-success': exists, 'table-danger': trials && !exists })} style={{ width: COLUMN_WIDTH, height: 70 }}>
                            {formatDate(endDate, 'YYYY/MM')}
                          </th>
                        );
                      })
                    }
                    <th style={{ width: COLUMN_WIDTH, height: 70 }}>増減額<br /> (対{({ bs: '前期末', pl: '前年同期', cr: '前年同期' })[documentType]})</th>
                    <th style={{ width: COLUMN_WIDTH, height: 70 }}>増減率</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredRows.filter(_ => !_.isSubRow || _.shows).map((row) => {
                      return  this.renderRow(row);
                    })
                  }
                </tbody>
              </Table>
              <OverlayLoading isOpen={!isSettingRows} />
            </div>
          </div>
        </div>
      </div>
    );
  }
});
