import React, { Component, } from 'react';
import { Table, Button, Alert, Input, } from 'reactstrap';
import numeral from 'numeral';
import { isEmpty, pick, chunk, groupBy, keyBy, omit, debounce, sumBy, } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { format as formatDate, parse as parseDate, endOfMonth, startOfMonth } from 'date-fns';
import fileDownload from 'js-file-download';
import { unparse as unparseCsv, parse as parseCsv } from 'papaparse';
import { TextEncoder, TextDecoder } from 'text-encoding';
import qs from 'qs';
import ReactTooltip from 'react-tooltip';

import firebase from '../../firebase';
import HelpLink from '../HelpLink';
import { canReadAccountItemAuditorSetting, canUpdateJournalModifyingStatus, canImportJournalModifyings } from '../../abilities';
import { mapKeysToJa, jas, } from '../../shared/texts';
import AccountItemDisplay from '../AccountItemDisplay';
import CompanyPage from '../hocs/CompanyPage';
import OverlayLoading from '../OverlayLoading';
import AmountDisplay from '../AmountDisplay';
import HoveredCommenter from '../HoveredCommenter';
import HoveredNoter from '../HoveredNoter';
import env from '../../env';
import { periodOfFiscalYear, fiscalYearOfPeriod, readFile, isBsAccountItem, closingDate, isDebitAccountItem } from '../../utils';
import { details, directions } from '../../shared/config';
import QueryBoolean from '../QueryBoolean';

const db = firebase.firestore();
const auth = firebase.auth();
const companiesRef = db.collection('companies');
const { abs } = Math;
const { values } = Object;
const COLUMN_WIDTH = 150;

export default CompanyPage(class CompanyAccountItem extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    this.listenJournals();
    this.listenJournalModifyings();
    this.listenComments();
    this.listenNotes();
    this.setState({ isFetchingTermItems: true });
    this.debouncedlyFetchTermItems();
  }
  componentWillUnmount() {
    this.unlistenJournals();
    this.unlistenComments();
    this.unlistenJournalModifyings();
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevProps.accountItems !== this.props.accountItems) {
      this.setState({ isFetchingTermItems: true });
      this.debouncedlyFetchTermItems();
    }
    if(prevProps.period !== this.props.period) {
      this.onPeriodChanged();
    }
    if(prevProps.targetMonth !== this.props.targetMonth) {
      this.setState({ isFetchingTermItems: true });
      this.debouncedlyFetchTermItems();
    }
  }
  type() {
    const { accountItems = [], match: { params: { accountItemId } } } = this.props;
    const accountItem = accountItems.find(_ => _.id === accountItemId);
    if(!accountItem) return;
    return isBsAccountItem(accountItem) ? 'bs' : 'pl';
  }
  async fetchTermItem([startDate, endDate]) {
    const { company: { sourceId: companySourceId }, match: { params: { companyId } } } = this.props;
    const token = await auth.currentUser.getIdToken(true);
    const type = this.type();
    if(!type) return;
    try {
      const url = `${env('CLOUD_FUNCTIONS_ENDPOINT')}/fetchFreeeTrial?${qs.stringify({ type, companyId, companySourceId, startDate: formatDate(startDate, 'YYYY-MM-DD'), endDate: formatDate(endDate, 'YYYY-MM-DD') })}`;
      const headers = { 'Firebase-User-Token': token };
      const res = await fetch(url, { headers });
      if(res.status !== 200) {
        const error = await res.text();
        throw new Error(error);
      }
      const balances = await res.json();
      return { startDate, endDate, balances, balancesByAccountItemName: keyBy(balances, 'account_item_name') };
    } catch(e) {
      toast.error(e.message);
      console.error(e);
      return {};
    }
  }
  debouncedlyFetchTermItems = debounce(() => this.fetchTermItems(), 500)
  prevFiscalYear = () => {
    const { period, company: { fiscalYears } } = this.props;
    const fiscalYear = fiscalYearOfPeriod(period, fiscalYears);
    return fiscalYears[fiscalYears.indexOf(fiscalYear) - 1];
  }
  async fetchTermItems() {
    await Promise.all([
      this.prevFiscalYear() ? this.fetchPrevYearTermItem() : Promise.resolve(),
      this.fetchTargetMonthTermItem(),
    ]);
    this.setState({ isFetchingTermItems: false });
  }
  async fetchPrevYearTermItem() {
    const { period, company } = this.props;
    const prevPeriod = periodOfFiscalYear(this.prevFiscalYear());
    const prevYearClosingDate = closingDate(company, prevPeriod);
    const termItem = await this.fetchTermItem([startOfMonth(prevYearClosingDate), prevYearClosingDate]);
    this.setState({ prevYearTermItem: termItem });
  }
  async fetchTargetMonthTermItem() {
    const { targetMonth, } = this.props;
    const targetMonthClosingDate = endOfMonth(targetMonth + '01');
    const termItem = await this.fetchTermItem([startOfMonth(targetMonthClosingDate), targetMonthClosingDate]);
    this.setState({ targetMonthTermItem: termItem });
  }
  onPeriodChanged() {
    this.unlistenJournals && this.unlistenJournals();
    this.listenJournals();
    this.unlistenJournalModifyings && this.unlistenJournalModifyings();
    this.listenJournalModifyings();
    this.debouncedlyFetchTermItems();
  }
  listenJournals() {
    const { period, match: { params: { companyId, accountItemId } } } = this.props;
    this.unlistenJournals = companiesRef
      .doc(companyId)
      .collection('journals')
      .where('period', '==', period)
      .where('accountName', '==', accountItemId)
      .orderBy('abs', 'desc')
      .onSnapshot(({ docs }) => {
        this.setState({ journals: docs.map(_ => ({ id: _.id, ..._.data() })), hasFetchedJournals: true });
      });
  }
  listenJournalModifyings() {
    const { period, match: { params: { companyId, accountItemId } } } = this.props;
    this.unlistenJournalModifyings = companiesRef
      .doc(companyId)
      .collection('journalModifyings')
      .where('period', '==', period)
      .where('accountName', '==', accountItemId)
      .onSnapshot(({ docs }) => {
        const journalModifyings = docs.map(_ => ({ id: _.id, ..._.data() }));
        this.setState({ journalModifyings, journalModifyingsById: keyBy(journalModifyings, 'id') });
      });
  }
  listenComments() {
    const { match: { params: { companyId, accountItemId } } } = this.props;
    this.unlistenComments = companiesRef
      .doc(companyId)
      .collection('comments')
      .where('queryKey', '==', `accountItem__${accountItemId}`)
      .orderBy('createdAt')
      .onSnapshot(({ docs }) => {
        this.setState({ comments: docs.map(_ => ({ id: _.id, ..._.data() })) });
      });
  }
  listenNotes() {
    const { match: { params: { companyId, accountItemId } } } = this.props;
    this.unlistenNotes = companiesRef
      .doc(companyId)
      .collection('notes')
      .where('queryKey', '==', `accountItem__${accountItemId}`)
      .onSnapshot(({ docs }) => {
        this.setState({ notes: docs.map(_ => ({ id: _.id, ..._.data() })) });
      });
  }
  toggleStatus = async (journalId, currentStatus) => {
    const { period, match: { params: { companyId, accountItemId } } } = this.props;
    const ref = companiesRef.doc(companyId).collection('journalModifyings').doc(journalId);
    const { exists } = await ref.get();
    const nextStatus = ({ done: 'undone', undone: 'done' })[currentStatus];
    ref[exists ? 'update' : 'set']({ 
      status: nextStatus,
      accountName: accountItemId,
      period,
      ...(!exists && { createdAt: new Date() }),
      doneAt: (nextStatus === 'done' ? new Date() : null),
    });
  }
  onSelectFiles = async ({ target, target: { files: [file] } }) => {
    const { period, match: { params: { companyId, accountItemId } } } = this.props;
    const { journalModifyingsById } = this.state;
    if(!file) return;
    this.setState({ isImporting: true });
    const decoder = new TextDecoder('Shift_JIS');
    const fileContent = decoder.decode(await readFile(file, 'readAsArrayBuffer'));
    const { data } = parseCsv(fileContent, { header: true });
    const filteredData = data.filter(({ id, status, isStarred }) => {
      if(!id) return false;
      const modifying = journalModifyingsById[id];
      if(modifying) return true;
      return !(status === 'undone' && isStarred.toLowerCase() === 'false');
    });
    try {
      await Promise.all(chunk(filteredData, 500).map(async (data) => {
        const batch = db.batch();
        data.forEach(async ({ id, status, isStarred }) => {
          const exists = !!journalModifyingsById[id];
          const ref = companiesRef.doc(companyId).collection('journalModifyings').doc(id);
          batch[exists ? 'update' : 'set'](ref, {
            status, isStarred: isStarred.toLowerCase() === 'true',
            ...(exists ? {} : { accountName: accountItemId, period, createdAt: new Date() }),
          });
        });
        await batch.commit();
      }));
      toast.success('インポートしました');
    } catch(e) {
      console.error(e);
      toast.error('インポートが失敗しました');
    } finally {
      this.setState({ isImporting: false });
      target.value = '';
    }
  }
  onClickExport = async () => {
    const { accountItems, match: { params: { accountItemId } } } = this.props;
    const accountItem = (accountItems || []).find(_ => _.id === accountItemId);
    this.setState({ isExporting: true });
    const encoder = new TextEncoder('Shift_JIS', { NONSTANDARD_allowLegacyEncoding: true });
    const fileContent = encoder.encode(unparseCsv(this.filteredRows().map(_ => ({ ...pick(_, ['id', 'accountName', 'amount', 'date', 'direction', 'journalsNumber', 'partnerAccount', 'partners', 'sections', 'items', 'memo', 'abstract', 'url', 'status', 'isStarred']), direction: jas[_.direction], })).map(_ => mapKeysToJa(_, { accountName: '相手勘定科目', }))));
    fileDownload(fileContent, `${accountItem.name}.csv`);
    this.setState({ isExporting: false });
  }
  items() {
    const { targetMonth, } = this.props;
    const { journals = [] } = this.state;
    return journals
      .filter(_ => parseDate(_.date) <= endOfMonth(targetMonth + '01'));
  }
  direction() {
    const { accountItems = [], match: { params: { accountItemId } } } = this.props;
    const accountItem = accountItems.find(_ => _.id === accountItemId);
    if(!accountItem) return;
    return isDebitAccountItem(accountItem) ? 'debit' : 'credit';
  }
  filteredItems() {
    const { targetMonth, location: { search } } = this.props;
    const { singleMonth, subItems = [], onlyStarred, } = qs.parse(decodeURI(search.slice(1)), { arrayLimit: Infinity });
    let filteredItems = this.items();
    if(onlyStarred === '1') filteredItems = this.filterWithStar(filteredItems);
    if(singleMonth === '1') filteredItems = filteredItems.filter(_ => parseDate(_.date) >= startOfMonth(targetMonth + '01'));
    if(!isEmpty(subItems) && subItems.every(_ => _.dimension !== 'none')) {
      filteredItems = filteredItems.filter((item) => {
        return subItems.every(_ => _.itemNames.map(_ => _ === '未選択' ? '' : _).includes(item[_.dimension]));
      });
    }
    return filteredItems;
  }
  itemsToRows(items) {
    const { user, company: { fiscalYears }, period, } = this.props;
    const { journalModifyings = [], } = this.state;
    const { start_date: startDate, end_date: endDate } = fiscalYearOfPeriod(period, fiscalYears);
    const journalModifyingsById = keyBy(journalModifyings, 'id');
    const type = this.type();
    const direction = this.direction();
    if(!type || !direction) return [];
    return items.map((item) => {
      const { id, abs, direction: journalDirection, updatedAt, journalNumber, source } = item;
      const isPlus = direction === journalDirection;
      const amount = abs * (isPlus ? 1 : -1);
      const { status = 'undone', isStarred = false, doneAt = null } = journalModifyingsById[id] || {};
      const isUpdatedFromDoneAt = !!(doneAt && doneAt.toDate() < updatedAt.toDate());
      const url = `https://secure.freee.co.jp/reports/journals?end_date=${formatDate(endDate, 'YYYY-MM-DD')}&start_date=${formatDate(startDate, 'YYYY-MM-DD')}&txn_number=${journalNumber}`;
      const memo = source[`${directions[journalDirection]}メモ`];
      const abstract = source['摘要'];
      return { ...item, memo, abstract, amount, url, isStarred, isUpdatedFromDoneAt, ...(canUpdateJournalModifyingStatus(user) && { status }) };
    });
  }
  rows() {
    return this.itemsToRows(this.items());
  }
  filteredRows() {
    return this.itemsToRows(this.filteredItems());
  }
  filterWithStar(items) {
    const { journalModifyings = [] } = this.state;
    const journalModifyingsById = keyBy(journalModifyings, 'id');
    return items.filter(_ => (journalModifyingsById[_.id] || {}).isStarred);
  }
  toggleStar = async (journalId, isStarredCurrently) => {
    const { period, match: { params: { companyId, accountItemId } } } = this.props;
    const ref = companiesRef.doc(companyId).collection('journalModifyings').doc(journalId);
    const { exists } = await ref.get();
    ref[exists ? 'update' : 'set']({
      isStarred: !isStarredCurrently,
      ...(exists ? {} : { accountName: accountItemId, period, createdAt: new Date() }),
    });
  }
  isFilteringWithStar() {
    const { location: { search } } = this.props;
    const { onlyStarred = '0' } = qs.parse(search.slice(1), { arrayLimit: Infinity });
    return onlyStarred === '1';
  }
  toggleStarFilter = () => {
    const { history, location: { pathname, search } } = this.props;
    const params = qs.parse(search.slice(1), { arrayLimit: Infinity });
    const isFilteringWithStar = this.isFilteringWithStar();
    const newParams = {
      ...omit(params, 'onlyStarred'),
      ...(isFilteringWithStar ? {} : { onlyStarred: '1' }),
    };
    const newSearch = qs.stringify(newParams);
    history.replace(`${pathname}${newSearch ? `?${newSearch}` : ''}`);
  }
  renderDiffTable() {
    const { accountItems = [], match: { params: { accountItemId } } } = this.props;
    const accountItem = accountItems.find(_ => _.id === accountItemId) || {};
    const { prevYearTermItem = {}, targetMonthTermItem = {}, isFetchingTermItems = false } = this.state;
    const { closing_balance: prevYearAmount = 0 } = (prevYearTermItem.balancesByAccountItemName || {})[accountItem.name] || {};
    const { closing_balance: targetMonthAmount = 0 } = (targetMonthTermItem.balancesByAccountItemName || {})[accountItem.name] || {};
    const sumAmount = sumBy(this.rows(), 'amount');
    const isBs = isBsAccountItem(accountItem);
    const totalAmount = (isBs ? prevYearAmount : 0) + sumAmount;
    const diffAmount = totalAmount - targetMonthAmount;
    return (
      <div className="position-relative">
        <table className="table table-hover table-bordered table-sm">
          <thead className="thead-light text-center">
            <tr>
              {
                isBs && (
                  <th>前期末</th>
                )
              }
              <th>仕訳合計</th>
              <th>残高</th>
              <th>試算表の残高</th>
              <th>差額</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {
                isBs && (
                  <td className="text-right">
                    <AmountDisplay amount={prevYearAmount} />
                  </td>
                )
              }
              <td className="text-right">
                <AmountDisplay amount={sumAmount} />
              </td>
              <td className="text-right">
                <AmountDisplay amount={totalAmount} />
              </td>
              <td className="text-right">
                <AmountDisplay amount={targetMonthAmount} />
              </td>
              <td className="text-right">
                <AmountDisplay amount={diffAmount} />
              </td>
            </tr>
          </tbody>
        </table>
        <OverlayLoading isOpen={isFetchingTermItems} />
      </div>
    );
  }
  removeFilter(key) {
    const { history, location: { pathname, search } } = this.props;
    const params = qs.parse(decodeURI(search.slice(1)), { arrayLimit: Infinity });
    const newParams = {
      ...omit(params, key),
    };
    const newSearch = qs.stringify(newParams);
    history.replace(encodeURI(`${pathname}${newSearch ? `?${newSearch}` : ''}`));
  }
  renderFilter() {
    const { location: { search } } = this.props;
    const { subItems = [], onlyStarred, } = qs.parse(decodeURI(search.slice(1)), { arrayLimit: Infinity });
    return (
      <div>
        {
          (!isEmpty(subItems) && subItems.every(_ => _.dimension !== 'none')) && (
            <Alert color="info" toggle={this.removeFilter.bind(this, 'subItems')}>
              {
                subItems.map(({ dimension, itemNames }) => {
                  // NOTE: itemNamesが21個以上になると、arrayでなくobjectとしてparseされてしまうのでObject.valuesを通す
                  return (
                    <div key={dimension}>
                      {details[dimension]}「{values(itemNames).map(_ => decodeURIComponent(_)).join(', ')}」で絞り込み
                    </div>
                  );
                })
              }
            </Alert>
          )
        }
      </div>
    );
  }
  openAll = () => this.setState({ shouldShowAll: true })
  render() {
    const { user, members, period, company, accountItems, accountItemSettingsById, accountItemAuditorSettingsById, match: { params: { companyId, accountItemId } }, targetMonth } = this.props;
    const { comments = [], journals = [], notes = [], isExporting = false, isImporting = false, shouldShowAll = false, hasFetchedJournals = false } = this.state;
    const { start_date: startDate } = fiscalYearOfPeriod(period, company.fiscalYears);
    const accountItem = (accountItems || []).find(_ => _.id === accountItemId);
    const accountItemAuditorSetting = accountItemAuditorSettingsById[`${accountItem && accountItem.name.replace(/\//g, '_')}__${period}`] || {};
    const isFilteringWithStar = this.isFilteringWithStar();
    const filteredRows = this.filteredRows();
    const endDate = endOfMonth(targetMonth + '01');
    const freeeUrl = `https://secure.freee.co.jp/reports/general_ledgers/show?${qs.stringify({ end_date: formatDate(endDate, 'YYYY-MM-DD'), name: (accountItem || {}).name, start_date: formatDate(startDate, 'YYYY-MM-DD') })}`;
    const commentsGroupedByCommenteeKey = groupBy(comments, 'commenteeKey');
    const notesByKey = keyBy(notes, 'noteKey');
    const accountItemSetting = accountItemSettingsById[`${accountItem && accountItem.name.replace(/\//g, '_')}`];
    return (
      <div className="company-account-item">
        <div className="container py-5">
          {
            accountItem && (
              <div className="container d-flex flex-column justify-content-center align-items-stretch">
                <div className="d-flex justify-content-end mb-3">
                  <HelpLink text="ドリルダウンして科目詳細を確認する" />
                </div>
                <h3 className="text-center">
                  <a href={freeeUrl} target="_blank">
                    <AccountItemDisplay accountItemName={accountItem.name} accountItemSetting={accountItemSetting} />
                  </a>
                </h3>
                {
                  canReadAccountItemAuditorSetting(user) && (
                    <h5 className="text-center">基準値: {numeral(accountItemAuditorSetting.threshold).format('0,0')}</h5>
                  )
                }
                <div className="py-3">
                  {this.renderDiffTable()}
                </div>
                <div className="d-flex justify-content-end flex-wrap mb-3 align-items-center">
                  {
                    canImportJournalModifyings(user) && (
                      <Button color="primary" className="ml-2" disabled={isImporting}>
                        <label className="m-0 cursor-pointer">
                          <span className={classnames('fas', 'mr-1', { 'fa-upload': !isImporting, 'fa-spin fa-spinner': isImporting})} />
                          <Input type="file" className="d-none" onChange={this.onSelectFiles} accept="text/*" />
                        </label>
                      </Button>
                    )
                  }
                  <Button color="secondary" onClick={this.onClickExport} className="ml-2" disabled={isExporting}>
                    <span className={classnames('fas', 'mr-1', { 'fa-download': !isExporting, 'fa-spin fa-spinner': isExporting })} />
                  </Button>
                </div>
                {
                  this.renderFilter()
                }
              </div>
            )
          }
        </div>
        <div className="d-flex justify-content-center position-relative">
          {
            accountItem && (
              journals.length > 0 ? (
                <div>
                  <div className="mb-2 d-flex justify-content-start align-items-end">
                    <QueryBoolean paramName="singleMonth" label="単月で絞り込み" />
                  </div>
                  <Table className="sticky-table" style={{ wordBreak: 'break-all' }}>
                    <thead className="text-center thead-light" style={{ lineHeight: '20px' }}>
                      <tr>
                        <th style={{ width: 50, position: 'relative' }}>
                          <span className={classnames('fa-star text-warning cursor-pointer', { fas: isFilteringWithStar, far: !isFilteringWithStar })} onClick={this.toggleStarFilter}/>
                          &nbsp;
                        </th>
                        <th style={{ width: COLUMN_WIDTH }}>日付</th>
                        <th style={{ width: COLUMN_WIDTH }}>相手勘定科目</th>
                        <th style={{ width: COLUMN_WIDTH }}>取引先</th>
                        <th style={{ width: COLUMN_WIDTH }}>品目</th>
                        <th style={{ width: COLUMN_WIDTH }}>部門</th>
                        <th style={{ width: COLUMN_WIDTH }}>メモ</th>
                        <th style={{ width: COLUMN_WIDTH }}>摘要</th>
                        <th style={{ width: COLUMN_WIDTH }}>金額</th>
                        {
                          canReadAccountItemAuditorSetting(user) && (
                            <th style={{ width: COLUMN_WIDTH }}>&nbsp;</th>
                          )
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (shouldShowAll ? filteredRows : filteredRows.slice(0, 100)).map(({ id, date, amount, type, partnerAccountName, partners, items, sections, status, isStarred, url, isUpdatedFromDoneAt, memo, abstract, }, i) => {
                          return (
                            <tr key={i} className={classnames({ 'font-weight-bold': abs(amount) >= accountItemAuditorSetting.threshold, 'table-warning': status !== 'done' && abs(amount) >= accountItemAuditorSetting.threshold, 'table-success': status === 'done' })}>
                              <td style={{ width: 50 }}>
                                <span className={classnames('fa-star text-warning cursor-pointer', { fas: isStarred, far: !isStarred })} onClick={this.toggleStar.bind(this, id, isStarred)}/>
                              </td>
                              <td style={{ width: COLUMN_WIDTH }}>
                                {date}
                              </td>
                              <td style={{ width: COLUMN_WIDTH }}>
                                {partnerAccountName}
                              </td>
                              <td style={{ width: COLUMN_WIDTH }}>
                                {partners}
                              </td>
                              <td style={{ width: COLUMN_WIDTH }}>
                                {items}
                              </td>
                              <td style={{ width: COLUMN_WIDTH }}>
                                {sections}
                              </td>
                              <td style={{ width: COLUMN_WIDTH }}>
                                {memo}
                              </td>
                              <td style={{ width: COLUMN_WIDTH }}>
                                {abstract}
                              </td>
                              <td className="text-right has-hovered-contents" style={{ width: COLUMN_WIDTH }}>
                                <a href={url} target="_blank" rel="noopener">
                                  <AmountDisplay amount={amount} />
                                </a>
                                <HoveredCommenter
                                  companyId={companyId}
                                  currentUser={user}
                                  commenteeKey={id}
                                  queryKey={`accountItem__${accountItemId}`}
                                  comments={commentsGroupedByCommenteeKey[id]}
                                  users={members}
                                  about={`仕訳帳 ${period}年度 ${accountItem.name} ${date} #${id}`}
                                />
                                <HoveredNoter companyId={companyId} noteKey={id} queryKey={`accountItem__${accountItemId}`} note={notesByKey[id]} pageType="CompanyAccountItem" />
                              </td>
                              {
                                canReadAccountItemAuditorSetting(user) && (
                                  <td className="text-nowrap" style={{ width: COLUMN_WIDTH }}>
                                    <Button color={({ done: 'danger', undone: 'primary '})[status]} onClick={this.toggleStatus.bind(this, id, status)}>
                                      <span className={classnames('fas', ({ done: 'fa-times', undone: 'fa-check' })[status])} />
                                    </Button>
                                    {
                                      isUpdatedFromDoneAt && (
                                        <span data-tip className="ml-1 fas fa-exclamation-triangle text-warning" id={`fa-exclamation-triangle-${i}`}>
                                          <ReactTooltip type="info" effect="solid">
                                            チェック日時以降に変更されています
                                          </ReactTooltip>
                                        </span>
                                      )
                                    }
                                  </td>
                                )
                              }
                            </tr>
                          );
                        })
                      }
                      <tr>
                        <td style={{ width: 50 }}>&nbsp;</td>
                        <td style={{ width: COLUMN_WIDTH }}>&nbsp;</td>
                        <td style={{ width: COLUMN_WIDTH }}>&nbsp;</td>
                        <td style={{ width: COLUMN_WIDTH }}>&nbsp;</td>
                        <td style={{ width: COLUMN_WIDTH }}>&nbsp;</td>
                        <td style={{ width: COLUMN_WIDTH }}>&nbsp;</td>
                        <td style={{ width: COLUMN_WIDTH }}>&nbsp;</td>
                        <td style={{ width: COLUMN_WIDTH }}>&nbsp;</td>
                        <td className="text-right" style={{ width: COLUMN_WIDTH }}>
                          <AmountDisplay amount={sumBy(filteredRows, 'amount')} />
                        </td>
                        {
                          canReadAccountItemAuditorSetting(user) && <td style={{ width: COLUMN_WIDTH }}>&nbsp;</td>
                        }
                      </tr>
                    </tbody>
                  </Table>
                  <div className="d-flex justify-content-center">
                    {
                      filteredRows.length > 100 && !shouldShowAll && (
                        <Button color="secondary" onClick={this.openAll}>
                          すべて表示する
                        </Button>
                      )
                    }
                  </div>
                </div>
              ) : (
                <div className="text-center">
                  データはありません。
                </div>
              )
            )
          }
          <OverlayLoading isOpen={!hasFetchedJournals} />
        </div>
      </div>
    );
  }
});
