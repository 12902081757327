import React, { Fragment, useState, } from 'react';
import { Button, } from 'reactstrap';
import { fromPairs, isEmpty, keyBy, pick, get, last, sortBy, range, omit } from 'lodash';
import { toast } from 'react-toastify';
import { format as formatDate, endOfMonth, addMonths, } from 'date-fns';
import { Container, Draggable } from 'react-smooth-dnd';
import arrayMove from 'array-move';
import { TextEncoder, } from 'text-encoding';
import { parse as parseCsv, unparse as unparseCsv, } from 'papaparse';
import fileDownload from 'js-file-download';
import { useToggle, useAsync, } from 'react-use';
import Select from 'react-select';
import numeral from 'numeral';

import firebase from '../../firebase';
import HelpLink from '../HelpLink';
import { batch } from '../../shared/firebase';
import { mapKeysToJa, mapKeysFromJa, } from '../../shared/texts';
import { expressionTokens, fields, displays, processManualCsvRows, } from '../../shared/models/customAccountItem';
import { enabledBudgetSubjectTypes, } from '../../shared/models/company';
import { documentTypes, dimensions, } from '../../shared/config';
import { fiscalYearOfPeriod, } from '../../utils';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ImportButton from '../ImportButton';
import ExportButton from '../ExportButton';
import SyncCustomAccountItemManualValuesButton from '../SyncCustomAccountItemManualValuesButton';
import CustomAccountItemFormModal from '../modals/CustomAccountItemFormModal';
import CompanyPage from '../hocs/CompanyPage';

const { keys, entries } = Object;
const db = firebase.firestore();
const companiesRef = db.collection('companies');

export default CompanyPage(CompanyCustomAccountItems);

function CompanyCustomAccountItems(props) {
  const { period, customSections, company, company: { fiscalYears }, match: { params: { companyId } } } = props;
  const customSectionsByName = keyBy(customSections, 'name');
  const [isDragging, toggleDragging] = useToggle(false);
  const [showsModal, setShowsModal] = useState(false);
  const customSegment1s = useCollectionSubscription(company?.ref.collection('customSegment1s').orderBy('createdAt'), [company]);
  const customSegment2s = useCollectionSubscription(company?.ref.collection('customSegment2s').orderBy('createdAt'), [company]);
  const customSegment3s = useCollectionSubscription(company?.ref.collection('customSegment3s').orderBy('createdAt'), [company]);
  const customAccountItemsRef = companiesRef.doc(companyId).collection('customAccountItems');
  const customAccountItems = useCollectionSubscription(customAccountItemsRef.orderBy('createdAt'), [companyId]);
  const customAccountItemsById = keyBy(customAccountItems, 'id');
  const sortedItems = sortBy(customAccountItems, _ => _.index != null ? _.index : customAccountItems.indexOf(_));
  const onSubmit = async (values) => {
    const ref = companiesRef.doc(companyId).collection('customAccountItems').doc();
    try {
      await ref.set({ ...omit(values, 'id'), createdAt: new Date(), index: ((last(sortedItems) || {}).index + 1 || sortedItems.length) });
      toast.success('保存しました');
      setShowsModal(false);
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  const onDrop = async ({ addedIndex, removedIndex }) => {
    const newIds = arrayMove(sortedItems, removedIndex, addedIndex).map(_ => _.id);
    await batch(db, newIds, (batch, id, index) => {
      batch.update(company.ref.collection('customAccountItems').doc(id), { index });
    });
  };
  const { start_date: startDate, end_date: endDate } = fiscalYearOfPeriod(period, fiscalYears);
  const months = range(0, 12).map(_ => endOfMonth(addMonths(startDate, _))).filter(_ => _ <= endOfMonth(endDate));
  const processRows = (rows) => {
    return rows.map(mapKeysFromJa).filter(_ => !Object.values(_).every(isEmpty)).map((row) => {
      const otherItems = [...customAccountItems, ...rows.filter(_ => _ !== row)];
      const exists = customAccountItemsById[row.id] != null;
      return {
        ...row,
        isManual: (row.isManual || '').toLowerCase() === 'true',
        conflicted: !exists && otherItems.map(_ => _.name).includes(row.name),
      };
    });
  };
  const processRow = (batch, row, i) => {
    const { id, } = row;
    const exists = customAccountItemsById[id] != null;
    const ref = customAccountItemsRef.doc(...(id ? [id] : []));
    batch.set(ref, {
      ...pick(row, ['name', 'dimension', 'isManual', 'expression', 'displayExpression']),
      displays: (row.displays || '').split(',').filter(_ => displays[_]),
      ...(!exists && { createdAt: new Date(), index: (get(last(sortedItems), 'index', 0) + i + 1 || sortedItems.length) })
    }, { merge: true });
  };
  const rowsForExport = sortedItems.map(_ => pick(_, ['id', 'name', 'displays', 'dimension', 'isManual', 'expression', 'displayExpression'])).map(mapKeysToJa);

  return company != null && (
    <div className="company-custom-account-items">
      <div className="container-fluid py-5">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="カスタム科目を作成する" />
        </div>
        <div className="d-flex justify-content-center mb-2">
          <h3>カスタム科目</h3>
        </div>
        <div className="d-flex justify-content-end gap-1">
          <SyncCustomAccountItemManualValuesButton company={company}>
            スプレッドシートから同期
          </SyncCustomAccountItemManualValuesButton>
          <ImportButton color="info" outline processRows={processRows} processRow={processRow} documentName="customAccountItem" fields={omit(fields(), ['manualType'])} />
          <ExportButton outline fileName="カスタム科目.csv" rows={rowsForExport} />
          <Button color="primary" onClick={_ => setShowsModal(true)}>
            <span className="fas fa-plus mr-1" />
            追加
          </Button>
        </div>
        {
          sortedItems.length > 0 ? (
            <table className="table table-hover mt-5">
              <thead className="text-nowrap">
                <tr>
                  <th></th>
                  <th style={{ minWidth: 200 }}>科目名</th>
                  <th style={{ minWidth: 150 }}>表示画面</th>
                  <th style={{ minWidth: 100 }}>明細</th>
                  <th style={{ minWidth: 200, }}>計算式</th>
                  <th style={{ minWidth: 100 }}>表示形式</th>
                  <th></th>
                </tr>
              </thead>
              <Container
                dragHandleSelector=".drag-handle"
                onDrop={onDrop}
                onDragStart={_ => toggleDragging(true)}
                onDragEnd={_ => toggleDragging(false)}
                dropPlaceholder={{ style: { background: 'eee', } }}
                render={(ref) => (
                  <tbody ref={ref}>
                    {
                      sortedItems.map((customAccountItem) => {
                        return <Row {...{ customAccountItem, company, months, customSections, customSegment1s, customSegment2s, customSegment3s, period, isDragging, customAccountItems, processManualCsvRows, }} />
                      })
                    }
                  </tbody>
                )}
              >
              </Container>
            </table>
          ) : (
            <span>カスタム科目は未登録です</span>
          )
        }
      </div>
      {showsModal && <CustomAccountItemFormModal isOpen customSections={customSections} customAccountItems={customAccountItems} onClickClose={_ => setShowsModal(false)} onSubmit={onSubmit} enabledBudgetSubjectTypes={enabledBudgetSubjectTypes(company)} />}
    </div>
  );
}

function Row (props) {
  const { customAccountItem, company, months, customSections, customSegment1s, customSegment2s, customSegment3s, period, isDragging, customAccountItems, processManualCsvRows, } = props;
  const { id, ref, name, dimension = 'none', expression, isManual, displayExpression, values, } = customAccountItem;
  const budgetSubjectTypeOptions = entries(enabledBudgetSubjectTypes(company)).map(([k, v]) => ({ value: k, label: v.label }));
  const [selectedBudgetSubjectType, selectBudgetSubjectType] = useState(null);
  const budgetSubjects = ({
    section: customSections,
    segment1: customSegment1s,
    segment2: customSegment2s,
    segment3: customSegment3s,
  })[selectedBudgetSubjectType] || [];
  const displayItems = (customAccountItem.displays || []).map(_ => displays[_]);
  const itemRef = company.ref.collection('customAccountItems').doc(id);
  const onClickExportFormat = async (budgetPerformanceType) => {
    const label = ({ performance: '実績', budget: '予算'})[budgetPerformanceType];
    const suffix = ({ performance: '', budget: '_budget'})[budgetPerformanceType];
    const encoder = new TextEncoder('Shift_JIS', { NONSTANDARD_allowLegacyEncoding: true });
    const data = months.map((month) => {
      return {
        年月: formatDate(month, 'YYYYMM'),
        ...[...(budgetPerformanceType === 'performance' ? [{ id: '', name: '全社' }] : []), ...budgetSubjects].reduce((x, budgetSubject) => {
          return {
            ...x,
            [budgetSubject.name]: get(values, [[formatDate(month, 'YYYY/MM') + budgetSubject.id + suffix].join('')], 0),
          };
        }, {}),
      };
    });
    const fileContent = encoder.encode(unparseCsv(data.map(_ => mapKeysToJa(_))));
    fileDownload(fileContent, `${['カスタム科目', name, period, label].join('_')}.csv`);
  }
  const validateOnSubmit = (values) => {
    if(customAccountItems.filter(_ => _.id !== values.id).some(_ => _.name === values.name)) {
      toast.error('同じ名前のカスタム科目が存在します');
      return false;
    } else {
      return true;
    }
  };

  return (
    <Draggable
      key={id}
      render={() => (
        <tr style={{ display: !isDragging && 'table-row' }}>
          <td style={{ width: 30 }}>
            <div className="drag-handle text-muted cursor-pointer">
              <span className="fas fa-grip-vertical" />
            </div>
          </td>
          <td style={{ minWidth: 200 }}>
            {name}
          </td>
          <td className="text-nowrap">
            {displayItems.map(_ => <div>{_.label}</div>)}
          </td>
          <td className="text-nowrap">
            {dimensions[dimension]}
          </td>
          <td>
            {isManual ? '(手入力)' : expression}
          </td>
          <td className="text-nowrap">
            {displayExpression}
          </td>
          <td>
            <div className="d-flex align-items-center gap-1 justify-content-end text-nowrap">
              {
                isManual && (
                  <div>
                    <Select
                      value={budgetSubjectTypeOptions.find(_ => _.value === selectedBudgetSubjectType) || null}
                      onChange={_ => selectBudgetSubjectType(_?.value)}
                      options={budgetSubjectTypeOptions}
                      isClearable
                      className="flex-fill"
                      components={{ IndicatorsContainer: _ => null }}
                    />
                    <div className="d-flex gap-1 text-nowrap">
                      <div className="d-flex flex-column gap-1">
                        <Button outline color="secondary" size="sm" onClick={onClickExportFormat.bind(null, 'budget')} disabled={selectedBudgetSubjectType == null}>
                          <span className="fas fa-download mr-1" />
                          予算
                        </Button>
                        <ImportButton outline color="info" size="sm" label="予算" processRows={processManualCsvRows.bind(null, customAccountItem, budgetSubjects, months, 'budget')} disabled={selectedBudgetSubjectType == null} />
                      </div>
                      <div className="d-flex flex-column gap-1">
                        <Button outline color="secondary" size="sm" onClick={onClickExportFormat.bind(null, 'performance')} disabled={selectedBudgetSubjectType == null}>
                          <span className="fas fa-download mr-1" />
                          実績
                        </Button>
                        <ImportButton outline color="info" size="sm" label="実績" processRows={processManualCsvRows.bind(null, customAccountItem, budgetSubjects, months, 'performance')} disabled={selectedBudgetSubjectType == null} />
                      </div>
                    </div>
                  </div>
                )
              }
              <EditButton validateOnSubmit={validateOnSubmit} itemRef={itemRef} FormModal={CustomAccountItemFormModal} formProps={{ enabledBudgetSubjectTypes: enabledBudgetSubjectTypes(company), customSections, customAccountItems: customAccountItems.filter(_ => _ !== customAccountItem), }} />
              <DeleteButton itemRef={itemRef} />
            </div>
          </td>
        </tr>
      )}
    />
  );
}
CompanyCustomAccountItems.MonthSelectDisabled = true;
