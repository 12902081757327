import qs from 'qs';
import { max as maxDate, parse as parseDate, format as formatDate, startOfMonth, endOfMonth, isWithinRange } from 'date-fns';
import { mapValues, capitalize, sum, sumBy, get, flattenDeep, uniqBy, isEmpty, zipObject, max, findIndex, groupBy, last, orderBy, sortBy, keyBy, omit, uniq, pick, } from 'lodash';
import { TextDecoder } from 'text-encoding';
import { parse as parseCsv } from 'papaparse';
import { evaluate } from 'mathjs';
import retry from 'async-retry';
import numeral from 'numeral';

import env from './env';
import firebase from './firebase';
import { accountCategories, bsAccountCategories, plAccountCategories, crAccountCategories, debitAccountCategories, trialPlCsvCategoryNames, trialBsCsvCategoryNames, trialCrCsvCategoryNames, documentTypes, budgetSubjectTypes, } from './shared/config';
import { accountItemCategoriesByName, accountItemCategoriesGroupedByType, } from './shared/category';

const { entries, values } = Object;
const db = firebase.firestore();

export const fetchIp = async () => {
  return await retry(async () => {
    const res = await fetch('https://api.ipify.org');
    const ip = await res.text();
    return ip;
  });
};

export const downloadPdf = (filename) => {
  return fetchPdf()
    .then(async (blob) => {
      const newBlob = new Blob([blob], { type: 'application/pdf' })
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        return window.navigator.msSaveOrOpenBlob(newBlob);
      }
      const data = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = data;
      link.download = filename;
      link.click();
      await setTimeout(() => {}, 100);
      window.URL.revokeObjectURL(data);
      return true;
    });
};

export const fetchPdf = () => {
  const url = env('SG_HTML_2_PDF_ENDPOINT');
  const html = document.documentElement.outerHTML
    .replace(/<script.*?>.*?<\/script>/gi, '')
    .replace(/href="\/static/gi, `href="${env('HOSTING_HOST')}/static`);
  const body = JSON.stringify({ html });
  const headers = { 'Content-Type': 'application/json' };
  return fetch(url, { method: 'POST', body, headers }).then(_ => _.blob());
};

export function readFile(file, type = 'readAsText') {
  const reader = new FileReader();
  reader[type](file);
  return new Promise((resolve) => {
    reader.addEventListener('load', _ => resolve(_.target.result));
  });
};

export function periodOfFiscalYear(fiscalYear) {
  return fiscalYear && parseInt(fiscalYear.start_date.replace(/-/g, '').slice(0, 6), 10);
};

export function fiscalYearOfPeriod(period, fiscalYears = []) {
  return fiscalYears.find(_ => periodOfFiscalYear(_) === period);
};

export function fullPathWithParams(params, { pathname, search }) {
  const currentParams = qs.parse(decodeURI(search.slice(1)), { arrayLimit: Infinity });
  const newParams = {
    ...currentParams,
    ...params
  };
  const newSearch = qs.stringify(newParams);
  return `${pathname}${newSearch ? `?${newSearch}` : ''}`;
};

export function closingDate({ fiscalYears }, period) {
  const fiscalYear = fiscalYearOfPeriod(period, fiscalYears);
  return endOfMonth((fiscalYear || {}).end_date);
};

export function isBsAccountItem({ categories }) {
  return bsAccountCategories.includes(last(categories));
};

export function isDebitAccountItem({ categories }) {
  return debitAccountCategories.includes(last(categories));
};

export function pickSearch(search, keys) {
  const params = qs.parse(search.slice(1), { arrayLimit: Infinity });
  return `?${qs.stringify(pick(params, keys))}`;
};

export function autoLink(text, { linkAttr = {} } = {}) {
  return text.replace(/((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g, `<a href="$1" ${entries(linkAttr).map(([k, v]) => `${k}="${v}"`).join(' ')}>$1</a> `);
};

export function nl2br(text) {
  return text.replace(/(\n|\r\n)/g, '<br />');
};

export function periodByDate(date, company) {
  return periodOfFiscalYear(fiscalYearOfDate(date, company));
};

export function fiscalYearOfDate(date, { fiscalYears = [] }) {
  return fiscalYears.find(_ => isWithinRange(date, startOfMonth(_.start_date), endOfMonth(_.end_date)));
};

export function existsInFiscalYears(date, { fiscalYears = [] }) {
  return fiscalYearOfDate(date, { fiscalYears }) != null;
};

export function startOfMonthByFiscalYears(date, { fiscalYears = [] } = {}) {
  const fiscalYear = fiscalYearOfDate(date, { fiscalYears });
  return maxDate(startOfMonth(date), parseDate((fiscalYear || {}).start_date));
};

export async function trialCsvFileToData(type, dimensionName, file) {
  const categoryNames = ({ bs: trialBsCsvCategoryNames, pl: trialPlCsvCategoryNames, cr: trialCrCsvCategoryNames })[type];
  const decoder = new TextDecoder('Shift_JIS');
  const fileContent = decoder.decode(await readFile(file, 'readAsArrayBuffer'));
  const { data: [, _headers, ...rows] } = parseCsv(fileContent, { header: false });
  const isBeginningMonth = _headers.filter(_ => _.match('期末')).length === 1;
  const amountColumnIndex = findIndex(_headers, _ => _ !== '');
  const hasSubItems = dimensionName !== 'none';
  const headers = _headers.map((name, i) => {
    return ({
      '借方金額': 'debit_amount',
      '貸方金額': 'credit_amount',
      '構成比': 'composition_ratio',
    })[name] || (
      name.match(/期首/) ? 'opening_balance' :
      (name.match(/期末/) && i === amountColumnIndex) ? 'opening_balance' :
      name.match(/期末/) ? 'closing_balance' :
      ''
    );
  });
  if(headers.filter(_ => _).length < (isBeginningMonth && ['pl', 'cr'].includes(type) ? 4 : 5)) throw new Error('ファイル形式が正しくありません');
  if(rows.every(_ => _.every(_ => _ !== categoryNames[0]))) throw new Error('BS/PL/CRが正しくありません');

  const items = rows
    .reduce((x, row) => {
      const leftCategoryNames = last(x) == null ? categoryNames : last(x).leftCategoryNames;
      const prevRowType = last(x) == null ? 'none' : last(x).rowType;
      const firstTextValue = row.find(_ => !isEmpty(_));
      const firstTextValueIndex = findIndex(row, _ => !isEmpty(_));
      const isCategoryName = leftCategoryNames.includes(firstTextValue);
      const categoryIndex = leftCategoryNames.indexOf(firstTextValue);
      const rowType = ({
        none: _ => isCategoryName ? 'category' : 'accountItem',
        category: _ => isCategoryName ? 'category' : 'accountItem',
        accountItem: _ => {
          return firstTextValueIndex > last(x).firstTextValueIndex ? (
            'subItem'
          ) : isCategoryName ? (
            'category'
          ) : (
            'accountItem'
          );
        },
      })[prevRowType]();
      const itemName = rowType === 'category' ? firstTextValue.replace(/ 計$/, '') : firstTextValue;
      const itemKey = rowType === 'category' ? `none__${itemName}` : `${itemName}__none`;
      const mappedRow = omit(zipObject(headers, row), '');
      const item = {
        ...mappedRow,
        row,
        itemKey,
        itemName,
        account_category_name: rowType === 'category' ? itemName : null,
        account_item_name: rowType === 'category' ? null : itemName,
        hierarchy_level: firstTextValueIndex + 1 + (type === 'bs' && (firstTextValue || '').match(' 計') ? -1 : 0),
        ...(
          ['opening_balance', 'closing_balance', 'debit_amount', 'credit_amount'].reduce((x, y) => ({
            ...x,
            [y]: parseInt(mappedRow[y], 10) || 0,
          }), {})
        ),
        rowType,
        firstTextValueIndex,
        leftCategoryNames: isCategoryName ? [...leftCategoryNames.slice(0, categoryIndex), ...leftCategoryNames.slice(categoryIndex + 1)] : leftCategoryNames,
      };
      return ({
        category: _ => [...x, item],
        accountItem: _ => [...x, { ...item, [dimensionName]: [], }],
        subItem: _ => {
          const lastX = last(x);
          return [
            ...x.slice(0, x.length -1),
            {
              ...lastX,
              [dimensionName]: [
                ...lastX[dimensionName],
                {
                  ...omit(item, ['itemKey', 'itemName', 'account_category_name', 'account_item_name', 'hierarchy_level', 'composition_ratio', 'row', 'rowType', 'firstTextValueIndex', 'leftCategoryNames']),
                  name: itemName,
                }
              ],
            },
          ];
        },
      })[rowType]();
    }, [])
    .filter(_ => _.row.some(_ => Number.isFinite(parseInt(_, 10))))
    .map((item, i) => {
      return {
        ...omit(item, ['row', 'rowType', 'firstTextValueIndex', 'leftCategoryNames']),
        index: i,
        ...(
          item[dimensionName] != null && {
            [dimensionName]: orderBy(item[dimensionName], _ => Math.abs(_.closing_balance), 'desc'),
          }
        ),
      };
    });
  return items;
};

export async function segmentedTrialCsvFileToData(type, dimensionName, file, budgetSubjectsFreeeName, freeeSubjects = []) {
  const freeeSubjectsByName = keyBy([{ id: 0, name: '未選択' }, ...freeeSubjects], 'name');
  const categoryNames = ({ bs: trialBsCsvCategoryNames, pl: trialPlCsvCategoryNames, cr: trialCrCsvCategoryNames })[type];
  const decoder = new TextDecoder('Shift_JIS');
  const fileContent = decoder.decode(await readFile(file, 'readAsArrayBuffer'));
  const { data: [, _headers, ...rows] } = parseCsv(fileContent, { header: false });
  const amountColumnIndex = findIndex(_headers, _ => _ !== '');
  const hasSubItems = dimensionName !== 'none';
  const headers = _headers;
  const subjectNames = _headers.filter(_ => _);

  const items = rows
    .reduce((x, row) => {
      const leftCategoryNames = last(x) == null ? categoryNames : last(x).leftCategoryNames;
      const prevRowType = last(x) == null ? 'none' : last(x).rowType;
      const firstTextValue = row.find(_ => !isEmpty(_));
      const firstTextValueIndex = findIndex(row, _ => !isEmpty(_));
      const isCategoryName = leftCategoryNames.includes(firstTextValue);
      const categoryIndex = leftCategoryNames.indexOf(firstTextValue);
      const rowType = ({
        none: _ => isCategoryName ? 'category' : 'accountItem',
        category: _ => isCategoryName ? 'category' : 'accountItem',
        accountItem: _ => {
          return firstTextValueIndex > last(x).firstTextValueIndex ? (
            'subItem'
          ) : isCategoryName ? (
            'category'
          ) : (
            'accountItem'
          );
        },
      })[prevRowType]();
      const itemName = rowType === 'category' ? firstTextValue.replace(/ 計$/, '') : firstTextValue;
      const itemKey = rowType === 'category' ? `none__${itemName}` : `${itemName}__none`;
      const mappedRow = omit(zipObject(headers, row), '');
      const item = {
        row,
        itemKey,
        itemName,
        account_category_name: rowType === 'category' ? itemName : null,
        account_item_name: rowType === 'category' ? null : itemName,
        hierarchy_level: firstTextValueIndex + 1 + (type === 'bs' && (firstTextValue || '').match(' 計') ? -1 : 0),
        rowType,
        firstTextValueIndex,
        leftCategoryNames: isCategoryName ? [...leftCategoryNames.slice(0, categoryIndex), ...leftCategoryNames.slice(categoryIndex + 1)] : leftCategoryNames,
        [budgetSubjectsFreeeName]: subjectNames.map((subjectName) => {
          const subject = freeeSubjectsByName[subjectName];
          return {
            id: subject?.id ?? null,
            name: subjectName,
            closing_balance: parseInt(mappedRow[subjectName], 10) || 0,
          };
        }),
      };
      return ({
        category: _ => [...x, item],
        accountItem: _ => [...x, { ...item, [dimensionName]: [], }],
        subItem: _ => {
          const lastX = last(x);
          return [
            ...x.slice(0, x.length -1),
            {
              ...lastX,
              [budgetSubjectsFreeeName]: subjectNames.map((subjectName, i) => {
                const lastXSubject = lastX?.[budgetSubjectsFreeeName][i];
                return {
                  ...lastXSubject,
                  [dimensionName]: [
                    ...(lastXSubject[dimensionName] || []),
                    {
                      name: itemName,
                      closing_balance: parseInt(mappedRow[subjectName], 10) || 0,
                    }
                  ],
                };
              }),
            },
          ];
        },
      })[rowType]();
    }, [])
    .filter(_ => _.row.some(_ => Number.isFinite(parseInt(_, 10))))
    .map((item, i) => {
      return {
        ...omit(item, ['row', 'rowType', 'firstTextValueIndex', 'leftCategoryNames']),
        index: i,
        [budgetSubjectsFreeeName]: subjectNames.map((subjectName, i) => {
          const subjectItem = item[budgetSubjectsFreeeName][i];
          return {
            ...subjectItem,
            ...(
              subjectItem[dimensionName] != null && {
                [dimensionName]: orderBy(subjectItem[dimensionName], _ => Math.abs(_.closing_balance), 'desc').slice(0, 1000)
              }
            ),
          };
        }),
      };
    });
  return items;
};

export const formatTrialCommentAbout = ({ documentType = 'bs', date, itemName, subItemName }) => {
  return ['試算表', documentTypes[documentType].name, formatDate(date, 'YYYY年MM月'), itemName, subItemName].filter(_ => _).join(' ');
};

export const computeCustomAmount = (item, closingDates, customAccountItems, customSectionIds, budgetPerformanceType, computeNormalAmount, amountType = 'occurrence') => {
  const { isManual, expression, itemName, } = item;

  try {
    if(isManual) {
      const suffix = ({ performance: '', budget: '_budget'})[budgetPerformanceType];
      const values = item[({ closing: 'balances', occurrence: 'occurrences' })[amountType]] || {};
      const _closingDates = closingDates || [closingDate];
      return sumBy(_closingDates, (closingDate) => {
        // NOTE: 一時的に予算はNaN
        return isEmpty(customSectionIds) ? (
          values[formatDate(closingDate, 'YYYY/MM')] || 0
        ) : (
          sumBy(customSectionIds, _ => values[[formatDate(closingDate, 'YYYY/MM'), _, suffix].join('')] || 0)
        );
      });
    }
    return evaluate(expression.replace(/acc:"([^"]+)"(?:\:(累計値|発生値))?/g, (_, _accountItemName, amountTypeLabel) => {
      const [accountItemName, subItemName] = _accountItemName.split(':');
      const item = {
        isSubRow: !!subItemName,
        subItemName,
        itemKey: [accountItemName, 'none'].join('__'),
        itemName: accountItemName,
      };
      const _amountType = ({ 累計値: 'closing', 発生値: 'occurrences' })[amountTypeLabel] || amountType;
      return computeNormalAmount(_amountType, item);
    }).replace(/cat:"([^"]+)"(?:\:(累計値|発生値))?/g, (_, accountCategoryName, amountTypeLabel) => {
      const item = {
        itemKey: ['none', accountCategoryName].join('__'),
        itemName: accountCategoryName,
      };
      const _amountType = ({ 累計値: 'closing', 発生値: 'occurrences' })[amountTypeLabel] || amountType;
      return computeNormalAmount(_amountType, item);
    }).replace(/cus:"([^"]+)"(?:\:(累計値|発生値))?/g, (_, targetCustomAccountItemName, amountTypeLabel) => {
      const targetCustomAccountItem = customAccountItems.find(_ => _.name === targetCustomAccountItemName);
      if(!targetCustomAccountItem) return 0;
      const targetCustomAccountItemIndex = findIndex(customAccountItems, _ => _.name === targetCustomAccountItemName);
      const currentCustomAccountItemIndex = findIndex(customAccountItems, _ => _.name === itemName);
      if(targetCustomAccountItemIndex >= currentCustomAccountItemIndex) return 0;
      const item = {
        isCustom: true,
        itemName: targetCustomAccountItem.name,
        ...targetCustomAccountItem,
      };
      const _amountType = ({ 累計値: 'closing', 発生値: 'occurrences' })[amountTypeLabel] || amountType;
      return computeCustomAmount(item, closingDates, customAccountItems, customSectionIds, budgetPerformanceType, computeNormalAmount, _amountType);
    }));
  } catch(e) {
    console.error(e);
    return 0;
  }
};

export const computeTrialAmount = (amountType, item, trialsGroupedByItemKey, trialSubItemsGroupedByItemKey, dimension, closingDates, customAccountItems, documentType) => {
  const { isCustom, isSubRow, itemKey, subItemName, itemName, } = item;
  if(isCustom) {
    return computeCustomAmount(item, closingDates, customAccountItems, null, 'performance', (amountType, item) => {
      // NOTE: カスタム科目ではBSの当期純損益金額を使わない
      const _trialsGroupedByItemKey = {
        ...trialsGroupedByItemKey,
        'none__当期純損益金額': {
          ...trialsGroupedByItemKey['none__当期純損益金額'],
          all: trialsGroupedByItemKey['none__当期純損益金額']?.pl,
        },
      };
      return computeTrialAmount(amountType, item, _trialsGroupedByItemKey, trialSubItemsGroupedByItemKey, dimension, closingDates);
    }, amountType);
  } else {
    const trialsGroupedByClosingDate = trialsGroupedByItemKey[itemKey]?.[documentType || 'all'] || [];
    const trialSubItemsGroupedByClosingDate = trialSubItemsGroupedByItemKey[itemKey]?.[documentType || 'all'] || [];
    return ({
      occurrence: _ => {
        const monthClosingBalances = closingDates.map((closingDate) => {
          const monthTrials = trialsGroupedByClosingDate[formatDate(closingDate, 'YYYY/MM')] || [];
          const monthTrialSubItemsGroupedByName = trialSubItemsGroupedByClosingDate[formatDate(closingDate, 'YYYY/MM')] || [];
          const dimenionItems = isSubRow ? (monthTrialSubItemsGroupedByName[subItemName] || []) : monthTrials;
          return sumBy(dimenionItems, _ => _.closing_balance - _.opening_balance);
        });
        return sum(monthClosingBalances);
      },
      closing: _ => {
        const [closingDate] = closingDates.slice(-1);
        const [monthTrial] = trialsGroupedByClosingDate[formatDate(closingDate, 'YYYY/MM')] || [];
        const monthTrialSubItemsGroupedByName = trialSubItemsGroupedByClosingDate[formatDate(closingDate, 'YYYY/MM')] || [];
        return isSubRow ? (
          monthTrialSubItemsGroupedByName[subItemName]?.[0]?.closing_balance || 0
        ) : get(monthTrial, 'closing_balance', 0);
      },
      opening: _ => {
        const [closingDate] = closingDates;
        const [monthTrial] = trialsGroupedByClosingDate[formatDate(closingDate, 'YYYY/MM')] || [];
        const monthTrialSubItemsGroupedByName = trialSubItemsGroupedByClosingDate[formatDate(closingDate, 'YYYY/MM')] || [];
        return isSubRow ? (
          monthTrialSubItemsGroupedByName[subItemName]?.[0]?.opening_balance || 0
        ) : get(monthTrial, 'opening_balance', 0);
      },
    })[amountType]();
  }
};

export const computeSectionTrialAmount = (item, trialSubjectRowsGroupedByItemKey, dimension, closingDates, customAccountItems, sectionIds, customSectionIds) => {
  const { isCustom, isSubRow, itemKey, subItemName, itemName, } = item;
  if(isCustom) {
    return computeCustomAmount(item, closingDates, customAccountItems, customSectionIds, 'performance', (amountType, item) => computeSectionTrialAmount(item, trialSubjectRowsGroupedByItemKey, dimension, closingDates, customAccountItems, sectionIds));
  } else {
    const trials = trialSubjectRowsGroupedByItemKey[itemKey] || [];
    const monthClosingBalances = closingDates.map((closingDate) => {
      const monthTrials = trials.filter(_ => formatDate(_.closingDate, 'YYYY/MM') === formatDate(closingDate, 'YYYY/MM'));
      const sectionItems = monthTrials.flatMap(_ => (_.sections || []).filter(_ => sectionIds.includes(_.id)))
      const dimenionItems = isSubRow ? sectionItems.flatMap(_ => (_[dimension] || []).filter(_ => _.name === subItemName)) : sectionItems;
      return sumBy(dimenionItems, 'closing_balance');
    });
    return sum(monthClosingBalances);
  }
};

export const computeSectionBudget = (item, budgetsGroupedByItemKey, dimension, closingDates, customAccountItems, customSectionIds) => {
  const { isCustom, isSubRow, itemKey, subItemName, itemName, } = item;
  if(isCustom) {
    return computeCustomAmount(item, closingDates, customAccountItems, customSectionIds, 'budget', (amountType, item) => computeSectionBudget(item, budgetsGroupedByItemKey, dimension, closingDates, customAccountItems, customSectionIds));
  } else {
    const budgets = budgetsGroupedByItemKey[itemKey] || [];
    const relatedBudgets = budgets
      .filter(_ => customSectionIds.includes(_.customSectionId))
      .filter(_ => _.dimension === dimension)
      .filter(_ => _.subItemName === (isSubRow ? subItemName : ''));
    const monthClosingBalances = closingDates.map((closingDate) => {
      const matchedBudgets = relatedBudgets
        .filter(_ => formatDate(_.closingDate, 'YYYY/MM') === formatDate(closingDate, 'YYYY/MM'))
      return sumBy(matchedBudgets, 'amount');
    });
    return sum(monthClosingBalances);
  }
};

export const computeSegmentedTrialAmount = (budgetSubjectType, item, trialSubjectRowsGroupedByItemKey, trialSubItemsGroupedByItemKey, dimension, closingDates, customAccountItems, freeeSubjectIds = [], budgetSubjectIds) => {
  const { isCustom, isSubRow, itemKey, subItemName, itemName, } = item;
  if(isCustom) {
    return computeCustomAmount(item, closingDates, customAccountItems, budgetSubjectIds, 'performance', (amountType, item) => computeSegmentedTrialAmount(budgetSubjectType, item, trialSubjectRowsGroupedByItemKey, trialSubItemsGroupedByItemKey, dimension, closingDates, customAccountItems, freeeSubjectIds));
  } else {
    const trialSubjectRowsGroupedByClosingDate = trialSubjectRowsGroupedByItemKey[itemKey] || [];
    const trialSubItemsGroupedByClosingDate = trialSubItemsGroupedByItemKey[itemKey] || [];
    const monthClosingBalances = closingDates.map((closingDate) => {
      const monthTrialSubjectRows = freeeSubjectIds.flatMap(_ => trialSubjectRowsGroupedByClosingDate[formatDate(closingDate, 'YYYY/MM')]?.[_] || []);
      const monthTrialSubItemsGroupedByName = freeeSubjectIds.map(_ => trialSubItemsGroupedByClosingDate[formatDate(closingDate, 'YYYY/MM')]?.[_] || {});
      const dimenionItems = isSubRow ? monthTrialSubItemsGroupedByName.flatMap(_ => _[subItemName] || []) : monthTrialSubjectRows;
      return sumBy(dimenionItems, 'closing_balance');
    });
    return sum(monthClosingBalances);
  }
};

export const computeBudget = (budgetSubjectType, item, budgetsGroupedByItemKey, dimension, closingDates, customAccountItems, budgetSubjectIds, mainItems) => {
  const { isCustom, isSubRow, itemKey, subItemName, subItems, itemName, } = item;
  const isCategory = itemKey.startsWith('none');
  const isMain = !isCategory && !isSubRow && !isCustom;
  if(isMain && dimension !== 'none') {
    return sumBy(subItems || [], _ => computeBudget(budgetSubjectType, _, budgetsGroupedByItemKey, dimension, closingDates, customAccountItems, budgetSubjectIds, mainItems));
  } else if(isCategory) {
    const category = accountItemCategoriesByName[itemName.replace('損益金額', '利益')];
    return category?.compute(_ => computeBudget(budgetSubjectType, _, budgetsGroupedByItemKey, dimension, closingDates, customAccountItems, budgetSubjectIds, mainItems), mainItems) ?? 0;
  } else if(isCustom) {
    return computeCustomAmount(item, closingDates, customAccountItems, budgetSubjectIds, 'budget', (amountType, item) => computeBudget(budgetSubjectType, item, budgetsGroupedByItemKey, dimension, closingDates, customAccountItems, budgetSubjectIds, mainItems));
  } else {
    const budgets = budgetsGroupedByItemKey[itemKey] || [];
    const relatedBudgets = budgets
      .filter(_ => budgetSubjectIds.includes(_.budgetSubjectId))
      .filter(_ => _.dimension === dimension)
      .filter(_ => _.subItemName === (isSubRow ? subItemName : ''));
    const monthClosingBalances = closingDates.map((closingDate) => {
      const matchedBudgets = relatedBudgets
        .filter(_ => formatDate(_.closingDate, 'YYYY/MM') === formatDate(closingDate, 'YYYY/MM'))
      return sumBy(matchedBudgets, 'amount');
    });
    return sum(monthClosingBalances);
  }
};

export const trialItemsToRowItems = (trialItems, { targetMonth, accountItems, customAccountItems = [], screenType, documentType, dimension, itemType, budgetSubjectsFreeeName, sortsAllPlAndCrCategories, accountItemsOrderSetting, }) => {
  const targetMonthTrialItem = trialItems.find(_ => formatDate(_.closingDate, 'YYYYMM') === targetMonth.toString());
  const accountItemsByName = keyBy(accountItems, 'name');
  const documentTypeCategories = accountItemCategoriesGroupedByType[documentType];
  const uniqItems = uniqBy(
    trialItems
      .map((t, i) => sortBy([...t.balances, ...(t.budgets || []).map(_ => ({ ..._, itemName: _.accountItemName, }))], 'index').map(_ => ({ ..._, trialItem: t, trialItemIndex: i })))
      .reduce((x, y) => [...x, ...y], [])
      .filter(_ => _.itemName)
    ,
    _ => _.type + _.itemKey
  );
  const items = uniqItems.map((item) => {
    const accountItemName = item.account_item_name || item.accountItemName;
    const isCategory = isEmpty(accountItemName);
    const accountItem = accountItemsByName[accountItemName];
    const categoryName = (_ => documentType !== 'bs' ? _?.replace('損益金額', '利益') : _)(last((accountItem || {}).categories) || (item.account_category_name || item.accountCategoryName));
    const accountItemCategory = accountItemCategoriesByName[categoryName];
    return {
      ...item,
      accountItemName,
      isCategory,
      hierarchyLevel: accountItemCategory?.hierarchyLevel() + (isCategory ? 0 : 1),
      accountItem,
      category: categoryName,
      accountItemCategory,
      categoryIndex: (sortsAllPlAndCrCategories ? [...accountItemCategoriesGroupedByType.pl, ...accountItemCategoriesGroupedByType.cr] : documentTypeCategories).map(_ => _.name).indexOf(categoryName),
      itemIndex: accountItemsOrderSetting?.[categoryName]?.indexOf(accountItemName) ?? Infinity,
    };
  });
  const sortedItems = sortBy(items, [
    'categoryIndex',
    _ => isEmpty(_.accountItemName) ? 1 : 0,
    'itemIndex',
  ]);
  return [
    ...sortedItems
      .map((item) => {
        const { itemKey } = item;
        const subItemNames = uniq(flattenDeep(
          [targetMonthTrialItem, ...trialItems]
            .map((trialItem) => {
              return budgetSubjectsFreeeName != null ? (
                [
                  ...(trialItem.trialSubItemsGroupedByItemKey[itemKey] || []).map(_ => _.name),
                  ...get(trialItem, ['budgetsGroupedByItemKey', itemKey], []).map(_ => _.subItemName),
                ]
              ) : (
                trialItem.trialSubItemsGroupedByItemKey[itemKey]?.map(_ => _.name)
              );
            })
        )).filter(_ => _);
        const subItems = subItemNames.map((_, i) => ({
          ...item,
          subItemName: _,
          isSubRow: true,
          mainRowItemKey: item.itemKey,
        }));
        return [
          {
            ...item,
            subItemName: null, // NOTE: 予算importで、メインアイテムにsubItemNameが設定されてしまうことがあるようなので、一旦ここでnullにし、対応
            subItemNames,
            subItems,
            mainRowItemKey: item.itemKey,
          },
          ...subItems
        ];
      })
      .reduce((x, y) => [...x, ...y], [])
      .filter(_ => (_.type || itemType) === documentType),
    ...customAccountItems
      .map(_ => ({
        isCustom: true,
        ...pick(_, ['displays', 'isManual', 'expression', 'displayExpression', 'values', 'occurrences', 'balances']),
        itemKey: `cus_${_.name}`,
        itemName: _.name,
        subItemName: null,
        hierarchy_level: 1,
        hierarchyLevel: 1,
        account_category_name: null,
      }))
      .filter(_ => (_.displays || []).some(_ => _ === screenType + capitalize(documentType))),
  ];
};

export async function log(company, modelName, operationType, user, payload = {}, _batch = null) {
  const batch = _batch || db.batch();
  const ip = await fetchIp();
  batch.set(company.ref.collection('logs').doc(), {
    modelName,
    operationType,
    payload,
    ip,
    ua: window.navigator.userAgent,
    createdBy: pick(user, ['id', 'displayName', 'email', 'type', 'admin']),
    createdAt: new Date(),
  });
  if(_batch == null) await batch.commit();
}

export function filterRowsByAmount(rows, queryParams, key, computeValue, pass = null) {
  const { [key + 'Min']: minString, [key + 'Max']: maxString, } = queryParams;
  let filteredRows = rows;
  if(!isEmpty(minString) || !isEmpty(maxString)) {
    const min = numeral(minString).value() || 0;
    const max = numeral(maxString).value() || Infinity;
    filteredRows = filteredRows.filter((row) => {
      if(pass && pass(row)) return true;

      const value = computeValue(row);
      const absValue = Math.abs(value);
      return min <= absValue && absValue <= max;
    });
  }
  return filteredRows;
};

export function groupTrials(trials) {
  return mapValues(
    groupBy(trials, 'itemKey'),
    _ => mapValues({
      ...groupBy(_, 'type'),
      all: _,
    },
      _ => groupBy(_, _ => formatDate(_.closingDate, 'YYYY/MM')),
    )
  );
}

export function groupTrialSubItems(trialSubItems) {
  return mapValues(
    groupBy(trialSubItems, 'itemKey'),
    _ => mapValues({
      ...groupBy(_, 'type'),
      all: _,
    },
      _ => mapValues(
        groupBy(_, _ => formatDate(_.closingDate, 'YYYY/MM')),
        _ => groupBy(_, 'name'),
      )
    )
  );
}

export function groupSegmentedTrials(trialSubjectRows) {
  return mapValues(
    groupBy(trialSubjectRows, 'itemKey'),
    _ => mapValues(
      groupBy(_, _ => formatDate(_.closingDate, 'YYYY/MM')),
      _ => groupBy(_, 'subjectId')
    )
  );
}

export function groupSegmentedTrialSubItems(trialSubItems) {
  return mapValues(
    groupBy(trialSubItems, 'itemKey'),
    _ => mapValues(
      groupBy(_, _ => formatDate(_.closingDate, 'YYYY/MM')),
      _ => mapValues(
        groupBy(_, 'subjectId'),
        _ => groupBy(_, 'name'),
      )
    )
  );
}
