import { useRef, useState, } from 'react';
import { useToggle, useMap, } from 'react-use';
import { fromPairs, isEmpty, } from 'lodash';

import useQueryParams from './useQueryParams';

export default function useMainTableUtilities (props) {
  const queryParams = useQueryParams();
  const [subRowsVisibilities, { setAll: setAllSubRowsVisibilities, set: setSubRowsVisibilities }] = useMap({});
  const [isCommentRowToggled, toggleCommentRowToggled] = useToggle();
  const rowsChangeListeners = useRef([]);
  const hoveredCommentersRef = useRef();
  const setHoveredCommenters = (commenteeKeys, el) => {
    hoveredCommentersRef.current = {
      ...hoveredCommentersRef.current,
      ...fromPairs(commenteeKeys?.map(_ => [_, el])),
    };
  };
  const onClickComment = (rows) => (commenteeKey) => {
    const [itemName] = commenteeKey.split('_');
    const targetRow = rows?.find(_ => _.key === itemName);
    if(targetRow == null) return;

    const isShowing = !targetRow.isSubRow || subRowsVisibilities[targetRow.itemKey];
    if(isShowing) {
      toggleCommenter(commenteeKey);
    } else {
      setSubRowsVisibilities(targetRow.itemKey, true);
      rowsChangeListeners.current = [...rowsChangeListeners.current, () => toggleCommenter(commenteeKey)];
    }
  };
  const toggleCommenter = (commenteeKey) => {
    const { [commenteeKey]: commenter } = hoveredCommentersRef.current;
    if(commenter) {
      setTimeout(() => {
        commenter.open();
        commenter.focus && commenter.focus();
      }, 1000);
      return true;
    }
  };
  const toggleInitiallyCommentRow = (rows) => {
    if(isEmpty(rows) || isCommentRowToggled || !queryParams.commenteeKey) return;

    toggleCommentRowToggled(true);
    onClickComment(rows)(queryParams.commenteeKey);
  }
  const onRowsChanged = async () => {
    rowsChangeListeners.current = rowsChangeListeners.current.filter(_ => !_());
  };

  return {
    onClickComment,
    toggleCommenter,
    toggleInitiallyCommentRow,
    subRowsVisibilities,
    setAllSubRowsVisibilities,
    setSubRowsVisibilities,
    setHoveredCommenters,
    onRowsChanged,
  };
};
