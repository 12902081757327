import React from 'react';
import { Button, } from 'reactstrap';
import { isEmpty, last, sortBy, omit } from 'lodash';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import { format as formatDate } from 'date-fns';
import classnames from 'classnames';

import firebase from '../../firebase';
import { diff } from '../../shared/util';
import { screens, roles, modelNames, operationTypes, documentTypes } from '../../shared/config';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import HelpLink from '../HelpLink';
import CompanyPage from '../hocs/CompanyPage';
import ExportButton from '../ExportButton';

const { keys } = Object;
const db = firebase.firestore();
const companiesRef = db.collection('companies');
const modelTypes = {
  model: {
    Component: ModelLogContent,
    logText: (log) => {
      const { operationType, payload: { fields, data, prevData }, } = log;
      const operationLabel = operationTypes[operationType];
      const detail = (({
        update: _ => `${keys(diff(data, prevData)).map(_ => fields?.[_].label).join(', ')}を`
      })[operationType] || (_ => ''))();
      return `${detail}${operationLabel}しました。`;
    },
  },
  screen: {
    Component: ScreenLogContent,
    logText: (log) => {
      const { operationType, payload: { documentType, targetMonth, customSection, budgetSubjects, objectName, }, } = log;
      const operationLabel = operationTypes[operationType];
      return [
        ...(documentType ? [documentTypes[documentType].name] : []),
        ...(customSection ? [customSection.name] : []),
        ...(budgetSubjects ? [budgetSubjects.map(_ => _.name).join(', ')] : []),
        ...(targetMonth ? [formatDate(targetMonth + '01', 'YYYY/MM')] : []),
        ...(objectName ? [objectName] : []),
        `を${operationLabel}しました。`,
      ].join(' ');
    },
  },
  member: {
    Component: MemberLogContent,
    logText: (log) => {
      const { operationType, payload: { data, prevData }, } = log;
      const operationLabel = operationTypes[operationType];
      const roleText = ({ role, enabledScreens }) => {
        const enabledScreensText = ['member', 'guest'].includes(role) && !isEmpty(enabledScreens) ? `(${enabledScreens.map(_ => screens[_]?.label).join(', ')})` : '';
        return (roles[role] || '(不明)') + enabledScreensText;
      };
      const detail = (({
        update: _ => `${roleText(prevData)}から${roleText(data)}に`
      })[operationType] || (_ => ''))();
      return `${data.displayName || data.email}を${detail}${operationLabel}しました。`;
    },
  },
};

export default CompanyPage(CompanyLogs);

function CompanyLogs(props) {
  const { match: { params: { companyId } } } = props;
  const logs = useCollectionSubscription(companiesRef.doc(companyId).collection('logs').orderBy('createdAt', 'desc').limit(1000), [companyId]);
  const rowsForExport = () => {
    return logs.map((log) => {
      const { id, modelName, operationType, ua, ip, createdBy, createdAt, } = log;
      const { logText } = modelTypes[modelNames[modelName]?.type];
      return {
        id,
        modelName: modelNames[modelName]?.label,
        operationType: operationTypes[operationType],
        createdBy: createdBy.displayName,
        createdAt: formatDate(createdAt.toDate(), 'YYYY/MM/DD HH:mm:ss'),
        body: logText(log),
        ua,
        ip,
      };
    });
  };

  return (
    <div className="company-custom-sections">
      <div className="container py-5">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="セキュリティについて" />
        </div>
        <div className="d-flex justify-content-center mb-2">
          <h3>操作ログ</h3>
        </div>
        <div className="d-flex justify-content-end gap-1">
          <ExportButton outline fileName="操作ログ.csv" rows={rowsForExport} />
        </div>
        {
          logs.length > 0 ? (
            <table className="table table-hover mt-5 table-hover">
              <thead>
                <tr>
                  <th>操作対象</th>
                  <th>操作種別</th>
                  <th>ユーザー</th>
                  <th>日時</th>
                  <th>内容</th>
                </tr>
              </thead>
              <tbody>
                {
                  logs.map((log) => {
                    const { id, modelName, operationType, createdBy, createdAt, } = log;
                    const { Component } = modelTypes[modelNames[modelName]?.type];

                    return (
                      <tr key={id}>
                        <td>
                          {modelNames[modelName]?.label}
                        </td>
                        <td>
                          {operationTypes[operationType]}
                        </td>
                        <td>
                          {createdBy.displayName}
                        </td>
                        <td>
                          {formatDate(createdAt.toDate(), 'YYYY/MM/DD HH:mm:ss')}
                        </td>
                        <td>
                          <Component log={log} />
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          ) : (
            <span>ログはまだありません</span>
          )
        }
      </div>
    </div>
  );
}

function MemberLogContent (props) {
  const { log } = props;

  return (
    <div>
      {modelTypes.member.logText(log)}
    </div>
  );
}

function ScreenLogContent (props) {
  const { log } = props;

  return (
    <div>
      {modelTypes.screen.logText(log)}
    </div>
  );
}

function ModelLogContent (props) {
  const { log } = props;

  return (
    <div>
      {modelTypes.model.logText(log)}
    </div>
  );
}
