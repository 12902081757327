import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button, Navbar, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import { pick, get, range, inRange } from 'lodash';
import { format as formatDate, differenceInDays } from 'date-fns';
import { toast } from 'react-toastify';
import classnames from 'classnames';

import logo from '../logo.png';
import firebase from '../firebase';
import { canSyncCompany } from '../abilities';
import HelpLink from './HelpLink';
import { periodOfFiscalYear, fullPathWithParams, fiscalYearOfPeriod, pickSearch } from '../utils';
import env from '../env';

const auth = firebase.auth();
const { entries } = Object;

export default class HeaderNav extends Component {
  constructor() {
    super();
    this.state = {};
  }
  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }
  onSelectPeriod = ({ value }) => {
    const { history, location } = this.props;
    const path = fullPathWithParams({ period: value }, location);
    history.replace(encodeURI(path));
  }
  onSelectMonth = ({ value }) => {
    const { history, location } = this.props;
    const path = fullPathWithParams({ targetMonth: value }, location);
    history.replace(encodeURI(path));
  }
  syncCompany = async () => {
    const { company: { sourceId: companySourceId, }, match: { params: { companyId } } } = this.props;
    const url = `${env('CLOUD_FUNCTIONS_ENDPOINT')}/syncCompany?${qs.stringify({ companyId, companySourceId })}`;
    const token = await auth.currentUser.getIdToken(true);
    const headers = { 'Firebase-User-Token': token };
    try {
      this.setState({ isSyncing: true });
      const res = await fetch(url, { headers });
      if(res.status !== 200) {
        const { errors } = await res.json();
        throw new Error(errors.join('\n'));
      }
      toast.success('会社情報を同期しました');
    } catch(e) {
      toast.error('失敗しました');
      console.error(e);
    }
    this.setState({ isSyncing: false });
  }
  onClickLogOut = async () => {
    if(!window.confirm('ログアウトしますか？')) return;
    await auth.signOut();
    window.location.href = '/';
  }
  render() {
    const { isPublic = false, links, user, role, match: { params: { companyId } }, location: { search }, leftTrialDays, targetMonth, period, company, periodSelectDisabled = false, monthSelectDisabled = false, admin = false, } = this.props;
    const { isSyncing = false } = this.state;
    const periodOptions = ((company || {}).fiscalYears || []).map(_ => ({ label: `${_.start_date.replace(/-/g, '/').slice(0, 7)} - ${_.end_date.replace(/-/g, '/').slice(0, 7)}`, value: periodOfFiscalYear(_), }));
    const { start_date: startDate, end_date: endDate } = fiscalYearOfPeriod(period, (company || {}).fiscalYears) || {};
    const monthOptions = range(formatDate(startDate, 'YYYYMM'), formatDate(endDate, 'YYYYMM') + '.1').filter(_ => inRange(parseInt(_.toString().slice(-2), 10), 1, 12.1)).map(_ => ({ label: `${_.toString().slice(0, 4)}/${_.toString().slice(4)}`, value: _ }));
    const pickedSearch = pickSearch(search, ['period', 'targetMonth']);
    const BrandTag = isPublic ? 'span' : Link;

    return (
      <header className="header-nav">
        <Navbar color={admin ? 'dark' : 'light'} {...{ [admin ? 'dark' : 'light']: true }} expand="md">
          <BrandTag to="/">
            <img src={logo} style={{ height: 38 }} />
          </BrandTag>
          <div className="ml-auto d-flex align-items-center">
            {
              company != null && (
                <Fragment>
                  {
                    !periodSelectDisabled && (
                      <div style={{ width: 220, position: 'relative', zIndex: 6 }}>
                        <Select
                          value={periodOptions.find(_ => _.value === period)}
                          options={periodOptions}
                          onChange={this.onSelectPeriod}
                          className="w-100"
                        />
                      </div>
                    )
                  }
                  {
                    !monthSelectDisabled && (
                      <div style={{ width: 150, position: 'relative', zIndex: 6 }} className="ml-1">
                        <Select
                          value={monthOptions.find(_ => _.value === targetMonth)}
                          options={monthOptions}
                          onChange={this.onSelectMonth}
                          className="w-100"
                        />
                      </div>
                    )
                  }
                  {
                    !isPublic && company != null && (
                      <div className="ml-3">
                        <div>{company.display_name}</div>
                      </div>
                    )
                  }
                  {
                    !isPublic && get(company, 'plan', 'trial') === 'trial' && (
                      <div className="ml-3">
                        <Button size="sm" color="success" tag="a" href="https://docs.google.com/forms/d/e/1FAIpQLSfzZgD9VOgC58qlHqsOlwinTBBkmhf5uHh7unn-5k2PrWtAEg/viewform" target="_blank">
                          有償版を申し込む{leftTrialDays >= 0 ? `(試用期間残り${leftTrialDays}日)` : ''}
                        </Button>
                      </div>
                    )
                  }
                  {
                    !isPublic && company && company.fiscalYears && user != null && canSyncCompany(user, role) && (
                      <div className="ml-1">
                        <Button size="sm" color="secondary" disabled={isSyncing} onClick={this.syncCompany}>
                          <span className={classnames('mr-1 fas fa-sync cursor-pointer', { 'fa-spin': isSyncing })} />
                          事業所を同期
                        </Button>
                      </div>
                    )
                  }
                </Fragment>
              )
            }
            <Nav navbar className="d-flex justify-content-end" style={{ minWidth: 150 }}>
              {
                user != null && (
                  <UncontrolledDropdown tag="div">
                    <DropdownToggle nav caret>
                      {user.displayName}
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem tag="a" href={`${env('CLOUD_FUNCTIONS_ENDPOINT')}/authFreee`}>
                        freee連携
                      </DropdownItem>
                      <DropdownItem tag={Link} to="/profile">
                        プロフィール
                      </DropdownItem>
                      <DropdownItem onClick={this.onClickLogOut}>
                        ログアウト
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )
              }
            </Nav>
          </div>
        </Navbar>
        {
          companyId && (
            <div className="d-flex flex-row bg-theme-light flex-wrap p-2 c-gap-4 r-gap-1">
              {
                entries(links).map(([name, { path, label }]) => {
                  return (
                    <div key={name} className>
                      <Link className="text-dark" to={`/companies/${companyId}${path}${pickedSearch}`}>{label}</Link>
                    </div>
                  );
                })
              }
            </div>
          )
        }
      </header>
    );
  }
}
