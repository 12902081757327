import React, { Component, Fragment, useState, useRef, useEffect, } from 'react';
import { useLocation } from 'react-router';
import qs from 'qs';
import { useRaf, } from 'react-use';

import useQueryParams from './useQueryParams';

export default function useScrollFocusByQuery (condition = _ => false) {
  const elapsed = useRaf(1000, 600);
  const elm = useRef(null);
  const queryParams = useQueryParams();
  const isSatisfied = condition({ queryParams });
  const focusColor = isSatisfied ? `rgba(255, 255, 50, ${1 - elapsed})` : null;
  useEffect(() => {
    if(isSatisfied && elm.current != null) {
      elm.current.scrollIntoView({ block: 'center', });
    }
  }, [isSatisfied, elm.current]);

  return { elapsed, elm, isSatisfied, focusColor, };
};

