import React from 'react';
import { Nav, NavItem, } from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

const tabs = [
  { name: 'detections', label: '検出事項', path: 'journalsCheck' },
  { name: 'creatorsAndApprovers', label: '作成者・承認者', path: 'journalsCheck/creators' },
];

export default function CompanyJournalsCheckTabs(props) {
  const { activeTab, companyId, search } = props;

  return (
    <Nav tabs>
      {
        tabs.map(({ label, name, path }) => {
          return (
            <NavItem key={name}>
              <Link className={classnames('nav-link cursor-pointer', { active: activeTab === name })} to={`/companies/${companyId}/${path}${search}`}>
                {label}
              </Link>
            </NavItem>
          )
        })
      }
    </Nav>
  );
};
