import React, { useState, useEffect, } from 'react';
import { omit, chunk, sumBy, } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { Button, Input } from 'reactstrap';
import { format as formatDate, addMinutes, } from 'date-fns';
import { useToggle, usePrevious, } from 'react-use';

import useCollectionSubscription from './hooks/useCollectionSubscription';
import { trialCsvFileToData, fiscalYearOfPeriod, } from '../utils';
import firebase from '../firebase';

const db = firebase.firestore();
const companiesRef = db.collection('companies');

export default function TrialScrapingButton(props) {
  const { isSegmented = false, budgetSubjectType, company, period, closingDate, dimension = 'none', ...extraProps } = props;
  const fiscalYear = fiscalYearOfPeriod(period, company.fiscalYears);
  const jobs = useCollectionSubscription(company.ref.collection('scrapeTrialJobs').where('closingDate', '==', closingDate).where('isSegmented', '==', isSegmented), [company, closingDate, isSegmented]);
  const prevJobs = usePrevious(jobs);
  const onClick = async () => {
    await company.ref.collection('scrapeTrialJobs').add({
      isSegmented,
      budgetSubjectType: budgetSubjectType || null,
      closingDate,
      period,
      dimension,
      fiscalYear,
      status: 'initial',
      createdAt: new Date(),
    });
  };
  const isProccessing = jobs.some(_ => ['initial', 'requested'].includes(_.status) && _.createdAt.toDate() > addMinutes(new Date(), -30));
  useEffect(() => {
    if(prevJobs?.length > 0 && sumBy(jobs, _ => _.status === 'completed') > sumBy(prevJobs, _ => _.status === 'completed')) {
      toast.success(`${closingDate.slice(0, 7).replace('-', '/')} 同期しました`);
    }
    if(prevJobs?.length > 0 && sumBy(jobs, _ => _.status === 'failed') > sumBy(prevJobs, _ => _.status === 'failed')) {
      toast.error(`${closingDate.slice(0, 7).replace('-', '/')} 同期失敗しました`);
    }
  }, [jobs, prevJobs]);

  return (
    <Button size="sm" className="small ml-1 px-0" color="link" disabled={isProccessing} {...extraProps} onClick={onClick}>
      <span className={classnames('fas fa-user-secret', { 'fa-beat': isProccessing})} />
    </Button>
  );
};
