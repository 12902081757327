import { useMemo } from 'react';
import { pickBy, isEmpty, } from 'lodash';
import { useLocation } from 'react-router';
import qs from 'qs';

export default function useQueryParams () {
  const location = useLocation();
  const queryParams = useMemo(() => pickBy(qs.parse(decodeURI(location.search.slice(1)), { arrayLimit: Infinity }), _ => _ !== ''), [location.search]);
  return queryParams;
};
