const { isEmpty } = require('lodash');
const { documentTypes, dimensions, manualTypes, } = require('../config');

const { keys, entries } = Object;
const types = {
  emptyTags: { label: 'タグ未選択', targetType: 'journal', },
  approvement: { label: '自己承認', targetType: 'journal', },
  combination: { label: '貸借異常', targetType: 'journal', },
  differentTax: { label: '税区分', targetType: 'journal', },
  dimensionBalances: { label: '明細残高', targetType: 'balance', },
  custom: { label: 'カスタム', },
};

const fields = () => {
  return {
    ignoringNote: {
      type: 'text',
      label: '無視メモ',
    },
  };
};

module.exports = {
  fields,
  types,
};
