import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react';

if(['production', 'staging'].includes(process.env.REACT_APP_ENV)) {
  Bugsnag.start({
    apiKey: 'c20fcd118799a478e84e42fda836a5b9',
    plugins: [new BugsnagPluginReact(React)],
    releaseStage: process.env.REACT_APP_ENV || 'development',
  });
}
