import React, { Fragment } from 'react';
import { Button, } from 'reactstrap';
import { chunk } from 'lodash';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';

import firebase from '../../firebase';
import { fields } from '../../shared/models/detection';
import ModelFormModal from '../modals/ModelFormModal';

const db = firebase.firestore();

export default function IgnoreButton(props) {
  const { detections, onFinished, ...extraProps } = props;
  const [showsModal, toggleModal] = useToggle(false);
  const onSubmit = async (values) => {
    try {
      await chunk(detections, 500).reduce(async (x, detections) => {
        await x;
        const batch = db.batch();
        detections.forEach(_ => batch.update(_.ref, { ...values, status: 'ignored', }));
        await batch.commit();
      }, Promise.resolve());
      toast.success('無視しました');
      toggleModal(false);
      onFinished && onFinished();
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <Fragment>
      <Button color="warning" onClick={toggleModal.bind(null, true)} {...extraProps}>
        無視する
      </Button>
      {showsModal && <ModelFormModal isOpen title="無視する" fields={fields} onClickClose={toggleModal.bind(null, false)} onSubmit={onSubmit} />}
    </Fragment>
  );
};
