import React, { Fragment, useEffect } from 'react';
import { Button, Input, } from 'reactstrap';
import { pickBy, countBy, intersection, uniq, chunk, orderBy, without, isEmpty, get, keyBy, last, sortBy, omit, difference, } from 'lodash';
import { toast } from 'react-toastify';
import { useToggle, useList, } from 'react-use';
import { Container, Draggable } from 'react-smooth-dnd';
import numeral from 'numeral';

import firebase from '../../firebase';
import { batch, getCollectionData, } from '../../shared/firebase';
import { fields, fieldsForImport, types as customPartnerTypes } from '../../shared/models/customPartner';
import { mapKeysToJa, mapKeysFromJa, } from '../../shared/texts';
import { canWriteCustomPartners } from '../../abilities';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import usePagination from '../hooks/usePagination';
import useQueryParams from '../hooks/useQueryParams';
import HelpLink from '../HelpLink';
import AddButton from '../AddButton';
import ImportButton from '../ImportButton';
import ExportButton from '../ExportButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ModelFormModal from '../modals/ModelFormModal';
import UserDisplay from '../UserDisplay';
import CompanyPage from '../hocs/CompanyPage';
import Pagination from '../Pagination';
import ProgressButton from '../ProgressButton';
import QueryInput from '../QueryInput';
import QueryBoolean from '../QueryBoolean';
import QuerySelector from '../QuerySelector';

const countPerPage = 50;
const { keys, entries } = Object;
const db = firebase.firestore();
const companiesRef = db.collection('companies');
const customPartnerTypesByLabel = entries(customPartnerTypes).reduce((x, [k, v]) => ({ ...x, [v.label]: { ...v, key: k } }), {});
const typeOptions = [{ label: '区分なし', value: 'noType', }, ...entries(customPartnerTypes).map(([k, v]) => ({ label: v.label, value: k }))];

export default CompanyPage(CompanyCustomPartners);

function CompanyCustomPartners(props) {
  const { company, user, members, match: { params: { companyId } } } = props;
  const queryParams = useQueryParams();
  const { queryText, targetMonth, onlyDup, } = queryParams;
  const [selectedItemIds, { set: setSelectedItemIds, push: selectItemId, removeAt: removeSelectedItemIdAt }] = useList([]);
  const unselectItemId = _ => removeSelectedItemIdAt(selectedItemIds.indexOf(_));
  const isSelecting = selectedItemIds.length > 0;
  const customPartners = useCollectionSubscription(company.ref.collection('customPartners').orderBy('name'), [companyId]);
  const customPartnersById = keyBy(customPartners, 'id');
  const partnerChunks = useCollectionSubscription(company.ref.collection('partnerChunks'), [companyId]);
  const freeePartners = [
    { id: 0, name: '未選択' },
    ...partnerChunks.flatMap(_ => _.data),
  ];
  const freeePartnersById = keyBy(freeePartners, 'id');
  const freeePartnersByName = keyBy(freeePartners, 'name');

  let filteredRows = customPartners;
  if(!isEmpty(queryText)) {
    filteredRows = filteredRows.filter(_ => _.name.includes(queryText));
  }
  if(!isEmpty(queryParams.types)) {
    filteredRows = filteredRows.filter(_ => (isEmpty(_.types) ? ['noType'] : _.types).some(_ => queryParams.types.includes(_)));
  }
  if(queryParams.onlyDup === '1') {
    const dupFreeeIds = keys(pickBy(countBy(filteredRows.flatMap(_ => _.freeePartnerIds || []), _ => _), _ => _ > 1));
    filteredRows = filteredRows.filter(_ => (_.freeePartnerIds || []).some(_ => dupFreeeIds.includes(_.toString())));
  }

  const pagination = usePagination({ countPerPage, totalCount: filteredRows.length, });
  const { pageNumber, offset, first, } = pagination;
  const displayRows = filteredRows.slice(offset, offset + countPerPage);
  const processRows = (rows) => {
    const processedRows = rows
      .map(_ => mapKeysFromJa(_, { 区分: 'types', freee名称: 'freeePartners', }))
      .filter(_ => !Object.values(_).every(isEmpty))
      .map(_ => ({ ..._, creditLimit: numeral(_.creditLimit).value(), }));
    return processedRows.map((row) => {
      const otherItems = [...customPartners.filter(_ => _.id !== row.id), ...without(processedRows, row)];
      return {
        ...row,
        conflicted: otherItems.map(_ => _.name).includes(row.name),
      };
    });
  };
  const processRow = (batch, row, i) => {
    const { id, } = row;
    const existingCustomPartner = customPartnersById[id];
    const exists = existingCustomPartner != null;
    const ref = company.ref.collection('customPartners').doc(...(id ? [id] : []));
    const currentCreditLimit = orderBy((existingCustomPartner?.creditLimits || []), 'yearMonth', 'desc').find(_ => _.yearMonth <= targetMonth)?.value || 0;
    const newCreditLimit = numeral(row.creditLimit).value();
    batch.set(ref, {
      types: row.types.split(',').filter(_ => _).map(_ => customPartnerTypesByLabel[_].key),
      name: row.name,
      freeePartnerIds: row.freeePartners.split('\n').filter(_ => _).map(_ => get(freeePartnersByName, [_, 'id'])),
      creditLimits: currentCreditLimit !== newCreditLimit ? [...(existingCustomPartner?.creditLimits || []).filter(_ => _.yearMonth !== targetMonth), { yearMonth: targetMonth, value: numeral(row.creditLimit).value() }] : (existingCustomPartner?.creditLimits || []),
      ...(!exists && { createdAt: new Date(), })
    }, { merge: true });
  };
  const rowsForExport = filteredRows.map((customPartner) => {
    const { id, types, name, freeePartnerIds, creditLimits = [], } = customPartner;
    return {
      id,
      types: types.map(_ => customPartnerTypes[_].label).join(','),
      name,
      freeePartners: freeePartnerIds.map(_ => get(freeePartnersById, [_, 'name'])).join('\n'),
      creditLimit: orderBy(creditLimits, 'yearMonth', 'desc').find(_ => _.yearMonth <= targetMonth)?.value,
    };
  }).map(_ => mapKeysToJa(_, { types: '区分', freeePartners: 'freee名称' }));
  const onClickComputeCustomers = async () => {
    if(!window.confirm('試算表の収益科目から得意先を自動判定します。よろしいですか？')) return;

    try {
      const accountItems = await getCollectionData(company.ref.collection('accountItems').where('account_category', '==', '売上高'));
      const trialSubItemChunks = (await Promise.all(chunk(accountItems, 10).map(_ => getCollectionData(company.ref.collection('trialSubItemChunks__partners').where('account_item_name', 'in', _.map(_ => _.name)))))).flat();
      const partnerIds = uniq(trialSubItemChunks.flatMap(_ => (_.subItems || []).map(_ => freeePartnersByName[_.name]?.id)));
      const targetCustomPartners = customPartners.filter(_ => intersection(_.freeePartnerIds, partnerIds).length > 0);
      await batch(db, targetCustomPartners, (batch, _) => batch.update(_.ref, { types: uniq([..._.types, 'customer']), }));
      toast.success('得意先を判定しました');
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  const onClickBatchDelete = async () => {
    if(!window.confirm('選択しているものを一括削除します。よろしいですか？')) return;

    try {
      await batch(db, selectedItemIds, (batch, _) => batch.delete(company.ref.collection('customPartners').doc(_)));
      toast.success('一括削除しました。');
      setSelectedItemIds([]);
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <div className="company-custom-partners">
      <div className="container py-5">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="カスタム取引先を作成する" />
        </div>
        <div className="d-flex justify-content-center mb-2">
          <h3>カスタム取引先</h3>
        </div>
        <div className="d-flex flex-wrap justify-content-between align-items-end">
          <div className="d-flex gap-1 align-items-end">
            <QueryInput paramName="queryText" label="名称で検索" disabled={isSelecting} />
            <QuerySelector paramName="types" width={180} isMulti options={typeOptions} label="区分で絞り込み" disabled={isSelecting} />
            <QueryBoolean paramName="onlyDup" label="複数指定されている取引先のみ" disabled={isSelecting} />
          </div>
        </div>
        <div className="mt-1 d-flex flex-wrap align-items-end justify-content-end gap-1">
          <Pagination {...pagination} disabled={isSelecting} />
          {
            canWriteCustomPartners(user, companyId) && (
              <Fragment>
                <BatchButton {...{ company, freeePartners, customPartners, }} disabled={isSelecting} />
                <ProgressButton process={onClickComputeCustomers} disabled={isSelecting}>得意先を判定する</ProgressButton>
                <ImportButton color="info" outline processRows={processRows} processRow={processRow} documentName="customPartner" fields={fieldsForImport({ freeePartners, })} disabled={isSelecting} />
                <ExportButton outline fileName="カスタム取引先.csv" rows={rowsForExport} disabled={isSelecting} />
                <AddButton itemRef={company.ref.collection('customPartners').doc()} processValues={_ => ({ ..._, creditLimits: [{ yearMonth: targetMonth, value: _.creditLimit }], })} FormModal={ModelFormModal} formProps={{ title: 'カスタム取引先 追加', fields: fields({ freeePartners, customPartners, }), }} disabled={isSelecting} />
              </Fragment>
            )
          }
        </div>
        {
          isSelecting && (
            <div className="w-100 position-fixed" style={{ bottom: 0, left: 0, zIndex: 3, }}>
              <div className="container">
                <div className="card p-2" style={{ backgroundColor: 'rgba(255, 255, 255, 0.85' }}>
                  <div className="p-2 d-flex justify-content-between align-items-center">
                    <div>
                      {selectedItemIds.length} 件を選択中
                    </div>
                    <div className="d-flex">
                      <ProgressButton color="danger" process={onClickBatchDelete}>
                        削除
                      </ProgressButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        {
          customPartners.length > 0 ? (
            <table className="table table-hover mt-3">
              <thead>
                <tr>
                  <th>
                    <Input type="checkbox" className="position-relative m-0" checked={difference(displayRows.map(_ => _.id), selectedItemIds).length === 0} onChange={_ => _.target.checked ? setSelectedItemIds(displayRows.map(_ => _.id)) : setSelectedItemIds([])} />
                  </th>
                  <th style={{ minWidth: 200 }}>区分</th>
                  <th style={{ minWidth: 200 }}>カスタム名称</th>
                  <th style={{ minWidth: 200 }}>freee名称</th>
                  <th style={{ minWidth: 200 }}>与信限度額</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  displayRows.map((customPartner) => {
                    const { id, ref, types, name, freeePartnerIds = [], creditLimits = [], } = customPartner;
                    const creditLimit = orderBy(creditLimits, 'yearMonth', 'desc').find(_ => _.yearMonth <= targetMonth)?.value;
                    return (
                      <tr key={id}>
                        <td>
                          <Input type="checkbox" className="position-relative m-0" checked={selectedItemIds.includes(id)} onChange={_ => selectedItemIds.includes(id) ? unselectItemId(id) : selectItemId(id)} />
                        </td>
                        <td>
                          {types.map(_ => customPartnerTypes[_].label).join(', ')}
                        </td>
                        <td>
                          {name}
                        </td>
                        <td>
                          {
                            freeePartnerIds.map((freeePartnerId) => {
                              const freeePartner = freeePartnersById[freeePartnerId];
                              return freeePartner && (
                                <span key={freeePartnerId} className="badge badge-secondary mr-1">
                                  {freeePartner.name}
                                </span>
                              );
                            })
                          }
                        </td>
                        <td className="text-right">
                          {numeral(creditLimit).format()}
                        </td>
                        <td className="text-right text-nowrap">
                          <EditButton itemRef={ref} processValues={_ => ({ ..._, creditLimits: [...creditLimits.filter(_ => _.yearMonth !== targetMonth), { yearMonth: targetMonth, value: _.creditLimit }], })} FormModal={ModelFormModal} formProps={{ title: 'カスタム取引先 編集', values: { ...customPartner, creditLimit }, fields: fields({ freeePartners, customPartners: without(customPartners, customPartner), }), }} />
                          <DeleteButton itemRef={ref} className="ml-2" />
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          ) : (
            <div className="mt-3">
              <span>カスタム取引先は未登録です</span>
            </div>
          )
        }
      </div>
    </div>
  );
}

function BatchButton (props) {
  const { customPartners, freeePartners, company, disabled = false, } = props;
  const usedFreeePartnerIds = customPartners.flatMap(_ => _.freeePartnerIds || []);
  const [isProcessing, toggleProcessing] = useToggle(false);
  const onClick = async () => {
    if(!window.confirm('freeeと同名のカスタム取引先を一括登録します。よろしいですか？')) return;

    toggleProcessing(true);
    try {
      await batch(db, freeePartners.filter(_ => !usedFreeePartnerIds.includes(_.id)), (batch, freeePartner) => {
        batch.set(company.ref.collection('customPartners').doc(), {
          name: freeePartner.name,
          types: [],
          freeePartnerIds: [freeePartner.id],
          creditLimit: 0,
          createdAt: new Date(),
        });
      });
      toast.success('一括登録しました');
    } catch(e) {
      toast.error('失敗しました');
      throw e;
    }
    toggleProcessing(false);
  };

  return (
    <Button disabled={isProcessing || disabled} onClick={onClick}>
      {isProcessing && <span className="fas fa-spin fa-spinner" />}
      freeeと同名のカスタム取引先を一括登録する
    </Button>
  );
}
