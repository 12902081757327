import React, { useState, } from 'react';
import { TextDecoder, TextEncoder } from 'text-encoding';
import { parse as parseCsv, unparse as unparseCsv } from 'papaparse';
import { get, isEmpty, chunk } from 'lodash';
import classnames from 'classnames';
import { Button, Input, } from 'reactstrap';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';

import firebase from '../firebase';
import { readFile } from '../utils';
import texts from '../shared/texts';

const { entries } = Object;
const db = firebase.firestore();

export default function ImportButton ({ label = 'インポート', encoding = 'Shift_JIS', onComplete = _ => _, processRows = _ => _, processRow = _ => _, beforeSave = _ => _, disabled = false, documentName = '', fields = {}, batchSize = 500, ...extraProps }) {
  const [isImporting, setIsImporting] = useState(false);

  const onSelectFiles = async ({ target, target: { files: [file] } }) => {
    if(!file) return;
    setIsImporting(true);
    try {
      const decoder = new TextDecoder(encoding);
      const fileContent = decoder.decode(await readFile(file, 'readAsArrayBuffer'));
      const { data: rows } = parseCsv(fileContent, { header: true });
      const processedRows = await processRows(rows);
      const validatedRows = processedRows.map(validate);
      if(validatedRows.filter(_ => !isEmpty(_.errors)).length > 0) {
        const encoder = new TextEncoder('Shift_JIS', { NONSTANDARD_allowLegacyEncoding: true });
        const content = encoder.encode(unparseCsv(validatedRows.map((row) => {
          return {
            ...row,
            errors: row.errors.join('\n'),
          };
        })));
        fileDownload(content, 'エラー結果.csv');
        throw new Error();
      }

      await beforeSave(processedRows);
      await chunk(processedRows, batchSize).reduce(async (x, rows, i1) => {
        await x;
        const batch = db.batch();
        rows.forEach((_, i) => processRow(batch, _, i1 * batchSize + i));
        await batch.commit();
      }, Promise.resolve());
      await onComplete(processedRows);
      toast.success('インポートしました');
    } catch(e) {
      console.error(e);
      toast.error('インポート失敗しました');
    }
    setIsImporting(false);
    target.value = '';
  };
  const validate = (row) => {
    const errors = entries(fields).map(([fieldName, { hidden = _ => false, validations = {} }]) => {
      const shouldHide = hidden(row);
      return entries(hidden(row) ? {} : validations)
        .filter(([k, v]) => !v(row[fieldName], row, k))
        .map(([key]) => `[${fieldName}] ` + (
          get(texts.validations[documentName], `${fieldName}.${key}`)
          || get(texts.validations.general, key)
        ));
    }).flat();
    return {
      ...row,
      errors,
    };
  };

  return (
    <Button color="secondary" disabled={disabled || isImporting} {...extraProps}>
      <label className="m-0 cursor-pointer">
        <span className={classnames('fas', { 'fa-upload': !isImporting, 'fa-spin fa-spinner': isImporting, 'mr-1': !isEmpty(label), })} />
        {label}
        {
          !disabled && (
            <Input type="file" className="d-none" onChange={onSelectFiles} accept=".csv" />
          )
        }
      </label>
    </Button>
  );
};
