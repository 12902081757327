import React, { Component, Fragment, useState, } from 'react';
import { Table, Button, Nav, NavItem, NavLink,  } from 'reactstrap';
import { last, chunk, uniqBy, orderBy, uniq, isEqual, get, sumBy, isEmpty, isNumber as _isNumber, groupBy, sortBy, keyBy, range, debounce, pick, } from 'lodash';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import { useToggle, useDebounce, useMap, } from 'react-use';
import { Container, Draggable } from 'react-smooth-dnd';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import classnames from 'classnames';
import { format as formatDate, startOfMonth, endOfMonth, addYears, addMonths, } from 'date-fns';
import { Link } from 'react-router-dom';

import { canWriteNote, } from '../../../abilities';
import { dimensions, budgetSubjectTypes, } from '../../../shared/config';
import HoveredCommenter from '../../HoveredCommenter';
import HoveredNoter from '../../HoveredNoter';

const suffix = documentType => ({ pl: '', cr: 'Cr' })[documentType];
const { keys, entries, } = Object;
const { abs } = Math;
const isFiniteNumber = _ => _isNumber(_) && isFinite(_);
const COLUMN_WIDTH = 150;
const AMOUNT_HEIGHT = '24px';
const dimensionOptions = entries(dimensions).filter(_ => _[0] !== 'sections').map(([k, v]) => ({ label: v, value: k }));
const aggregationTypes = {
  cumulative: { label: '累計', },
  single: { label: '単月', },
};
const aggregationTypeOptions = entries(aggregationTypes).map(([k, v]) => ({ label: v.label, value: k }));

export default class Column extends Component {
  shouldComponentUpdate(nextProps) {
    const watchedPropNames = [
      '_key',
      'commenteeKey',
      'isComparing',
      'metricsForFilter',
      'amount',
      'budgetAmount',
      'comparedBudgetAmount',
      'diffBetweenBudgets',
      'budgetDiff',
      'achievementRate',
      'estimatedAmount',
      'prevYearAmount',
      'prevYearDiff',
      'prevYearDiffRate',
      'commentsGroupedByCommenteeKey',
      'notesByKey',
      'budgetSubjectType',
    ]
    if(!isEqual(pick(this.props, watchedPropNames), pick(nextProps, watchedPropNames))) {
      return true;
    }
    return false;
  }
  render() {
    const { company, isComparing, role, metricsForFilter, isCategory, isCustom, _key: key, commenteeKey, noteKey, freeeSubjects = [], members, user, isSubRow, yearMonth, amount, budgetAmount, comparedBudgetAmount, diffBetweenBudgets, budgetDiff, achievementRate, estimatedAmount, prevYearAmount, prevYearDiff, prevYearDiffRate, isSummaryColumn, itemName, subItemName, period, companyId, commentsGroupedByCommenteeKey, notesByKey, commenterRef, dimension, displayExpression, budgetSubjectType, } = this.props;
    const { budgetCollectionName, freeeCollectionName, } = budgetSubjectTypes[budgetSubjectType];
    const pathParams = {
      period,
      targetMonth: yearMonth,
      subItems: [
        { dimension: freeeCollectionName, itemNames: freeeSubjects.map(_ => _.name), },
        ...(
          isSubRow ? [{
            dimension,
            itemNames: [subItemName],
          }] : []
        ),
      ],
    };
    return (
      <td className={classnames('text-right has-hovered-contents')} style={{ width: COLUMN_WIDTH }}>
        {
          metricsForFilter.includes('budgetAmount') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              <span>{isFiniteNumber(budgetAmount) ? numeral(budgetAmount).format(displayExpression) : '-'}</span>
            </div>
          )
        }
        {
          isComparing && metricsForFilter.includes('comparedBudgetAmount') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              <span>{isFiniteNumber(comparedBudgetAmount) ? numeral(comparedBudgetAmount).format(displayExpression) : '-'}</span>
            </div>
          )
        }
        {
          isComparing && metricsForFilter.includes('diffBetweenBudgets') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              <span>{isFiniteNumber(diffBetweenBudgets) ? numeral(diffBetweenBudgets).format('+' + displayExpression) : '-'}</span>
            </div>
          )
        }
        {
          metricsForFilter.includes('amount') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              {
                (isCategory || isCustom || isSummaryColumn) ? (
                  <span>{isFiniteNumber(amount) ? numeral(amount).format(displayExpression) : '-'}</span>
                ) : (
                  <Link to={encodeURI(`/companies/${companyId}/accountItems/${itemName}?${qs.stringify(pathParams)}`)}>
                    {isFiniteNumber(amount) ? numeral(amount).format(displayExpression) : '-'}
                  </Link>
                )
              }
            </div>
          )
        }
        {
          metricsForFilter.includes('budgetDiff') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              {isFiniteNumber(budgetDiff) ? numeral(budgetDiff).format('+' + displayExpression) : '-'}
            </div>
          )
        }
        {
          metricsForFilter.includes('achievementRate') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              {isFiniteNumber(achievementRate) ? numeral(achievementRate).format('0,0.0%') : '-'}
            </div>
          )
        }
        {
          metricsForFilter.includes('estimatedAmount') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              <span>{isFiniteNumber(estimatedAmount) ? numeral(estimatedAmount).format(displayExpression) : '-'}</span>
            </div>
          )
        }
        {
          metricsForFilter.includes('prevYearAmount') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              <span>{isFiniteNumber(prevYearAmount) ? numeral(prevYearAmount).format(displayExpression) : '-'}</span>
            </div>
          )
        }
        {
          metricsForFilter.includes('prevYearDiff') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              {isFiniteNumber(prevYearDiff) ? numeral(prevYearDiff).format('+' + displayExpression) : '-'}
            </div>
          )
        }
        {
          metricsForFilter.includes('prevYearDiffRate') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              {isFiniteNumber(prevYearDiffRate) ? numeral(prevYearDiffRate).format('+0,0.0%') : '-'}
            </div>
          )
        }
        {
          !isSummaryColumn && (
            <Fragment>
              <HoveredCommenter
                ref={commenterRef.bind(null, commenteeKey)}
                company={company}
                companyId={companyId}
                currentUser={user}
                commenteeKey={commenteeKey}
                queryKey={budgetCollectionName}
                values={{ yearMonth: yearMonth.toString() }}
                comments={commentsGroupedByCommenteeKey[commenteeKey]}
                users={members}
                about={['予実', yearMonth.toString(), itemName, subItemName].filter(_ => _).join(' ')}
              />
              <HoveredNoter companyId={companyId} noteKey={noteKey} pageType="CompanyBudgets" queryKey={budgetCollectionName} values={{ yearMonth: yearMonth.toString() }} note={notesByKey[noteKey]} writable={canWriteNote(user, role)} />
            </Fragment>
          )
        }
      </td>
    );
  }
}

