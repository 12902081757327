const { get, isEmpty } = require('lodash');
const { isEmail, } = require('validator');

const getRole = (user, companyId) => {
  return get(user, ['companies', companyId, 'role']);
};

module.exports = {
  fields: {
    email: {
      type: 'string',
      label: 'メールアドレス',
      validations: {
        required: v => !isEmpty(v),
        format: v => !v || isEmail(v),
      },
    },
    displayName: {
      type: 'string',
      label: 'ユーザー名',
      validations: {
        required: v => !isEmpty(v),
      },
    },
  },
  getRole,
};
