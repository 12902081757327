import React from 'react';
import { Route, Switch, Redirect } from 'react-router';

import AdminRoot from './components/pages/AdminRoot';
import AdminUsers from './components/pages/AdminUsers';
import AdminCompanies from './components/pages/AdminCompanies';
import AdminJobLogs from './components/pages/AdminJobLogs';
import Dashboard from './components/pages/Dashboard';
import CompanyDashboard from './components/pages/CompanyDashboard';
import CompanyJournals from './components/pages/CompanyJournals';
import CompanyJournalsByTax from './components/pages/CompanyJournalsByTax';
import CompanyAccountItem from './components/pages/CompanyAccountItem';
import CompanyJournalsCheck from './components/pages/CompanyJournalsCheck';
import CompanyJournalsCheckCreators from './components/pages/CompanyJournalsCheckCreators';
import CompanyCustomJournalsCheckRules from './components/pages/CompanyCustomJournalsCheckRules';
import CompanyTrialsCheck from './components/pages/CompanyTrialsCheck';
import CompanyAccountItemSettings from './components/pages/CompanyAccountItemSettings';
import CompanyPeriodComparison from './components/pages/CompanyPeriodComparison';
import CompanyMonthlyTrends from './components/pages/CompanyMonthlyTrends';
import CompanyBudgets from './components/pages/CompanyBudgets';
import CompanyMonthlyBudgets from './components/pages/CompanyMonthlyBudgets';
import CompanyMonthBudgets from './components/pages/CompanyMonthBudgets';
import CompanySectionsBudgets from './components/pages/CompanySectionsBudgets';
import CompanySectionBudgets from './components/pages/CompanySectionBudgets';
import CompanyMonthSectionBudgets from './components/pages/CompanyMonthSectionBudgets';
import CompanyMonthlyPartnerBalances from './components/pages/CompanyMonthlyPartnerBalances';
import CompanyPartnerBalances from './components/pages/CompanyPartnerBalances';
import CompanyCustomAccountItems from './components/pages/CompanyCustomAccountItems';
import CompanyCustomSections from './components/pages/CompanyCustomSections';
import CompanyCustomSegments from './components/pages/CompanyCustomSegments';
import CompanyCustomPartners from './components/pages/CompanyCustomPartners';
import CompanyMembers from './components/pages/CompanyMembers';
import CompanyLogs from './components/pages/CompanyLogs';
import CompanyGeneralSettings from './components/pages/CompanyGeneralSettings';
import Profile from './components/pages/Profile';
import Confirmation from './components/pages/Confirmation';
import CompanyPublicMonthlyBudgets from './components/pages/CompanyPublicMonthlyBudgets';
import CompanyPublicMonthBudgets from './components/pages/CompanyPublicMonthBudgets';

const routeObjs = [
  {
    exact: true,
    path: '/admin',
    component: AdminRoot,
  },
  {
    exact: true,
    path: '/admin/users',
    component: AdminUsers,
  },
  {
    exact: true,
    path: '/admin/companies',
    component: AdminCompanies,
  },
  {
    exact: true,
    path: '/admin/jobLogs',
    component: AdminJobLogs,
  },
  {
    exact: true,
    path: '/',
    component: Dashboard,
  },
  {
    exact: true,
    path: '/companies/:companyId',
    component: _ => <Redirect to={`/companies/${_.match.params.companyId}/dashboard`} />,
  },
  {
    exact: true,
    path: '/companies/:companyId/dashboard',
    component: CompanyDashboard,
  },
  {
    exact: true,
    path: '/companies/:companyId/journals',
    component: CompanyJournals,
  },
  {
    exact: true,
    path: '/companies/:companyId/journalsByTax',
    component: CompanyJournalsByTax,
  },
  {
    exact: true,
    path: '/companies/:companyId/accountItems/:accountItemId',
    component: CompanyAccountItem,
  },
  {
    exact: true,
    path: '/companies/:companyId/accountItemSettings',
    component: CompanyAccountItemSettings,
  },
  {
    exact: true,
    path: '/companies/:companyId/periodComparison',
    component: CompanyPeriodComparison,
  },
  {
    exact: true,
    path: '/companies/:companyId/monthlyTrends',
    component: CompanyMonthlyTrends,
  },
  {
    exact: true,
    path: '/companies/:companyId/budgets',
    component: CompanyBudgets,
  },
  {
    exact: true,
    path: '/companies/:companyId/monthlyBudgets',
    component: CompanyMonthlyBudgets,
  },
  {
    exact: true,
    path: '/companies/:companyId/monthBudgets',
    component: CompanyMonthBudgets,
  },
  {
    exact: true,
    path: '/companies/:companyId/sectionsBudgets',
    component: CompanySectionsBudgets,
  },
  {
    exact: true,
    path: '/companies/:companyId/sectionBudgets',
    component: CompanySectionBudgets,
  },
  {
    exact: true,
    path: '/companies/:companyId/monthSectionBudgets',
    component: CompanyMonthSectionBudgets,
  },
  {
    exact: true,
    path: '/companies/:companyId/monthlyPartnerBalances',
    component: CompanyMonthlyPartnerBalances,
  },
  {
    exact: true,
    path: '/companies/:companyId/partnerBalances',
    component: CompanyPartnerBalances,
  },
  {
    exact: true,
    path: '/companies/:companyId/customAccountItems',
    component: CompanyCustomAccountItems,
  },
  {
    exact: true,
    path: '/companies/:companyId/customSections',
    component: CompanyCustomSections,
  },
  {
    exact: true,
    path: '/companies/:companyId/customSegments',
    component: CompanyCustomSegments,
  },
  {
    exact: true,
    path: '/companies/:companyId/customPartners',
    component: CompanyCustomPartners,
  },
  {
    exact: true,
    path: '/companies/:companyId/journalsCheck',
    component: CompanyJournalsCheck,
  },
  {
    exact: true,
    path: '/companies/:companyId/journalsCheck/creators',
    component: CompanyJournalsCheckCreators,
  },
  {
    exact: true,
    path: '/companies/:companyId/customJournalsCheckRules',
    component: CompanyCustomJournalsCheckRules,
  },
  {
    exact: true,
    path: '/companies/:companyId/trialsCheck',
    component: CompanyTrialsCheck,
  },
  {
    exact: true,
    path: '/companies/:companyId/members',
    component: CompanyMembers,
  },
  {
    exact: true,
    path: '/companies/:companyId/logs',
    component: CompanyLogs,
  },
  {
    exact: true,
    path: '/companies/:companyId/settings',
    component: _ => <Redirect to={`/companies/${_.match.params.companyId}/settings/general`} />,
  },
  {
    exact: true,
    path: '/companies/:companyId/settings/general',
    component: CompanyGeneralSettings,
  },
  {
    exact: true,
    path: '/profile',
    component: Profile,
  },
  {
    exact: true,
    path: '/confirmation',
    component: Confirmation,
  },
  {
    exact: true,
    path: '/p/monthlyBudgets',
    component: CompanyPublicMonthlyBudgets,
  },
  {
    exact: true,
    path: '/p/monthBudgets',
    component: CompanyPublicMonthBudgets,
  },
]

export default function routes (extraProps) {
  return (
    <Switch>
      {
        routeObjs.map((route , i) => {
          const { exact, path, render, props } = route;
          return (
            <Route exact={exact} path={path} render={render || (_ =>
              <route.component {..._} {...extraProps} {...props} />
            )} key={i} />
          );
        })
      }
    </Switch>
  );
};
