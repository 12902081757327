const formatMentions = (text) => {
  return text.replace(/@\[([^\]]*)\]\([^)]*\)/g, '@$1');
};

const findMentions = (text) => {
  return Array.from(text.match(/@\[[^\]]*\]\([^)]*\)/g) || []).map(_ => _.match(/\(([^)]*)\)/)[1]);
};

module.exports = {
  formatMentions,
  findMentions,
};
