import React, { Fragment, useEffect, } from 'react';
import { Link } from 'react-router-dom';
import { format as formatDate } from 'date-fns';

import AdminPage from '../hocs/AdminPage';

export default AdminPage(function AdminRoot (props) {

  return (
    <div className="admin-root container">
      <div className="row">
        <div className="p-4 bg-white my-4 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
          <div className="d-flex justify-content-center mb-3">
            <h4>管理画面トップ</h4>
          </div>
          <ul className="nav flex-column">
            <li className="nav-item">
              <Link className="nav-link" to="/admin/companies">会社一覧</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/admin/users">ユーザー一覧</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/admin/jobLogs">Job Logs</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
});

