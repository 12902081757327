const { omit } = require('lodash');

const allDimensions = {
  none: '明細なし',
  partners: '取引先',
  items: '品目',
  sections: '部門',
  segment1s: 'セグメント1',
  segment2s: 'セグメント2',
  segment3s: 'セグメント3',
};

const liquidAssetAccountCategories = [
  '現金・預金',
  '売上債権',
  '棚卸資産',
  '有価証券',
  '他流動資産',
  '流動資産',
];

const fixedAssetAccountCategories = [
  '有形固定資産',
  '無形固定資産',
  '投資その他の資産',
  '投資等', // 個人
  '固定資産',
];

const assetAccountCategories = [
  ...liquidAssetAccountCategories,
  ...fixedAssetAccountCategories,
  '繰延資産',
  '事業主貸', // 個人
  '諸口', // 個人
  '未設定', // 個人
  '資産',
];

const liquidLiabilityAccountCategories = [
  '仕入債務',
  '未払金',
  'credit_card',
  '他流動負債',
  '流動負債',
];

const fixedLiabilityAccountCategories = [
  '固定負債',
];

const liabilityAccountCategories = [
  ...liquidLiabilityAccountCategories,
  ...fixedLiabilityAccountCategories,
  '事業主借', // 個人
  '負債',
];

const netAssetAccountCategories = [
  '資本金',
  '元入金', // 個人
  '新株式申込証拠金',
  '資本準備金',
  'その他資本剰余金',
  '資本剰余金',
  '利益準備金',
  '任意積立金',
  '繰越利益剰余金',
  '利益剰余金',
  'その他利益剰余金',
  '当期純損益金額',
  '繰入額等', // 個人
  '繰戻額等', // 個人
  '振替', // 個人
  '差引損益計算', // 個人
  '株主資本',
  '自己株式',
  '自己株式申込証拠金',
  '他有価証券評価差額金',
  '繰延ヘッジ損益',
  '土地再評価差額金',
  '評価・換算差額等',
  '新株予約権',
  '純資産',
  '資本', // 個人
];

const liabilityAndNetAssetAccountCategories = [
  ...liabilityAccountCategories,
  ...netAssetAccountCategories,
  '負債及び純資産',
];


const bsAccountCategories = [
  ...assetAccountCategories,
  ...liabilityAndNetAssetAccountCategories,
];

const operatingAccountCategories = [
  '売上高',
  '収入金額', // 個人
  '期首商品棚卸',
  '当期商品仕入',
  '他勘定振替高(商)',
  '期末商品棚卸',
  '商品売上原価',
  '期首製品棚卸',
  '当期製品製造原価',
  '他勘定振替高(製)',
  '期末製品棚卸',
  '製品売上原価',
  '売上原価',
  '売上総損益金額',
  '売上総利益', // 個人
  '販売管理費',
  '経費', // 個人
  '営業損益金額',
  '営業損益', // 個人
];

const plAccountCategories = [
  ...operatingAccountCategories,
  '営業外収益',
  '営業外費用',
  '経常損益金額',
  '特別利益',
  '特別損失',
  '税引前当期純損益金額',
  '法人税等',
  '法人税等調整額',
  '当期純損益金額',
  '差引損益計算', // 個人
];

const crAccountCategories = [
  '期首原材料棚卸',
  '当期原材料仕入高',
  '[製]材料仕入高',
  '期末原材料棚卸',
  '材料費',
  '[製]給料手当',
  '労務費',
  '製造経費',
  '総製造費用',
  '期首仕掛品棚卸',
  '仕掛品',
  '期末仕掛品棚卸',
  '製造原価',
];

const accountCategories = [
  ...bsAccountCategories,
  ...plAccountCategories,
  ...crAccountCategories,
];

const revenueAccountCategories = [
  '売上高',
  '収入金額', // 個人
  '営業外収益',
  '特別利益',
];

const costAccountCategories = [
  '期首原材料棚卸',
  '期末仕掛品棚卸',
  '製造経費',
  '労務費',
  '期末原材料棚卸',
  '期首仕掛品棚卸',
  '当期原材料仕入高',
  '当期商品仕入',
  '他勘定振替高(商)',
  '販売管理費',
  '他勘定振替高(製)',
  '棚卸資産',
  '期末製品棚卸',
  '期首製品棚卸',
  '経費', // 個人
  '営業外費用',
  '特別損失',
  '法人税等',
  '法人税等調整額',
];

const strictCostAccountCategories = [
  '製造経費',
  '労務費',
  '販売管理費',
  '経費', // 個人
  '営業外費用',
  '特別損失',
  '法人税等',
];

const debitAccountCategories = [
  ...assetAccountCategories,
  ...costAccountCategories,
];

const creditAccountCategories = [
  ...liabilityAccountCategories,
  ...netAssetAccountCategories,
  ...revenueAccountCategories,
];

const nonTrialAccountCategories = [
  '現金・預金',
  '売上債権',
  '棚卸資産',
  '有価証券',
  '他流動資産',
  '諸口',
  '仕入債務',
  '未払金',
  'credit_card',
  '他流動負債',
  '資本金',
  '元入金',
  '新株式申込証拠金',
  '資本準備金',
  'その他資本剰余金',
  '利益準備金',
  '任意積立金',
  '繰越利益剰余金',
  '繰入額等',
  '繰戻額等',
  '振替',
  '自己株式',
  '自己株式申込証拠金',
  '他有価証券評価差額金',
  '繰延ヘッジ損益',
  '土地再評価差額金',
  '評価・換算差額等',
  '新株予約権',
];
const segmentTypes = {
  segment1: { label: 'セグメント1', nameJa: 'カスタムセグメント1', idName: 'customSegment1Id', collectionName: 'customSegment1s', freeeCollectionName: 'segment1s', freeeSubjectIdsName: 'segmentIds', trialsCollectionName: 'segment1Trials', budgetContainerCollectionName: 'segment1BudgetContainers', budgetCollectionName: 'segment1Budgets', budgetContainerIdName: 'segment1BudgetContainerId', freeeTrialApiSuffix: 'segment_1_tags', freeeTrialApiSubjectIdsName: 'segment_1_tag_ids', compareTypeName: 'division_1', freeeParameterName: 'division_tag_1_name', },
  segment2: { label: 'セグメント2', nameJa: 'カスタムセグメント2', idName: 'customSegment2Id', collectionName: 'customSegment2s', freeeCollectionName: 'segment2s', freeeSubjectIdsName: 'segmentIds', trialsCollectionName: 'segment2Trials', budgetContainerCollectionName: 'segment2BudgetContainers', budgetCollectionName: 'segment2Budgets', budgetContainerIdName: 'segment2BudgetContainerId', freeeTrialApiSuffix: 'segment_2_tags', freeeTrialApiSubjectIdsName: 'segment_2_tag_ids', compareTypeName: 'division_2', freeeParameterName: 'division_tag_2_name', },
  segment3: { label: 'セグメント3', nameJa: 'カスタムセグメント3', idName: 'customSegment3Id', collectionName: 'customSegment3s', freeeCollectionName: 'segment3s', freeeSubjectIdsName: 'segmentIds', trialsCollectionName: 'segment3Trials', budgetContainerCollectionName: 'segment3BudgetContainers', budgetCollectionName: 'segment3Budgets', budgetContainerIdName: 'segment3BudgetContainerId', freeeTrialApiSuffix: 'segment_3_tags', freeeTrialApiSubjectIdsName: 'segment_3_tag_ids', compareTypeName: 'division_3', freeeParameterName: 'division_tag_3_name', },
};

const screens = {
  dashboard: { label: 'ダッシュボード' },
  journals: { label: '仕訳帳' },
  journalsByTax: { label: '仕訳分析' },
  accountItemSettings: { label: '科目設定' },
  periodComparison: { label: '期間比較', },
  monthlyTrends: { label: '月次推移', },
  budgets: { label: '予実', pathNames: ['budgets', 'monthlyBudgets', 'monthBudgets'], },
  monthlyPartnerBalances: { label: '取引先別月次推移', pathNames: ['monthlyPartnerBalances', 'partnerBalances'], },
  customAccountItems: { label: 'カスタム科目', },
  customJournalsCheckRules: { label: 'カスタムルール', },
  customSections: { label: 'カスタム部門', },
  customSegments: { label: 'カスタムセグメント', },
  customPartners: { label: 'カスタム取引先', },
  journalsCheck: { label: '仕訳チェック', },
  trialsCheck: { label: '残高チェック', },
  members: { label: 'ユーザー', },
  logs: { label: '操作ログ', },
  settings: { label: '設定', },
};
const guestScreens = ['dashboard', 'periodComparison', 'monthlyTrends', 'budgets', 'monthlyBudgets', 'monthBudgets', 'sectionsBudgets', 'sectionBudgets', 'monthSectionBudgets'];

module.exports = {
  liquidAssetAccountCategories,
  fixedAssetAccountCategories,
  assetAccountCategories,
  liquidLiabilityAccountCategories,
  fixedLiabilityAccountCategories,
  liabilityAccountCategories,
  netAssetAccountCategories,
  bsAccountCategories,
  operatingAccountCategories,
  plAccountCategories,
  crAccountCategories,
  accountCategories,
  revenueAccountCategories,
  costAccountCategories,
  strictCostAccountCategories,
  debitAccountCategories,
  creditAccountCategories,
  nonTrialAccountCategories,

  accountNamesMap: {
    売掛金: '未収金',
    買掛金: '未払金',
  },

  fixedAssetTypes: {
    tangible: '有形固定資産',
    intangible: '無形固定資産',
    investment: '投資その他の資産',
  },
  categoriesMap: {
    流動資産: liquidAssetAccountCategories,
    固定資産: fixedAssetAccountCategories,
    流動負債: liquidLiabilityAccountCategories,
    固定負債: fixedLiabilityAccountCategories,
  },
  nonRecurringIncomeCategories: ['特別利益'],
  nonRecurringCostCategories: ['特別損失'],
  taxCategories: ['法人税等', '法人税等調整額'],
  plans: [
    { label: '有料', value: 'paid' },
    { label: '無料', value: 'free' },
  ],
  freeeDomain: 'secure.freee.co.jp',
  monthlyTrendsComparisons: {
    prevYearSameMonth: '対前年同期',
    prevMonth: '対前月',
  },
  dimensions: omit(allDimensions, ['segment1s', 'segment2s', 'segment3s']),
  dimensionValues: {
    sections: {
      breakdownDisplayType: 'section',
      collectionName: 'sections',
    },
    partners: {
      breakdownDisplayType: 'partner',
      collectionName: 'partners',
    },
    items: {
      breakdownDisplayType: 'item',
      collectionName: 'freeeItems',
    },
    segment1s: {
      breakdownDisplayType: 'segment_1_tag',
      collectionName: 'segment1s',
      freeeCollectionName: 'segment_1_tags',
    },
    segment2s: {
      breakdownDisplayType: 'segment_2_tag',
      collectionName: 'segment2s',
      freeeCollectionName: 'segment_2_tags',
    },
    segment3s: {
      breakdownDisplayType: 'segment_3_tag',
      collectionName: 'segment3s',
      freeeCollectionName: 'segment_3_tags',
    },
  },
  allDimensions,
  manualTypes: {
    balance: '累計値',
    occurrence: '発生値',
  },
  breakdownItemsLimitUnit: 30,
  details: {
    partners: '取引先',
    items: '品目',
    sections: '部門',
    segment1s: 'セグメント1',
    segment2s: 'セグメント2',
    segment3s: 'セグメント3',
  },
  journalTags: {
    partners: '取引先',
    items: '品目',
    sections: '部門',
    segment1s: 'セグメント1',
    segment2s: 'セグメント2',
    segment3s: 'セグメント3',
  },
  colors: [
    '#8dd3c7',
    '#ffffb3',
    '#bebada',
    '#fb8072',
    '#80b1d3',
    '#fdb462',
    '#b3de69',
    '#fccde5',
    '#d9d9d9',
    '#bc80bd',
    '#ccebc5',
    '#ffed6f',
  ],
  trialBsCsvCategoryNames: [
    '資産の部',
    '流動資産',
    '流動資産 計',
    '固定資産',
    '有形固定資産',
    '有形固定資産 計',
    '無形固定資産',
    '無形固定資産 計',
    '投資その他の資産',
    '投資その他の資産 計',
    '固定資産 計',
    '資産 計',
    '負債の部',
    '流動負債',
    '流動負債 計',
    '固定負債',
    '固定負債 計',
    '負債 計',
    '純資産の部',
    '株主資本',
    '資本剰余金',
    '資本剰余金 計',
    '利益剰余金',
    'その他利益剰余金',
    '当期純損益金額',
    'その他利益剰余金 計',
    '利益剰余金 計',
    '株主資本 計',
    '評価・換算差額等',
    '評価・換算差額等 計',
    '新株予約権',
    '新株予約権 計',
    '純資産 計',
    '負債及び純資産 計',
  ],
  trialPlCsvCategoryNames: [
    '売上高',
    '売上高 計',
    '売上原価',
    '売上原価',
    '期首商品棚卸',
    '当期商品仕入',
    '他勘定振替高(商)',
    '期末商品棚卸',
    '商品売上原価',
    '期首製品棚卸',
    '当期製品製造原価',
    '他勘定振替高(製)',
    '期末製品棚卸',
    '製品売上原価',
    '売上総損益金額',
    '販売管理費',
    '販売管理費 計',
    '営業損益金額',
    '営業外収益',
    '営業外費用',
    '経常損益金額',
    '特別利益',
    '特別損失',
    '税引前当期純損益金額',
    '法人税等',
    '法人税等調整額',
    '当期純損益金額',
  ],
  trialCrCsvCategoryNames: [
    '材料費',
    '期首原材料棚卸',
    '当期原材料仕入高',
    '期末原材料棚卸',
    '材料費 計',
    '労務費',
    '労務費 計',
    '製造経費',
    '製造経費 計',
    '総製造費用',
    '期首仕掛品棚卸 計',
    '仕掛品 計',
    '期末仕掛品棚卸 計',
    '製造原価',
  ],
  userTypes: {
    auditor: 'Auditor',
    auditee: 'Auditee',
  },
  userStatuses: {
    active: 'Active',
    inactive: 'Inactive',
  },
  documentTypes: {
    bs: { name: '貸借対照表', label: 'BS', },
    pl: { name: '損益計算書', label: 'PL', },
    cr: { name: '製造原価報告書', label: 'CR', },
  },
  directions: {
    debit: '借方',
    credit: '貸方',
  },
  detectionStatuses: {
    initial: '未対応',
    ignored: '無視済み',
  },
  combinationItems: {
    asset: '資産',
    cost: '費用',
  },
  roles: {
    owner: 'オーナー',
    admin: '管理者',
    member: '一般',
    guest: 'ゲスト',
  },
  modelNames: {
    generalSetting: { label: '設定', type: 'model', },
    customCharts: { label: 'チャート', type: 'screen', },
    member: { label: 'メンバー', type: 'member', },
    periodComparizon: { label: '期間比較', type: 'screen', },
    monthlyTrends: { label: '月次推移', type: 'screen', },
    sectionsBudgets: { label: '部門別予実', type: 'screen', },
    sectionBudgets: { label: '部門別予実月次推移', type: 'screen', },
    monthSectionBudgets: { label: '部門予実', type: 'screen', },
    budgets: { label: '予実', type: 'screen', },
    monthlyBudgets: { label: '予実月次推移', type: 'screen', },
    monthBudgets: { label: '月予実', type: 'screen', },
    monthlyPartnerBalances: { label: '取引先別月次推移', type: 'screen', },
    partnerBalances: { label: '取引先別分析', type: 'screen', },
    journals: { label: '仕訳帳', type: 'screen', },
    journalsByTax: { label: '仕訳分析', type: 'screen', },
  },
  operationTypes: {
    create: '作成',
    update: '更新',
    delete: '削除',
    add: '追加',
    invite: '招待',
    remove: '除外',
    exportCsv: 'CSV出力',
  },
  colors: [
    '#66c2a5',
    '#ffd92f',
    '#fc8d62',
    '#8da0cb',
    '#e78ac3',
    '#a6d854',
    '#e5c494',
    '#b3b3b3',
  ],
  segmentTypes,
  budgetSubjectTypes: {
    section: { label: '部門', nameJa: 'カスタム部門', idName: 'customSectionId', collectionName: 'customSections', freeeCollectionName: 'sections', freeeSubjectIdsName: 'sectionIds', trialsCollectionName: 'sectionTrials', budgetContainerCollectionName: 'sectionBudgetContainers', budgetCollectionName: 'sectionBudgets', budgetContainerIdName: 'sectionBudgetContainerId', freeeTrialApiSuffix: 'sections', freeeTrialApiSubjectIdsName: 'section_ids', compareTypeName: 'sections', freeeParameterName: 'section_name', },
    ...segmentTypes,
  },
  screens,
  guestScreens,
  accountItemSettingCsvUrls: {
    accountItem: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRXMDCtn0R1byYFP8lOJnlDVhxBmDXWaruA_gAZce-fAB4mLVgq3wXwDxGOoqjIaJDd45qsSWmrZbjH/pub?gid=1436562403&single=true&output=csv',
    category: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRXMDCtn0R1byYFP8lOJnlDVhxBmDXWaruA_gAZce-fAB4mLVgq3wXwDxGOoqjIaJDd45qsSWmrZbjH/pub?gid=1620351390&single=true&output=csv',
  },
};
