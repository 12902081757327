import React from 'react';
import { Button, } from 'reactstrap';
import { useToggle, } from 'react-use';
import { pick, } from 'lodash';
import classnames from 'classnames';

export default function CheckButton(props) {
  const { user, collectionRef, period, targetMonth, isChecking, ...extraProps } = props;
  const [isProccessing, toggleProcessing] = useToggle();
  const onClickCheck = async () => {
    toggleProcessing();
    try {
      await collectionRef.add({
        period,
        targetMonth,
        status: 'initial',
        createdAt: new Date(),
        createdBy: pick(user, ['id', 'type']),
      });
    } catch(e) {
      console.error(e);
    }
    toggleProcessing();
  };

  return (
    <Button color="primary" onClick={onClickCheck} disabled={isChecking || isProccessing} {...extraProps}>
      <span className={classnames('fas mr-1', { 'fa-check': !isChecking, 'fa-spin fa-spinner': isChecking })} />
      実行
    </Button>
  );
};
