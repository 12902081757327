import React from 'react';
import { Link } from 'react-router-dom';
import { addHours, format as formatDate } from 'date-fns';

import AdminPage from '../hocs/AdminPage';
import firebase from '../../firebase';
import useCollectionSubscription from '../hooks/useCollectionSubscription';

const db = firebase.firestore();
const jobLogsRef = db.collection('jobLogs');

export default AdminPage(function AdminJobLogs(props) {
  const jobLogs = useCollectionSubscription(jobLogsRef.where('createdAt', '>=', addHours(new Date(), -24)).orderBy('createdAt', 'desc'));

  return (
    <div className="company-members">
      <div className="container py-5">
        <div className="d-flex justify-content-center mb-2">
          <h3>Job Logs</h3>
        </div>
        <div className="mt-3 alert alert-info">
          過去24時間以内のものを表示
        </div>
        <table className="table table-hover mt-3 table-bordered">
          <thead className="thead-light">
            <tr>
              <th>種別</th>
              <th>会社</th>
              <th>エラーメッセージ</th>
              <th>日時</th>
            </tr>
          </thead>
          <tbody>
            {
              jobLogs.map(({ id, ref, type, companyId, companyName, errorMessage, createdAt, }) => {
                return (
                  <tr key={id}>
                    <td>
                      {type}
                    </td>
                    <td>
                      <Link to={`/companies/${companyId}`}>
                        {companyName}
                      </Link>
                    </td>
                    <td>
                      {errorMessage}
                    </td>
                    <td>
                      {formatDate(createdAt.toDate(), 'YYYY/MM/DD HH:mm:ss')}
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
});
