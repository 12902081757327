const { omitBy, isEqual, isFunction } = require('lodash');
const { differenceInDays } = require('date-fns');

const diff = (x, y) => omitBy(x, (v, k) => isEqual(y?.[k], v));

const leftTrialDays = (company = {}) => {
  return company.createdAt ? (company.trialDays >= 0 ? company.trialDays : 30) - differenceInDays(new Date(), company.createdAt.toDate()) : -1;
};

const fieldDisplayValue = (_v, { type, options: _options = [], values = {}, initialValue, }) => {
  const options = isFunction(_options) ? _options(values) : _options;
  const v = _v === undefined ? initialValue : _v;
  return (({
    multiSelect: _ => v?.map(v => (options.find(_ => _.value === v) || {}).label).join(', '),
    select: _ => (options.find(_ => _.value === v) || {}).label,
    date: _ => v && formatDate(v.toDate(), 'yyyy/MM/dd'),
    datetime: _ => v && formatDate(v.toDate(), 'yyyy/MM/dd HH:mm'),
    boolean: _ => ({ true: 'はい', false: 'いいえ' })[v || false],
  })[type] || (_ => v))();
};

const serial = async (xs, f) => {
  return await xs.reduce(async (_, x, i) => {
    return [...(await _), await f(x, i)];
  }, Promise.resolve([]));
};

module.exports = {
  diff,
  leftTrialDays,
  fieldDisplayValue,
  serial,
};
