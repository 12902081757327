import React from 'react';
import Select from 'react-select';
import qs from 'qs';
import { useLocation, useHistory } from 'react-router';
import AsyncSelect from 'react-select/async';

import { fullPathWithParams, } from '../utils';

export default function QuerySelector(props) {
  const { paramName = 'filter', defaultValue = [], label = '絞込み', isMulti = false, width = 200, loadOptions, options = [], disabled = false, selectorProps, ...extraProps } = props;
  const Component = props.async ? AsyncSelect : Select;
  const location = useLocation();
  const history = useHistory();
  const { [paramName]: paramValue = defaultValue } = qs.parse(decodeURI(location.search.slice(1)), { arrayLimit: Infinity });
  const onSelect = (selected) => {
    const value = isMulti ? options.map(_ => _.value).filter(_ => (selected || []).map(_ => _.value).includes(_)) : (selected ? selected.value : null);
    const path = fullPathWithParams({ [paramName]: value, }, location);
    history.replace(encodeURI(path));
  };

  return (
    <div style={{ width, }} {...extraProps}>
      <div className="text-muted small">{label}</div>
      <Component
        isMulti={isMulti}
        value={isMulti ? options.filter(_ => paramValue.includes(_.value)) : options.find(_ => _.value === paramValue)}
        loadOptions={loadOptions}
        options={options}
        onChange={onSelect}
        className="w-100"
        isDisabled={disabled}
        isClearable
        components={{ IndicatorsContainer: _ => null }}
        {...{
          ...selectorProps,
          ...(props.async ? { loadOptions, } : { options }),
        }}
      />
    </div>
  );
};
