const { addMinutes, } = require('date-fns');

const statuses = {
  processing: {
    label: '同期中',
  },
  completed: {
    label: '完了',
  },
  failed: {
    label: '失敗',
  },
};

const computeStatus = (job) => {
  const fields = ['journalsStatus', ...(job.dimensions?.map(_ => `trialsStatus-${_}`) || [])];
  const isLongProcessing = (new Date() > addMinutes(job.createdAt.toDate(), 20));
  const statuses = fields.map(_ => job[_]);
  const status = statuses.includes('failed') ? 'failed'
    : statuses.every(_ => _ === 'completed') ? 'completed'
    : isLongProcessing ? 'failed'
    : 'processing';
  return { status, isLongProcessing };
};

module.exports = {
  statuses,
  computeStatus,
};
