import React, { Fragment, useMemo, } from 'react';
import { Button, Table } from 'reactstrap';
import { omit, orderBy, isEmpty, sumBy, sortBy, last, mapValues, uniq, uniqBy, groupBy, get, range, keyBy } from 'lodash';
import classnames from 'classnames';
import { eachDay, format as formatDate, startOfMonth, endOfMonth, addMonths, addMinutes } from 'date-fns';
import { useToggle, useAsync, } from 'react-use';
import { toast } from 'react-toastify';
import { CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line, } from 'recharts';
import numeral from 'numeral';
import { ReactSortable } from 'react-sortablejs';
import { Link, } from 'react-router-dom';

import firebase from '../../firebase';
import { getCollectionData, } from '../../shared/firebase';
import { canWriteCustomChart, } from '../../abilities';
import { dimensionsOfCompany, } from '../../shared/models/company';
import { fields, terms, xAxisUnits, chartTypes, metricComponentTypes, scopeTypes, sourceTypes, } from '../../shared/models/customChart';
import { mapKeysToJa, jas, } from '../../shared/texts';
import { computeRelatedItems, } from '../../shared/models/customAccountItem';
import { colors } from '../../shared/config';
import { log, computeTrialAmount, computeSectionTrialAmount, computeSectionBudget, fiscalYearOfDate, pickSearch, groupTrials, groupTrialSubItems, computeSegmentedTrialAmount, groupSegmentedTrials, groupSegmentedTrialSubItems, } from '../../utils';
import HelpLink from '../HelpLink';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionsFetch from '../hooks/useCollectionsFetch';
import useQueryParams from '../hooks/useQueryParams';
import CompanyPage from '../hocs/CompanyPage';
import OverlayLoading from '../OverlayLoading';
import CustomChartFormModal from '../modals/CustomChartFormModal';
import SyncCustomAccountItemManualValuesButton from '../SyncCustomAccountItemManualValuesButton';
import ExportButton from '../ExportButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import Comment from '../Comment';
import QueryBoolean from '../QueryBoolean';

const db = firebase.firestore();
const { keys, } = Object;
const { round, max, pow, floor, log10, } = Math;
const cardWidth = 600;
const cardHeight = cardWidth * 0.8;
const chartWidth = cardWidth * 0.95;
const chartHeight = chartWidth * 0.7;

export default CompanyPage(function CompanyDashboard (props) {
  const { period, targetMonth, user, role, company, customSections, sortedAccountItems, customAccountItems, members, sections, location, } = props;
  const queryParams = useQueryParams();
  const { onlyAboutMe, } = queryParams;
  const customSectionsById = keyBy(customSections, 'id');
  const accountItemsById = keyBy(sortedAccountItems, 'id');
  const customAccountItemsById = keyBy(customAccountItems, 'id');
  const customAccountItemsByName = keyBy(customAccountItems, 'name');
  const partnerChunks = useCollectionSubscription(company.ref.collection('partnerChunks'), [company]);
  const partners = useMemo(_ => partnerChunks.flatMap(_ => _.data), [partnerChunks]);
  const freeeItems = useCollectionSubscription(company.ref.collection('freeeItems'), [company]);

  // DEV
  const customCharts = useCollectionSubscription(company.ref.collection('customCharts').orderBy('createdAt'), [company]);
  // const customCharts = useCollectionSubscription(company.ref.collection('customCharts').where('title', '==', '相続'), [company]);

  const comments = useCollectionSubscription(company.ref.collection('comments').orderBy('createdAt', 'desc'), [company]);
  const sortedItems = sortBy(customCharts, _ => _.index != null ? _.index : customCharts.indexOf(_));
  const onSort = async (items) => {
    const batch = db.batch();
    items.filter(_ => _.id !== 'add').forEach((item, index) => {
      batch.update(company.ref.collection('customCharts').doc(item.id), { index });
    });
    await batch.commit();
  };
  let filteredComments = comments.filter(_ => !_.done);
  if(onlyAboutMe === '1'){
    filteredComments = filteredComments.filter(_ => _.followers?.[user.id]);
  }

  return (
      <div className="company-root">
        <div className="py-5 container-fluid">
          <div className="d-flex justify-content-end mb-3">
            <HelpLink text="ダッシュボードを活用する" />
          </div>
          <div className="d-flex justify-content-end gap-1 mt-3">
            <SyncCustomAccountItemManualValuesButton company={company}>
              カスタム科目
            </SyncCustomAccountItemManualValuesButton>
          </div>
          <div className="d-flex mt-3">
            <div className="flex-fill">
              <ReactSortable className="d-flex justify-content-around flex-wrap" list={[...sortedItems, { id: 'add' }]} setList={onSort}>
                {
                  [...sortedItems.map((customChart) => {
                    return (
                      <ChartCard key={customChart.id} className="mb-4" {...{ user, customSectionsById, accountItemsById, customAccountItemsById, customAccountItemsByName, company, customChart, customSections, accountItems: sortedAccountItems, customAccountItems, partners, freeeItems, sections, }} writable={canWriteCustomChart(user, role)} />
                    );
                  }), (
                    canWriteCustomChart(user, role) && (
                      <AddingCard key="add" {...{ company, customSections, accountItems: sortedAccountItems, customAccountItems, partners, freeeItems, sections, }} nextIndex={((last(sortedItems) || {}).index + 1 || sortedItems.length)} />
                    )
                  )].filter(_ => _)
                }
              </ReactSortable>
            </div>
            <div style={{ minWidth: 400, width: 400 }} className="border-left px-4 ml-4">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div>
                      未完了コメント
                    </div>
                    <div className="small">
                      <QueryBoolean paramName="onlyAboutMe" label="自分に関するもののみ" />
                    </div>
                  </div>
                </div>
                <div className="card-body px-2">
                  {
                    filteredComments.length > 0 ? filteredComments.map((comment) => {
                      const { id, commenteeKey } = comment;
                      return (
                        <div key={id}>
                          <Comment {...{ ...comment, company, companyId: company.id, commentId: id, currentUser: user, usersById: keyBy(members, 'id'), users: members, link: true, }} />
                        </div>
                      );
                    }) : (
                      <div className="px-2">No Comments</div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
});

function AddingCard(props) {
  const { company, customSections, accountItems, customAccountItems, partners, freeeItems, sections, nextIndex, } = props;
  const [showsModal, toggleModal] = useToggle(false);
  const onSubmit = async (values) => {
    await company.ref.collection('customCharts').add({
      ...values,
      index: nextIndex,
      createdAt: new Date(),
    });
    toast.success('保存しました');
    toggleModal(false);
  };

  return (
    <Fragment>
      <div className="card cursor-pointer" style={{ width: cardWidth, height: cardHeight, }} onClick={toggleModal.bind(null, true)}>
        <div className="card-body d-flex justify-content-center align-items-center">
          <div className="text-primary" style={{ fontSize: 30 }}>
            <span className="fas fa-plus mr-1" />
            チャートを追加
          </div>
        </div>
      </div>
      {showsModal && <CustomChartFormModal isOpen {...{ customSections, accountItems, customAccountItems, partners, freeeItems, sections, }} onClickClose={toggleModal.bind(null, false)} onSubmit={onSubmit} />}
    </Fragment>
  );
}

function ChartCard (props) {
  const { user, customSectionsById, accountItemsById, customAccountItemsById, customAccountItemsByName, company, customChart, className, customSections, accountItems, customAccountItems, writable, partners, freeeItems, sections, ...extraProps } = props;

  // DEV
  const { title, scopeType = 'all', baseMonth = 'thisMonth', metrics = [], } = customChart;
  // const { title, scopeType = 'all', baseMonth = 'thisMonth', } = customChart;
  // const metrics = customChart.metrics.slice(1, 2);

  const metricItems = metrics.map((metric, i) => {
    const { customSectionId, sourceType, metricType, metricId, dimension, subItemName, } = metric;
    const nameIndex = metrics.filter(_ => _.metricId === metric.metricId).indexOf(metric);
    const label = _ => [
      _,
      dimension !== 'none' ? subItemName : '',
      (nameIndex > 0 && dimension === 'none') ? nameIndex + 1 : '',
      scopeType === 'section' ? `(${get(customSectionsById, [customSectionId, 'name'], '')} ${sourceTypes[sourceType].label})` : '',
    ].join(' ');
    const values = ({
      accountItem: _ => ({
        isCustom: false,
        itemName: metricId,
        itemKey: [metricId, 'none'].join('__'),
        label: label(metric.metricId),
        itemKeys: [[metricId, 'none'].join('__')],
      }),
      category: _ => ({
        isCustom: false,
        itemName: metricId,
        itemKey: ['none', metricId].join('__'),
        label: label(metric.metricId),
        itemKeys: [['none', metricId].join('__')],
      }),
      customAccountItem: () => {
        const customAccountItem = customAccountItemsById[metricId];
        const relatedItems = computeRelatedItems(customAccountItem, customAccountItemsByName);
        return {
          isCustom: true,
          itemName: get(customAccountItem, 'name'),
          ...customAccountItem,
          label: label(get(customAccountItem, 'name')),
          itemKeys: relatedItems.map(_ => ({
            accountItem: [_.value, 'none'].join('__'),
            accountCategory: ['none', _.value].join('__'),
          })[_.type]),
        };
      },
    })[metricType]();
    return { metric, ...values, };
  });
  const itemKeys = uniq(metricItems.map(_ => _.itemKeys).flat());
  const term = terms[customChart.term];
  const xAxisUnit = xAxisUnits[customChart.xAxisUnit];
  const chartType = chartTypes[customChart.chartType];
  const ChartComponent = require('recharts')[chartType.chartComponentName];
  const endOn = endOfMonth(baseMonth === 'thisMonth' ? new Date() : new Date(baseMonth + '/01'));
  const startOn = addMonths(endOn, (-term.months + 1));

  const trialsRef = company.ref.collection(({ all: 'trials', section: 'sectionTrials' })[scopeType])
    .where('closingDate', '>=', formatDate(startOn, 'YYYY-MM-DD'))
    .where('closingDate', '<=', formatDate(endOn, 'YYYY-MM-DD'))
    .where('dimension', '==', 'none');
  const trialSubItemChunksRefs = keys(omit(dimensionsOfCompany(company), ['none', ...(scopeType === 'section' ? ['sections'] : [])])).map((dimension) => ({
    dimension,
    ref: company.ref.collection(({ all: 'trialSubItemChunks', section: 'sectionTrialsSubItemChunks' })[scopeType] + '__' + dimension)
      .where('closingDate', '>=', formatDate(startOn, 'YYYY-MM-DD'))
      .where('closingDate', '<=', formatDate(endOn, 'YYYY-MM-DD')),
  }));
  const _trials = useCollectionsFetch(itemKeys.map(_ => trialsRef.where('itemKey', '==', _)), [customChart]);
  const { value: trialSubItemsGroupedByDimension = {}, loading, error } = useAsync(async () => {
    return mapValues(keyBy(await Promise.all(trialSubItemChunksRefs.map(async ({ dimension, ref }) => {
      const trialSubItems = (await Promise.all(itemKeys.map(async (itemKey) => {
        return (await getCollectionData(ref.where('itemKey', '==', itemKey))).flatMap(_ => _.subItems.map(__ => ({ ..._, ...__ })));
      }))).flat();
      return {
        dimension,
        trialSubItems,
      };
    })), 'dimension'), 'trialSubItems');
  }, [customChart]);

  // NOTE: カスタムチャートではBSの当期純損益金額を使わない
  const trials = _trials.filter(_ => !(_.type === 'bs' && _.itemName === '当期純損益金額'));

  const customSectionIds = metrics.map(_ => _.customSectionId).filter(_ => _);
  const sectionBudgetContainers = useCollectionsFetch(customSectionIds.map(_ => company.ref.collection('sectionBudgetContainers').where('customSectionId', '==', _).where('dimension', '==', 'none')), [customSectionIds.join('-')]);
  const uniqSectionBudgetContainers = uniqBy(orderBy(sectionBudgetContainers, _ => _.createdAt.toDate(), 'desc'), _ => [_.period, _.customSectionId].join('-'));
  const uniqSectionBudgetContainerIds = uniqSectionBudgetContainers.map(_ => _.id);
  const budgetsRef = company.ref.collection('sectionBudgets')
    .where('closingDate', '>=', formatDate(startOn, 'YYYY-MM-DD'))
    .where('closingDate', '<=', formatDate(endOn, 'YYYY-MM-DD'));
  const _budgets = useCollectionsFetch(itemKeys.map(_ => budgetsRef.where('itemKey', '==', _)), [customChart]);
  const budgets = _budgets
    .filter(_ => uniqSectionBudgetContainerIds.includes(_.sectionBudgetContainerId))
    .filter(_ => _.dimension === 'none');

  // TODO: ゆくゆくは明細を使えるように
  const trialsGroupedByItemKey = groupTrials(trials);
  const trialSubjectRowsGroupedByItemKey = scopeType === 'section' ? groupSegmentedTrials(trials.flatMap(t => t.sections.map(_ => ({ ...t, ...omit(_, 'id'), subjectId: _.id, })))) : {};
  const budgetsGroupedByItemKey = groupBy(budgets, 'itemKey');
  const months = uniqBy(eachDay(startOn, endOn), _ => formatDate(_, 'YYYY/MM')).map(_ => endOfMonth(_));

  // DEV
  const data = xAxisUnit.xs(months.map(_ => ({ month: _, periodEnd: endOfMonth((fiscalYearOfDate(_, company) || {}).end_date) })))
  // const data = xAxisUnit.xs(months.map(_ => ({ month: _, periodEnd: endOfMonth((fiscalYearOfDate(_, company) || {}).end_date) })).slice(0, 1))

    .map(({ months }) => {
      const [startMonth] = months;
      const [endMonth] = months.slice(-1);
      const metricValues = ({
        all: _ => {
          return metricItems.map((metricItem, i) => {
            const { metric, isCustom, } = metricItem;
            const { valueType, subItemName, } = metric;
            const dimension = isCustom ? metricItem.dimension : (metric.dimension || 'none');
            const value = computeTrialAmount(
              valueType,
              {
                ...metricItem,
                isSubRow: dimension !== 'none' && !isEmpty(subItemName),
                subItemName,
              },
              trialsGroupedByItemKey,
              groupTrialSubItems(trialSubItemsGroupedByDimension[dimension]),
              dimension,
              months,
              customAccountItems,
            );
            return { ...metricItem, value, };
          });
        },
        section: _ => {
          return metricItems.map((metricItem, i) => {
            const { metric, } = metricItem;
            const { sourceType, customSectionId, } = metric;
            const sectionIds = get(customSectionsById, [customSectionId, 'sectionIds'], []);
            const value = ({
              trial: _ => {
                return computeSegmentedTrialAmount(
                  'section',
                  metricItem,
                  trialSubjectRowsGroupedByItemKey,
                  {},
                  'none',
                  months,
                  customAccountItems,
                  sectionIds,
                  [customSectionId]
                );
              },
              budget: _ => {
                return computeSectionBudget(metricItem, budgetsGroupedByItemKey, 'none', months, customAccountItems, [customSectionId]);
              },
            })[sourceType]();
            return { ...metricItem, value, };
          });
        },
      })[scopeType]();
      return {
        x: formatDate(endMonth, 'YYYY/MM'),
        ...(
          metricValues.reduce((x, y) => ({ ...x, [y.label]: Math.abs(y.value) === Infinity ? null : y.value, }), {})
        ),
      };
    });
  const rowsForExport = () => {
    return metricItems.map(({ itemName, label, metric, displayExpression }, i) => {
      const { metricType, valueType, yAxisType, dimension, subItemName, componentType, } = metric;
      const metricComponentName = chartType.metricComponentName || metricComponentTypes[componentType].componentName;
      const MetricComponent = require('recharts')[metricComponentName];
      return mapKeysToJa({
        ...(
          scopeType === 'section' && {
            customSectionName: get(customSectionsById, [metric.customSectionId, 'name'], ''),
            sourceType: sourceTypes[metric.sourceType].label,
          }
        ),
        metricType: jas[metricType],
        metricLabel: itemName,
        dimension: jas[dimension],
        subItemName,
        valueType: jas[valueType],
        ...(
          data.reduce((x, y) => {
            return {
              ...x,
              [y.x]: y[label],
            }
          }, {})
        ),
      });
    });
  };
  const onExported = async () => {
    await log(company, 'customCharts', 'exportCsv', user, { objectName: customChart.title, });
  };

  return (
    <div className={classnames('card', className)} style={{ width: cardWidth, height: cardHeight + (scopeType === 'section' ? 20 : 0) }} {...extraProps}>
      <div className="card-header d-flex justify-content-between">
        <div className="flex-fill">
          {title}
        </div>
        <div>
          <ExportButton label="" rows={rowsForExport} onFinish={onExported} fileName={`${title}_${xAxisUnit.label}.csv`} />
          {
            writable && (
              <Fragment>
                <EditButton className="ml-2" label={false} itemRef={customChart.ref} FormModal={CustomChartFormModal} formProps={{ customSections, accountItems, customAccountItems, partners, freeeItems, sections, }} />
                <DeleteButton label="" itemRef={customChart.ref} className="ml-2" />
              </Fragment>
            )
          }
        </div>
      </div>
      <div className="card-body d-flex justify-content-center align-items-center">
        <div className="text-secondary">
          <ChartComponent width={chartWidth} height={chartHeight} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="x" />
            {
              uniqBy(metricItems, _ => _.metric.yAxisType).map((metricItem, i) => {
                const { metric: { yAxisType }, displayExpression, } = metricItem;
                const numeralFormat = (displayExpression || '').includes('%') ? '0,0%' : '0,0a';
                return (
                  <YAxis key={i} yAxisId={yAxisType} orientation={yAxisType} tickFormatter={_ => numeral(_).format(numeralFormat)} tick={{ fontSize: 14.5 }} />
                );
              })
            }
            <Tooltip />
            <Legend />
            {
              metricItems.map(({ label, metric, displayExpression }, i) => {
                const { yAxisType, componentType, color = i, } = metric;
                const metricComponentName = chartType.metricComponentName || metricComponentTypes[componentType].componentName;
                const MetricComponent = require('recharts')[metricComponentName];
                return (
                  <MetricComponent yAxisId={yAxisType} key={i} type="monotone" dataKey={label} fill={colors[color]} stroke={colors[color]} strokeWidth={2} formatter={_ => numeral(_).format(displayExpression)} />
                );
              })
            }
          </ChartComponent>
          {
            scopeType === 'section' && (
              <div className="text-secondary small text-right">
                ※ 現在、予算の数値については正しくない可能性があります。
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}
