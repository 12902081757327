import React, { Component, Fragment, useEffect, } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Button, TabContent, TabPane, Nav, NavItem, NavLink, Form, FormGroup, Label, Input, } from 'reactstrap';
import qs from 'qs';
import classnames from 'classnames';
import { isEmail } from 'validator';
import { toast } from 'react-toastify';
import { pickBy } from 'lodash';
import { createBrowserHistory } from 'history';
import { generate as generatePassword } from 'generate-password';
import { useToggle, } from 'react-use';

import { initialize as initializeChannelIo, boot as bootChannelIo } from '../channelIo';
import useDocumentsFetch from './hooks/useDocumentsFetch';
import HelpLink from './HelpLink';
import PleaseConfirmEmail from './PleaseConfirmEmail';
import AcceptionFormModal from './modals/AcceptionFormModal';
import firebase, { functions } from '../firebase';
import logo from '../logo.png';

const { keys } = Object;
const checkUserEmail = functions.httpsCallable('checkUserEmail');
const auth = firebase.auth();
const db = firebase.firestore();
const usersRef = db.collection('users');
const queryParams = qs.parse(window.location.search.slice(1), { arrayLimit: Infinity });
const history = new createBrowserHistory();

export default class Root extends Component {
  constructor() {
    super();
    this.state = {
      validations: {},
      tab: queryParams.signUp === '1' ? 'signUp' : 'signIn',
      withPassword: queryParams.withpassword === '1',
      formEmail: '',
      formDisplayName: '',
      password: '',
      passwordConfirmation: '',
    };
  }
  componentWillMount() {
    this.checkSignInWithEmailLink();
    auth.onAuthStateChanged((firebaseUser) => {
      this.setState({ firebaseUser });
      if (!(firebaseUser && (firebaseUser.displayName || firebaseUser.email))) {
        auth.signOut();
        this.setState({ uid: null, email: null, displayName: null });
        this.openLoginModal();
        return;
      }
      const { email, uid, displayName } = firebaseUser;
      this.setState(pickBy({ uid, email, displayName }, _ => _));
    });
  }
  checkSignInWithEmailLink = async () => {
    if (auth.isSignInWithEmailLink(window.location.href)) {
      this.setState({ isSignInWithEmailLink: true });
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('ログインリンク送信に利用したデバイスでこちらにアクセスするか、こちらにメールアドレスを再度入力してください');
      }
      try {
        await auth.signInWithEmailLink(email, window.location.href);
      } catch (e) {
        toast.error('不正なログインURLです。再度ログインリンク送信をお試しください。');
        history.replace('/');
        this.setState({ isSignInWithEmailLink: false });
      }
    }
  }
  onSubmitSignIn = async (e) => {
    e.preventDefault();
    this.setState({ isProccessing: true });
    const { formEmail, withPassword, password, } = this.state;
    try {
      const { data: exists } = await checkUserEmail(formEmail);
      if(!exists) {
        toast.error('そちらのメールアドレスのユーザーは見つかりませんでした');
        this.setState({ isProccessing: false });
        return;
      }

      if(withPassword) {
        await auth.signInWithEmailAndPassword(formEmail, password);
      } else {
        await auth.sendSignInLinkToEmail(formEmail, {
          url: window.location.href,
          handleCodeInApp: true,
        });
        window.localStorage.setItem('emailForSignIn', formEmail);
        toast.success(`${formEmail}にログインリンクをお送りしました`);
      }
    } catch(e) {
      const message = ({
        'auth/user-not-found': 'そちらのメールアドレスのユーザーは見つかりませんでした',
      })[e.code] || 'ログインできませんでした';
      toast.error(message);
      console.error(e);
    }
    this.setState({ isProccessing: false });
  }
  onSubmitSignUp = async (e) => {
    e.preventDefault();
    this.setState({ isProccessing: true });
    const { formEmail, formDisplayName, password, withPassword, } = this.state;
    try {
      const { user: firebaseUser } = await auth.createUserWithEmailAndPassword(formEmail, withPassword ? password : generatePassword());
      const { email, uid } = auth.currentUser;
      await Promise.all([
        firebaseUser.sendEmailVerification({ url: window.location.href, }),
        auth.currentUser.updateProfile({ displayName: formDisplayName }),
      ]);
      this.setState({ uid, email, displayName: formDisplayName });
      this.updateUser();
    } catch(e) {
      toast.error(e.message);
    }
  }
  signInWithProvider = (providerName) => {
    const provider = new firebase.auth[`${providerName}AuthProvider`]();
    auth.signInWithRedirect(provider);
  }
  openLoginModal() {
    this.setState({ shouldShowLoginForm: true });
  }
  onSetUid() {
    this.listenUser();
    this.updateUser();
  }
  listenUser() {
    const { uid } = this.state;
    usersRef
      .doc(uid)
      .onSnapshot(_ => _.exists && this.setState({ user: { ..._.data(), ref: _.ref, id: _.id, } }));
  }
  updateUser() {
    const { uid, email, displayName } = this.state;
    usersRef
      .doc(uid)
      .get()
      .then(async (doc) => {
        const { ref, exists } = doc;
        const data = doc.data() || {};
        const { accessToken, refreshToken, expires } = queryParams;
        if (accessToken && refreshToken && expires) {
          await ref.update({ freee: { accessToken, refreshToken, expires } });
          window.location.href = '/';
          return;
        }
        ref[exists ? 'update' : 'set']({ uid, email, displayName: data.displayName || displayName, ...(exists ? {} : { admin: false, type: 'auditee', status: 'active', createdAt: new Date(), }) });
      });
  }
  toggleTab = (tabName) => {
    this.setState({ tab: tabName });
  }
  onChangeText = (name, { target: { value } }) => {
    this.setState({ [name]: value });
  }
  componentDidUpdate(prevProps, prevState) {
    ['formEmail', 'formDisplayName', 'password', 'passwordConfirmation'].forEach(_ => {
      if(prevState[_] !== this.state[_]) {
        this.validate(_, this.state[_]);
      }
    });
    if (this.state.uid && this.state.uid !== prevState.uid) {
      this.onSetUid();
    }
    ['formEmail', 'formDisplayName', 'password', 'passwordConfirmation'].forEach(_ => {
      if(prevState[_] !== this.state[_]) {
        this.validate(_, this.state[_]);
      }
    });
    if (this.state.user && !prevState.user) {
      this.checkActiveness();
    }
  }
  checkActiveness() {
    const { user } = this.state;
    if(user.status === 'inactive') {
      toast.error('無効なアカウントです');
      auth.signOut();
    }
  }
  validate = (name, value) => {
    const { validations, tab, password } = this.state;
    switch (name) {
      case 'formEmail':
        this.setState({ validations: { ...validations, [name]: { isValid: value && isEmail(value) } } });
        break;
      case 'password':
      case 'passwordConfirmation':
        this.setState({ validations: { ...validations, [name]: { isValid: tab === 'signUp' ? !!value && value === password : true } } });
        break;
      case 'formDisplayName':
        this.setState({ validations: { ...validations, [name]: { isValid: tab === 'signUp' ? !!value : true } } });
        break;
      default:
    }
  }
  validationCss(name) {
    const { validations: { [name]: { isValid } = {} } } = this.state;
    return classnames({ 'is-valid': isValid, 'is-invalid': isValid === false });
  }
  isUnsubmittable() {
    const { validations, tab } = this.state;
    const isInvalid = {
      signIn: ['formEmail'],
      signUp: ['formEmail', 'formDisplayName'],
    }[tab].some(_ => !((validations[_] || {}).isValid));
    return isInvalid;
  }
  render() {
    const { isSignInWithEmailLink = false, firebaseUser, isProccessing = false, formDisplayName, formEmail, password, user, shouldShowLoginForm = false, tab = 'signIn', withPassword, } = this.state;
    const isPublicPage = window.location.pathname.startsWith('/p/');
    return (
      <div>
        {
          (user && firebaseUser) ? (
            firebaseUser.emailVerified ? (
              <Fragment>
                <BrowserRouter>
                  {this.props.routes({ user })}
                </BrowserRouter>
                {!user.isAccepted && <AcceptionFormModal user={user} />}
              </Fragment>
            ) : (
              <PleaseConfirmEmail firebaseUser={firebaseUser} />
            )
          ) : (
            isPublicPage ? (
              <BrowserRouter>
                {this.props.routes({ user })}
              </BrowserRouter>
            ) : (
              !isSignInWithEmailLink && shouldShowLoginForm && (
                <div className="container py-5">
                  <div className="row">
                    <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                      <div className="d-flex justify-content-center">
                        <img src={logo} style={{ height: 50 }} />
                      </div>
                      <div className="card mt-5">
                        <div className="card-body">
                          <div className="d-flex justify-content-end mb-3">
                            <HelpLink text="ログインする" />
                          </div>
                          <Nav tabs>
                            <NavItem>
                              <NavLink className={classnames('cursor-pointer', { active: tab === 'signIn' })} onClick={this.toggleTab.bind(this, 'signIn')}>
                                ログイン
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink className={classnames('cursor-pointer', { active: tab === 'signUp' })} onClick={this.toggleTab.bind(this, 'signUp')}>
                                ユーザー登録
                              </NavLink>
                            </NavItem>
                          </Nav>
                          <TabContent activeTab={tab}>
                            <TabPane tabId="signIn">
                              <Form className="mt-3" onSubmit={this.onSubmitSignIn}>
                                <FormGroup>
                                  <Label>メールアドレス</Label>
                                  <Input value={formEmail} onChange={this.onChangeText.bind(this, 'formEmail')} className={this.validationCss('formEmail')} />
                                </FormGroup>
                                {
                                  withPassword && (
                                    <FormGroup>
                                      <Label>パスワード</Label>
                                      <Input type="password" name="password" value={password} onChange={this.onChangeText.bind(this, 'password')} className={this.validationCss('password')} />
                                    </FormGroup>
                                  )
                                }
                                <div className="d-flex justify-content-center mt-4">
                                  <Button type="submit" color="primary" block disabled={this.isUnsubmittable() || isProccessing}>
                                    {withPassword ? 'ログイン' : 'ログインリンクをこのメールアドレスに送る'}
                                  </Button>
                                </div>
                              </Form>
                            </TabPane>
                            <TabPane tabId="signUp">
                              <Form className="mt-3" onSubmit={this.onSubmitSignUp}>
                                <FormGroup>
                                  <Label>メールアドレス</Label>
                                  <Input name="formEmail" value={formEmail} onChange={this.onChangeText.bind(this, 'formEmail')} className={this.validationCss('formEmail')} />
                                </FormGroup>
                                <FormGroup>
                                  <Label>ユーザー名</Label>
                                  <Input name="formDisplayName" value={formDisplayName} onChange={this.onChangeText.bind(this, 'formDisplayName')} className={this.validationCss('formDisplayName')} />
                                </FormGroup>
                                {
                                  withPassword && (
                                    <FormGroup>
                                      <Label>パスワード</Label>
                                      <Input type="password" name="password" value={password} onChange={this.onChangeText.bind(this, 'password')} className={this.validationCss('password')} />
                                    </FormGroup>
                                  )
                                }
                                <div className="d-flex justify-content-center mt-4">
                                  <Button type="submit" color="primary" block disabled={this.isUnsubmittable() || isProccessing}>登録</Button>
                                </div>
                              </Form>
                            </TabPane>
                          </TabContent>
                        </div>
                      </div>
                      <div className="card mt-5">
                        <div className="card-body">
                          <h6 className="text-center mb-3">他のアカウントでログイン</h6>
                          <div className="d-flex justify-content-center">
                            <Button onClick={this.signInWithProvider.bind(this, 'Google')} block>
                              <span className="fab fa-google mr-1" />
                              Google
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )
          )
        }
        <ToastContainer />
        <ChannelIO {...{ user, }} />
      </div>
    );
  }
}

function ChannelIO (props) {
  const { user, } = props;
  const _companies = useDocumentsFetch(keys(pickBy(user?.companies || {}, 'isJoined')).map(_ => db.collection('companies').doc(_)), [user]);
  const companies = user == null ? null : _companies.filter(_ => _);
  const [showsChannelIo, toggleChannelIo] = useToggle(process.env.REACT_APP_ENV === 'production');
  useEffect(() => {
    initializeChannelIo();
  }, []);
  useEffect(() => {
    if(user != null && companies != null) {
      bootChannelIo({ user, companies, });
    }
  }, [user, _companies]);
  useEffect(() => {
    if(process.env.REACT_APP_ENV === 'production' && showsChannelIo === false) {
      window.ChannelIO && window.ChannelIO('shutdown');
      toast.info('画面を再読み込みすると、チャットを再び表示します。');
    }
  }, [showsChannelIo]);

  return showsChannelIo && (
    <Button color="link" className="text-secondary rounded-pill" style={{ position: 'fixed', bottom: 64, right: 4, zIndex: 10000000 }} onClick={toggleChannelIo.bind(null, false)}>
      <span className="fas fa-times" />
    </Button>
  );
}
