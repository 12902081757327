import React from 'react';
import { Button, } from 'reactstrap';
import { chunk } from 'lodash';
import { toast } from 'react-toastify';

import firebase from '../../firebase';

const db = firebase.firestore();

export default function ResetIgnoredButton(props) {
  const { detections, onFinished, ...extraProps } = props;
  const onClickResetIgnored = async () => {
    if(!window.confirm('本当に無視を解除しますか？')) return;
    try {
      await chunk(detections, 500).reduce(async (x, detections) => {
        await x;
        const batch = db.batch();
        detections.forEach(_ => batch.update(_.ref, { ignoringNote: '', status: 'initial', }));
        await batch.commit();
      }, Promise.resolve());
      toast.success('解除しました');
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
    onFinished && onFinished();
  };

  return (
    <Button onClick={onClickResetIgnored} {...extraProps}>
      無視を解除
    </Button>
  );
};

