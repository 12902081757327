import React, { Component, Fragment, useState, useEffect, useMemo, useRef, } from 'react';
import { Button, Nav, NavItem, NavLink,  } from 'reactstrap';
import { mapValues, sum, orderBy, pick, uniq, range, isEmpty, sumBy, groupBy, get, keyBy, last, sortBy, omit } from 'lodash';
import { toast } from 'react-toastify';
import { format as formatDate, endOfMonth, addMonths, addYears } from 'date-fns';
import { useList, useToggle, useDebounce, useMap, } from 'react-use';
import { useLocation, useHistory } from 'react-router-dom';
import numeral from 'numeral';
import qs from 'qs';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { ResizableBox } from 'react-resizable';

import firebase from '../../firebase';
import { canWriteNote, } from '../../abilities';
import { getCollectionData } from '../../shared/firebase';
import { accountItemCategoriesByName } from '../../shared/category';
import { mapKeysToJa, } from '../../shared/texts';
import { computeCreditBalance, types as customPartnerTypes } from '../../shared/models/customPartner';
import { log, fiscalYearOfPeriod, } from '../../utils';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import usePagination from '../hooks/usePagination';
import TrialQueriesSection from '../TrialQueriesSection';
import HelpLink from '../HelpLink';
import CompanyPage from '../hocs/CompanyPage';
import CommentsDropdown from '../CommentsDropdown';
import QuerySelector from '../QuerySelector';
import ExportButton from '../ExportButton';
import ProgressButton from '../ProgressButton';
import Pagination from '../Pagination';
import HoveredCommenter from '../HoveredCommenter';
import HoveredNoter from '../HoveredNoter';

const { keys, entries } = Object;
const db = firebase.firestore();
const companiesRef = db.collection('companies');
const COLUMN_WIDTH = 160;
const ROW_HEIGHT = 130;
const countPerPage = 50;
const accountItemMetrics = {
  openingBalance: { label: '前月残高', },
  debitAmount: { label: '借方金額', },
  creditAmount: { label: '貸方金額', },
  closingBalance: { label: '当月残高', },
};
const partnerMetrics = {
  creditLimit: { label: '与信限度額', },
  creditBalance: { label: '与信残高', },
  creditRate: { label: '与信残高割合', format: '0,0.00%', },
};
const metricOptions = entries({ ...accountItemMetrics, ...partnerMetrics }).map(([k, v]) => ({ label: v.label, value: k }));

// NOTE: あえてreact外のstateを使う
const hoveredCommenters = {};

export default CompanyPage(CompanyCustomPartners);

function CompanyCustomPartners(props) {
  const { company, user, role, period, targetMonth, members, sortedAccountItems: accountItems, match: { params: { companyId } } } = props;
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();
  const {
    customPartnerType,
    customPartnerIds,
    accountItemNamesForFilter,
  } = queryParams;
  const [columnWidths, { updateAt: updateColumnWidthAt, }] = useList([COLUMN_WIDTH, COLUMN_WIDTH, COLUMN_WIDTH * 0.8]);
  const partnerChunks = useCollectionSubscription(company.ref.collection('partnerChunks'), [company]);
  const _freeePartners = useMemo(_ => partnerChunks.flatMap(_ => _.data), [partnerChunks]);
  const freeePartners = useMemo(_ => [{ id: 0, name: '未選択' }, ..._freeePartners], [_freeePartners]);
  const [rowGroups, setRowGroups] = useState([]);
  const [isSettingRows, toggleSettingRows] = useToggle(false);
  const [isCommentRowToggled, toggleCommentRowToggled] = useToggle();
  const freeePartnersById = useMemo(_ => keyBy(freeePartners, 'id'), [freeePartners]);
  const freeePartnersByName = useMemo(_ => keyBy(freeePartners, 'name'), [freeePartners]);
  const { start_date: startDate, } = fiscalYearOfPeriod(period, company.fiscalYears);
  const currentYearMonths = range(0, 12).map(_ => endOfMonth(addMonths(startDate, _)));
  const accountItemNames = accountItems.map(_ => _.name);
  const accountItemsById = keyBy(accountItems, 'id');
  const { debit: debitPartnerCreditAccountItemIds, credit: creditPartnerCreditAccountItemIds } = groupBy(company.partnerCreditAccountItemIds, _ => accountItemCategoriesByName[accountItemsById[_]?.account_category]?.direction);
  const tradeReceivableNames = accountItems.filter(_ => _.account_category === '売上債権' || debitPartnerCreditAccountItemIds?.includes(_.id)).map(_ => _.name);
  const tradePayableNames = accountItems.filter(_ => _.account_category === '仕入債務' || creditPartnerCreditAccountItemIds?.includes(_.id)).map(_ => _.name);
  const customPartners = useCollectionSubscription(customPartnerType && (customPartnerType === 'all' ? company.ref.collection('customPartners').orderBy('name') : company.ref.collection('customPartners').where('types', 'array-contains', customPartnerType).orderBy('name')), [companyId, customPartnerType]);
  const trials = useCollectionSubscription(company.ref.collection('trials').where('closingDate', '==', formatDate(endOfMonth(targetMonth + '01'), 'YYYY-MM-DD')).where('dimension', '==', 'none'), [targetMonth]);
  const trialsByItemKey = keyBy(trials, 'itemKey');
  const trialSubItemChunks = useCollectionSubscription(company.ref.collection('trialSubItemChunks__partners').where('closingDate', '>=', formatDate(currentYearMonths[0], 'YYYY-MM-DD')).where('closingDate', '<=', formatDate(endOfMonth(targetMonth + '01'), 'YYYY-MM-DD')), [targetMonth]);
  const trialSubItems = useMemo(_ => trialSubItemChunks.flatMap(_ => _.subItems.map(__ => ({ ..._, ...__ }))), [trialSubItemChunks]);
  const trialSubItemsGroupedByClosingDate = useMemo(() => {
    return mapValues(
      groupBy(trialSubItems, _ => formatDate(_.closingDate, 'YYYYMM')),
      _ => mapValues(
        groupBy(_, 'account_item_name'),
        _ => groupBy(_, _ => get(freeePartnersByName, [_.name, 'id']))
      )
    );
  }, [trialSubItems, freeePartnersByName]);
  const trialPartnerItemsGroupedByFreeePartnerId = groupBy(trialSubItems, _ => get(freeePartnersByName, [_.name, 'id']));
  const comments = useCollectionSubscription(companiesRef.doc(companyId).collection('comments').where('queryKey', '==', 'partnerBalances').orderBy('createdAt'));
  const commentsGroupedByCommenteeKey = groupBy(comments, 'commenteeKey');
  const notes = useCollectionSubscription(company.ref.collection('notes').where('queryKey', '==', 'partnerBalances'));
  const notesByKey = keyBy(notes, 'noteKey');
  const generateRowGroups = (customPartners) => {
    return customPartners.map((customPartner) => {
      const { freeePartnerIds = [], } = customPartner;
      const trialPartnerItems = freeePartnerIds.flatMap(_ => trialPartnerItemsGroupedByFreeePartnerId[_] || []);
      const accountItemNames = uniq(trialPartnerItems.map(_ => _.account_item_name));
      const accountItemRows = accountItemNames.map((accountItemName) => {
        const columnItems = currentYearMonths.map((month) => {
          const isTargetMonth = formatDate(month, 'YYYYMM') === targetMonth.toString();
          const items = freeePartnerIds.flatMap(id => trialSubItemsGroupedByClosingDate[formatDate(month, 'YYYYMM')]?.[accountItemName]?.[id] || []);
          const openingBalance = sumBy(items, 'opening_balance') || 0;
          const debitAmount = sumBy(items, 'debit_amount') || 0;
          const creditAmount = sumBy(items, 'credit_amount') || 0;
          const closingBalance = sumBy(items, 'closing_balance') || 0;
          const occurrence = closingBalance - openingBalance;
          const commenteeKey = [customPartner.id, accountItemName, formatDate(month, 'YYYYMM')].join('_');
          const noteKey = [customPartner.id, accountItemName, formatDate(month, 'YYYYMM')].join('_');
          return {
            month,
            isTargetMonth,
            openingBalance,
            debitAmount,
            creditAmount,
            closingBalance,
            occurrence,
            commenteeKey,
            noteKey,
          };
        });
        const targetMonthColumnItem = columnItems.find(_ => _.isTargetMonth);
        const [firstColumnItem] = columnItems;
        const totalOccurrence = sumBy(columnItems, 'occurrence');
        const totalAmount = firstColumnItem.openingBalance + totalOccurrence;
        const trialAmount = targetMonthColumnItem.closingBalance;
        const diff = totalAmount - trialAmount;
        const summaryColumns = [
          { isSummaryColumn: true, name: 'opening', key: `${accountItemName}_opening`, amount: firstColumnItem.openingBalance, },
          { isSummaryColumn: true, name: 'sum', key: `${accountItemName}_sum`, amount: totalOccurrence, },
          { isSummaryColumn: true, name: 'total', key: `${accountItemName}_total`, amount: totalAmount, },
          { isSummaryColumn: true, name: 'trial', key: `${accountItemName}_trial`, amount: trialAmount, },
          { isSummaryColumn: true, name: 'diff', key: `${accountItemName}_diff`, amount: diff, },
        ];
        const hasDiff = Math.abs(diff) > 0;

        return {
          accountItemName,
          columnItems,
          summaryColumns,
          hasDiff,
        };
      });
      const columnItems = currentYearMonths.map((month, i) => {
        const items = freeePartnerIds.flatMap(id => Object.values(trialSubItemsGroupedByClosingDate[formatDate(month, 'YYYYMM')] || {}).flatMap(_ => _[id] || []));
        const creditLimit = orderBy((customPartner?.creditLimits || []), 'yearMonth', 'desc').find(_ => _.yearMonth <= formatDate(month, 'YYYYMM'))?.value || 0;
        const creditBalance = computeCreditBalance(creditLimit, tradeReceivableNames, tradePayableNames, items);
        return {
          month,
          creditLimit,
          creditBalance,
          creditRate: creditBalance / creditLimit,
        };
      });
      return {
        customPartner,
        accountItemRows,
        columnItems,
      };
    });
  };
  const rowsDependencies = [accountItems, customPartners, trialSubItemChunks];
  useEffect(_ => toggleSettingRows(true), rowsDependencies);
  useDebounce(async () => {
    setRowGroups(generateRowGroups(customPartners));
    toggleSettingRows(false);
  }, 1000, rowsDependencies);
  const allAccountItemRows = rowGroups.flatMap(_ => _.accountItemRows);
  const accountItemNameOptions = sortBy(uniq(allAccountItemRows.map(_ => _.accountItemName)), _ => accountItemNames.indexOf(_)).map(_ => ({ label: _, value: _ }));
  const summaryRows = customPartnerType === 'all' ? Object.values(groupBy(allAccountItemRows, _ => _.accountItemName)).map((rows) => {
    const [row] = rows;
    const { accountItemName } = row;
    const columnItems = currentYearMonths.map((month, i) => {
      const isTargetMonth = formatDate(month, 'YYYYMM') === targetMonth.toString();
      const openingBalance = sumBy(rows, `columnItems[${i}].openingBalance`);
      const closingBalance = sumBy(rows, `columnItems[${i}].closingBalance`);
      const occurrence = closingBalance - openingBalance;
      return {
        month,
        isTargetMonth,
        openingBalance,
        closingBalance,
        occurrence,
      };
    });
    const targetMonthColumnItem = columnItems.find(_ => _.isTargetMonth);
    const [firstColumnItem] = columnItems;
    const totalOccurrence = sumBy(columnItems, 'occurrence');
    const totalAmount = firstColumnItem.openingBalance + totalOccurrence;
    const trialAmount = trialsByItemKey[accountItemName + '__none']?.closing_balance;
    const diff = totalAmount - trialAmount;
    const summaryColumns = [
      { isSummaryColumn: true, name: 'opening', key: `${accountItemName}_opening`, amount: firstColumnItem.openingBalance, },
      { isSummaryColumn: true, name: 'sum', key: `${accountItemName}_sum`, amount: totalOccurrence, },
      { isSummaryColumn: true, name: 'total', key: `${accountItemName}_total`, amount: totalAmount, },
      { isSummaryColumn: true, name: 'trial', key: `${accountItemName}_trial`, amount: trialAmount, },
      { isSummaryColumn: true, name: 'diff', key: `${accountItemName}_diff`, amount: diff, },
    ];
    const hasDiff = Math.abs(diff) > 0;

    return {
      accountItemName,
      columnItems,
      summaryColumns,
      hasDiff,
    };
  }) : [];

  const onClickComment = (commenteeKey) => {
    const { [commenteeKey]: commenter } = hoveredCommenters;
    if(commenter) {
      commenter.open();
      commenter.focus();
    }
  };
  const toggleCommentRow = () => {
    if(isEmpty(rowGroups) || isCommentRowToggled || !queryParams.commenteeKey) return;

    toggleCommentRowToggled(true);
    setTimeout(() => onClickComment(queryParams.commenteeKey), 1000);
  }

  let filteredRowGroups = rowGroups;
  if(!isEmpty(customPartnerIds)) {
    filteredRowGroups = filteredRowGroups.filter(_ => customPartnerIds.includes(_.customPartner.id));
  }
  if(!isEmpty(accountItemNamesForFilter)) {
    filteredRowGroups = filteredRowGroups.map((rowGroup) => {
      const { accountItemRows } = rowGroup;
      return {
        ...rowGroup,
        accountItemRows: accountItemRows.filter(_ => accountItemNamesForFilter.includes(_.accountItemName)),
      };
    });
  }
  filteredRowGroups = filteredRowGroups.filter(_ => _.accountItemRows.length > 0);

  const pagination = usePagination({ countPerPage, totalCount: filteredRowGroups.length, });
  const { pageNumber, offset, first, } = pagination;
  const displayRowGroups = filteredRowGroups.slice(offset, offset + countPerPage);
  const customPartnerOptions = customPartners.map(_ => ({ label: _.name, value: _.id }));
  const toggleTab = (customPartnerType) => {
    history.replace(location.pathname + '?' + qs.stringify({ ...queryParams, customPartnerType, }));
  };
  const rowsForExport = async () => {
    const { metrics: metricsForFilter = keys({ ...accountItemMetrics, ...partnerMetrics }), } = queryParams;
    return filteredRowGroups.flatMap((rowGroup) => {
      const { customPartner, accountItemRows, columnItems, } = rowGroup;
      const typesText = customPartner.types?.map(_ => customPartnerTypes[_].label).join(', ');
      return [
        ...accountItemRows.flatMap((accountItemRow) => {
          const { accountItemName, columnItems, summaryColumns, } = accountItemRow;
          return [
            ...entries(pick(accountItemMetrics, metricsForFilter)).map(([metric, { label }]) => {
              return mapKeysToJa({
                customPartnerName: customPartner.name,
                types: typesText,
                accountItemName,
                metric: label,
                ...columnItems.reduce((x, columnItem) => {
                  const { month } = columnItem;
                  return {
                    ...x,
                    [formatDate(month, 'YYYY/MM')]: columnItem[metric],
                  };
                }, {}),
                ...summaryColumns.reduce((x, summaryColumn) => {
                  const { name, amount } = summaryColumn;
                  const shows = metric === 'closingBalance';
                  return {
                    ...x,
                    [name]: shows ? amount : null,
                  };
                }, {}),
              }, { sum: '発生額合計', diff: '差額', });
            }),
            ...(
              columnItems.every(_ => isEmpty(notesByKey[_.noteKey]?.value)) ? [] : [
                mapKeysToJa({
                  customPartnerName: customPartner.name,
                  types: typesText,
                  accountItemName,
                  metric: 'メモ',
                  ...columnItems.reduce((x, columnItem) => {
                    const { month, noteKey } = columnItem;
                    return {
                      ...x,
                      [formatDate(month, 'YYYY/MM')]: notesByKey[noteKey]?.value,
                    };
                  }, {}),
                }),
              ]
            ),
          ];
        }),
        ...entries(pick(partnerMetrics, metricsForFilter)).map(([metric, { label, format }]) => {
          return mapKeysToJa({
            customPartnerName: customPartner.name,
            types: typesText,
            accountItemName: null,
            metric: label,
            ...columnItems.reduce((x, columnItem) => {
              const { month } = columnItem;
              return {
                ...x,
                [formatDate(month, 'YYYY/MM')]: columnItem[metric],
              };
            }, {}),
          });
        }),
      ];
    });
  };
  const logExport = async () => {
    await log(company, 'partnerBalances', 'exportCsv', user, { period, targetMonth, });
  };
  useEffect(() => {
    const { customPartnerType = 'all', } = queryParams;
    history.replace(location.pathname + '?' + qs.stringify({ ...queryParams, customPartnerType, }));
  }, []);
  useEffect(() => {
    toggleCommentRow();
  }, [rowGroups]);

  return (
    <div className="company-partner-balances">
      <div className="p-5">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="取引先別分析を行う" />
        </div>
        <div className="d-flex justify-content-center mb-2">
          <h3>取引先別月次推移</h3>
        </div>
        <Nav tabs>
          {
            entries({ all: { label: '全区分', }, ...customPartnerTypes }).map(([k, { label }]) => {
              return (
                <NavItem key={k}>
                  <NavLink className={classnames('cursor-pointer', { active: customPartnerType === k })} onClick={toggleTab.bind(this, k)}>
                    {label}
                  </NavLink>
                </NavItem>
              )
            })
          }
        </Nav>
        <div className="mt-5 d-flex align-items-end justify-content-between position-relative" style={{ zIndex: 4 }}>
          <div className="d-flex gap-1 align-items-end">
            <TrialQueriesSection 
              company={company}
              screenType="monthlyPartnerBalances"
              items={[
                [
                  {
                    name: 'customPartnerIds',
                    type: 'multiSelect',
                    options: customPartnerOptions,
                    label: 'カスタム取引先',
                    isFront: true,
                    props: { width: 250, },
                  },
                  {
                    name: 'metrics',
                    type: 'multiSelect',
                    options: metricOptions,
                    label: '数値項目',
                    isFront: true,
                    props: { width: 200, }
                  },
                  {
                    name: 'accountItemNamesForFilter',
                    type: 'multiSelect',
                    options: accountItemNameOptions,
                    label: '科目名',
                    isFront: true,
                    props: { width: 300, }
                  },
                ],
              ]}
            />
          </div>
          <div className="d-flex gap-1 align-items-end">
            <Pagination {...pagination} />
            <ExportButton size="sm" outline fileName="取引先別分析.csv" rows={rowsForExport} onFinish={logExport} />
            <CommentsDropdown company={company} companyId={company.id} currentUser={user} comments={comments} usersById={keyBy(members, 'id')} onClickComment={onClickComment} />
          </div>
        </div>
        {[...allAccountItemRows, ...summaryRows].some(_ => _.hasDiff) && <div className="my-2 alert alert-danger">差額があります</div>}
        <div className="mt-2 d-flex justify-content-start position-relative" style={{ zIndex: 1 }}>
          <table className="table table-hover sticky-table" style={{ width: 'auto', wordBreak: 'break-all' }}>
            <thead className="thead-light text-center text-nowrap">
              <tr>
                <th style={{ width: sum(columnWidths), }}>
                  <div className="d-flex">
                    <ResizableBox width={COLUMN_WIDTH} height={24} axis="x" resizeHandles={['e']} onResize={(e, _) => updateColumnWidthAt(0, _.size.width,)}>カスタム取引先</ResizableBox>
                    <ResizableBox width={COLUMN_WIDTH} height={24} axis="x" resizeHandles={['e']} onResize={(e, _) => updateColumnWidthAt(1, _.size.width,)}>勘定科目</ResizableBox>
                    <div style={{ width: COLUMN_WIDTH * 0.8}}>数値項目</div>
                  </div>
                </th>
                {
                  currentYearMonths.map((month, i) => {
                    return (
                      <th key={i} className="text-nowrap" style={{ width: COLUMN_WIDTH }}>
                        <Link to={`/companies/${companyId}/partnerBalances?${qs.stringify({ period, targetMonth: formatDate(month, 'YYYYMM'), })}`}>
                          {formatDate(month, 'YYYY/MM')}
                        </Link>
                      </th>
                    );
                  })
                }
                <th style={{ width: COLUMN_WIDTH }}>期首残高</th>
                <th style={{ width: COLUMN_WIDTH }}>発生額合計</th>
                <th style={{ width: COLUMN_WIDTH }}>合計</th>
                <th style={{ width: COLUMN_WIDTH }}>試算表残高</th>
                <th style={{ width: COLUMN_WIDTH }}>差額</th>
              </tr>
            </thead>
            <tbody>
              {
                displayRowGroups.map((rowGroup) => {
                  return (
                    <RowGroup key={rowGroup.customPartner.id} {...{ rowGroup, company, user, role, members, period, freeePartnersById, notesByKey, commentsGroupedByCommenteeKey, columnWidths, }} />
                  );
                })
              }
            </tbody>
          </table>
        </div>
        <div className="mt-5 d-flex justify-content-start position-relative" style={{ zIndex: 0 }}>
          <table className="table table-hover sticky-table">
            <thead className="thead-light text-center text-nowrap">
              <tr>
                <th style={{ width: sum(columnWidths) }}>
                  <div className="d-flex">
                    <div style={{ width: columnWidths[0], }}></div>
                    <div style={{ width: columnWidths[0], }}>勘定科目</div>
                    <div style={{ width: COLUMN_WIDTH * 0.8 }}>数値項目</div>
                  </div>
                </th>
                {
                  currentYearMonths.map((month, i) => {
                    return (
                      <th key={i} className="text-nowrap" style={{ width: COLUMN_WIDTH }}>
                        <Link to={`/companies/${companyId}/partnerBalances?${qs.stringify({ period, targetMonth: formatDate(month, 'YYYYMM'), })}`}>
                          {formatDate(month, 'YYYY/MM')}
                        </Link>
                      </th>
                    );
                  })
                }
                <th style={{ width: COLUMN_WIDTH }}>期首残高</th>
                <th style={{ width: COLUMN_WIDTH }}>発生額合計</th>
                <th style={{ width: COLUMN_WIDTH }}>合計</th>
                <th style={{ width: COLUMN_WIDTH }}>試算表残高</th>
                <th style={{ width: COLUMN_WIDTH }}>差額</th>
              </tr>
            </thead>
            <tbody>
              {
                summaryRows?.map((summaryRow, i) => {
                  const { accountItemName, columnItems, summaryColumns, hasDiff, } = summaryRow;
                  return (
                    <tr key={accountItemName} className={classnames('hovered-row', { 'table-danger': hasDiff })}>
                      <th className="font-weight-normal" style={{ width: sum(columnWidths) }}>
                        <div className="d-flex">
                          <div style={{ width: columnWidths[0], }}></div>
                          <div style={{ width: columnWidths[1], }}>{accountItemName}</div>
                          <div style={{ width: COLUMN_WIDTH * 0.8 }}>当月残高</div>
                        </div>
                      </th>
                      {
                        columnItems.map((columnItem) => {
                          return (
                            <td className="text-right" style={{ width: COLUMN_WIDTH }}>
                              {numeral(columnItem.closingBalance).format()}
                            </td>
                          );
                        })
                      }
                      {
                        summaryColumns.map((summaryColumn) => {
                          const { name, amount } = summaryColumn;
                          return (
                            <td className="text-right" style={{ width: COLUMN_WIDTH }}>
                              {numeral(amount).format()}
                            </td>
                          );
                        })
                      }
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function RowGroup (props) {
  const { company, user, members, role, rowGroup, period, freeePartnersById, notesByKey, commentsGroupedByCommenteeKey, columnWidths, } = props;
  const { customPartner, accountItemRows, columnItems, } = rowGroup;
  const queryParams = useQueryParams();
  const {
    metrics: metricsForFilter,
  } = queryParams;
  const displayAccountItemMetrics = isEmpty(metricsForFilter) ? accountItemMetrics : pick(accountItemMetrics, metricsForFilter);
  const displayPartnerMetrics = isEmpty(metricsForFilter) ? partnerMetrics : pick(partnerMetrics, metricsForFilter);

  return (
    <Fragment>
      {
        !isEmpty(displayAccountItemMetrics) && accountItemRows.map((accountItemRow, accountItemRowIndex) => {
          const { accountItemName, columnItems, summaryColumns, hasDiff, } = accountItemRow;
          return (
            <tr key={accountItemName} className={classnames('hovered-row', { 'table-danger': hasDiff })}>
              <th className="font-weight-normal" style={{ width: sum(columnWidths), }}>
                <div className="d-flex gap-2">
                  <div style={{ width: columnWidths[0], }}>
                    {accountItemRowIndex === 0 && customPartner.name}
                  </div>
                  <div style={{ width: columnWidths[1], }}>
                    {!isEmpty(displayAccountItemMetrics) && accountItemName}
                  </div>
                  <div style={{ width: COLUMN_WIDTH * 0.8, }}>
                    {
                      entries(displayAccountItemMetrics).map(([metric, { label }], metricIndex) => (
                        <div key={metric}>{label}</div>
                      ))
                    }
                  </div>
                </div>
              </th>
              {
                columnItems.map((columnItem) => {
                  const { month, commenteeKey, noteKey, } = columnItem;
                  const pathParams = {
                    period,
                    targetMonth: formatDate(month, 'YYYYMM'),
                    complexFilter: [
                      ['accountName', '==', accountItemName],
                      ['partners', 'in', customPartner.freeePartnerIds?.map(_ => freeePartnersById[_]?.name)],
                    ],
                    usesArrayFilter: 1,
                    complexFilterDescription: `科目: ${accountItemName}, カスタム取引先: ${customPartner.name}`,
                  };
                  return (
                    <td className="text-right has-hovered-contents" style={{ width: COLUMN_WIDTH }}>
                      <div className="d-flex flex-column">
                        {
                          entries(displayAccountItemMetrics).map(([metric, { label }], metricIndex) => (
                            ['debitAmount', 'creditAmount'].includes(metric) ? (
                              <Link key={metric} to={encodeURI(`/companies/${company.id}/journals?${qs.stringify(pathParams)}`)}>{numeral(columnItem[metric]).format()}</Link>
                            ) : (
                              <div key={metric}>{numeral(columnItem[metric]).format()}</div>
                            )
                          ))
                        }
                      </div>
                      <HoveredCommenter
                        ref={_ => hoveredCommenters[commenteeKey] = _}
                        company={company}
                        companyId={company.id}
                        currentUser={user}
                        commenteeKey={commenteeKey}
                        queryKey={'partnerBalances'}
                        values={{ yearMonth: formatDate(month, 'YYYYMM') }}
                        comments={commentsGroupedByCommenteeKey[commenteeKey]}
                        users={members}
                        about={['取引先分析', formatDate(month, 'YYYYMM'), customPartner.name, accountItemName].filter(_ => _).join(' ')}
                      />
                      <HoveredNoter companyId={company.id} noteKey={noteKey} pageType="PartnerBalances" queryKey="partnerBalances" values={{ yearMonth: formatDate(month, 'YYYYMM') }} note={notesByKey[noteKey]} writable={canWriteNote(user, role)} />
                    </td>
                  );
                })
              }
              {
                summaryColumns.map((summaryColumn) => {
                  const { name, amount } = summaryColumn;
                  return (
                    <td className="text-right" style={{ width: COLUMN_WIDTH }}>
                      {
                        entries(displayAccountItemMetrics).map(([metric, { label }], metricIndex) => {
                          const shows = metric === 'closingBalance';
                          return <div key={metric}>{shows ? numeral(amount).format() : '-'}</div>;
                        })
                      }
                    </td>
                  );
                })
              }
            </tr>
          );
        })
      }
      {
        !isEmpty(displayPartnerMetrics) && (
          <tr className="hovered-row">
            <th className="font-weight-normal" style={{ width: sum(columnWidths), }}>
              <div className="d-flex">
                <div style={{ width: columnWidths[0], }}>
                  {isEmpty(displayAccountItemMetrics) && customPartner.name}
                </div>
                <div style={{ width: columnWidths[1] }}></div>
                <div style={{ width: COLUMN_WIDTH * 0.8 }}>
                  {
                    entries(displayPartnerMetrics).map(([metric, { label }]) => (
                      <div key={metric}>{label}</div>
                    ))
                  }
                </div>
              </div>
            </th>
            {
              columnItems.map((columnItem) => {
                return (
                  <td className="text-right" style={{ width: COLUMN_WIDTH }}>
                    {
                      entries(displayPartnerMetrics).map(([metric, { label, format }], metricIndex) => (
                        <div key={metric}>{numeral(columnItem[metric]).format(format)}</div>
                      ))
                    }
                  </td>
                );
              })
            }
            {
              Array(5).fill().map((_, i) => {
                return (
                  <td className="text-right" style={{ width: COLUMN_WIDTH }}>
                    {
                      entries(displayPartnerMetrics).map(([metric, { label, format }], metricIndex) => (
                        <div key={metric}>-</div>
                      ))
                    }
                  </td>
                );
              })
            }
          </tr>
        )
      }
    </Fragment>
  );
}
