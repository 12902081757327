import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { Button, } from 'reactstrap';

export default class ListForm extends Component {
  constructor() {
    super();
    this.state = {};
  }
  onClickAdd = () => {
    const { items, onChange, } = this.props;
    onChange([...items, {}], items);
  }
  onClickDelete = (index) => {
    const { items, onChange } = this.props;
    onChange([...items.slice(0, index), ...items.slice(index + 1)], items);
  }
  render() {
    const { items, renderItem, itemClassName, deleteDisabled = false, minItems = 0, addButtonLabel = '追加', disabled, } = this.props;
    return (
      <Fragment>
        {
          items.map((item, i) => {
            return (
              <div key={i} className={classnames('d-flex mb-2 align-items-start', itemClassName)}>
                <div className="mr-1 flex-grow-1">
                  {renderItem(item, i)}
                </div>
                {
                  items.length > minItems && (
                    <Button color="danger" onClick={this.onClickDelete.bind(this, i)} disabled={deleteDisabled || disabled}>
                      <span className="fas fa-trash" />
                    </Button>
                  )
                }
              </div>
            )
          })
        }
        <div>
          <Button color="secondary" onClick={this.onClickAdd} disabled={disabled}>
            <span className="fas fa-plus mr-1" />
            {addButtonLabel}
          </Button>
        </div>
      </Fragment>
    );
  }
};
