import React, { Component, Fragment, useState, useRef, } from 'react';
import { Table, Button, Nav, NavItem, NavLink,  } from 'reactstrap';
import { last, chunk, uniqBy, orderBy, uniq, isEqual, get, sumBy, isEmpty, isNumber as _isNumber, groupBy, sortBy, keyBy, range, debounce, pick, } from 'lodash';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import { useToggle, useDebounce, useMap, } from 'react-use';
import { Container, Draggable } from 'react-smooth-dnd';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import classnames from 'classnames';
import { format as formatDate, startOfMonth, endOfMonth, addYears, addMonths, } from 'date-fns';
import { Link } from 'react-router-dom';

import { canWriteNote, } from '../../../abilities';
import { breakdownItemsLimitUnit, budgetSubjectTypes, } from '../../../shared/config';
import useQueryParams from '../../hooks/useQueryParams';
import SyncCustomAccountItemManualValuesButton from '../../SyncCustomAccountItemManualValuesButton';
import AccountItemDisplay from '../../AccountItemDisplay';
import HoveredCommenter from '../../HoveredCommenter';
import HoveredNoter from '../../HoveredNoter';
import BudgetCellModal from '../../modals/BudgetCellModal';
import MonthlyColumn from '../CompanyMonthlyBudgets/Column';
import ModalButton from '../../ModalButton';
import PublicBudgetCellModalContent from '../../PublicBudgetCellModalContent';

const suffix = documentType => ({ pl: '', cr: 'Cr' })[documentType];
const { keys, entries, } = Object;
const { abs } = Math;
const isFiniteNumber = _ => _isNumber(_) && isFinite(_);
const COLUMN_WIDTH = 150;
const AMOUNT_HEIGHT = '24px';

export default function Row (props) {
  const { company, pageProps, row, commentsGroupedByCommenteeKey, notesByKey, budgetSubjectType, setHoveredCommenters, metrics, toggleSubRows, breakdownItemsLimitRate, showsSubRows, showMoreSubRows, isPublic = false, updateVersion, leftColumnWidth, } = props;
  const { hierarchyLevel = 1, key, isCategory, isCustom, isManual, itemName, itemKey, subItemName, isSubRow, rowName, summaryColumns, diff, displayExpression, breakDownItemIndex, subItemsCount, column, } = row;
  const { role, targetMonth, accountItemSettingsById, user, members, period, match: { params: { companyId } } } = pageProps;
  const { key: monthKey, commenteeKeys, noteKeys, budgetAmount, comparedBudgetAmount, diffBetweenBudgets, amount, budgetDiff, achievementRate, estimatedAmount, prevYearAmount, prevYearDiff, prevYearDiffRate, freeeSubjects, } = column;
  const queryParams = useQueryParams();
  const { metrics: metricsForFilter = keys(metrics), } = queryParams;
  const modalRef = useRef(null);
  const isMultiSubjectsSelected = commenteeKeys?.length > 1;
  const { tabKey = 'section__pl', dimension = 'none', aggregationType = 'cumulative', comparedBudgetContainerId, } = queryParams;
  const { budgetCollectionName, freeeCollectionName, } = budgetSubjectTypes[budgetSubjectType];
  const isComparing = !isEmpty(comparedBudgetContainerId);
  const accountItemSetting = accountItemSettingsById?.[`${rowName.replace(/\//g, '_')}`];
  const showingCount = breakdownItemsLimitUnit * breakdownItemsLimitRate;
  const shouldHide = isSubRow && (breakDownItemIndex + 1) > showingCount;
  if(shouldHide) return null;
  const isLastSubRow = (breakDownItemIndex + 1) === showingCount;
  const leftSubItemsCount = subItemsCount - showingCount;
  const pathParams = {
    period,
    targetMonth,
    subItems: [
      // TODO
      { dimension: freeeCollectionName, itemNames: freeeSubjects.map(_ => _.name), },
      ...(
        isSubRow ? [{
          dimension,
          itemNames: [subItemName],
        }] : []
      ),
    ],
    singleMonth: aggregationType === 'single' ? '1' : '0',
  };

  return (
    <tr key={itemKey + key} className={classnames({ 'sub-row': isSubRow })}>
      <th className="border-right has-hovered-contents" style={{ width: leftColumnWidth, textIndent: `${hierarchyLevel - 1}rem`, fontWeight: isCategory ? 700 : 400 }} colSpan={5 - hierarchyLevel}>
        <div className="d-flex justify-content-between align-items-start">
          <div>
            {
              dimension !== 'none' && !isSubRow && !isCategory && !isCustom && (
                <Button size="sm" className="small px-0 mr-1" color="link" onClick={toggleSubRows}>
                  <span className={classnames('fas cursor-pointer', { 'fa-plus': !showsSubRows, 'fa-minus': showsSubRows })} />
                </Button>
              )
            }
            <AccountItemDisplay accountItemName={rowName} accountItemSetting={accountItemSetting} iconStyles={{ textIndent: 0 }} isCustom={isCustom} />
            {isCustom && isManual && <SyncCustomAccountItemManualValuesButton color="link" size="sm" company={company} />}
            {
              isLastSubRow && leftSubItemsCount > 0 && (
                <div className="mt-2">
                  <Button size="sm" onClick={showMoreSubRows.bind(null, itemKey)}>
                    もっと見る (残り{leftSubItemsCount}件)
                  </Button>
                </div>
              )
            }
          </div>
        </div>
        <div style={{ textIndent: 0 }}>
          {
            !isPublic && (
              <HoveredCommenter
                ref={(el) => setHoveredCommenters(commenteeKeys, el)}
                company={company}
                companyId={companyId}
                currentUser={user}
                commenteeKey={commenteeKeys[0]}
                queryKey={budgetCollectionName}
                values={{ yearMonth: targetMonth.toString(), }}
                comments={commenteeKeys.flatMap(_ => commentsGroupedByCommenteeKey[_] || [])}
                users={members}
                about={['予実', targetMonth, itemName, subItemName].filter(_ => _).join(' ')}
                unopenable={isMultiSubjectsSelected}
                onClick={_ => isMultiSubjectsSelected && modalRef.current?.open()}
              />
            )
          }
          <HoveredNoter
            companyId={companyId || company?.id}
            noteKey={noteKeys[0]}
            pageType="CompanyBudgets"
            queryKey={budgetCollectionName}
            values={{ yearMonth: targetMonth.toString() }}
            note={noteKeys.map(_ => notesByKey[_]).find(_ => _)}
            writable={canWriteNote(user, role)}
            isPublic={isPublic}
            onSubmitted={updateVersion}
            onDeleted={updateVersion}
            unopenable={isMultiSubjectsSelected}
            onClick={_ => isMultiSubjectsSelected && modalRef.current?.open()}
          />
        </div>
      {
        isMultiSubjectsSelected && (
          <BudgetCellModal
            ref={_ => {
              modalRef.current = _;
              setHoveredCommenters(commenteeKeys, _);
            }}
            {...{
              ...props,
              ...pick(row, ['itemName', 'subItemName']),
              ...column,
              column,
              metricsForFilter,
            }}
            Cell={_ => <MonthlyColumn {...{ ...pageProps, ...props, ...row, ...column, column, ..._, metricsForFilter, }} />}
          />
        )
      }
      </th>
      <td className={classnames('text-right')} style={{ width: COLUMN_WIDTH }}>
        <div style={{ lineHeight: AMOUNT_HEIGHT }}>
          <span>{isFiniteNumber(budgetAmount) ? numeral(budgetAmount).format(displayExpression) : '-'}</span>
        </div>
      </td>
      {
        isComparing && (
          <td className={classnames('text-right')} style={{ width: COLUMN_WIDTH }}>
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              <span>{isFiniteNumber(comparedBudgetAmount) ? numeral(comparedBudgetAmount).format(displayExpression) : '-'}</span>
            </div>
          </td>
        )
      }
      {
        isComparing && (
          <td className={classnames('text-right')} style={{ width: COLUMN_WIDTH }}>
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              <span>{isFiniteNumber(diffBetweenBudgets) ? numeral(diffBetweenBudgets).format('+' + displayExpression) : '-'}</span>
            </div>
          </td>
        )
      }
      <td className={classnames('text-right')} style={{ width: COLUMN_WIDTH }}>
        <div style={{ lineHeight: AMOUNT_HEIGHT }}>
          {
            (isCategory || isCustom) ? (
              <span>{isFiniteNumber(amount) ? numeral(amount).format(displayExpression) : '-'}</span>
            ) : (
              isPublic ? (
                <ModalButton color="link" className="p-0 text-primary cursor-pointer" title={`freee${budgetSubjectTypes[budgetSubjectType]?.label}別`} content={_ => <PublicBudgetCellModalContent {...{ ...props, ...column, }} />}>
                  {isFiniteNumber(amount) ? numeral(amount).format(displayExpression) : '-'}
                </ModalButton>
              ) : (
                <Link to={encodeURI(`/companies/${companyId}/accountItems/${itemName}?${qs.stringify(pathParams)}`)}>
                  {isFiniteNumber(amount) ? numeral(amount).format(displayExpression) : '-'}
                </Link>
              )
            )
          }
        </div>
      </td>
      <td className={classnames('text-right')} style={{ width: COLUMN_WIDTH }}>
        <div style={{ lineHeight: AMOUNT_HEIGHT }}>
          {isFiniteNumber(budgetDiff) ? numeral(budgetDiff).format('+' + displayExpression) : '-'}
        </div>
      </td>
      <td className={classnames('text-right')} style={{ width: COLUMN_WIDTH }}>
        <div style={{ lineHeight: AMOUNT_HEIGHT }}>
          {isFiniteNumber(achievementRate) ? numeral(achievementRate).format('0,0.0%') : '-'}
        </div>
      </td>
      <td className={classnames('text-right')} style={{ width: COLUMN_WIDTH }}>
        <div style={{ lineHeight: AMOUNT_HEIGHT }}>
          <span>{isFiniteNumber(estimatedAmount) ? numeral(estimatedAmount).format(displayExpression) : '-'}</span>
        </div>
      </td>
      <td className={classnames('text-right')} style={{ width: COLUMN_WIDTH }}>
        <div style={{ lineHeight: AMOUNT_HEIGHT }}>
          <span>{isFiniteNumber(prevYearAmount) ? numeral(prevYearAmount).format(displayExpression) : '-'}</span>
        </div>
      </td>
      <td className={classnames('text-right')} style={{ width: COLUMN_WIDTH }}>
        <div style={{ lineHeight: AMOUNT_HEIGHT }}>
          {isFiniteNumber(prevYearDiff) ? numeral(prevYearDiff).format('+' + displayExpression) : '-'}
        </div>
      </td>
      <td className={classnames('text-right')} style={{ width: COLUMN_WIDTH }}>
        <div style={{ lineHeight: AMOUNT_HEIGHT }}>
          {isFiniteNumber(prevYearDiffRate) ? numeral(prevYearDiffRate).format('+0,0.0%') : '-'}
        </div>
      </td>
    </tr>
  );
}

