import React, { Component } from 'react';
import { Input, FormGroup, Label } from 'reactstrap';
import { get, } from 'lodash';
import Select from 'react-select';
import { format as formatDate } from 'date-fns';

import AdminPage from '../hocs/AdminPage';
import UserDisplay from '../UserDisplay';
import ProgressButton from '../ProgressButton';
import firebase, { functions, } from '../../firebase';
import { userTypes, userStatuses, }  from '../../shared/config';

const db = firebase.firestore();
const updateUser = functions.httpsCallable('updateUser');
const usersRef = db.collection('users');
const companiesRef = db.collection('companies');
const { entries } = Object;
const userTypeOptions = entries(userTypes).map(([k, v]) => ({ label: v, value: k }));
const statusOptions = entries(userStatuses).map(([k, v]) => ({ label: v, value: k }));

export default AdminPage(class CompanyMembers extends Component {
  constructor() {
    super();
    this.state = {};
  } componentDidMount() {
    this.listenUsers();
    this.listenCompanies();
  }
  listenUsers() {
    usersRef
      .orderBy('displayName')
      .onSnapshot((snapshot) => {
        this.setState({ users: snapshot.docs.map(_ => _.data()) });
      });
  }
  listenCompanies() {
    companiesRef
      .onSnapshot((snapshot) => {
        const companies = snapshot.docs.map(_ => ({ id: _.id, ..._.data() }));
        this.setState({ companies });
      });
  }
  onCheck = (id, name, { target: { checked } }) => {
    usersRef.doc(id).update({ [name]: checked });
  }
  onSelect = (id, name, { value }) => {
    usersRef.doc(id).update({ [name]: value });
  }
  render() {
    const { users, companies = [], } = this.state;
    return (
      <div className="company-members">
        <div className="container-fluid py-5">
          <div className="d-flex justify-content-center mb-2">
            <h3>ユーザー管理</h3>
          </div>
          <table className="table table-hover mt-5 table-bordered">
            <thead class="thead-light">
              <tr>
                <th>ユーザー名</th>
                <th>Email</th>
                <th>Admin</th>
                <th>Auditor/Auditee</th>
                <th style={{ minWidth: 150 }}>状態</th>
                <th>オーナー事業所数</th>
                <th>登録日時</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                (users || []).map((user) => {
                  const { email, uid, admin = false, type = 'auditee', status = 'active', createdAt, } = user;
                  const ownerCompanies = companies.filter(_ => get(user, `companies.${_.id}.role`) === 'owner');
                  const onClickVerifyEmail = async () => {
                    if(!window.confirm('本当にメール認証済みにしますか？')) return;

                    await updateUser({ uid, emailVerified: true, });
                  };
                  return (
                    <tr key={uid} id={uid}>
                      <td>
                        <UserDisplay user={user} />
                      </td>
                      <td>
                        {email}
                      </td>
                      <td>
                        <FormGroup check>
                          <Label check>
                            <Input type="checkbox" onChange={this.onCheck.bind(this, uid, 'admin')} checked={admin} disabled={user.uid === uid} />
                            Admin
                          </Label>
                        </FormGroup>
                      </td>
                      <td>
                        <Select
                          options={userTypeOptions}
                          value={userTypeOptions.find(_ => _.value === type)}
                          onChange={this.onSelect.bind(this, uid, 'type')}
                        />
                      </td>
                      <td>
                        <Select
                          options={statusOptions}
                          value={statusOptions.find(_ => _.value === status)}
                          onChange={this.onSelect.bind(this, uid, 'status')}
                        />
                      </td>
                      <td className="text-right">
                        {ownerCompanies.length}
                      </td>
                      <td>
                        {createdAt != null && formatDate(createdAt.toDate(), 'YYYY/MM/DD HH:mm')}
                      </td>
                      <td>
                        <ProgressButton className="ml-1" process={onClickVerifyEmail}>
                          メール認証済みにする
                        </ProgressButton>
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
});
