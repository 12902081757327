const { chunk, pick } = require('lodash');
const numeral = require('numeral');

const docToData = (doc) => {
  return { id: doc.id, ...doc.data(), ref: doc.ref, };
};

const getDocumentData = async (ref) => {
  return docToData(await ref.get());
};

const getCollectionData = async (ref) => {
  return (await ref.get()).docs.map(docToData);
};

const batch = async (db, data, f, chunkSize = 500) => {
  return await chunk(data, chunkSize).reduce(async (x, data, i) => {
    const prevs = await x;

    const batch = db.batch();
    const refs = data.map((_, i2) => f(batch, _, i * chunkSize + i2));
    await batch.commit();
    return [...prevs, ...refs];
  }, Promise.resolve([]));
};

const getAllCollectionDataByChunk = async (_ref, limit = 10000, lastDoc = null, prevs = []) => {
  const ref = lastDoc != null ? _ref.startAfter(lastDoc) : _ref;
  const { docs } = await ref.limit(limit).get();
  const [nextLastDoc] = docs.slice(-1);
  const all = [...prevs, ...docs.map(docToData)];
  console.info('current: ', numeral(all.length).format());
  return nextLastDoc == null ? all : await getAllCollectionDataByChunk(ref, limit, nextLastDoc, all);
};

module.exports = {
  docToData,
  getDocumentData,
  getCollectionData,
  getAllCollectionDataByChunk,
  batch,
};
