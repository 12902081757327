import React, { useState } from 'react';
import { omit, chunk } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { Button, Input } from 'reactstrap';
import { format as formatDate, } from 'date-fns';

import { trialCsvFileToData, segmentedTrialCsvFileToData, } from '../utils';
import { batch, } from '../shared/firebase';
import { budgetSubjectTypes, } from '../shared/config';
import firebase from '../firebase';

const db = firebase.firestore();
const companiesRef = db.collection('companies');

export default function TrialImportButton(props) {
  const { isSegmented = false, budgetSubjectType, freeeSubjects, companyId, closingDate, dimension, documentType, fetchWalletables = _ => [], ...extraProps } = props;
  const { trialsCollectionName, freeeCollectionName, } = budgetSubjectTypes[budgetSubjectType] || {};
  const fullTrialsCollectionName = trialsCollectionName + ({ pl: '', cr: 'Cr' })[documentType];
  const conclusiveTrialsCollectionName = isSegmented ? fullTrialsCollectionName : 'trials';
  const [isProccessing, setIsProccessing] = useState(false);

  const onSelectFiles = async ({ target, target: { files: [file] } }) => {
    if(!file) return;
    setIsProccessing(true);
    await new Promise(_ => setTimeout(_, 300));
    try {
      const walletables = await fetchWalletables();
      const walletableNames = walletables.map(_ => _.name);
      const nonDimensionAccoutNames = [...walletableNames, '仮払消費税', '仮受消費税'];
      // const items = isSegmented ? await segmentedTrialCsvFileToData(documentType, dimension, file, freeeCollectionName, freeeSubjects) : await trialCsvFileToData(documentType, dimension, file);
      const items = await trialCsvFileToData(documentType, dimension, file);
      console.log(1, items);
      await (async () => {
        let trialsRef = companiesRef.doc(companyId).collection(conclusiveTrialsCollectionName).where('dimension', '==', 'none').where('closingDate', '==', formatDate(closingDate, 'YYYY-MM-DD'));
        if(!isSegmented) {
          trialsRef = trialsRef.where('type', '==', documentType);
        }
        const { docs: existingTrialDocs } = await trialsRef.get();
        await batch(db, existingTrialDocs, (b, _) => b.delete(_.ref));
        await items.reduce(async (x, item) => {
          await x;

          const { account_item_name: accountItemName, itemKey } = item;
          // const id = isSegmented ? `${formatDate(closingDate, 'YYYYMM')}__${dimension}__${itemKey.replace(/\//g, '_')}` : `${formatDate(closingDate, 'YYYYMM')}__${documentType}__${dimension}__${itemKey.replace(/\//g, '_')}`;
          const id = `${formatDate(closingDate, 'YYYYMM')}__${documentType}__${itemKey.replace(/\//g, '_')}`;
          await companiesRef.doc(companyId).collection(conclusiveTrialsCollectionName).doc(id).set({
            ...omit(item, dimension),
            closingDate: formatDate(closingDate, 'YYYY-MM-DD'),
            dimension: 'none',
            ...(!isSegmented && { type: documentType, }),
          });
        }, Promise.resolve());
      })();
      await (async () => {
        const collectionName = 'trialSubItemChunks__' + dimension;
        const trialSubItemsRef = companiesRef.doc(companyId).collection(collectionName).where('type', '==', documentType).where('closingDate', '==', formatDate(closingDate, 'YYYY-MM-DD'));
        const { docs: existings } = await trialSubItemsRef.get();
        await batch(db, existings, (b, _) => b.delete(_.ref));
        const subItemChunks = items.filter(_ => !nonDimensionAccoutNames.includes(_.account_item_name)).map((item) => {
          const { [dimension]: subItems = [], itemKey, } = item;
          const itemId = `${formatDate(closingDate, 'YYYYMM')}__${documentType}__${itemKey.replace(/\//g, '_')}`;
          return {
            ...omit(item, dimension),
            itemId,
            subItems: subItems.filter(_ => ['opening_balance', 'debit_amount', 'credit_amount', 'closing_balance'].some(k => Math.abs(_[k]) > 0)),
          };
        }).flatMap(_ => chunk(_.subItems, 1000).map((subItems, index) => ({ ..._, subItems, index, })));
        await batch(db, subItemChunks, (batch, subItemChunk) => {
          const id = subItemChunk.itemId + '__' + subItemChunk.index;
          batch.set(companiesRef.doc(companyId).collection(collectionName).doc(id), {
            ...omit(subItemChunk, 'index'),
            closingDate: formatDate(closingDate, 'YYYY-MM-DD'),
            type: documentType,
          });
        });
      })();
      toast.success('インポートしました');
    } catch(e) {
      console.error(e);
      toast.error('インポート失敗しました');
    }
    setIsProccessing(false);
    target.value = '';
  };

  return (
    <Button size="sm" className="small ml-1 px-0" color="link" disabled={isProccessing} {...extraProps}>
      <label className="m-0 cursor-pointer">
        <span className={classnames('fas cursor-pointer', { 'fa-upload': !isProccessing, 'fa-spin fa-spinner': isProccessing})} />
        <Input type="file" className="d-none" onChange={onSelectFiles} accept="text/*" />
      </label>
    </Button>
  );
};
