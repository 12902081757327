import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import Select from 'react-select';

export default class FreeeCompaniesModal extends Component {
  constructor() {
    super();
    this.state = {};
  }
  onSubmit = () => {
    const { onSubmit } = this.props;
    const { selectedFreeeCompany } = this.state;
    onSubmit(selectedFreeeCompany);
    this.setState({ selectedFreeeCompany: null });
  }
  onChange = ({ value }) => {
    this.setState({ selectedFreeeCompany: value });
  }
  render() {
    const { isOpen, onClickClose, freeeCompanies } = this.props;
    const { selectedFreeeCompany } = this.state;
    const options = (freeeCompanies || []).map(_ => ({ label: _.display_name, value: _ }));
    return (
      <Modal isOpen={isOpen} className="carrying-amounts-importion-modal">
        <ModalHeader>
          追加する事業所の選択
        </ModalHeader>
        <ModalBody>
          <Alert color="info">
            すでに登録されている事業所は選択肢に表示されません。
          </Alert>
          <Select
            value={options.find(_ => _.value.id === (selectedFreeeCompany && selectedFreeeCompany.id))}
            onChange={this.onChange}
            options={options}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={onClickClose}>閉じる</Button>
          <Button color="primary" onClick={this.onSubmit} disabled={!selectedFreeeCompany}>決定</Button>
        </ModalFooter>
      </Modal>
    );
  }
}
