import React, { forwardRef, useImperativeHandle, useEffect, } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, } from 'reactstrap';
import { isEmpty, mapValues } from 'lodash';
import { format as formatDate, } from 'date-fns';
import { useToggle, } from 'react-use';

import useFormState from '../hooks/useFormState';
import Field from '../Field';

const { keys, entries } = Object;
const AMOUNT_HEIGHT = '24px';

export default forwardRef(function BudgetCellModal(props, ref) {
  const { isComparing, metrics, metricsForFilter, budgetSubjectRows, budgetSubjectsSummaryRow, itemName, subItemName, column, Cell, } = props;
  const { closingDate, yearMonth, } = column;
  const [isOpen, toggle] = useToggle(false);
  const title = [itemName, subItemName, closingDate && formatDate(closingDate, 'YYYY/MM')].filter(_ => _).join(' ');
  useImperativeHandle(ref, () => {
    return {
      open: _ => toggle(true),
      close: _ => toggle(false),
    };
  }, []);

  return (
    <Modal isOpen={isOpen} style={{ minWidth: '90vw', }}>
      <ModalHeader>
        {title}
      </ModalHeader>
      <ModalBody>
        <ModalBody>
          <div className="d-flex justify-content-center position-relative" style={{ zIndex: 0 }}>
            <table className="table sticky-table table-bordered" style={{ width: 'auto', height: 'auto', }}>
              <thead className="text-center">
                <tr>
                  <th style={{ width: 150, height: 70, }}>
                    &nbsp;
                  </th>
                  {
                    [...(budgetSubjectRows || []), budgetSubjectsSummaryRow].map(({ key, label, budgetSubject }) => {
                      return (
                        <th key={key} style={{ width: 150, height: 70, }} className="overflow-auto">
                          {label || budgetSubject?.name}
                        </th>
                      );
                    })
                  }
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style={{ width: 150, }}>
                    <div className="text-nowrap">
                      {
                        keys(metrics).filter(_ => !(!isComparing && ['comparedBudgetAmount', 'diffBetweenBudgets'].includes(_))).map((metric) => {
                          const { label } = metrics[metric];
                          return (
                            <div key={metric} style={{ lineHeight: AMOUNT_HEIGHT }}>
                              {label}
                            </div>
                          );
                        })
                      }
                    </div>
                  </th>
                  {
                    [...(budgetSubjectRows || []), budgetSubjectsSummaryRow].map((cell, index) => {
                      return (
                        <Cell key={cell.key} {...{ ...cell, metricsForFilter: keys(metrics), column: cell, hoveredContentHeight: 180, commenterRef: _ => _, }} />
                      );
                    })
                  }
                </tr>
              </tbody>
            </table>
          </div>
        </ModalBody>
      </ModalBody>
      <ModalFooter>
        <Button className="cancel" color="secondary" onClick={_ => toggle(false)}>閉じる</Button>
      </ModalFooter>
    </Modal>
  );
});
