import React from 'react';
import { orderBy, isEmpty, keyBy, } from 'lodash';
import { format as formatDate, endOfMonth, startOfMonth, } from 'date-fns';
import numeral from 'numeral';

import firebase from '../../firebase';
import { fiscalYearOfPeriod, } from '../../utils';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import CompanyJournalsCheckTabs from '../CompanyJournalsCheckTabs';
import HelpLink from '../HelpLink';
import CompanyPage from '../hocs/CompanyPage';

const db = firebase.firestore();
const companiesRef = db.collection('companies');
const { entries } = Object;

export default CompanyPage(CompanyJournalsCheckCreators);

function CompanyJournalsCheckCreators(props) {
  const { period, targetMonth, company: { fiscalYears, }, match: { params: { companyId } }, location } = props;
  const { start_date: startDate, } = fiscalYearOfPeriod(period, fiscalYears);
  const freeeUsers = useCollectionSubscription(companiesRef.doc(companyId).collection('freeeUsers'), [companyId]);
  const freeeUsersByName = keyBy(freeeUsers, _ => _.display_name || _.email);
  const journalMetas = useCollectionSubscription(
    companiesRef
      .doc(companyId)
      .collection('journalMetas')
      .where('closingDate', '>=', formatDate(startOfMonth(targetMonth + '01'), 'YYYY-MM-DD'))
      .where('closingDate', '<=', formatDate(endOfMonth(targetMonth + '01'), 'YYYY-MM-DD')),
    [companyId, period, targetMonth]
  );

  return (
    <div className="company-journals-check-creators">
      <div className="container py-5">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="仕訳チェックを行う" />
        </div>
        <div className="d-flex justify-content-center mb-2">
          <h3>仕訳チェック</h3>
        </div>
        <div className="mb-3">
          <CompanyJournalsCheckTabs activeTab="creatorsAndApprovers" companyId={companyId} search={location.search} />
        </div>
        {
          [
            { label: '作成者', countsName: 'countByCreator', userIdName: 'create_user', },
            { label: '承認者', countsName: 'countByApprover', userIdName: 'approve_user', },
          ].map(({ label, countsName, userIdName, }) => {
            const counts = journalMetas.reduce((x, y) => {
              return {
                ...x,
                ...entries(y[countsName] || {}).reduce((a, [name, count]) => ({ ...a, [name]: count + (x[name] || 0) }), {}),
              };
            }, {});
            return (
              <div className="mb-5" key={label}>
                <h5>{label}</h5>
                {
                  !isEmpty(counts) ? (
                    <table className="table table-hover">
                      <thead className="text-center thead-light" style={{ lineHeight: '20px' }}>
                        <tr>
                          <th>{label}</th>
                          <th>件数</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          orderBy(entries(counts), '[1]', 'desc').map(([name, count]) => {
                            const freeeUser = freeeUsersByName[name];
                            const url = `https://secure.freee.co.jp/reports/journals?end_date=${formatDate(endOfMonth(targetMonth + '01'), 'YYYY-MM-DD')}&start_date=${formatDate(startOfMonth(targetMonth + '01'), 'YYYY-MM-DD')}&${name === 'unknown' ? 'with_approval=in_progress' : `${userIdName}=${freeeUser && freeeUser.id}`}`;
                            return (
                              <tr key={name}>
                                <td>
                                  {name === 'unknown' ? '(承認者なし)' : name}
                                </td>
                                <td>
                                  {numeral(count).format(0,0)}
                                </td>
                                <td className="text-nowrap text-right">
                                  <a className="btn btn-secondary mr-2" href={url} target="_blank">
                                    <span className="fas fa-external-link-alt mr-1" />
                                    freeeへ
                                  </a>
                                </td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                  ) : (
                    <div>
                      データがありません
                    </div>
                  )
                }
              </div>
            );
          })
        }
      </div>
    </div>
  );
}
