import React, { useState, useMemo, } from 'react';
import { Button,  } from 'reactstrap';
import { isEmpty, last, pick, get, omit, keyBy, } from 'lodash';
import { toast } from 'react-toastify';
import numeral from 'numeral';
import classnames from 'classnames';

import firebase from '../../firebase';
import HelpLink from '../HelpLink';
import { directions, } from '../../shared/config';
import { ruleTypes, valueTypes, subjects, operators, journalRuleTypes, } from '../../shared/models/customRule';
import { mapKeysToJa, mapKeysFromJa, } from '../../shared/texts';
import useQueryParams from '../hooks/useQueryParams';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useScrollFocusByQuery from '../hooks/useScrollFocusByQuery';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ImportButton from '../ImportButton';
import ExportButton from '../ExportButton';
import CustomRuleFormModal from '../modals/CustomRuleFormModal';
import CompanyPage from '../hocs/CompanyPage';

const db = firebase.firestore();
const companiesRef = db.collection('companies');
const emptyOption = { id: '未選択', name: '未選択' };

export default CompanyPage(CompanyCustomJournalsCheckRules);

function CompanyCustomJournalsCheckRules(props) {
  const { company, sortedAccountItems: accountItems = [], taxes = [], match: { params: { companyId } } } = props;
  const sections = props.sections.filter(_ => _.id !== 0);
  const partnerChunks = useCollectionSubscription(companiesRef.doc(companyId).collection('partnerChunks'), [companyId]);
  const partners = useMemo(_ => partnerChunks.flatMap(_ => _.data), [partnerChunks]);
  const segment1s = useCollectionSubscription(companiesRef.doc(companyId).collection('segment1s'), [companyId]);
  const segment2s = useCollectionSubscription(companiesRef.doc(companyId).collection('segment2s'), [companyId]);
  const segment3s = useCollectionSubscription(companiesRef.doc(companyId).collection('segment3s'), [companyId]);
  const freeeItems = useCollectionSubscription(companiesRef.doc(companyId).collection('freeeItems'), [companyId]);
  const taxesByName = keyBy(taxes, 'name_ja');
  const partnersByName = keyBy([emptyOption, ...partners], 'name');
  const freeeItemsByName = keyBy([emptyOption, ...freeeItems], 'name');
  const sectionsByName = keyBy([emptyOption, ...sections], 'name');
  const segment1sByName = keyBy([emptyOption, ...segment1s], 'name');
  const segment2sByName = keyBy([emptyOption, ...segment2s], 'name');
  const segment3sByName = keyBy([emptyOption, ...segment3s], 'name');
  const taxesById = keyBy(taxes, 'id');
  const partnersById = keyBy([emptyOption, ...partners], 'id');
  const freeeItemsById = keyBy([emptyOption, ...freeeItems], 'id');
  const sectionsById = keyBy([emptyOption, ...sections], 'id');
  const segment1sById = keyBy([emptyOption, ...segment1s], 'id');
  const segment2sById = keyBy([emptyOption, ...segment2s], 'id');
  const segment3sById = keyBy([emptyOption, ...segment3s], 'id');
  const [showsModal, setShowsModal] = useState(false);
  const customJournalsCheckRules = useCollectionSubscription(companiesRef.doc(companyId).collection('customJournalsCheckRules').orderBy('createdAt'), [companyId]);
  const customJournalsCheckRulesById = keyBy(customJournalsCheckRules, 'id');
  const rowsForExport = () => {
    const rows = customJournalsCheckRules.flatMap((customJournalsCheckRule) => {
      const { journalRules, balanceRules, noneDimensionBalanceRules, ruleType = 'journal', } = customJournalsCheckRule;
      return ({
        journal: () => {
          return journalRules.flatMap((journalRule, i) => {
            const { journalRuleType = 'some', rules } = journalRule;
            return rules.map((rule, i2) => {
              const { subject, operator, value } = rule;
              return mapKeysToJa({
                ...(
                  i === 0 && i2 === 0 && {
                    ...pick(customJournalsCheckRule, ['id', 'ruleType', 'name', 'message', ]),
                  }
                ),
                ...(
                  i2 === 0 && {
                    journalRuleType,
                  }
                ),
                subject,
                operator,
                //value: valueTypes[subjects[subject].valueType].formatForCsv(value, { taxesByName, partnersByName, ),
                value: subjects[subject].valueToLabel(value, { taxesById, partnersById, freeeItemsById, sectionsById, segment1sById, segment2sById, segment3sById, }),
              });
            });
          });
        },
        balance: () => {
          return balanceRules.map((rule, i) => {
            const { subject, operator, value } = rule;
            return mapKeysToJa({
              ...(
                i === 0 && {
                  ...pick(customJournalsCheckRule, ['id', 'ruleType', 'name', 'message', ]),
                }
              ),
              journalRuleType: null,
              subject,
              operator,
              value: subjects[subject].valueToLabel(value, { taxesById, partnersById, freeeItemsById, sectionsById, segment1sById, segment2sById, segment3sById, }),
            });
          });
        },
        noneDimensionBalance: () => {
          return noneDimensionBalanceRules.map((rule, i) => {
            const { subject, operator, value } = rule;
            return mapKeysToJa({
              ...(
                i === 0 && {
                  ...pick(customJournalsCheckRule, ['id', 'ruleType', 'name', 'message', ]),
                }
              ),
              journalRuleType: null,
              subject,
              operator,
              value: subjects[subject].valueToLabel(value, { taxesById, partnersById, freeeItemsById, sectionsById, segment1sById, segment2sById, segment3sById, }),
            });
          });
        },
      })[ruleType]();
    });
    return rows;
  };
  const processRows = (rows) => {
    return rows.map(_ => mapKeysFromJa(_, { 種別: 'ruleType', })).filter(_ => _.subject).reduce((x, y) => {
      return isEmpty(y.id) ? [...x.slice(0, x.length - 1), [...last(x), y]] : [...x, [y]];
    }, []);
  };
  const processRow = (batch, group, i) => {
    const [{ id, ruleType, name, message, }] = group;
    const ref = company.ref.collection('customJournalsCheckRules').doc(...(id !== '*' ? [id] : []));
    const exists = customJournalsCheckRulesById[id] != null;
    const data = {
      ruleType,
      name,
      message,
      ...({
        journal: () => {
          const ruleGroups = group.reduce((x, y) => {
            return isEmpty(y.journalRuleType) ? [...x.slice(0, x.length - 1), [...last(x), y]] : [...x, [y]];
          }, []);
          return {
            journalRules: ruleGroups.map((ruleGroup) => {
              const { journalRuleType, } = ruleGroup;
              return {
                rules: ruleGroup.map(_ => ({ ...pick(_, ['subject', 'operator']), value: subjects[_.subject].labelToValue(_.value, { taxesByName, partnersByName, freeeItemsByName, sectionsByName, segment1sByName, segment2sByName, segment3sByName, }) })),
              };
            }),
          };
        },
        balance: () => {
          return {
            balanceRules: group.map(_ => ({ ...pick(_, ['subject', 'operator']), value: subjects[_.subject].labelToValue(_.value, { taxesByName, partnersByName, freeeItemsByName, sectionsByName, segment1sByName, segment2sByName, segment3sByName, }) })),
          };
        },
        noneDimensionBalance: () => {
          return {
            noneDimensionBalanceRules: group.map(_ => ({ ...pick(_, ['subject', 'operator']), value: subjects[_.subject].labelToValue(_.value, { taxesByName, partnersByName, freeeItemsByName, sectionsByName, segment1sByName, segment2sByName, segment3sByName, }) })),
          };
        },
      })[ruleType](),
      ...(
        !exists && { createdAt: new Date(), }
      ),
    };
    batch.set(ref, data, { merge: true });
  };

  return (
    <div className="company-custom-account-items">
      <div className="container py-5">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="カスタムルールを設定する" />
        </div>
        <div className="d-flex justify-content-center mb-2">
          <h3>カスタムルール</h3>
        </div>
        <div className="d-flex justify-content-end gap-1">
          <ImportButton processRows={processRows} processRow={processRow} />
          <ExportButton outline fileName="カスタムルール.csv" rows={rowsForExport} />
          <AddButton itemRef={company.ref.collection('customJournalsCheckRules').doc()} FormModal={CustomRuleFormModal} formProps={{ title: 'カスタムルール 追加', company, accountItems, taxes, partners, freeeItems, sections, segment1s, segment2s, segment3s, }} />
        </div>
        {
          customJournalsCheckRules.length > 0 ? (
            <table className="table table-hover mt-5">
              <thead>
                <tr>
                  <th style={{ minWidth: 200 }}>種別</th>
                  <th style={{ minWidth: 200 }}>ルール名</th>
                  <th style={{ minWidth: 200 }}>メッセージ</th>
                  <th style={{ minWidth: 200 }}>ルール</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  customJournalsCheckRules.map((customJournalsCheckRule) => {
                    return (
                      <Row key={customJournalsCheckRule.id} {...{ customJournalsCheckRule, company, taxesById, partnersById, freeeItemsById, segment1sById, segment2sById, segment3sById, accountItems, taxes, partners, freeeItems, sections, sectionsById, segment1s, segment2s, segment3s, }} />
                    );
                  })
                }
              </tbody>
            </table>
          ) : (
            <span>カスタムルールは未登録です</span>
          )
        }
      </div>
    </div>
  );
}
function Row(props) {
  const { customJournalsCheckRule, company, taxesById, partnersById, freeeItemsById, segment1sById, segment2sById, segment3sById, accountItems, taxes, partners, freeeItems, sections, sectionsById, segment1s, segment2s, segment3s, } = props;
  const { id, ruleType = 'journal', name, message, journalRules = [], balanceRules = [], noneDimensionBalanceRules = [], } = customJournalsCheckRule;
  const itemRef = company.ref.collection('customJournalsCheckRules').doc(id);
  const queryParams = useQueryParams();
  const { elm, focusColor, } = useScrollFocusByQuery(_ => _.queryParams.customJournalsCheckRuleId === id);

  return (
    <tr ref={elm} style={{ backgroundColor: focusColor, }}>
      <td>
        {ruleTypes[ruleType]?.label}
      </td>
      <td>
        {name}
      </td>
      <td>
        {message}
      </td>
      <td>
        {
          ({
            journal: (
              journalRules.map(({ journalRuleType = 'some', rules }, i) => {
                return (
                  <div key={i} className="border rounded small p-2 mb-1">
                    <div className="small badge mb-1">
                      {journalRuleTypes[journalRuleType].label}
                    </div>
                    <ul className="m-0">
                      {
                        rules.map((rule, i) => {
                          const { subject, operator, value } = rule;
                          const displayValue = (({
                            direction: () => value.map(_ => directions[_]).join(','),
                            accountItem: () => value.join(','),
                            amount: () => numeral(value).format('0,0'),
                            tax: () => value.map(_ => get(taxesById, [_, 'name_ja'], '')).join(','),
                            route: () => value.join(','),
                            partner: () => value.map(_ => get(partnersById, [_, 'name'], '')).join(','),
                            item: () => value.map(_ => get(freeeItemsById, [_, 'name'], '')).join(','),
                            section: () => value.map(_ => get(sectionsById, [_, 'name'], '')).join(','),
                            segment1: () => value.map(_ => get(segment1sById, [_, 'name'], '')).join(','),
                            segment2: () => value.map(_ => get(segment2sById, [_, 'name'], '')).join(','),
                            segment3: () => value.map(_ => get(segment3sById, [_, 'name'], '')).join(','),
                            summary: () => value.join(','),
                          })[subject] || (_ => null))();
                          return (
                            <li key={i} className="small">
                              <span>{subjects[subject].label}</span>
                              <span className="ml-1">"{displayValue}"</span>
                              <span className="ml-1">{operators[operator].label}</span>
                            </li>
                          );
                        })
                      }
                    </ul>
                  </div>
                );
              })
            ),
            balance: (
              <ul className="m-0">
                {
                  balanceRules.map((rule, i) => {
                    const { subject, operator, value } = rule;
                    const displayValue = (({
                      creditBalance: () => numeral(value).format('0,0'),
                      creditBalanceRate: () => numeral(value).format('0,0.00%'),
                    })[subject] || (_ => null))();
                    return (
                      <li key={i} className="small">
                        <span>{subjects[subject].label}</span>
                        <span className="ml-1">"{displayValue}"</span>
                        <span className="ml-1">{operators[operator].label}</span>
                      </li>
                    );
                  })
                }
              </ul>
            ),
            noneDimensionBalance: (
              <ul className="m-0">
                {
                  noneDimensionBalanceRules.map((rule, i) => {
                    const { subject, operator, value } = rule;
                    const displayValue = (({
                      balanceAccountItem: () => value.join(','),
                      balanceAmount: () => numeral(value).format('0,0'),
                      balanceDimensionAmount: () => numeral(value).format('0,0'),
                      partner: () => value.map(_ => get(partnersById, [_, 'name'], '')).join(','),
                      item: () => value.map(_ => get(freeeItemsById, [_, 'name'], '')).join(','),
                      section: () => value.map(_ => get(sectionsById, [_, 'name'], '')).join(','),
                      segment1: () => value.map(_ => get(segment1sById, [_, 'name'], '')).join(','),
                      segment2: () => value.map(_ => get(segment2sById, [_, 'name'], '')).join(','),
                      segment3: () => value.map(_ => get(segment3sById, [_, 'name'], '')).join(','),
                    })[subject] || (_ => null))();
                    return (
                      <li key={i} className="small">
                        <span>{subjects[subject].label}</span>
                        <span className="ml-1">"{displayValue}"</span>
                        <span className="ml-1">{operators[operator].label}</span>
                      </li>
                    );
                  })
                }
              </ul>
            ),
          })[ruleType]
        }
      </td>
      <td className="text-right text-nowrap">
        <EditButton itemRef={itemRef} FormModal={CustomRuleFormModal} formProps={{ company, accountItems, taxes, partners, freeeItems, sections, segment1s, segment2s, segment3s, }} />
        <DeleteButton itemRef={itemRef} className="ml-2" />
      </td>
    </tr>
  );
}

CompanyCustomJournalsCheckRules.MonthSelectDisabled = true;
