import React, { useEffect, useState, Component, Fragment, } from 'react';
import { Alert, Button, Table } from 'reactstrap';
import { chunk, omit, findKey, debounce, sortBy, uniq, isEmpty, zip, groupBy, range, keyBy } from 'lodash';
import classnames from 'classnames';
import { format as formatDate, startOfMonth, endOfMonth, addMonths, addMinutes, eachDay, } from 'date-fns';
import numeral from 'numeral';
import qs from 'qs';
import { useToggle, useList, } from 'react-use';

import OverlayLoading from '../OverlayLoading';
import HelpLink from '../HelpLink';
import { log, fiscalYearOfPeriod, filterRowsByAmount, } from '../../utils';
import { getDocumentData, getCollectionData, } from '../../shared/firebase';
import { usedSegments, } from '../../shared/models/company';
import { routes, } from '../../shared/models/journal';
import { directions, } from '../../shared/config';
import { mapKeysToJa, } from '../../shared/texts';
import firebase, { functions } from '../../firebase';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionFetch from '../hooks/useCollectionFetch';
import useCollectionsFetch from '../hooks/useCollectionsFetch';
import useQueryParams from '../hooks/useQueryParams';
import CompanyPage from '../hocs/CompanyPage';
import ProgressButton from '../ProgressButton';
import QuerySelector from '../QuerySelector';
import QueryInput from '../QueryInput';
import ExportButton from '../ExportButton';
import JournalsSyncButton from '../JournalsSyncButton';

const { entries } = Object;
const searchFreeePartners = functions.httpsCallable('searchFreeePartners');
const db = firebase.firestore();
const companiesRef = db.collection('companies');
const countPerPage = 100;
const routeOptions = routes.map(_ => ({ label: _, value: _ }));

export default CompanyPage(function CompanyJournals (props) {
  const { company, user, period, targetMonth, sortedAccountItems, taxes, location, history, } = props;
  const [limit, setLimit] = useState(countPerPage);
  const [isFetching, toggleFetching] = useToggle();
  const queryParams = useQueryParams();
  const {
    journalNumber,
    date,
    accountItemName,
    taxId,
    route,
    sectionId,
    partnerId,
    itemId,
    segment1Id,
    segment2Id,
    segment3Id,
    complexFilter,
    usesArrayFilter,
    complexFilterDescription,
  } = queryParams;
  const [partners, setPartners] = useState(null);
  const partnersById = keyBy(partners, 'id');
  const journalsFetchJob = useDocumentSubscription(company.ref.collection('journalsFetchJobs').doc('0'), [company]);
  const sections = useCollectionSubscription(company.ref.collection('sections'), [company], { initialValue: null });
  const freeeItems = useCollectionSubscription(company.ref.collection('freeeItems'), [company], { initialValue: null });
  const segment1s = useCollectionSubscription(company.ref.collection('segment1s'), [company], { initialValue: null });
  const segment2s = useCollectionSubscription(company.ref.collection('segment2s'), [company], { initialValue: null });
  const segment3s = useCollectionSubscription(company.ref.collection('segment3s'), [company], { initialValue: null });
  const [[allSections, sectionsById], [allFreeeItems, freeeItemsById], [allSegment1s, segment1sById], [allSegment2s, segment2sById], [allSegment3s, segment3sById]] = [
    sections, freeeItems, segment1s, segment2s, segment3s,
  ].map((data) => {
    const allData = [{ id: 0, name: '未選択', }, ...(data || [])];
    const byId = keyBy(allData, 'id')
    return [allData, byId];
  });
  const taxesById = keyBy(taxes, 'id');

  let filteredJournalsRef = company.ref.collection('journals').where('yearMonth', '==', targetMonth?.toString() || null);;
  if(complexFilter) {
    if(usesArrayFilter === '1') {
      complexFilter.filter(_ => _[1] !== 'in').map((filter) => {
        filteredJournalsRef = filteredJournalsRef.where(...filter);
      });
      const arrayFilter = complexFilter.find(_ => _[1] === 'in');
      filteredJournalsRef = chunk(arrayFilter[2], 10).map(_ => filteredJournalsRef.where(arrayFilter[0], 'in', _));
    } else {
      complexFilter.map((filter) => {
        filteredJournalsRef = filteredJournalsRef.where(...filter);
      });
    }
  } else {
    if(!isEmpty(journalNumber)) {
      filteredJournalsRef = filteredJournalsRef.where('journalNumber', '==', journalNumber);
    }
    if(!isEmpty(date)) {
      filteredJournalsRef = filteredJournalsRef.where('date', '==', date.replace(/-/g, '/'));
    }
    if(!isEmpty(accountItemName)) {
      filteredJournalsRef = filteredJournalsRef.where('accountName', '==', accountItemName);
    }
    if(!isEmpty(taxId)) {
      const taxName = taxesById[taxId]?.name_ja;
      filteredJournalsRef = filteredJournalsRef.where('taxName', '==', taxName || '');
    }
    if(!isEmpty(route)) {
      filteredJournalsRef = filteredJournalsRef.where('source.登録した方法', '==', route);
    }
    if(!isEmpty(sectionId)) {
      const sectionName = (_ => _ === '未選択' ? '' : _)(sectionsById[sectionId]?.name);
      filteredJournalsRef = filteredJournalsRef.where('sections', '==', sectionName || '');
    }
    if(!isEmpty(partnerId)) {
      const partnerName = (_ => _ === '未選択' ? '' : _)(partnersById[partnerId]?.name);
      filteredJournalsRef = filteredJournalsRef.where('partners', '==', partnerName || '');
    }
    if(!isEmpty(itemId)) {
      const itemName = (_ => _ === '未選択' ? '' : _)(freeeItemsById[itemId]?.name);
      filteredJournalsRef = filteredJournalsRef.where('items', '==', itemName || '');
    }
    if(!isEmpty(segment1Id)) {
      const segment1Name = (_ => _ === '未選択' ? '' : _)(segment1sById[segment1Id]?.name);
      filteredJournalsRef = filteredJournalsRef.where('segment1s', '==', segment1Name || '');
    }
    if(!isEmpty(segment2Id)) {
      const segment2Name = (_ => _ === '未選択' ? '' : _)(segment2sById[segment2Id]?.name);
      filteredJournalsRef = filteredJournalsRef.where('segment2s', '==', segment2Name || '');
    }
    if(!isEmpty(segment3Id)) {
      const segment3Name = (_ => _ === '未選択' ? '' : _)(segment3sById[segment3Id]?.name);
      filteredJournalsRef = filteredJournalsRef.where('segment3s', '==', segment3Name || '');
    }
  }

  const journalDependencies = [company, targetMonth, taxes, sections, freeeItems, segment1s, segment2s, segment3s];
  const journalInfluences = [journalNumber, date, accountItemName, taxId, route, sectionId, partnerId, itemId, segment1Id, segment2Id, segment3Id, complexFilter, partners];
  const _journals = useCollectionFetch(usesArrayFilter !== '1' && journalDependencies.every(_ => _ != null) && filteredJournalsRef.limit(limit), [...journalDependencies, ...journalInfluences, usesArrayFilter, limit], { refreshDisabled: true });
  const __journals = useCollectionsFetch(usesArrayFilter === '1' && journalDependencies.every(_ => _ != null) && filteredJournalsRef.map(_ => _.limit(limit)), [...journalDependencies, ...journalInfluences, usesArrayFilter, limit], { refreshDisabled: true });
  const journals = usesArrayFilter ? __journals : _journals;
  const hasLoadedAll = !isFetching && journals.length < limit;
  const loadMore = async () => {
    toggleFetching(true);
    setLimit(limit + countPerPage);
  };

  const rows = journals;
  const journalNumberOptions = sortBy(uniq(rows.map(_ => _.journalNumber)), _ => _).map(_ => ({ value: _, label: _, }));
  const dateOptions = eachDay(startOfMonth(targetMonth + '01'), endOfMonth(targetMonth + '01')).map(_ => ({ label: formatDate(_, 'YYYY/MM/DD'), value: formatDate(_, 'YYYY-MM-DD') }));
  const accountItemNameOptions = sortedAccountItems.map(_ => ({ label: _.name, value: _.name, }));
  const [sectionOptions, freeeItemOptions, segment1Options, segment2Options, segment3Options] = [
    allSections, allFreeeItems, allSegment1s, allSegment2s, allSegment3s,
  ].map(_ => _.map(_ => ({ label: _.name, value: _.id.toString() })));
  const availableTaxes = taxes.filter(_ => _.available);
  const taxOptions = availableTaxes.map(_ => ({ label: _.name_ja, value: _.id.toString() }));
  const rowsForExport = async () => {
    const journals = usesArrayFilter === '1' ? (await Promise.all(filteredJournalsRef.map(getCollectionData))).flat() : await getCollectionData(filteredJournalsRef);
    return journals.map((row) => {
      const { journalNumber, date, accountName, amount, sections, partners, items, abstract, direction, source, } = row;
      return mapKeysToJa({
        journalNumber,
        date,
        accountName,
        tax: source[`${directions[direction]}税区分`],
        route: source[`登録した方法`],
        section: sections,
        partner: partners,
        item: items,
        ...usedSegments(company).reduce((x, segmentNumber) => {
          return {
            ...x,
            [`segment${segmentNumber}`]: row[`segment${segmentNumber}s`],
          };
        }, {}),
        debitAmount: direction === 'debit' ? amount : null,
        creditAmount: direction === 'credit' ? amount : null,
        abstract,
      });
    });
  };
  const logExport = async () => {
    await log(company, 'journals', 'exportCsv', user, { period, targetMonth, });
  };
  const loadPartnerOptions = debounce(async (query, cb) => {
    const { data: partners } = await searchFreeePartners({ companyId: company.id, companySourceId: company.sourceId, query, });
    setPartners(partners);
    cb(partners.map(_ => ({ label: _.name, value: _.id })));
  }, 500);
  const toggleComplexFilter = () => {
    history.replace(location.pathname + '?' + qs.stringify({ ...omit(queryParams, ['complexFilter', 'usesArrayFilter', 'complexFilterDescription']), }));
  };
  useEffect(() => {
    if(journals && journals.length > 0) {
      toggleFetching(false);
    }
  }, [journals]);
  useEffect(() => {
    setLimit(countPerPage);
  }, [...journalDependencies]);
  useEffect(() => {
    if(queryParams.partnerId) {
      (async () => {
        const partner = (await getCollectionData(company.ref.collection('partnerChunks'))).flatMap(_ => _.data).find(_ => _.id.toString() === partnerId);
        setPartners([partner]);
      })();
    }
  }, []);

  return (
    <div className="company-journals">
      <div className="py-5 container-fluid">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="仕訳帳を同期する" />
        </div>
        <div className="mb-3 d-flex flex-column justify-content-center align-items-stretch">
          <h3 className="text-center">仕訳帳</h3>
        </div>
        <div className="d-flex align-items-end flex-wrap justify-content-start mb-1 gap-1">
          {
            complexFilter ? (
              <Alert className="flex-grow-1" color="info" toggle={toggleComplexFilter}>
                <div>
                  以下の内容で絞り込んでいます。
                </div>
                <div style={{ whiteSpace: 'pre-line', }}>
                  「{complexFilterDescription}」
                </div>
              </Alert>
            ) : (
              <Fragment>
                <QuerySelector paramName="journalNumber" width={180} options={journalNumberOptions} label="仕訳番号" />
                <QuerySelector paramName="date" width={180} options={dateOptions} label="日付" />
                <QuerySelector paramName="accountItemName" width={180} options={accountItemNameOptions} label="科目" />
                <QuerySelector paramName="taxId" width={180} options={taxOptions} label="税区分" />
                <QuerySelector paramName="route" width={180} options={routeOptions} label="登録した方法" />
                <QuerySelector paramName="sectionId" width={180} options={sectionOptions} label="部門" />
                <QuerySelector async paramName="partnerId" width={180} loadOptions={loadPartnerOptions} label="取引先" selectorProps={{ placeholder: '検索...' }} />
                <QuerySelector paramName="itemId" width={180} options={freeeItemOptions} label="品目" />
                {
                  usedSegments(company).map((segmentNumber) => {
                    return <QuerySelector paramName={`segment${segmentNumber}Id`} width={180} options={({ 1: segment1Options, 2: segment2Options, 3: segment3Options, })[segmentNumber]} label={`セグメント${segmentNumber}`} />
                  })
                }
              </Fragment>
            )
          }
        </div>
        <div className="d-flex align-items-end justify-content-between">
          <div className="d-flex gap-1 align-items-end">
            <ExportButton outline fileName="仕訳帳.csv" rows={rowsForExport} onFinish={logExport} />
            <JournalsSyncButton company={company} label="同期する" period={period} targetMonth={targetMonth} />
            {user.admin && <JournalsSyncButton company={company} serial label="連続同期する" period={period} targetMonth={targetMonth} />}
            <div className="text-muted">{(_ => _ && formatDate(_.toDate(), 'YYYY/MM/DD HH:mm:ss'))(journalsFetchJob?.months?.[targetMonth]?.completedAt)}</div>
          </div>
        </div>
        <div className="mt-2 d-flex justify-content-start position-relative" style={{ zIndex: 0 }}>
          <table className="table table-bordered table-hover vertical-sticky-table">
            <thead className="thead-light text-center text-nowrap">
              <tr>
                <th>仕訳番号</th>
                <th>日付</th>
                <th>科目</th>
                <th>税区分</th>
                <th>登録した方法</th>
                <th>部門</th>
                <th>取引先</th>
                <th>品目</th>
                {
                  usedSegments(company).map((segmentNumber) => {
                    return <th key={segmentNumber}>セグメント{segmentNumber}</th>
                  })
                }
                <th>借方金額</th>
                <th>貸方金額</th>
                <th>摘要</th>
              </tr>
            </thead>
            <tbody>
              {
                rows.map((row) => {
                  const { id, journalNumber, } = row;
                  return (
                    <Row key={id} {...{ company, period, targetMonth, ...row, } } />
                  );
                })
              }
            </tbody>
          </table>
        </div>
        {
          !hasLoadedAll && (
            <div className="d-flex justify-content-center">
              <Button color="primary" onClick={loadMore} disabled={isFetching}>
                {isFetching && <span className="fas fa-spinner fa-spin mr-1" />}
                もっと読み込む
              </Button>
            </div>
          )
        }
      </div>
    </div>
  );
});

function Row (props) {
  const { company, period, targetMonth, journalNumber, date, accountName, amount, sections, partners, items, abstract, source, direction, } = props;
  const { start_date: startDate } = fiscalYearOfPeriod(period, company.fiscalYears) || {};
  const url = `https://secure.freee.co.jp/reports/journals?end_date=${formatDate(endOfMonth(targetMonth + '01'), 'YYYY-MM-DD')}&start_date=${formatDate(startDate, 'YYYY-MM-DD')}&txn_number=${journalNumber}`;

  return (
    <tr>
      <td>
        <a href={url} target="_blank">
          {journalNumber}
        </a>
      </td>
      <td>
        {date}
      </td>
      <td>
        {accountName}
      </td>
      <td>
        {source[`${directions[direction]}税区分`]}
      </td>
      <td>
        {source[`登録した方法`]}
      </td>
      <td>
        {sections}
      </td>
      <td>
        {partners}
      </td>
      <td>
        {items}
      </td>
      {
        usedSegments(company).map((segmentNumber) => {
          return <td key={segmentNumber}>{props[`segment${segmentNumber}s`]}</td>;
        })
      }
      <td className="text-right">
        {direction === 'debit' && numeral(amount).format()}
      </td>
      <td className="text-right">
        {direction === 'credit' && numeral(amount).format()}
      </td>
      <td>
        <div>{abstract}</div>
      </td>
    </tr>
  );
}
