import React from 'react';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { Button, } from 'reactstrap';
import { useToggle, } from 'react-use';

export default function DeleteButton ({ label = '削除', itemRef, deleteItems, ...extraProps }) {
  const [isDeleting, toggleDeleting] = useToggle(false);
  const onClickDelete = async () => {
    if (!window.confirm('本当に削除しますか？')) return;

    toggleDeleting(true);
    deleteItems ? (
      await deleteItems()
    ) : (
      await itemRef.delete()
    );
    toast.success('削除しました');
    toggleDeleting(false);
  }

  return (
    <Button color="danger" onClick={onClickDelete} disabled={isDeleting} {...extraProps}>
      <span className={classnames('fas fa-trash', { 'mr-1': !isEmpty(label) })} />
      {label}
    </Button>
  );
};
