const { isEmpty } = require('lodash');

const fields = ({ freeeSegments, customSegments, companyMembers, } ) => {
  return {
    name: {
      type: 'string',
      label: 'セグメント名',
      validations: {
        required: v => !isEmpty(v),
        unique: (v, s) => !s.conflicted && !customSegments.map(_ => _.name).includes(v),
      },
    },
    segmentIds: {
      type: 'multiSelect',
      label: 'freeeセグメント',
      options: freeeSegments.map(_ => ({ label: _.name, value: _.id })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
  };
};

module.exports = {
  fields,
};
