import React, { Component, Fragment } from 'react';
import ReactTooltip from 'react-tooltip';

import { nl2br } from '../utils';

export default class AccountItemDisplay extends Component {
  componentDidMount() {
    ReactTooltip.rebuild()
  }
  componentDidUpdate() {
    const { accountItemSetting: { note } = {} } = this.props;
    if(note) {
      ReactTooltip.rebuild()
    }
  }
  render() {
    const { isCustom = false, iconStyles = {}, accountItemName, accountItemSetting: { note } = {} } = this.props;
    return (
      <Fragment>
        {
          isCustom && (
            <span className="fas fa-wrench mr-1 text-secondary" />
          )
        }
        <span>{accountItemName}</span>
        {
          note && (
            <span data-tip={nl2br(note)} data-type="info" className="cursor-pointer ml-1 fas fa-info-circle text-info" style={iconStyles} />
          )
        }
      </Fragment>
    );
  }
};
