import React from 'react';
import numeral from 'numeral';

export default function AmountDisplay({ amount }) {
  return (
    <span className="text-right">
      {numeral(amount).format('0,0')}
    </span>
  );
};
