import React, { Component, useMemo, useState, useRef, useEffect, } from 'react';
import { Button } from 'reactstrap';
import { mapValues, isEmpty, get, pick, pickBy, omit, last, groupBy, sortBy, keyBy } from 'lodash';
import qs from 'qs';
import { format as formatDate } from 'date-fns';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { useNetworkState, useToggle, useAsync, usePrevious, } from 'react-use';
import { toast, } from 'react-toastify';
import ipRangeCheck from 'ip-range-check';

import { fetchIp, } from '../../utils';
import { leftTrialDays as _leftTrialDays } from '../../shared/util';
import { isTrialExpired, canUseFeature, } from '../../shared/models/company';
import firebase, { functions } from '../../firebase';
import { getRole } from '../../shared/models/user';
import { accountCategories, screens, guestScreens, } from '../../shared/config';
import { canReadAccountItemAuditorSetting, canWriteCustomSection, } from '../../abilities';
import { periodOfFiscalYear, fiscalYearOfPeriod, fullPathWithParams } from '../../utils';
import UserContext from '../contexts/user';
import HeaderNav from '../HeaderNav';
import HelpLink from '../HelpLink';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useQueryParams from '../hooks/useQueryParams';

const { entries, keys } = Object;
const db = firebase.firestore();
const usersRef = db.collection('users');
const companiesRef = db.collection('companies');
const getCompanyMembers = functions.httpsCallable('getCompanyMembers');
const periodFromSearch = (search) => {
  const { period } = qs.parse(search.slice(1), { arrayLimit: Infinity });
  return parseInt(period, 10);
};
const targetMonthFromSearch = (search) => {
  const { targetMonth } = qs.parse(search.slice(1), { arrayLimit: Infinity });
  return parseInt(targetMonth, 10);
};
const headerLinks = {
  dashboard: { path: '/dashboard', label: 'ダッシュボード', },
  journals: { path: '/journals', label: '仕訳帳', },
  journalsByTax: { path: '/journalsByTax', label: '仕訳分析', },
  accountItemSettings: { path: '/accountItemSettings', label: '科目設定', },
  periodComparison: { path: '/periodComparison', label: '期間比較', },
  monthlyTrends: { path: '/monthlyTrends', label: '月次推移', },
  budgets: { path: '/budgets', label: '予実', },
  monthlyPartnerBalances: { path: '/monthlyPartnerBalances', label: '取引先別分析', },
  customAccountItems: { path: '/customAccountItems', label: 'カスタム科目', },
  customJournalsCheckRules: { path: '/customJournalsCheckRules', label: 'カスタムルール', },
  customSections: { path: '/customSections', label: 'カスタム部門', },
  customSegments: { path: '/customSegments', label: 'カスタムセグメント', },
  customPartners: { path: '/customPartners', label: 'カスタム取引先', },
  journalsCheck: { path: '/journalsCheck', label: '仕訳チェック', },
  trialsCheck: { path: '/trialsCheck', label: '残高チェック', },
  members: { path: '/members', label: 'ユーザー', },
  logs: { path: '/logs', label: '操作ログ', },
  settings: { path: '/settings', label: '設定', },
};
const choreScreens = ['members', 'logs', 'settings'];

export default function CompanyPageHOC(WrappedComponent) {
  return function CompanyPage (props) {
    const { history, location, user, match: { path, params: { companyId } } } = props;
    const queryParams = useQueryParams();
    const { 
      period: periodString,
      targetMonth: targetMonthString,
    } = queryParams;
    const period = periodString && parseInt(periodString, 10);
    const prevPeriod = usePrevious(period);
    const targetMonth = targetMonthString && parseInt(targetMonthString, 10);
    const headerNavRef = useRef(null);
    const [isSyncing, toggleSyncing] = useToggle(false);
    const [ipAddressRestrictionState, setIpAddressRestrictionState] = useState('initial');
    const networkState = useNetworkState();
    const company = useDocumentSubscription(db.collection('companies').doc(companyId), [companyId]);
    const accountItemsOrderSetting = useDocumentSubscription(db.collection('companies').doc(companyId).collection('settings').doc('accountItemsOrder'), [companyId]);
    const accountItems = useCollectionSubscription(company?.ref.collection('accountItems'), [company], { mapDoc: _ => ({ ..._.data(), ...pick(_, ['id', 'ref']), }) });
    const accountItemSettings = useCollectionSubscription(company?.ref.collection('accountItemSettings').where('period', '==', null), [company]);
    const _customAccountItems = useCollectionSubscription(company?.ref.collection('customAccountItems').orderBy('createdAt'), [company]);
    const customAccountItems = sortBy(_customAccountItems, _ => _.index != null ? _.index : _customAccountItems.indexOf(_));
    const _customSections = useCollectionSubscription(company?.ref.collection('customSections').orderBy('createdAt'), [company]);
    const customSections = sortBy(_customSections,_ => _.index != null ? _.index : _customSections.indexOf(_));
    const _sections = useCollectionSubscription(company?.ref.collection('sections'), [company]);
    const sections = [{ id: 0, name: '未選択', }, ..._sections];
    const taxes = useCollectionSubscription(company?.ref.collection('taxes').orderBy('code'), [company]);
    const accountItemAuditorSettings = useCollectionSubscription(period && canReadAccountItemAuditorSetting(user) && company?.ref.collection('accountItemAuditorSettings').where('period', '==', period), [company, period]);
    const journalsFetchJobs = useCollectionSubscription(company?.ref.collection('journalsFetchJobs'), [company]);
    const { value: members = [], } = useAsync(async () => {
      const { data: members, } = await getCompanyMembers({ companyId });
      return members;
    }, [company]);
    const { value: ipAddress, loading: isLoadingIpAddress, } = useAsync(async () => {
      if(company?.ipAddressRestriction && networkState.online) {
        return await fetchIp();
      }
    }, [company, networkState]);
    const accountItemSettingsById = keyBy(accountItemSettings, 'id');
    const accountItemAuditorSettingsById = keyBy(accountItemAuditorSettings, 'id');
    const accountItemsGroupedByCategory = useMemo(_ => mapValues(groupBy(accountItems, 'account_category'), (accountItems, categoryName) => sortBy(accountItems, _ => accountItemsOrderSetting?.[categoryName]?.indexOf(_.name) ?? Infinity)), [accountItems, accountItemsOrderSetting]);
    const sortedAccountItems = useMemo(_ => accountCategories.map(_ => accountItemsGroupedByCategory[_]).filter(_ => _).flat(), [accountItemsGroupedByCategory]);
    const leftTrialDays = _leftTrialDays(company);
    const role = getRole(user, companyId);
    const enabledScreens = user?.companies?.[companyId]?.enabledScreens || [];
    const companyOptionFilteredHeaderLinks = canUseFeature(company, 'segments') ? headerLinks : omit(headerLinks, 'customSegments');
    const headerLinksForUser = ({
      guest: isEmpty(enabledScreens) ? pick(companyOptionFilteredHeaderLinks, guestScreens) : pickBy(companyOptionFilteredHeaderLinks, (v, k) => enabledScreens.includes(k)),
      member: isEmpty(enabledScreens) ? omit(companyOptionFilteredHeaderLinks, ['settings']) : pickBy(companyOptionFilteredHeaderLinks, (v, k) => enabledScreens.includes(k)),
    })[role] || companyOptionFilteredHeaderLinks;
    const pathName = get(path.match(/:companyId\/([^/]+)?/), 1) || '';
    const currentScreen = entries(screens).find(([screen, { pathNames = [] }]) => {
      return [...pathNames, screen].includes(pathName);
    })?.[0];
    const canAccess = keys(headerLinksForUser).includes(currentScreen) || pathName === 'accountItems';
    const writableCustomSections = (customSections || []).filter(_ => canWriteCustomSection(user, companyId, _));
    const onClickSync = async () => {
      toggleSyncing(true);
      await headerNavRef.current.syncCompany();
      toggleSyncing(false);
    }
    useEffect(() => {
      if(period) return;

      const newPeriod = period || periodOfFiscalYear(last(company?.fiscalYears));
      if(!newPeriod) return;

      const path = fullPathWithParams({ period: newPeriod, }, location);
      history.replace(encodeURI(path));
    }, [company]);
    useEffect(() => {
      if(prevPeriod !== period || !targetMonth) {
        const fiscalYear = fiscalYearOfPeriod(period, company?.fiscalYears);
        const newTargetMonth = fiscalYear && parseInt(formatDate(fiscalYear.end_date, 'YYYYMM'), 10);
        if(newTargetMonth) {
          const path = fullPathWithParams({ targetMonth: newTargetMonth, }, location);
          history.replace(encodeURI(path));
        }
      }
    }, [company, period]);
    useEffect(() => {
      if(company?.display_name) {
        if(company.ipAddressRestriction) {
          if(isLoadingIpAddress || !ipAddress) {
            setIpAddressRestrictionState('loading');
          } else {
            if(company.whiteIps.split(/\r\n|\r|\n/g).some(_ => ipRangeCheck(ipAddress, _))) {
              setIpAddressRestrictionState('ok');
            } else {
              setIpAddressRestrictionState('ng');
            }
          }
        } else {
          setIpAddressRestrictionState('ignored');
        }
      }
    }, [company, ipAddress, isLoadingIpAddress]);
    useEffect(() => {
      if(ipAddressRestrictionState === 'ng') {
        toast.error('不正なアクセスです');
        history.push('/');
      }
    }, [ipAddressRestrictionState]);

    return !isEmpty(company?.display_name) && (
      ['ignored', 'ok'].includes(ipAddressRestrictionState) ? (
        <UserContext.Provider value={user}>
          <div>
            <HeaderNav ref={headerNavRef} {...{ ...props, role, company, period, targetMonth, leftTrialDays, periodSelectDisabled: WrappedComponent.PeriodSelectDisabled, monthSelectDisabled: WrappedComponent.MonthSelectDisabled }} links={headerLinksForUser} />
            {
              !isTrialExpired(company) ? (
                (company.fiscalYears || choreScreens.includes(currentScreen)) ? (
                  canAccess ? (
                    customSections != null && (
                      <WrappedComponent {...{
                        ...props,
                        leftTrialDays,
                        company,
                        members,
                        journalsFetchJobs,
                        accountItemSettings,
                        accountItemsOrderSetting,
                        accountItemSettingsById,
                        accountItemAuditorSettings,
                        accountItemAuditorSettingsById,
                        customAccountItems,
                        customSections,
                        writableCustomSections,
                        sections,
                        taxes,
                        period,
                        targetMonth,
                        accountItems,
                        accountItemsGroupedByCategory,
                        sortedAccountItems,
                        role,
                      }}
                      />
                    )
                  ) : (
                    <div className="container">
                      <div className="alert alert-danger mt-3">権限がありません</div>
                    </div>
                  )
                ) : (
                  <div className="py-5 container text-danger text-center">
                    <div className="d-flex justify-content-end mb-3">
                      <HelpLink text="事業所情報を同期する" />
                    </div>
                    <Button color="secondary" disabled={isSyncing} onClick={onClickSync}>
                      <span className={classnames('mr-1 fas fa-sync cursor-pointer', { 'fa-spin': isSyncing })} />
                      事業所を同期
                    </Button>
                  </div>
                )
              ) : (
                <div className="py-5 container text-danger text-center">
                  <div className="row">
                    <div className="col-md-6 offset-md-3">
                      <div className="alert alert-info">
                        <div className="mb-3">
                          kansapoの試用期間が終了しました。
                          <br />
                          有償版をぜひご利用ください。
                        </div>
                        <Button color="primary" tag="a" href="https://docs.google.com/forms/d/e/1FAIpQLSfzZgD9VOgC58qlHqsOlwinTBBkmhf5uHh7unn-5k2PrWtAEg/viewform" target="_blank">
                          <span className="fas fa-external-link-alt mr-1" />
                          有償版ご利用を申し込む
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            <ReactTooltip effect="solid" multiline />
          </div>
        </UserContext.Provider>
      ) : (
        ipAddressRestrictionState === 'loading' && (
          <div className="d-flex justify-content-center align-items-center p-5">
            <span className="fas fa-spin fa-spinner" />
            <span className="ml-1">
              loading...
            </span>
          </div>
        )
      )
    );
  };
}
