import { useState, } from 'react';
import qs from 'qs';
import { useLocation, useHistory } from 'react-router';

import useQueryParams from './useQueryParams';

export default function usePagination({ countPerPage = 100, initialPageNumber = 0, totalCount = Infinity, }) {
  const queryParams = useQueryParams();
  const { pageNumber: _pageNumber = '0' } = queryParams;
  const pageNumber = parseInt(_pageNumber, 10);
  const setPageNumber = (pageNumber) => {
    history.replace(location.pathname + '?' + qs.stringify({ ...queryParams, pageNumber }));
  };
  const location = useLocation();
  const history = useHistory();
  const lastPageNumber = Math.max(Math.floor((totalCount - 1) / countPerPage), 0);
  const offset = pageNumber * countPerPage;
  const goTo = _ => setPageNumber(_);
  const prev = _ => setPageNumber(pageNumber - 1);
  const next = _ => setPageNumber(pageNumber + 1);
  const first = _ => setPageNumber(0);
  const hasPrev = pageNumber > 0;
  const hasNext = pageNumber < lastPageNumber;
  const currentStart = offset + 1;
  const currentEnd = Math.min(offset + countPerPage, totalCount);

  return { pageNumber, offset, goTo, hasPrev, hasNext, prev, next, first, currentStart, currentEnd, totalCount, };
};
