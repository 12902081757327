const { isEmpty } = require('lodash');

const { keys, entries } = Object;

const fields = () => {
  return {
    name: {
      type: 'string',
      label: '予算名',
      validations: {
        required: v => !isEmpty(v),
      },
    },
  };
};

module.exports = {
  fields,
};
