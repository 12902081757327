import React, { useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, } from 'reactstrap';
import { omit, get, mapValues } from 'lodash';
import { useList, } from 'react-use';

import { directions, } from '../../shared/config';
import { routes, } from '../../shared/models/journal';
import { fields, journalRuleFields, ruleFields, equalityOperators, inclusionOperators, numberOperators, } from '../../shared/models/customRule';
import useFormState from '../hooks/useFormState';
import Field from '../Field';
import ListForm from '../ListForm';

const { entries } = Object;

function JournalRuleForm (props) {
  const { company, ruleType, accountItems, taxes, partners, freeeItems, sections, segment1s, segment2s, segment3s, values, onChange, } = props;
  const statedFields = useFormState(values, journalRuleFields(), values);
  const [rules, { set: setRules, updateAt: updateRuleAt }] = useList(get(values, 'rules') || []);
  const onChangeRules = (items, prevItems) => {
    setRules(items);
  };
  useEffect(() => {
    onChange({
      ...mapValues(statedFields, 'value'),
      rules,
      isValid: rules.every(_ => _.isValid),
    });
  }, [rules, ...Object.values(statedFields).map(_ => _.value)]);

  return (
    <div>
      <Field
        {...statedFields.journalRuleType}
      />
      <ListForm
        items={rules}
        renderItem={(item, itemIndex) => {
          return (
            <RuleForm
              rules={rules}
              index={itemIndex}
              values={item}
              ruleType={ruleType}
              onChange={_ => updateRuleAt(itemIndex, { ...item, ..._, })}
              {...{ company, accountItems, taxes, partners, freeeItems, sections, segment1s, segment2s, segment3s, }}
            />
          );
        }}
        onChange={onChangeRules}
        minItems={1}
      />
    </div>
  );
}

function RuleForm (props) {
  const { company, ruleType, dimension, accountItems, taxes, partners, freeeItems, sections, segment1s, segment2s, segment3s, values, onChange, } = props;
  const statedFields = useFormState(values, ruleFields({ company, ruleType, dimension, }), values);
  const operatorOptions = entries(({
    direction: equalityOperators,
    accountItem: equalityOperators,
    amount: numberOperators,
    tax: equalityOperators,
    route: equalityOperators,
    partner: equalityOperators,
    item: equalityOperators,
    section: equalityOperators,
    segment1: equalityOperators,
    segment2: equalityOperators,
    segment3: equalityOperators,
    summary: inclusionOperators,
    creditBalance: numberOperators,
    creditBalanceRate: numberOperators,
    balanceAccountItem: equalityOperators,
    balanceAmount: numberOperators,
    balanceDimensionAmount: numberOperators,
  })[statedFields.subject.value] || {}).map(([k, { label }]) => ({ label, value: k }));
  const emptyOption = { label: '未選択', value: '未選択' };
  const valueField = (({
    direction: () => {
      return (
        <Field
          {...statedFields.value}
          name="value"
          type="multiSelect"
          options={entries(directions).map(([k, v]) => ({ value: k, label: v, }))}
        />
      );
    },
    accountItem: () => {
      return (
        <Field
          {...statedFields.value}
          name="value"
          type="multiSelect"
          options={accountItems.map(_ => ({ label: _.name, value: _.id }))}
        />
      );
    },
    amount: () => {
      return (
        <Field
          {...statedFields.value}
          name="value"
          type="integer"
        />
      );
    },
    tax: () => {
      return (
        <Field
          {...statedFields.value}
          name="value"
          type="multiSelect"
          options={taxes.filter(_ => _.available).map(_ => ({ label: _.name_ja, value: _.id, }))}
        />
      );
    },
    route: () => {
      return (
        <Field
          {...statedFields.value}
          name="value"
          type="multiSelect"
          options={routes.map(_ => ({ label: _, value: _, }))}
        />
      );
    },
    partner: () => {
      return (
        <Field
          {...statedFields.value}
          name="value"
          type="multiSelect"
          options={[emptyOption, ...partners.map(_ => ({ label: _.name, value: _.id }))]}
        />
      );
    },
    item: () => {
      return (
        <Field
          {...statedFields.value}
          name="value"
          type="multiSelect"
          options={[emptyOption, ...freeeItems.map(_ => ({ label: _.name, value: _.id }))]}
        />
      );
    },
    section: () => {
      return (
        <Field
          {...statedFields.value}
          name="value"
          type="multiSelect"
          options={[emptyOption, ...sections.map(_ => ({ label: _.name, value: _.id }))]}
        />
      );
    },
    segment1: () => {
      return (
        <Field
          {...statedFields.value}
          name="value"
          type="multiSelect"
          options={[emptyOption, ...segment1s.map(_ => ({ label: _.name, value: _.id }))]}
        />
      );
    },
    segment2: () => {
      return (
        <Field
          {...statedFields.value}
          name="value"
          type="multiSelect"
          options={[emptyOption, ...segment2s.map(_ => ({ label: _.name, value: _.id }))]}
        />
      );
    },
    segment3: () => {
      return (
        <Field
          {...statedFields.value}
          name="value"
          type="multiSelect"
          options={[emptyOption, ...segment3s.map(_ => ({ label: _.name, value: _.id }))]}
        />
      );
    },
    summary: () => {
      return (
        <Field
          {...statedFields.value}
          name="value"
          type="creatableMultiSelect"
          options={[]}
        />
      );
    },
    creditBalance: () => {
      return (
        <Field
          {...statedFields.value}
          name="value"
          type="integer"
        />
      );
    },
    creditBalanceRate: () => {
      return (
        <Field
          {...statedFields.value}
          name="value"
          type="float"
        />
      );
    },
    balanceAccountItem: () => {
      return (
        <Field
          {...statedFields.value}
          name="value"
          type="multiSelect"
          options={accountItems.map(_ => ({ label: _.name, value: _.id }))}
        />
      );
    },
    balanceAmount: () => {
      return (
        <Field
          {...statedFields.value}
          name="value"
          type="integer"
        />
      );
    },
    balanceDimensionAmount: () => {
      return (
        <Field
          {...statedFields.value}
          name="value"
          type="integer"
        />
      );
    },
  })[statedFields.subject.value] || (_ => null))();
  useEffect(() => {
    onChange({
      ...mapValues(statedFields, 'value'),
      isValid: Object.values(statedFields).every(_ => _.isValid),
    });
  }, [...Object.values(statedFields).map(_ => _.value)]);

  return (
    <div className="d-flex">
      <div style={{ width: 150 }}>
        <Field name="subject" {...statedFields.subject} inputProps={{ components: { IndicatorsContainer: _ => null } }}/>
      </div>
      <div className="ml-2" style={{ width: 170 }}>
        <Field name="operator" {...statedFields.operator} options={operatorOptions} inputProps={{ components: { IndicatorsContainer: _ => null } }}/>
      </div>
      <div className="ml-2" style={{ width: 300 }}>
        {valueField}
      </div>
    </div>
  );
}

export default function CustomJournalsCheckRuleFormModal(props) {
  const { company, accountItems, taxes, partners, freeeItems, sections, segment1s, segment2s, segment3s, isOpen, values, onClickClose } = props;
  const isNew = !values;
  const statedFields = useFormState(values, fields({ company, }), isOpen);
  const [balanceRules, { set: setBalanceRules, updateAt: updateBalanceRuleAt }] = useList(get(values, 'balanceRules') || []);
  const [noneDimensionBalanceRules, { set: setNoneDimensionBalanceRules, updateAt: updateNoneDimensionBalanceRuleAt }] = useList(get(values, 'noneDimensionBalanceRules') || []);
  const [journalRules, { set: setJournalRules, updateAt: updateJournalRuleAt }] = useList(get(values, 'journalRules') || []);
  const isUnsubmittable =
    Object.values(statedFields).some(_ => !_.isValid) ||
    journalRules.some(_ => !_.isValid) ||
    balanceRules.some(_ => !_.isValid) ||
    noneDimensionBalanceRules.some(_ => !_.isValid);
  const onSubmit = (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;
    props.onSubmit({
      ...mapValues(statedFields, 'value'),
      journalRules: journalRules.map(_ => omit(_, 'isValid')),
      balanceRules: balanceRules.map(_ => omit(_, 'isValid')),
      noneDimensionBalanceRules: noneDimensionBalanceRules.map(_ => omit(_, 'isValid')),
    });
  };
  const onChangeJournalRules = (items, prevItems) => {
    setJournalRules(items);
  };

  return (
    <Modal isOpen={isOpen} style={{ minWidth: 800 }}>
      <ModalHeader>
        カスタムルール{isNew ? '追加' : '編集'}
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          <Field name="ruleType" {...statedFields.ruleType} />
          <Field name="dimension" {...statedFields.dimension} />
          <Field name="name" {...statedFields.name} />
          <Field name="message" {...statedFields.message} />
          <div>
            <label>ルール</label>
            {
              ({
                journal: (
                  <ListForm
                    items={journalRules}
                    renderItem={(item, itemIndex) => {
                      return (
                        <div className="card p-3">
                          <JournalRuleForm
                            journalRules={journalRules}
                            index={itemIndex}
                            values={item}
                            ruleType={statedFields.ruleType.value}
                            onChange={_ => updateJournalRuleAt(itemIndex, { ...item, ..._, })}
                            {...{ company, accountItems, taxes, partners, freeeItems, sections, segment1s, segment2s, segment3s, }}
                          />
                        </div>
                      );
                    }}
                    onChange={_ => setJournalRules(_)}
                    minItems={1}
                  />
                ),
                balance: (
                  <ListForm
                    items={balanceRules}
                    renderItem={(item, itemIndex) => {
                      return (
                        <div className="card p-3">
                          <RuleForm
                            company={company}
                            balanceRules={balanceRules}
                            index={itemIndex}
                            values={item}
                            ruleType={statedFields.ruleType.value}
                            onChange={_ => updateBalanceRuleAt(itemIndex, { ...item, ..._, })}
                          />
                        </div>
                      );
                    }}
                    onChange={_ => setBalanceRules(_)}
                    minItems={1}
                  />
                ),
                noneDimensionBalance: (
                  <ListForm
                    items={noneDimensionBalanceRules}
                    renderItem={(item, itemIndex) => {
                      return (
                        <div className="card p-3">
                          <RuleForm
                            noneDimensionBalanceRules={noneDimensionBalanceRules}
                            index={itemIndex}
                            values={item}
                            ruleType={statedFields.ruleType.value}
                            dimension={statedFields.dimension.value}
                            onChange={_ => updateNoneDimensionBalanceRuleAt(itemIndex, { ...item, ..._, })}
                            {...{ company, accountItems, partners, freeeItems, sections, segment1s, segment2s, segment3s, }}
                          />
                        </div>
                      );
                    }}
                    onChange={_ => setNoneDimensionBalanceRules(_)}
                    minItems={1}
                  />
                )
              })[statedFields.ruleType.value]
            }
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>保存</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
