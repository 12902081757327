import React, { Component } from 'react';
import { FormGroup, Label, Input, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { toast } from 'react-toastify';
import { orderBy } from 'lodash';

import firebase from '../firebase';
import Comment from './Comment';

const db = firebase.firestore();
const companiesRef = db.collection('companies');

export default class CommentsDropdown extends Component {
  constructor() {
    super();
    this.state = {};
  }
  toggleDropdown = (key) => {
    this.setState({ shouldShowDropdown: !this.state.shouldShowDropdown });
  }
  onClickDelete = async (id) => {
    if(!window.confirm('本当に削除しますか？')) return;
    const { companyId } = this.props;
    await companiesRef.doc(companyId).collection('comments').doc(id).delete();
    toast.success('削除しました');
  }
  onCheck = (name, { target: { checked: value } }) => this.setState({ [name]: value })
  render() {
    const { company, companyId, currentUser, comments = [], usersById, users, onClickComment, className, } = this.props;
    const { shouldShowDropdown = false, showsAll = false } = this.state;
    const filteredComments = showsAll ? comments : comments.filter(_ => !_.done);
    const sortedComments = orderBy(filteredComments, _ => _.createdAt.toDate(), 'desc');

    return (
      <div className={className}>
        <Dropdown isOpen={shouldShowDropdown} toggle={this.toggleDropdown}>
          <DropdownToggle>
            <span className="fas fa-comment" />
          </DropdownToggle>
          <DropdownMenu right tabIndex="">
            <div className="p-2" style={{ minWidth: 450, }}>
              <div className="mb-1">
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" onChange={this.onCheck.bind(this, 'showsAll')} checked={showsAll} />
                    完了済みも表示する
                  </Label>
                </FormGroup>
              </div>
              <div style={{ maxHeight: 500, overflow: 'scroll' }}>
                {
                  filteredComments.length > 0 ? sortedComments.map((comment) => {
                    const { id, commenteeKey } = comment;
                    return (
                      <div key={id} onClick={onClickComment.bind(null, commenteeKey)}>
                        {commenteeKey}
                        <Comment {...{ ...comment, company, companyId, commentId: id, currentUser, usersById, users, }} />
                      </div>
                    );
                  }) : (
                    <div>コメントはありません</div>
                  )
                }
              </div>
            </div>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
};
