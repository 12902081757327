import { useState, useEffect } from 'react';

export default function useCollectionSubscription (ref, dependencies = [], { initialValue = [], mapDoc, refreshDisabled = false, } = {}) {
  const [items, setItems] = useState(initialValue);
  useEffect(() => {
    if(!refreshDisabled) setItems([]);
    if(!ref) return;

    const unsubscribe = ref
      .onSnapshot(({ docs }) => {
        setItems(docs.map(_ => mapDoc ? mapDoc(_) : ({ id: _.id, ..._.data(), ref: _.ref, })));
      }, e => console.error(e, ref));
    return unsubscribe;
  }, dependencies);
  return items;
};
