const { invert, omit, pickBy, flatten, isDate, isEmpty, get, } = require('lodash');
const numeral = require('numeral');

const { directions } = require('../config');
const { computeCreditBalance, } = require('./customPartner');
const { usedSegments, dimensionsOfCompany, } = require('./company');

const isNumeric = value => !Number.isNaN(parseFloat(value, 10));
const directionsByName = invert(directions);

const { entries } = Object;
const ruleTypes = {
  journal: {
    label: '仕訳',
  },
  balance: {
    label: '残高(与信)',
  },
  noneDimensionBalance: {
    label: '残高',
  },
};
const subjects = {
  balanceAccountItem: {
    ruleTypes: ['noneDimensionBalance'],
    label: '科目',
    value: _ => _.account_item_name,
    valueType: 'multiSelect',
    valueToLabel: _ => _.join(','),
    labelToValue: _ => _.split(','),
  },
  balanceAmount: {
    ruleTypes: ['noneDimensionBalance'],
    label: '残高',
    value: _ => _.closing_balance,
    valueType: 'integer',
    valueToLabel: _ => _,
    labelToValue: _ => numeral(_).value(),
  },
  balanceDimensionAmount: {
    ruleTypes: ['noneDimensionBalance'],
    label: '明細残高',
    value: _ => _.closing_balance,
    valueType: 'integer',
    valueToLabel: _ => _,
    labelToValue: _ => numeral(_).value(),
  },
  direction: {
    ruleTypes: ['journal'],
    label: '貸借',
    value: _ => _.direction,
    valueType: 'multiSelect',
    valueToLabel: _ => _.map(_ => directions[_]).join(','),
    labelToValue: _ => _.split(',').map(_ => directionsByName[_]),
  },
  accountItem: {
    ruleTypes: ['journal'],
    label: '科目',
    value: _ => _.accountName,
    valueType: 'multiSelect',
    valueToLabel: _ => _.join(','),
    labelToValue: _ => _.split(','),
  },
  amount: {
    ruleTypes: ['journal'],
    label: '金額',
    value: _ => _.amount,
    valueType: 'integer',
    valueToLabel: _ => _,
    labelToValue: _ => numeral(_).value(),
  },
  tax: {
    ruleTypes: ['journal'],
    label: '税区分',
    value: (_, { taxesByName }) => taxesByName[_.source[`${directions[_.direction]}税区分`]].code.toString(),
    valueType: 'multiSelect',
    valueToLabel: (_, { taxesById }) => _.map(_ => taxesById[_]?.name_ja).join(','),
    labelToValue: (_, { taxesByName }) => _.split(',').map(_ => taxesByName[_]?.code.toString()),
  },
  route: {
    ruleTypes: ['journal'],
    label: '登録した方法',
    value: _ => _.source[`登録した方法`],
    valueType: 'multiSelect',
    valueToLabel: _ => _.join(','),
    labelToValue: _ => _.split(','),
  },
  partner: {
    ruleTypes: ['journal', 'noneDimensionBalance'],
    label: '取引先',
    // NOTE: 名称にドットがある場合があるため、get使えない。
    value: (_, { partnersByName }) => (partnersByName[_.partners] || {}).id,
    valueType: 'multiSelect',
    dimension: 'partners',
    valueToLabel: (_, { partnersById }) => _.map(_ => partnersById[_]?.name).join(','),
    labelToValue: (_, { partnersByName }) => _.split(',').map(_ => partnersByName[_]?.id),
  },
  item: {
    ruleTypes: ['journal', 'noneDimensionBalance'],
    label: '品目',
    value: (_, { freeeItemsByName }) => (freeeItemsByName[_.items] || {}).id,
    valueType: 'multiSelect',
    dimension: 'items',
    valueToLabel: (_, { freeeItemsById }) => _.map(_ => freeeItemsById[_]?.name).join(','),
    labelToValue: (_, { freeeItemsByName }) => _.split(',').map(_ => freeeItemsByName[_]?.id),
  },
  section: {
    ruleTypes: ['journal', 'noneDimensionBalance'],
    label: '部門',
    value: (_, { sectionsByName }) => (sectionsByName[_.sections] || {}).id,
    valueType: 'multiSelect',
    dimension: 'sections',
    valueToLabel: (_, { sectionsById }) => _.map(_ => sectionsById[_]?.name).join(','),
    labelToValue: (_, { sectionsByName }) => _.split(',').map(_ => sectionsByName[_]?.id),
  },
  segment1: {
    ruleTypes: ['journal', 'noneDimensionBalance'],
    label: 'セグメント1',
    // NOTE: 名称にドットがある場合があるため、get使えない。
    value: (_, { segment1sByName }) => (segment1sByName[_.segment1s || ''] || {}).id,
    valueType: 'multiSelect',
    dimension: 'segment1s',
    valueToLabel: (_, { segment1sById }) => _.map(_ => segment1sById[_]?.name).join(','),
    labelToValue: (_, { segment1sByName }) => _.split(',').map(_ => segment1sByName[_]?.id),
  },
  segment2: {
    ruleTypes: ['journal', 'noneDimensionBalance'],
    label: 'セグメント2',
    // NOTE: 名称にドットがある場合があるため、get使えない。
    value: (_, { segment2sByName }) => (segment2sByName[_.segment2s || ''] || {}).id,
    valueType: 'multiSelect',
    dimension: 'segment2s',
    valueToLabel: (_, { segment2sById }) => _.map(_ => segment2sById[_]?.name).join(','),
    labelToValue: (_, { segment2sByName }) => _.split(',').map(_ => segment2sByName[_]?.id),
  },
  segment3: {
    ruleTypes: ['journal', 'noneDimensionBalance'],
    label: 'セグメント3',
    // NOTE: 名称にドットがある場合があるため、get使えない。
    value: (_, { segment3sByName }) => (segment3sByName[_.segment3s || ''] || {}).id,
    valueType: 'multiSelect',
    dimension: 'segment3s',
    valueToLabel: (_, { segment3sById }) => _.map(_ => segment3sById[_]?.name).join(','),
    labelToValue: (_, { segment3sByName }) => _.split(',').map(_ => segment3sByName[_]?.id),
  },
  summary: {
    ruleTypes: ['journal'],
    label: '摘要',
    value: _ => _.abstract,
    valueType: 'creatableMultiSelect',
    valueToLabel: _ => _.join(','),
    labelToValue: _ => _.split(','),
  },
  creditBalance: {
    ruleTypes: ['balance'],
    label: '与信残高',
    value: (_, { creditBalance, }) => creditBalance,
    valueType: 'integer',
    valueToLabel: _ => _,
    labelToValue: _ => numeral(_).value(),
  },
  creditBalanceRate: {
    ruleTypes: ['balance'],
    label: '与信残高割合',
    value: (_, { customPartner, creditBalance, }) => creditBalance / customPartner.creditLimit,
    valueType: 'float',
    valueToLabel: _ => _,
    labelToValue: _ => numeral(_).value(),
  },
};
const valueTypes = {
  multiSelect: {
    formatForCsv: _ => _.join(','),
    parseForCsv: _ => _.split(',').map(_ => isNumeric(_) ? numeral(_).value() : _),
  },
  integer: {
    formatForCsv: _ => _,
    parseForCsv: _ => numeral(_).value(),
  },
  float: {
    formatForCsv: _ => _,
    parseForCsv: _ => numeral(_).value(),
  },
  creatableMultiSelect: {
    formatForCsv: _ => _.join(','),
    parseForCsv: _ => _.split(',').map(_ => isNumeric(_) ? numeral(_).value() : _),
  },
};
const equalityOperators = {
  someEquals: {
    label: '一致する',
    operator: (s, v) => v.map(_ => _.toString()).includes(s?.toString()),
    type: 'some',
  },
  everyUnequals: {
    label: '一致しない',
    operator: (s, v) => !v.map(_ => _.toString()).includes(s?.toString()),
    type: 'every',
  },
};
const inclusionOperators = {
  includes: {
    label: '含む',
    operator: (s, v) => v.some(_ => s.match(_)),
    type: 'some',
  },
  excludes: {
    label: '含まない',
    operator: (s, v) => v.every(_ => !s.match(_)),
    type: 'every',
  },
};
const numberOperators = {
  greaterThan: {
    label: 'より大きい',
    operator: (s, v) => s > v,
    type: 'some',
  },
  greaterThanOrEquals: {
    label: '以上',
    operator: (s, v) => s >= v,
    type: 'some',
  },
  lessThan: {
    label: 'より小さい',
    operator: (s, v) => s < v,
    type: 'some',
  },
  lessThanOrEquals: {
    label: '以下',
    operator: (s, v) => s <= v,
    type: 'some',
  },
  equals: {
    label: '同じ',
    operator: (s, v) => s === v,
    type: 'some',
  },
  otherThan: {
    label: '以外',
    operator: (s, v) => s !== v,
    type: 'some',
  },
};
const operators = {
  ...equalityOperators,
  ...inclusionOperators,
  ...numberOperators,
};
const journalRuleTypes = {
  some: { label: '仕訳内のいずれかの行がルールを満たす【行単位ルール】' },
  every: { label: '仕訳内のすべての行がルールを満たす【仕訳単位ルール】' },
};

module.exports = {
  fields: ({ company, } = {}) => {
    return {
      ruleType: {
        type: 'select',
        label: '種別',
        options: entries(ruleTypes).map(([k, { label }]) => ({ label, value: k })),
        validations: {
          required: v => !isEmpty(v),
        },
      },
      dimension: {
        type: 'select',
        label: '明細種別',
        options: entries(dimensionsOfCompany(company)).map(([k, v]) => ({ label: v, value: k })),
        hidden: _ => _.ruleType !== 'noneDimensionBalance',
        initialValue: 'none',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      name: {
        type: 'string',
        label: 'ルール名',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      message: {
        type: 'string',
        label: 'メッセージ',
        validations: {
          required: v => !isEmpty(v),
        },
      },
    };
  },
  journalRuleFields: () => {
    return {
      journalRuleType: {
        type: 'select',
        options: entries(journalRuleTypes).map(([k, { label }]) => ({ label, value: k })),
        validations: {
          required: v => !isEmpty(v),
        },
        initialValue: 'some',
      },
    };
  },
  ruleFields: ({ company, ruleType, dimension, } = {}) => {
    let filteredSubjects = omit(
      pickBy(subjects, _ => _.ruleTypes.includes(ruleType)),
      [...(!usedSegments(company).includes(1) ? ['segment1'] : []), ...(!usedSegments(company).includes(2) ? ['segment2', 'segment3'] : [])]
    );
    if(ruleType === 'noneDimensionBalance') {
      filteredSubjects = pickBy(filteredSubjects, (v, k) => {
        return v.dimension == null || v.dimension === dimension;
      });
      if(dimension === 'none') {
        filteredSubjects = omit(filteredSubjects, 'balanceDimensionAmount');
      } else {
        filteredSubjects = omit(filteredSubjects, 'balanceAmount');
      }
    }
    return {
      subject: {
        type: 'select',
        options: entries(filteredSubjects).map(([k, { label }]) => ({ label, value: k })),
        validations: {
          required: v => !isEmpty(v),
        },
      },
      operator: {
        type: 'select',
        options: [],
        validations: {
          required: v => !isEmpty(v),
        },
      },
      value: {
        validations: {
          required: v => v != null && (v.length != null ? !isEmpty(v) : true),
        },
      },
    };
  },
  ruleTypes,
  subjects,
  equalityOperators,
  inclusionOperators,
  numberOperators,
  operators,
  journalRuleTypes,
  valueTypes,
};
