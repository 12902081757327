const { isEmpty } = require('lodash');

const { journalTags, } = require('../config');

const { keys, entries } = Object;
const checkTargets = {
  debitJournals: {
    label: '仕訳(借方)',
  },
  creditJournals: {
    label: '仕訳(貸方)',
  },
  minusBalance: {
    label: 'マイナス残高',
  },
};

const fields = ({ displayJournalTags, availableTaxes, } ) => {
  return {
    journalsCheckDimensions: {
      type: 'multiSelect',
      label: 'チェックタグ',
      options: entries(displayJournalTags).map(([k, v]) => ({ label: v, value: k, })),
      initialValue: keys(displayJournalTags),
    },
    ...entries(journalTags).reduce((x, [k, v]) => {
      return {
        ...x,
        [`${k}JournalCheckTargets`]: {
          type: 'multiSelect',
          label: `${v}チェック対象`,
          options: entries(checkTargets).map(([k, v]) => ({ label: v.label, value: k, })),
          hidden: _ => !_.journalsCheckDimensions?.includes(k),
          initialValue: keys(checkTargets),
        },
      };
    }, {}),
    defaultDebitTaxes: {
      type: 'multiSelect',
      label: '借方デフォルト税区分',
      options: availableTaxes.map(_ => ({ label: _.name_ja, value: _.name_ja, })),
      initialValue: availableTaxes.map(_ => _.name_ja),
    },
    defaultCreditTaxes: {
      type: 'multiSelect',
      label: '貸方デフォルト税区分',
      options: availableTaxes.map(_ => ({ label: _.name_ja, value: _.name_ja, })),
      initialValue: availableTaxes.map(_ => _.name_ja),
    },
    note: {
      label: 'メモ',
      type: 'text',
    },
  };
};

module.exports = {
  fields,
  checkTargets,
};
