import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, } from 'reactstrap';
import { isEmpty, mapValues } from 'lodash';

import useFormState from '../hooks/useFormState';
import { fields } from '../../shared/models/customSection';
import Field from '../Field';

const { entries } = Object;

export default function CustomSectionFormModal(props) {
  const { isOpen, values, freeeSections = [], customSections = [], companyMembers = [], onClickClose } = props;
  const isNew = !values;
  const statedFields = useFormState(values, fields({ freeeSections, customSections, companyMembers, }), isOpen);
  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const onSubmit = (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;
    const fieldValues = mapValues(statedFields, 'value');
    props.onSubmit({ ...fieldValues, });
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        カスタム部門{isNew ? '追加' : '編集'}
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          <ModalBody>
            {
              entries(statedFields).map(([fieldName, fieldSetting]) => (
                <Field
                  key={fieldName}
                  name={fieldName}
                  {...fieldSetting}
                />
              ))
            }
          </ModalBody>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>保存</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
