import { useState, useEffect } from 'react';

export default function useDocumentSubscription (ref, dependencies = [], options = {}) {
  const [item, setItem] = useState();
  useEffect(() => {
    if(!ref) return;

    const unsubscribe = ref
      .onSnapshot((doc) => {
        if(options.strict && !doc.exists) return setItem(null);

        setItem({ id: doc.id, ref: doc.ref, exists: doc.exists, ...doc.data() });
      });
    return unsubscribe;
  }, dependencies);
  return item;
};
