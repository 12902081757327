import { useState, useEffect } from 'react';

export default function useCollectionFetch (ref, dependencies = [], { initialItems = [] } = {}) {
  const [items, setItems] = useState(initialItems);
  useEffect(() => {
    if(!ref) return;

    ref
      .get()
      .then(({ docs }) => {
        setItems(docs.map(_ => ({ id: _.id, ..._.data(), ref: _.ref, })));
      });
  }, dependencies);
  return items;
};
