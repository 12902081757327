import React, { Component, useState, useRef, useEffect, } from 'react';
import { Button } from 'reactstrap';
import { isEmpty, get, pick, omit, last, groupBy, sortBy, keyBy } from 'lodash';
import qs from 'qs';
import { format as formatDate } from 'date-fns';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { useNetworkState, useToggle, useAsync, usePrevious, } from 'react-use';
import { toast, } from 'react-toastify';
import ipRangeCheck from 'ip-range-check';

import { fetchIp, } from '../../utils';
import { leftTrialDays as _leftTrialDays } from '../../shared/util';
import { isTrialExpired } from '../../shared/models/company';
import firebase, { functions } from '../../firebase';
import { getRole } from '../../shared/models/user';
import { accountCategories } from '../../shared/config';
import { canReadAccountItemAuditorSetting, canWriteCustomSection, } from '../../abilities';
import { periodOfFiscalYear, fiscalYearOfPeriod, fullPathWithParams } from '../../utils';
import HeaderNav from '../HeaderNav';
import HelpLink from '../HelpLink';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useQueryParams from '../hooks/useQueryParams';

const { keys } = Object;
const db = firebase.firestore();
const usersRef = db.collection('users');
const companiesRef = db.collection('companies');
const getCompany = functions.httpsCallable('getCompany');
const periodFromSearch = (search) => {
  const { period } = qs.parse(search.slice(1), { arrayLimit: Infinity });
  return parseInt(period, 10);
};
const targetMonthFromSearch = (search) => {
  const { targetMonth } = qs.parse(search.slice(1), { arrayLimit: Infinity });
  return parseInt(targetMonth, 10);
};

export default function CompanyPublicPageHOC(WrappedComponent) {
  return function CompanyPublicPage (props) {
    const { history, location, user, } = props;
    const queryParams = useQueryParams();
    const { 
      subjectType,
      publicKey,
      period: periodString,
      targetMonth: targetMonthString,
    } = queryParams;
    const period = periodString && parseInt(periodString, 10);
    const prevPeriod = usePrevious(period);
    const targetMonth = targetMonthString && parseInt(targetMonthString, 10);
    const headerNavRef = useRef(null);
    const { value: company } = useAsync(async () => {
      const { data: company } = await getCompany({ subjectType, publicKey, });
      return { ...company, createdAt: { ...company.createdAt, toDate: _ => new Date(company.createdAt._seconds * 1000), } };
    }, [subjectType, publicKey]);
    useEffect(() => {
      if(period) return;

      const newPeriod = period || periodOfFiscalYear(last(company?.fiscalYears));
      if(!newPeriod) return;

      const path = fullPathWithParams({ period: newPeriod, }, location);
      history.replace(encodeURI(path));
    }, [company]);
    useEffect(() => {
      if(prevPeriod !== period || !targetMonth) {
        const fiscalYear = fiscalYearOfPeriod(period, company?.fiscalYears);
        const newTargetMonth = fiscalYear && parseInt(formatDate(fiscalYear.end_date, 'YYYYMM'), 10);
        if(newTargetMonth) {
          const path = fullPathWithParams({ targetMonth: newTargetMonth, }, location);
          history.replace(encodeURI(path));
        }
      }
    }, [company, period]);

    return company != null && !company.disablesBudgetsShare && period != null && (
      <div>
        <HeaderNav ref={headerNavRef} isPublic {...{ ...props, user, company, period, targetMonth, }} />
        {
          !isTrialExpired(company) && (
            <WrappedComponent {...{
              ...props,
              user,
              company,
              period,
              targetMonth,
            }}
            />
          )
        }
      </div>
    );
  };
}
